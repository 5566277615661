import { Build } from "@state/services/types"

import { generateVacationAccumulationCancelURL } from "../urls"

export const cancelVacationAccumulation = (build: Build) =>
  build.mutation<void, string>({
    query: id => ({
      url: generateVacationAccumulationCancelURL(id),
      method: "PUT"
    }),
    invalidatesTags: ["VacationAccumulation", "Balance"]
  })
