import backendApi from "@state/services/backendApi"

import { fetchSlackGroups } from "./GET/fetchSlackGroups"
import { fetchSlackGroup } from "./GET/fetchSlackGroup"
import { createSlackGroup } from "./POST/createSlackGroup"
import { updateSlackGroup } from "./PUT/updateSlackGroup"
import { deleteSlackGroup } from "./DELETE/deleteSlackGroup"

export const slackGroupsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchSlackGroups: fetchSlackGroups(build),
    fetchSlackGroup: fetchSlackGroup(build),
    createSlackGroup: createSlackGroup(build),
    updateSlackGroup: updateSlackGroup(build),
    deleteSlackGroup: deleteSlackGroup(build)
  })
})
