import * as yup from "yup"

import { validationErrors } from "../validationErrors"

export const relationshipSchema = yup.object().shape({
  chief: yup.object().nullable().required(validationErrors.REQUIRED_ERROR),
  chiefRole: yup.string().required(validationErrors.REQUIRED_ERROR),
  subordinate: yup.object().nullable().required(validationErrors.REQUIRED_ERROR),
  subordinateRole: yup.string().required(validationErrors.REQUIRED_ERROR)
})
