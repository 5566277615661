import { PersonalDocumentUpdateData } from "@interfaces"
import { Build } from "@state/services/types"

import { generatePersonalDocumentURL } from "../urls"

export const updatePersonalDocument = (build: Build) =>
  build.mutation<unknown, PersonalDocumentUpdateData>({
    query: ({ id, ...attributes }) => ({
      url: generatePersonalDocumentURL(id),
      method: "PUT",
      body: {
        data: {
          id,
          type: "personal_documents",
          attributes
        }
      }
    }),
    invalidatesTags: ["PersonalDocument"]
  })
