import { Build } from "@state/services/types"
import { ProfileDataFromBackend, UserByIdParams } from "@state/types/users"

import { generateGetUserByIdURL } from "../urls"
import { Profile, userProfileTransformer } from "../transformer"

export const fetchUserById = (build: Build) =>
  build.query<Profile, UserByIdParams>({
    query: params => ({ url: generateGetUserByIdURL(String(params.userId), params.includes) }),
    providesTags: ["Role", "User", "EmploymentSettings"],
    transformResponse: (response: ProfileDataFromBackend) => userProfileTransformer(response as ProfileDataFromBackend)
  })
