import React, { FC, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "@hooks"
import Tree from "react-d3-tree"
import { orgChartActions } from "@state/reducers/OrgChart"
import { orgChartApi, usersApi } from "@state/services/subApis"
import { UserForFilter } from "@interfaces*"
import { generateFullName } from "@helpers"
import { Button, Flex, Spin } from "antd"
import { Point } from "react-d3-tree/lib/types/common"

import { DebounceSelect } from "@components/DebounceSelect"

import style from "./style.m.scss"
import { useCenteredTree } from "./helpers/useCenteredTree"
import useOrgChartSvgNode from "./SvgNode"
import { ZoomChanger } from "./ZoomChanger"
import { OrgChartScrolls } from "./Scrolls"
import { DIVIDER } from "./helpers/constants"

const convertOptions = (users?: Array<UserForFilter>) =>
  users
    ? users.map(option => ({
        label: generateFullName(option.name.split(" ")[1], option.name.split(" ")[0]),
        value: option.id
      }))
    : []

const OrgChart: FC = () => {
  const dispatch = useAppDispatch()
  const tree = useAppSelector(state => state.orgChartReducer.currentTree)
  const currentUserId = useAppSelector(state => state.meReducer.user?.id)
  const [searchingUserId, setSearchingUserId] = useState<string | null>(null)

  const { orgChartPosition, isLoading } = orgChartApi.endpoints.fetchTree.useQuery("", {
    selectFromResult: ({ data, isLoading }) => ({
      orgChartPosition: data || [],
      isLoading
    })
  })

  const [fetchOptions, { isFetching }] = usersApi.endpoints.fetchUsersWithoutPosition.useLazyQuery()

  useEffect(() => {
    if (!isLoading) {
      dispatch(orgChartActions.setPositions({ positions: orgChartPosition }))
      dispatch(orgChartActions.setStartedTree({ currentUserId: String(currentUserId) }))
    }
  }, [orgChartPosition, currentUserId, isLoading])

  const { treeRef, containerRef, translate, zoom, setCoords, setZoom, setTranslate } = useCenteredTree(tree)

  const OrgChartSvgNode = useOrgChartSvgNode(setCoords)

  const optionsFetcher = (searchValue: string) =>
    fetchOptions({
      only_appointed: "1",
      search_string: searchValue
    })

  const handleSearchUser = () => {
    if (!searchingUserId) return
    dispatch(orgChartActions.setStartedTree({ currentUserId: searchingUserId }))
    setSearchingUserId(null)
  }

  const handleChangeUser = (userId: string | null) => {
    setSearchingUserId(userId)
  }

  const handleTreeUpdate = ({ zoom: changedZoom }: { zoom: number; translate: Point }) => {
    const zoomInIntegers = Math.round(changedZoom * DIVIDER)

    setZoom(zoomInIntegers)
  }

  return (
    <div className={style.root} draggable={false} ref={containerRef}>
      <div className={style.searchWrapper}>
        <DebounceSelect
          value={searchingUserId}
          optionsFetcher={optionsFetcher}
          onChange={handleChangeUser}
          isFetching={isFetching}
          convertOptions={convertOptions}
        >
          <Button type="primary" onClick={handleSearchUser} disabled={!searchingUserId}>
            Search
          </Button>
        </DebounceSelect>
      </div>
      <ZoomChanger zoom={zoom} setZoom={setZoom} />
      <OrgChartScrolls coords={translate} rootClassName={style.root} setTranslate={setTranslate} />
      {Object.keys(tree).length ? (
        <Tree
          ref={treeRef}
          data={tree}
          collapsible={false}
          translate={translate}
          zoom={zoom / DIVIDER}
          zoomable={false}
          nodeSize={{ x: 380, y: 800 }}
          pathFunc="step"
          depthFactor={800}
          orientation="vertical"
          renderCustomNodeElement={OrgChartSvgNode}
          pathClassFunc={() => style.customDarkLink}
          rootNodeClassName={style.node}
          branchNodeClassName={style.node}
          leafNodeClassName={style.node}
          onUpdate={handleTreeUpdate}
        />
      ) : (
        <Flex className={style.loader} justify="center" align="center">
          <Spin size="large" />
        </Flex>
      )}
    </div>
  )
}

export default OrgChart
