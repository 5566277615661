import React, { FC } from "react"
import { Button, ButtonProps, Tooltip, Upload } from "antd"
import { RcFile } from "antd/es/upload"
import { CameraOutlined } from "@ant-design/icons"
import ImgCrop from "antd-img-crop"

import { ASPECT_RATIO, SUPPORTED_FILES } from "../constants"

type PhotoButtonProps = Omit<ButtonProps, "icon" | "onChange"> & {
  onChange: (file: File) => void
  className?: string
}

export const PhotoButton: FC<PhotoButtonProps> = ({ onChange, className }) => {
  const handleBeforeUpload = (file: RcFile) => {
    if (file) {
      onChange(file)
    }
    return false
  }

  return (
    <div className={className}>
      <Tooltip
        title={
          <>
            <span>Upload new photo</span>
            <br />
            <span>max allowed size: 2 MB</span>
          </>
        }
      >
        <ImgCrop aspect={ASPECT_RATIO} quality={1}>
          <Upload showUploadList={false} maxCount={1} accept={SUPPORTED_FILES} beforeUpload={handleBeforeUpload}>
            <Button icon={<CameraOutlined />} type="default" data-testid="change-photo-button" />
          </Upload>
        </ImgCrop>
      </Tooltip>
    </div>
  )
}
