import React, { FC } from "react"
import { Form } from "antd"
import { digitsRule } from "@helpers"

import { FormField } from "@components/FormField"
import { SaveButton } from "@components/buttons/SaveButton"
import { CancelButton } from "@components/buttons/CancelButton"
import { ValidatedTextInput } from "@components/ValidatedInputs/ValidatedTextInput"

type FormFields = { phone: string }

type PhoneFormProps = {
  value?: string
  onSave: (value: string) => void
  onCancel: () => void
}

export const PhoneForm: FC<PhoneFormProps> = ({ value, onSave, onCancel }) => {
  const [form] = Form.useForm<FormFields>()

  const handleFormSubmit = (formFields: FormFields) => {
    onSave(formFields.phone)
  }

  return (
    <Form form={form} onFinish={handleFormSubmit} initialValues={{ phone: value || "" }}>
      <FormField
        label="Phone number"
        fieldName="phone"
        rules={[digitsRule]}
        editor={<ValidatedTextInput prefix="+" data-testid="phone-input" />}
        actions={
          <>
            <SaveButton htmlType="submit" data-testid="save-phone" />
            <CancelButton onClick={onCancel} data-testid="cancel-save-phone" />
          </>
        }
      />
    </Form>
  )
}
