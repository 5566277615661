import { NewPersonalDocument, PersonalDocumentsResponse } from "@interfaces"
import { BackendPersonalDocument, BackendPersonalDocumentsResponse } from "@state/types/personalDocuments"

const derivePersonalDocument = (doc: BackendPersonalDocument) => ({
  id: doc.id,
  fileName: doc.name,
  userId: doc.user_id.toString(),
  status: doc.status,
  createdAt: doc.created_at,
  createdBy: doc.created_by,
  comment: doc.comment,
  closedAt: doc.closed_at || ""
})

export const personalDocumentsTransformer = (
  backendResponse: BackendPersonalDocumentsResponse
): PersonalDocumentsResponse => {
  const currentDocuments = backendResponse.current.data.map(derivePersonalDocument)

  const archivedDocuments = Object.entries(backendResponse.archived.data).map(([location, docs]) => ({
    location,
    documents: docs.map(derivePersonalDocument)
  }))

  return { currentDocuments, archivedDocuments }
}

export const deriveCreateParams = (data: NewPersonalDocument) => {
  const { userId, file } = data

  const formData = new FormData()
  formData.append("data[user_id]", userId)
  formData.append("data[document_attributes][file]", file)

  return formData
}
