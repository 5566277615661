import * as yup from "yup"

import { validationErrors } from "../validationErrors"
import { passwordReg } from "../validationRegex"

export const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .matches(passwordReg, validationErrors.VALID_PASSWORD_ERROR)
    .required(validationErrors.REQUIRED_ERROR),
  new_password: yup
    .string()
    .matches(passwordReg, validationErrors.VALID_PASSWORD_ERROR)
    .required(validationErrors.REQUIRED_ERROR),
  password_confirm: yup.string().oneOf([yup.ref("new_password"), null], validationErrors.VALID_CONFIRM_PASSWORD_ERROR)
})
