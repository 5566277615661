import { Option } from "@interfaces"
import { Build } from "@state/services/types"

import { DATA_STORAGE_OPTIONS_URL } from "../urls"

export const fetchDataStorageOptions = (build: Build) =>
  build.query<{ s3: Array<Option>; short_names: Array<Option> }, string>({
    query: () => ({
      url: DATA_STORAGE_OPTIONS_URL
    }),
    providesTags: res => (res ? res.s3.map(option => ({ type: "Location" as const, id: option.value })) : ["Location"])
  })
