import React, { FC, useState } from "react"
import { useAlert, useProfileHandlers } from "@hooks"

import { EditButton } from "@components/buttons/EditButton"
import { Field } from "@components/Field"

import { PhoneForm } from "./PhoneForm"
import style from "./style.m.scss"

type EditablePhoneProps = {
  value?: string
}

export const EditablePhone: FC<EditablePhoneProps> = ({ value }) => {
  const [isEditMode, setIsEditMode] = useState(false)

  const { saveUserProfile } = useProfileHandlers()
  const { success } = useAlert()

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSave = async (value: string) => {
    const result = await saveUserProfile({
      phone_number: value || null
    })

    if (result) {
      setIsEditMode(false)
      success("Phone has been saved successfully")
    }
  }

  const handleCancel = () => {
    setIsEditMode(false)
  }

  return isEditMode ? (
    <PhoneForm value={value} onSave={handleSave} onCancel={handleCancel} />
  ) : (
    <Field
      title="Phone number"
      value={value ? `+ ${value}` : ""}
      actions={<EditButton onClick={handleEditClick} className={style.editButton} data-testid="edit-phone" />}
      data-testid="phone"
    />
  )
}
