import { useAlert } from "@hooks"
import { ErrorResponse, PlanningDates, TLocationFormFields } from "@interfaces"
import { vacationsSettingsApi } from "@state/services/subApis"
import dayjs, { Dayjs } from "dayjs"

export const useSettingsCreate = () => {
  const { success, error } = useAlert()

  const [createVacationSetting] = vacationsSettingsApi.endpoints.createVacationSettings.useMutation()

  return (location: number, settings: TLocationFormFields, quotas: Array<[string, number, string]>) => {
    const requestQuotas = Object.fromEntries(quotas.map(([, value, category]) => [category, value]))

    createVacationSetting({
      setting: {
        location_id: location,
        expiration: settings?.expiration ? dayjs(settings?.expiration_date as Dayjs).format("YYYY-MM-DD") : null,
        leave_type: settings?.leave_type as string,
        tooltip: settings?.tooltip as string,
        workdays: settings?.workdays as Array<string>,
        planning_start_date: (settings.planning_dates as PlanningDates).planning_start_date,
        planning_end_date: (settings.planning_dates as PlanningDates).planning_end_date,
        quota: requestQuotas
      }
    }).then(res => {
      if ("data" in res) success("Settings created successfully")
      if ("error" in res) {
        const { data } = res.error as ErrorResponse
        error(data.errors.join("\n"))
      }
    })
  }
}
