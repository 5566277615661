import React, { FC } from "react"
import { useAppSelector, useProfileLocation } from "@hooks"
import { useParams } from "react-router-dom"
import { useJobHistoryAbilities, useSalariesAbilities } from "hooks/query/useAbilities"
import { positionsApi, salariesApi } from "@state/services/subApis"

import { ViewPanel } from "./ViewPanel"

const JobHistory: FC = () => {
  const { isMyself } = useProfileLocation()
  const { read_all: readSalaries } = useSalariesAbilities()
  const { read_all: readJobHistory } = useJobHistoryAbilities()

  const { myId, isChief } = useAppSelector(state => ({
    myId: state.meReducer.user?.id,
    isChief: state.meReducer.user?.attributes.is_chief
  }))

  const { userId } = useParams()

  const id = isMyself ? String(myId) : String(userId)

  const isJobHistoryWatchable = isMyself || isChief || !!readJobHistory
  const isSalariesWatchable = isMyself || isChief || !!readSalaries

  const { data: jobHistory } = positionsApi.endpoints.fetchUserPositionChanges.useQuery(id, {
    skip: !isJobHistoryWatchable
  })

  const { data: salariesHistory } = salariesApi.endpoints.fetchUserSalariesChanges.useQuery(id, {
    skip: !isSalariesWatchable
  })

  return <ViewPanel jobHistory={jobHistory} salariesHistory={salariesHistory} userId={id} />
}

export default JobHistory
