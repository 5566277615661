import React, { FC } from "react"
import { Leave } from "@interfaces"

import style from "./style.m.scss"

type LeaveInfoProps = {
  leave: Leave
}

export const LeaveInfo: FC<LeaveInfoProps> = ({ leave }) => (
  <div className={style.leave}>
    <span>
      {leave.startedAt} - {leave.finishedAt}
    </span>
    <span>
      {leave.daysCount} day{leave.daysCount > 1 && "s"}
    </span>
  </div>
)
