import { capitalizeFirstLetter } from "@helpers"
import { Option } from "@interfaces*"
import { Form } from "antd"
import React, { FC } from "react"

import { CancelButton } from "@components/buttons/CancelButton"
import { SaveButton } from "@components/buttons/SaveButton"
import { FormField } from "@components/FormField"
import { ValidatedSelect } from "@components/ValidatedInputs"

type FormFields = { gender: string }

type GenderFormProps = {
  value?: string
  options: Array<string>
  onSave: (value: string) => void
  onCancel: () => void
}

export const GenderForm: FC<GenderFormProps> = ({ value, options, onCancel, onSave }) => {
  const [form] = Form.useForm()

  const genderOptions: Array<Option> = options.map(gender => ({ label: capitalizeFirstLetter(gender), value: gender }))

  const handleFormSubmit = (formFields: FormFields) => {
    onSave(formFields.gender)
  }

  return (
    <Form form={form} onFinish={handleFormSubmit} initialValues={{ gender: value || "" }}>
      <FormField
        label="Gender"
        fieldName="gender"
        editor={<ValidatedSelect options={genderOptions} data-testid="gender-input" />}
        actions={
          <>
            <SaveButton htmlType="submit" data-testid="save-gender" />
            <CancelButton onClick={onCancel} data-testid="cancel-save-gender" />
          </>
        }
      />
    </Form>
  )
}
