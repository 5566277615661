import { FormInstance } from "antd"
import { DepartmentCard, DepartmentProducts } from "@interfaces"
import { capitalizeFirstLetter } from "@helpers"

import { UserFormFields } from "../../../types"

const deriveProductsText = (products: Array<string>) =>
  `product${products.length > 1 ? "s" : ""} ${products.map(product => capitalizeFirstLetter(product)).join(", ")}`

export const deriveProductsValidator =
  (
    form: FormInstance<UserFormFields>,
    initialProducts: Array<string>,
    selectedDepartment?: DepartmentCard,
    departmentProducts?: DepartmentProducts
  ) =>
  (_: unknown, value: number) => {
    if (!selectedDepartment || !departmentProducts) return Promise.resolve()

    if (!value) return Promise.resolve()

    const products: Array<string> = [form.getFieldValue("mainProduct"), ...form.getFieldValue("additionalProducts")]
    const isChiefPosition = value.toString() === selectedDepartment.chiefPositionId

    if (isChiefPosition) {
      // if products of a chief have been changed then handle this case on a backend
      if (
        products.find(item => !initialProducts.includes(item)) ||
        initialProducts.find(item => !products.includes(item))
      ) {
        return Promise.resolve()
      }

      const newProducts = departmentProducts.ownedProducts.filter(product => !products.includes(product))

      if (newProducts.length > 0) {
        const productsText = deriveProductsText(newProducts)
        const message = `The employee you are assigning as the head of the department \
        ${selectedDepartment.name} does not currently have the ${productsText}, which is available to the employees of this department. \
        If you assign this head to the department, the ${productsText} will be added to them`
        return Promise.reject(new Error(message))
      }
    } else {
      const newProducts = products.filter(product => !departmentProducts.headProducts.includes(product))
      if (newProducts.length > 0) {
        const productsText = deriveProductsText(newProducts)
        const message = `The head of the department ${departmentProducts.firstDepartmentWithOccupiedHeadPosition} is currently not associated with\
        the ${productsText}. If you add this employee to the department, the ${productsText}\
        will be assigned to the department head and all heads above in the hierarchy`
        return Promise.reject(new Error(message))
      }
    }

    return Promise.resolve()
  }
