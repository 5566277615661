import { Button, Col, Form, FormListFieldData, Row, Space } from "antd"
import React, { FC } from "react"
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"

import { checkMaterial, checkMaterialName, urlRule } from "../../../helpers/validation"

import { ValidatedDigitInput, ValidatedTextInput } from "@components/ValidatedInputs"

import style from "./style.m.scss"

type LinkFormItemProps = {
  linkField: FormListFieldData
  onAddItem: () => void
  onRemoveItem: (index: number) => void
  isLastItem: boolean
}

export const LinkFormListItem: FC<LinkFormItemProps> = ({ linkField, isLastItem, onAddItem, onRemoveItem }) => {
  const handleAdd = () => {
    onAddItem()
  }

  const handleRemove = () => {
    onRemoveItem(linkField.name)
  }

  return (
    <Row gutter={16} key={linkField.key} className={style.linkRow}>
      <Col span={8}>
        <Form.Item
          label="Add link to educational materials"
          name={[linkField.name, "link"]}
          style={{ marginBottom: 0 }}
          rules={[
            urlRule,
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return checkMaterial(rule, value, getFieldValue(["newLinks", linkField.name, "name"]))
              }
            })
          ]}
          labelCol={{ span: 24 }}
        >
          <ValidatedTextInput placeholder="Url" data-testid="link-input" />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          label="Name"
          name={[linkField.name, "name"]}
          dependencies={["newLinks", linkField.name, "link"]}
          style={{ marginBottom: 0 }}
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return checkMaterialName(rule, value, getFieldValue(["newLinks", linkField.name, "link"]))
              }
            })
          ]}
          labelCol={{ span: 24 }}
        >
          <ValidatedTextInput placeholder="Name" data-testid="link-name-input" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Review time, days"
          name={[linkField.name, "reviewTime"]}
          style={{ marginBottom: 0 }}
          labelCol={{ span: 24 }}
        >
          <ValidatedDigitInput placeholder="Review time, days" data-testid="review-time-input" />
        </Form.Item>
      </Col>
      <Col span={2} className={style.btnContainer}>
        <Space>
          {isLastItem && <Button icon={<PlusCircleOutlined />} onClick={handleAdd} />}
          <Button icon={<DeleteOutlined />} onClick={handleRemove} />
        </Space>
      </Col>
    </Row>
  )
}
