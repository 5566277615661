import React, { FC } from "react"
import { Divider, Flex } from "antd"
import { DownOutlined } from "@ant-design/icons"
import cn from "classnames"

import { Title } from "@components/Title"

import style from "./style.m.scss"

type ExpandableContainerProps = {
  isExpanded: boolean
  title: React.ReactNode
  onToggleExpanded: () => void
}

export const ExpandableContainer: FC<ExpandableContainerProps> = ({
  isExpanded,
  title,
  onToggleExpanded,
  children
}) => (
  <div className={style.container}>
    <Flex justify="space-between">
      <Title>{title}</Title>
      <DownOutlined
        onClick={onToggleExpanded}
        className={cn(style.expandCollapseIcon, isExpanded && style.collapseIcon)}
        data-testid="expand-collapse"
      />
    </Flex>
    <Divider />
    <div className={style.wrapper}>
      <div className={cn(style.expandableContent, isExpanded && style.expanded)}>{children}</div>
    </div>
  </div>
)
