import { useParams } from "react-router"
import { userProfileApi, usersApi } from "@state/services/subApis"

import { useProfileLocation } from "./useProfileLocation"

type UseProfileDataParams = {
  includeLeaves?: boolean
  includeSubordinates?: boolean
  includeAvailableSystems?: boolean
} | void

export const useProfileData = ({
  includeLeaves,
  includeSubordinates,
  includeAvailableSystems
}: UseProfileDataParams = {}) => {
  const { isMyself } = useProfileLocation()

  const { userId } = useParams()

  const {
    data: userData,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
    error: userError,
    refetch: userRefetch
  } = usersApi.endpoints.fetchUserById.useQuery(
    { userId, includes: { includeLeaves, includeSubordinates, includeAvailableSystems } },
    {
      skip: isMyself
    }
  )

  const {
    data: profileData,
    isLoading: isProfileLoading,
    isFetching: isProfileFetching,
    error: profileError,
    refetch: profileRefetch
  } = userProfileApi.endpoints.fetchUserProfile.useQuery(
    { includes: { includeLeaves, includeSubordinates, includeAvailableSystems } },
    {
      skip: !isMyself
    }
  )

  return isMyself
    ? {
        user: profileData,
        isLoading: isProfileLoading,
        isFetching: isProfileFetching,
        error: profileError,
        refetch: profileRefetch
      }
    : { user: userData, isLoading: isUserLoading, isFetching: isUserFetching, error: userError, refetch: userRefetch }
}
