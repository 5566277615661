export const AVAILABLE_SYSTEMS_URL = "available_systems"
export const AVAILABLE_SYSTEMS_LOGS_URL = `${AVAILABLE_SYSTEMS_URL}/logs`

export const generateAvailableSystemsUrl = (showHasUsers: boolean = false) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("fields[available_systems]", "name,link,description,show_name,show_icon,settings,icon_id")

  if (showHasUsers) {
    searchParams.set("extra_fields[available_systems]", "has_users")
  }

  return `${AVAILABLE_SYSTEMS_URL}${separator}${searchParams.toString()}`
}

export const generateAvailableSystemUrl = (id: string) => `${AVAILABLE_SYSTEMS_URL}/${id}`

export const generateAvailableSystemsLogsUrl = () => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("include", "author")

  return `${AVAILABLE_SYSTEMS_LOGS_URL}${separator}${searchParams.toString()}`
}
