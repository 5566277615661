import React, { FC, useState } from "react"
import { PersonalDocument } from "@interfaces"

import { SectionWrapper } from "@components/SectionWrapper"

import { EditPanel } from "./EditPanel"
import { ViewPanel } from "./ViewPanel"

type PersonalDocumentsGroupProps = {
  userId: string
  documents: Array<PersonalDocument>
  isArchived: boolean
  location?: string
  dataTestId: string
  isLoading: boolean
}

export const PersonalDocumentsGroup: FC<PersonalDocumentsGroupProps> = ({
  userId,
  documents,
  dataTestId,
  isArchived,
  location,
  isLoading
}) => {
  const [isEditMode, setIsEditMode] = useState(false)

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSaveClick = () => {
    setIsEditMode(false)
  }

  return (
    <SectionWrapper>
      {isEditMode ? (
        <EditPanel
          userId={userId}
          documents={documents}
          isArchived={isArchived}
          location={location}
          onSave={handleSaveClick}
          isLoading={isLoading}
          dataTestId={dataTestId}
        />
      ) : (
        <ViewPanel
          userId={userId}
          documents={documents}
          isArchived={isArchived}
          location={location}
          onEdit={handleEditClick}
          isLoading={isLoading}
          dataTestId={dataTestId}
        />
      )}
    </SectionWrapper>
  )
}
