import { LocationQuotaWithCategoryId } from "interfaces"

export const parseQuotas = (quotas: Array<LocationQuotaWithCategoryId>) => {
  const lastCalculableQuota = quotas.reduce((reducer, quota, index) => (quota.calculable ? index : reducer), 0) + 1

  return {
    calculableQuotas: quotas.slice(0, lastCalculableQuota),
    otherQuotas: quotas.slice(lastCalculableQuota, quotas.length)
  }
}
