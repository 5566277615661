import React, { FC } from "react"
import { Button, DatePicker, Form, Upload, UploadFile } from "antd"
import { UploadChangeParam } from "antd/lib/upload"
import { useAlert } from "@hooks"
import { UploadOutlined } from "@ant-design/icons"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { Dayjs } from "dayjs"
import { educationalMaterialsApi } from "@state/services/subApis"

import { checkFilesSize, requiredRule } from "../../../../helpers/validation"
import style from "../style.m.scss"
import { deriveErrorMessages } from "../helpers/errors"
import { formItemStyle } from "../helpers/style"

import { ValidatedTextInput } from "@components/ValidatedInputs/ValidatedTextInput"

type FormFile = UploadFile<any>

type FormFields = {
  file: Array<FormFile>
  name: string
  dueDate?: Dayjs
}

type FileFormProps = {
  userId: string
}

export const FileForm: FC<FileFormProps> = ({ userId }) => {
  const [addMaterial, { isLoading }] = educationalMaterialsApi.endpoints.createEducationalMaterial.useMutation()

  const { error } = useAlert()

  const [form] = Form.useForm<FormFields>()

  const name = Form.useWatch("name", form)
  const file = Form.useWatch("file", form)

  const handleFormSubmit = async ({ file, name, dueDate }: FormFields) => {
    try {
      await addMaterial({
        type: "file",
        name,
        userId,
        file: file[0].originFileObj!,
        dueAt: dueDate ? dueDate.format("YYYY-MM-DD") : undefined
      }).unwrap()

      form.resetFields()
    } catch (e) {
      const errors = deriveErrorMessages(e as FetchBaseQueryError)
      errors.forEach(errorMsg => error(errorMsg))
    }
  }

  const getFile = (e: UploadChangeParam<FormFile>) => {
    if (Array.isArray(e)) {
      return e
    }
    return e ? e.fileList : []
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFormSubmit}
      initialValues={{ link: [], name: "", dueDate: undefined }}
      className={style.form}
    >
      <Form.Item
        label="Or add a file"
        name="file"
        style={formItemStyle}
        getValueFromEvent={getFile}
        valuePropName="fileList"
        rules={[requiredRule, { validator: checkFilesSize }]}
      >
        <Upload
          name="file"
          maxCount={1}
          accept=".pdf"
          beforeUpload={() => {
            return false
          }}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item label="Name" name="name" style={formItemStyle} rules={[requiredRule]}>
        <ValidatedTextInput placeholder="Name" data-testid="file-name-input" />
      </Form.Item>
      <Form.Item label="Due date" name="dueDate" style={formItemStyle}>
        <DatePicker data-testid="file-due-date-picker" />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
        disabled={!name || !file?.length}
        className={style.addButton}
        data-testid="add-file-button"
      >
        Add
      </Button>
    </Form>
  )
}
