import { Build } from "@state/services/types"

import { generateHolidaysIdURL } from "../urls"

export const deleteHoliday = (build: Build) =>
  build.mutation<void, { id: string }>({
    query: ({ id }) => ({
      url: generateHolidaysIdURL(id),
      method: "DELETE"
    }),
    invalidatesTags: (result, error, arg) => [{ type: "Holiday", id: arg.id }, "HolidayLogs"]
  })
