import React, { FC, useEffect } from "react"
import { Button, Flex, Form, Modal } from "antd"
import { AvailableSystem, AvailableSystemMutationData } from "@interfaces"
import { useAlert } from "@hooks"
import { isErrorResponse } from "@helpers"
import { availableSystemsApi } from "@state/services/subApis"

import { AvailableSystemFormValues } from "../types"
import { AvailableSystemForm } from "../AvailableSystemForm"

import { Title } from "@components/Title"

import { deriveAvailableSystemMutationData, deriveInitialValues } from "./utils"

type AvailableSystemModalProps = {
  availableSystem: AvailableSystem | null | undefined
  onCancel: () => void
  onSave: () => void
}

export const AvailableSystemModal: FC<AvailableSystemModalProps> = ({ availableSystem, onCancel, onSave }) => {
  const isNewForm = availableSystem === null

  const { error, success } = useAlert()

  const [addAvailableSystem] = availableSystemsApi.endpoints.createAvailableSystem.useMutation()
  const [updateAvailableSystem] = availableSystemsApi.endpoints.updateAvailableSystem.useMutation()

  const [form] = Form.useForm<AvailableSystemFormValues>()
  const name = Form.useWatch("name", form)
  const link = Form.useWatch("link", form)
  const showNameOnBadge = Form.useWatch("showNameOnBadge", form)
  const showLogoOnBadge = Form.useWatch("showLogoOnBadge", form)

  const handleCancel = () => {
    onCancel()
  }

  const handleSave = async () => {
    try {
      const fieldsValues = await form.validateFields()
      const mutationData = deriveAvailableSystemMutationData(fieldsValues)
      if (isNewForm) {
        handleAdd(mutationData)
      } else {
        handleUpdate(availableSystem!.id, mutationData)
      }
    } catch {
      // do nothing here
    }
  }

  const handleAdd = async (data: AvailableSystemMutationData) => {
    try {
      await addAvailableSystem(data).unwrap()
      success("New system added successfully")
      onSave()
    } catch (err) {
      if (isErrorResponse(err)) {
        err.data.errors.forEach(errorMsg => error(errorMsg))
      } else error("Cannot add new system")
    }
  }

  const handleUpdate = async (id: string, data: AvailableSystemMutationData) => {
    try {
      await updateAvailableSystem({ ...data, id }).unwrap()
      success("System updated successfully")
      onSave()
    } catch (err) {
      if (isErrorResponse(err)) {
        err.data.errors.forEach(errorMsg => error(errorMsg))
      } else error("Cannot update system")
    }
  }

  useEffect(() => {
    form.setFieldsValue(deriveInitialValues(availableSystem))
  }, [availableSystem])

  return (
    <Modal
      open={availableSystem !== undefined}
      maskClosable={false}
      width="800px"
      onCancel={handleCancel}
      destroyOnClose
      footer={() => (
        <Flex justify="center" gap="15px">
          <Button
            type="primary"
            onClick={handleSave}
            data-testid="save-button"
            disabled={!(name && link && (showLogoOnBadge || showNameOnBadge))}
          >
            {isNewForm ? "Add" : "Update"}
          </Button>
          <Button onClick={handleCancel} data-testid="cancel-button">
            Cancel
          </Button>
        </Flex>
      )}
    >
      <Title style={{ marginBottom: "20px" }}>{isNewForm ? "Add new system" : "Edit system"}</Title>
      <AvailableSystemForm form={form} />
    </Modal>
  )
}
