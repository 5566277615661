import {
  BackendGroupsInclude,
  BackendGroupsPolicy,
  BackendAccessGroupsResponse,
  BackendAccessGroupLogs,
  BackendLogIncludedUser
} from "@state/types/accessGroups"
import { BackendPolicy, BackendPolicyAttributesForGroups } from "@state/types/policies"
import { AccessGroup } from "interfaces/accessGroups"

const convertPolicy = (policy: BackendPolicy<BackendPolicyAttributesForGroups>) => {
  const { id, attributes } = policy

  return {
    id,
    name: attributes.name,
    permissions: attributes.full_permissions
  }
}

const convertGroupsIncluded = (includedPolicies: Array<BackendGroupsInclude>) => {
  const includedMap: { [k: string]: BackendPolicy<BackendPolicyAttributesForGroups> } = {}

  includedPolicies.forEach(include => {
    includedMap[include.id] = include
  })

  return includedMap
}

const convertLogsIncluded = (include: Array<BackendLogIncludedUser>) => {
  const includedMap: { [k: string]: BackendLogIncludedUser } = {}

  include.forEach(include => {
    includedMap[include.id] = include
  })

  return includedMap
}

const makePoliciesByGroup = (groupPolicies: Array<BackendGroupsPolicy>, included: Array<BackendGroupsInclude>) => {
  const includesMap = convertGroupsIncluded(included)
  return groupPolicies.map(policy => convertPolicy(includesMap[policy.id]))
}

export const transformGroups = (res: BackendAccessGroupsResponse): Array<AccessGroup> =>
  res.data.map(group => {
    const { id, attributes, relationships } = group
    const policiesByGroup = relationships.policies.data
    const currentPolicies = makePoliciesByGroup(policiesByGroup, res.included)
    const users = attributes.users_data.map(user => ({
      id: String(user.id),
      email: user.email,
      firstName: user.first_name,
      secondName: user.second_name,
      lastName: user.last_name
    }))

    return {
      id,
      name: attributes.name,
      inUse: attributes.in_use,
      policies: currentPolicies,
      users,
      extraPermissions: attributes.full_permissions
    }
  })

export const transformLogs = (res: BackendAccessGroupLogs) => {
  if (!res.data.length) return []
  const usersMap = convertLogsIncluded(res.included)

  return res.data.map(log => {
    const { id, attributes } = log
    const userId = attributes.user_id

    return {
      id,
      userId,
      email: usersMap[userId].attributes.email,
      record: attributes.record,
      createdAt: attributes.created_at
    }
  })
}
