import { ThingsToDoData } from "@interfaces"
import { Build } from "@state/services/types"

import { THIGS_TO_DO_URL } from "../urls"

export const fetchThingsToDo = (build: Build) =>
  build.query<ThingsToDoData, string>({
    query: () => ({
      url: THIGS_TO_DO_URL
    }),
    providesTags: ["EducationalMaterial"]
  })
