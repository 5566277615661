import { CreateDeptBodyReq } from "@interfaces"
import { BackendCreateDeptResponse } from "@state/types/departments"
import { Build } from "@state/services/types"

import { DEPARTMENTS_URL } from "../urls"

export const createDepartment = (build: Build) =>
  build.mutation<string, CreateDeptBodyReq>({
    query: body => ({
      url: DEPARTMENTS_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["Department"],
    transformResponse: (response: BackendCreateDeptResponse) => String(response.data.id)
  })
