import { SlackSettingsOptions } from "@interfaces"
import { BackendSlackSettingsOptions } from "@state/types/slackSettings"
import { Build } from "@state/services/types"

import { generateSlackSettingsOptionsURL } from "../urls"
import { transformSlackSettingsOptions } from "../transformer"

export const fetchSlackSettingsOptions = (build: Build) =>
  build.query<SlackSettingsOptions, string>({
    query: id => ({
      url: generateSlackSettingsOptionsURL(id)
    }),
    transformResponse: (data: BackendSlackSettingsOptions) => transformSlackSettingsOptions(data),
    providesTags: ["SlackSettingsOptions"]
  })
