import { useAlert, useAppDispatch, useAppSelector } from "@hooks"
import { Button, Flex, List, Typography } from "antd"
import React, { FC, useEffect, useState } from "react"
import cn from "classnames"
import { manageGroupsActions } from "@state/reducers/manageGroups"
import { AccessGroupUser, SearchingUser } from "@interfaces*"
import { defaultError } from "@helpers"
import { groupsApi, usersApi } from "@state/services/subApis"

import Search from "../../components/Search/Search"
import { Logs } from "../../components/Logs"
import { formatedLongNames } from "../../helpers/formatedLongNames"

import { DeleteButton } from "@components/buttons/DeleteButton"
import { DebounceSelect } from "@components/DebounceSelect"

import style from "./style.m.scss"

const MAX_OPTION_LABEL_LENGTH = 50
const MAX_LIST_TEXT_LENGTH = 50

const convertOptions = (users: Array<SearchingUser>) =>
  users
    ? users.map(user => ({
        label: formatedLongNames(`${user.email} - ${user.fullName}`, MAX_OPTION_LABEL_LENGTH),
        value: user.id
      }))
    : []

const ManageGroups: FC = () => {
  const dispatch = useAppDispatch()
  const { success, error } = useAlert()

  const [changeUsersInGroups] = groupsApi.endpoints.changeUsersInGroups.useMutation()

  const [currentGroupId, setCurrentGroupId] = useState<string | null>(null)
  const [searchingGroupId, setSearchingGroupId] = useState<string | null>(null)
  const [searchingUserId, setSearchingUserId] = useState<string | null>(null)

  const { groupsList, manage, firstGroupId } = useAppSelector(state => state.manageGroupsSlice)

  const { groups } = groupsApi.useFetchGroupsQuery("", {
    selectFromResult: res => ({ groups: res.data || [] })
  })

  const [fetchOptions, { data, isFetching }] = usersApi.endpoints.searchUsers.useLazyQuery()

  useEffect(() => {
    dispatch(manageGroupsActions.initGroups(groups))
    setCurrentGroupId(firstGroupId)

    return () => {
      dispatch(manageGroupsActions.clearManage())
    }
  }, [groups, firstGroupId])

  const users = currentGroupId
    ? Object.entries(groupsList)
        .map(([, info]) => info)
        .find(group => group.id === currentGroupId)?.users
    : []

  const usersIds = users?.map(user => user.id)

  const optionsFetcher = (searchValue: string) =>
    fetchOptions({
      type: "email",
      value: searchValue,
      exclude: usersIds
    })

  const handleAddUser = () => {
    const currentUser = data?.find(user => user.id === searchingUserId)
    if (currentUser && currentGroupId) {
      dispatch(manageGroupsActions.addUser({ user: currentUser, groupId: currentGroupId }))
    }
    setSearchingUserId(null)
  }

  const handleGroupSearch = (groupId: string) => {
    setSearchingGroupId(groupId)
    setCurrentGroupId(groupId)
  }

  const handleClearGroupSearch = () => {
    setSearchingGroupId(null)
  }

  const handeleDeleteUser = (user: AccessGroupUser) => {
    if (currentGroupId) dispatch(manageGroupsActions.deleteUser({ user, groupId: currentGroupId }))
  }

  const handleChangeUser = (userId: string | null) => {
    setSearchingUserId(userId)
  }

  const handleSaveAll = async () => {
    const bodyReq = {
      access_groups: {
        ...manage
      }
    }

    try {
      await changeUsersInGroups(bodyReq).unwrap()
      dispatch(manageGroupsActions.clearManage())
      success("Group was succesfully update")
    } catch {
      error(defaultError)
    }
  }

  const filteredGroupsList = searchingGroupId
    ? Object.entries(groupsList)
        .map(([, info]) => info)
        .filter(group => group.id === currentGroupId)
    : groups

  const groupOptions = groups.map(group => ({
    label: formatedLongNames(group.name, MAX_OPTION_LABEL_LENGTH),
    value: group.id
  }))

  const disabledCondition = !Object.entries(manage).length

  return (
    <div className={style.container}>
      <Flex align="flex-end" justify="space-between" className={style.header}>
        <Typography.Title datatest-id="dept-name" level={4}>
          Groups
        </Typography.Title>
        <Button type="primary" onClick={handleSaveAll} disabled={disabledCondition}>
          Save all changes
        </Button>
      </Flex>
      <Flex>
        <Flex vertical className={style.listGroupWrapper}>
          <Search options={groupOptions} onSearch={handleGroupSearch} onClear={handleClearGroupSearch} />
          <List
            className={style.list}
            itemLayout="horizontal"
            dataSource={filteredGroupsList}
            renderItem={group => (
              <List.Item
                className={cn(style.listGroupItem, group.id === currentGroupId ? style.picked : "")}
                onClick={() => {
                  setCurrentGroupId(group.id)
                }}
              >
                {formatedLongNames(group.name, MAX_LIST_TEXT_LENGTH)}
              </List.Item>
            )}
          />
        </Flex>
        <Flex vertical className={style.listUserWrapper}>
          <div className={style.searchWrapper}>
            <DebounceSelect
              value={searchingUserId}
              onChange={handleChangeUser}
              optionsFetcher={optionsFetcher}
              isFetching={isFetching}
              convertOptions={convertOptions}
            >
              <Button type="primary" onClick={handleAddUser} disabled={!searchingUserId}>
                Add user
              </Button>
            </DebounceSelect>
          </div>
          <List
            className={style.list}
            itemLayout="horizontal"
            dataSource={[...(users ?? [])].sort((a, b) => a.email.localeCompare(b.email))}
            renderItem={user => (
              <List.Item
                className={style.listUserItem}
                actions={[<DeleteButton onConfirm={() => handeleDeleteUser(user)} />]}
              >
                <List.Item.Meta
                  title={formatedLongNames(`${user.email} - ${user.firstName} ${user.lastName}`, MAX_LIST_TEXT_LENGTH)}
                />
              </List.Item>
            )}
          />
        </Flex>
      </Flex>
      <Logs type="group_manage" />
    </div>
  )
}

export default ManageGroups
