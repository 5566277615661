export const deriveFileFormData = (content: File) => {
  const formData = new FormData()
  formData.append("file", content)

  return formData
}

export const getUsersExtraFields = [
  "department",
  "employment_status",
  "work_format",
  "work_schedule",
  "has_commission",
  "is_terminated",
  "location",
  "status",
  "photo_id",
  "position"
]
