import React, { FC } from "react"
import { RefSelectProps, Select, SelectProps } from "antd"

import { Regular } from "../ValidateInputWrap/ValidateInputWrap"

import { ValidateInputWrap } from "@components/ValidatedInputs/ValidateInputWrap"

type ValidatedSelectProps = {
  ref?: React.RefObject<RefSelectProps>
  searchValue?: string
  onSearch?: (value: string) => void
  isWarningHidden?: boolean
} & Omit<SelectProps, "searchValue" | "onSearch">

export const ValidatedSelect: FC<ValidatedSelectProps> = props => {
  const { ref, searchValue, onSearch, isWarningHidden, ...restProps } = props

  return (
    <ValidateInputWrap
      regular={Regular.Text}
      value={searchValue}
      onValueChange={onSearch}
      renderInput={(internalSearchValue, onInternalSearch) => (
        <Select ref={ref} searchValue={internalSearchValue} onSearch={onInternalSearch} {...restProps} />
      )}
      isWarningHidden={isWarningHidden}
    />
  )
}
