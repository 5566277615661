import { useAlert } from "@hooks"
import { QuotaCategoryOption, SaveVacationError } from "@interfaces"
import { vacationsQuotasApi } from "@state/services/subApis"

export const useQuotasEdit = (categories: Array<QuotaCategoryOption>) => {
  const { success, error } = useAlert()
  const [changeQuota] = vacationsQuotasApi.endpoints.changeVacationsQuotas.useMutation()

  return (quotasNewData: Array<[id: string, days: number, categoryId: string]>) => {
    quotasNewData.forEach(async ([id, days, categoryId]) => {
      try {
        await changeQuota({
          data: {
            id,
            type: "vacation_quota",
            attributes: { days: days === null ? null : days === undefined ? 0 : days }
          }
        }).unwrap()

        const currentCategory = categories.find(category => String(category.value) === categoryId)
        success(`${currentCategory ? currentCategory.label : ""} quota changed successfully`)
      } catch (e) {
        const { error: resError } = e as SaveVacationError
        error(resError.data.errors.map(error => error.detail).join("\n"))
      }
    })
  }
}
