import { NewPersonalDocument } from "@interfaces"
import { Build } from "@state/services/types"

import { PERSONAL_DOCUMENTS_URL } from "../urls"
import { deriveCreateParams } from "../transformer"

export const createPersonalDocument = (build: Build) =>
  build.mutation<unknown, NewPersonalDocument>({
    query: data => ({
      url: PERSONAL_DOCUMENTS_URL,
      method: "POST",
      body: deriveCreateParams(data)
    }),
    invalidatesTags: ["PersonalDocument"]
  })
