import { BackendUsersForFilters, UserForFilter } from "@interfaces"
import { Build } from "@state/services/types"
import { generateFiltersRoute } from "@helpers"

import { USERS_URL } from "../urls"
import { transformUsersForFilters } from "../transformer"

export const fetchUsersForFilters = (build: Build) =>
  build.query<Array<UserForFilter>, { [k: string]: string }>({
    query: filters => ({
      url: generateFiltersRoute(filters, USERS_URL)
    }),
    transformResponse: (data: BackendUsersForFilters) => transformUsersForFilters(data)
  })
