import backendApi from "../../backendApi"

import { fetchDepartments } from "./GET/fetchDepartments"
import { fetchDepartmentById } from "./GET/fetchDepartmentById"
import { fetchDepartmentsOptions } from "./GET/fetchDepartmentsOptions"
import { fetchDepartmentsLogs } from "./GET/fetchDepartmentsLogs"
import { createDepartment } from "./POST/createDepartment"
import { updateDepartment } from "./PUT/updateDepartment"
import { fetchDepartmentProducts } from "./GET/fetchDepartmentProducts"

export const departmentsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchDepartments: fetchDepartments(build),
    fetchDepartmentById: fetchDepartmentById(build),
    fetchDepartmentsOptions: fetchDepartmentsOptions(build),
    fetchDepartmentsLogs: fetchDepartmentsLogs(build),
    fetchDepartmentProducts: fetchDepartmentProducts(build),
    createDepartment: createDepartment(build),
    updateDepartment: updateDepartment(build)
  })
})
