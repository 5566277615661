import { FetchArgs, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { UNAUTHORIZED_STATUS_CODE } from "@helpers"
import { RESET_ALL_ACTION_TYPE } from "@state/const"
import { BACK_END_URL } from "@state/services/apiConsts"
import { batch } from "react-redux"

import {
  LOGIN_URL,
  OTP_AUTH_URL,
  OTP_SETUP_URL,
  REQUEST_PASSWORD_RESET_URL,
  RESET_PASSWORD_URL
} from "./subApis/auth/urls"

const tagTypes = [
  "Vacation",
  "User",
  "NeutralUser",
  "AssignmentHr",
  "Intern",
  "Mentor",
  "Profile",
  "Teamlead",
  "Role",
  "Holiday",
  "HolidaysOptions",
  "Location",
  "Balance",
  "Quota",
  "VacationSetting",
  "Department",
  "DepartmentLog",
  "Relationship",
  "UserRole",
  "SlackGroup",
  "SlackUser",
  "Tag",
  "Position",
  "EducationalMaterial",
  "PersonalDocument",
  "Certificate",
  "Conference",
  "PerformanceReview",
  "HolidayLogs",
  "Policy",
  "AccessGroup",
  "BannedIps",
  "NotificationSettings",
  "NotificationSettingsOptions",
  "SlackSettingsOptions",
  "2FA",
  "AvailableSystem",
  "LeaveType",
  "VacationAccumulation",
  "VacationsNotificationsSetting"
]

const baseQuery = fetchBaseQuery({ baseUrl: BACK_END_URL })

const skipUnauthorizedRedirectUrls = [
  REQUEST_PASSWORD_RESET_URL,
  RESET_PASSWORD_URL,
  LOGIN_URL,
  OTP_AUTH_URL,
  OTP_SETUP_URL
]

const baseQueryWithAuthCheck: typeof baseQuery = async (args: FetchArgs, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)

  if (
    result.error &&
    result.error.status === UNAUTHORIZED_STATUS_CODE &&
    !skipUnauthorizedRedirectUrls.includes(args.url)
  ) {
    batch(() => {
      api.dispatch({ type: RESET_ALL_ACTION_TYPE })
      api.dispatch(backendApi.util.resetApiState())
    })
  }

  return result
}

const backendApi = createApi({
  reducerPath: "backend",
  tagTypes,
  baseQuery: baseQueryWithAuthCheck,
  endpoints: () => ({})
})

export default backendApi
