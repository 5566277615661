import React, { FC } from "react"
import { PersonalDocument } from "@interfaces"

import { DocumentsTable } from "../components/DocumentsTable"
import { GroupHeader } from "../components/GroupHeader"

import { EditButton } from "@components/buttons/EditButton"

type ViewPanelProps = {
  userId: string
  documents: Array<PersonalDocument>
  isArchived: boolean
  location?: string
  dataTestId: string
  isLoading?: boolean
  onEdit?: () => void
}

export const ViewPanel: FC<ViewPanelProps> = ({
  userId,
  documents,
  isArchived,
  location,
  dataTestId,
  isLoading,
  onEdit
}) => (
  <>
    <GroupHeader
      isArchived={isArchived}
      location={location}
      actions={onEdit && <EditButton onClick={onEdit} data-testid={`${dataTestId}-edit-button`} />}
    />

    <DocumentsTable
      userId={userId}
      documents={documents}
      isLoading={isLoading}
      isArchived={isArchived}
      isEditMode={false}
      dataTestId={dataTestId}
    />
  </>
)
