import { Abilities } from "@interfaces*"
import { EmploymentSettings, LocationsSettings, AvailableSystems } from "@app/sections/Settings"
import { VacationsSettingsPaging } from "@app/sections/Settings/Vacations"

import { DefaultWrapper } from "@components/PageWrappers"

import { RouteItem } from "./types"
import {
  AVAILABLE_SYSTEMS_ROUTE,
  EMPLOYMENT_PAGE_SETTINGS_ROUTE,
  LOCATIONS_SETTINGS_ROUTE,
  VACATIONS_TRACKER_SETTINGS_ROUTE
} from "./inboundRoutes"

export const settingsMenuRoutes = (abilities: Abilities | null): Array<RouteItem> => [
  {
    path: EMPLOYMENT_PAGE_SETTINGS_ROUTE,
    title: "Employment settings",
    slug: "employment-settings",
    component: EmploymentSettings,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.employment_settings?.manage
  },
  {
    path: VACATIONS_TRACKER_SETTINGS_ROUTE,
    title: "Vacation tracker settings",
    slug: "locations",
    component: VacationsSettingsPaging,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.vacations?.manage
  },
  {
    path: LOCATIONS_SETTINGS_ROUTE,
    title: "Locations settings",
    slug: "locations-settings",
    component: LocationsSettings,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.locations?.index
  },
  {
    path: AVAILABLE_SYSTEMS_ROUTE,
    title: "Available systems",
    slug: "available-systems",
    component: AvailableSystems,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.available_systems?.manage
  }
]
