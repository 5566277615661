import { Build } from "@state/services/types"

import { generateVacationCancelURL } from "../urls"

export const cancelVacation = (build: Build) =>
  build.mutation<void, { id: string }>({
    query: ({ id }) => ({
      url: generateVacationCancelURL(id),
      method: "PUT"
    }),
    invalidatesTags: (result, error, arg) => [{ type: "Vacation", id: arg.id }, "Balance", "Quota"]
  })
