import React, { FC, useMemo, useState } from "react"
import { AnnualLeavesData } from "@interfaces"

import { SectionWrapper } from "@components/SectionWrapper"
import { ValidatedSelect } from "@components/ValidatedInputs"
import { Title } from "@components/Title"

import { LeavesInfo } from "./LeavesInfo"
import style from "./style.m.scss"

const deriveYearOptions = (leaves: Array<AnnualLeavesData>) => leaves.map(({ year }) => ({ value: year, label: year }))

type LeavesProps = {
  leaves: Array<AnnualLeavesData>
}

export const Leaves: FC<LeavesProps> = ({ leaves }) => {
  const yearOptions = useMemo(() => deriveYearOptions(leaves), [leaves])

  const [selectedYear, setSelectedYear] = useState(yearOptions[0]?.value)

  const handleYearChange = (value: number) => {
    setSelectedYear(value)
  }

  const currentYearLeavesData = useMemo(() => leaves.find(leave => leave.year === selectedYear), [leaves, selectedYear])

  return (
    <SectionWrapper>
      <Title>Leaves</Title>
      <div className={style.period}>
        Period: <ValidatedSelect options={yearOptions} value={selectedYear} onChange={handleYearChange} /> year
      </div>
      {currentYearLeavesData && <LeavesInfo leavesData={currentYearLeavesData} />}
    </SectionWrapper>
  )
}
