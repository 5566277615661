import dayjs, { Dayjs } from "dayjs"
import { PlanningDates, TLocationFormFields } from "@interfaces*"

import {
  QUOTA_INPUT_CATEGORY_POSITION,
  QUOTA_INPUT_ID_POSITION,
  QUOTA_INPUT_SEPARATOR
} from "../LocationFormFields/QuotaInput"

type NewSettings = { [n: string]: string | Array<string> | Dayjs | PlanningDates }

export const separateQuotaFormFields = (fields: TLocationFormFields) => {
  const newQuotas: Array<[string, number, string]> = []
  let newSettings: NewSettings = {}
  let location: number = NaN

  Object.entries(fields).forEach(([name, value]) => {
    if (name.includes(QUOTA_INPUT_SEPARATOR)) {
      const fieldData = name.split(QUOTA_INPUT_SEPARATOR)
      const quotaId = fieldData[QUOTA_INPUT_ID_POSITION]
      const quotaCategoryId = fieldData[QUOTA_INPUT_CATEGORY_POSITION]

      newQuotas.push([quotaId, value === undefined ? 0 : (value as number), quotaCategoryId])
    } else {
      switch (name) {
        case "expiration":
          newSettings = { ...newSettings, expiration: value as string }
          break
        case "brought_forward_limit":
          newSettings = { ...newSettings, brought_forward_limit: value as string }
          break
        case "expiration_date":
          newSettings = { ...newSettings, expiration_date: value as Dayjs }
          break
        case "leave_type":
          newSettings = { ...newSettings, leave_type: value as string }
          break
        case "tooltip":
          newSettings = { ...newSettings, tooltip: value as string }
          break
        case "workdays":
          newSettings = { ...newSettings, workdays: value as Array<string> }
          break
        case "location":
          location = value as number
          break
        case "planning_dates":
          const formatedDates = (value as Array<Dayjs>).map(date => dayjs(date).format("YYYY-MM-DD"))
          newSettings = {
            ...newSettings,
            planning_dates: {
              planning_start_date: formatedDates[0],
              planning_end_date: formatedDates[1]
            } as PlanningDates
          }
          break
        default:
      }
    }
  })

  return { newQuotas, newSettings, location }
}
