import { SeparateUserParams } from "@interfaces"
import { Build } from "@state/services/types"

import { generateSeparateUserURL } from "../urls"

export const separateUser = (build: Build) =>
  build.mutation<unknown, SeparateUserParams>({
    query: params => ({
      url: generateSeparateUserURL(params.userId),
      method: "PUT",
      body: {
        date: params.separationDate
      }
    }),
    invalidatesTags: ["User", "Vacation"]
  })
