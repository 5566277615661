import React, { FC, useEffect, useState } from "react"
import { UserOutlined } from "@ant-design/icons"

import style from "./style.m.scss"

type PhotoViewerProps = {
  src?: string | undefined
}

export const PhotoViewer: FC<PhotoViewerProps> = ({ src }) => {
  const [source, setSource] = useState(src)

  useEffect(() => {
    if (src !== source) {
      setSource(src)
    }
  }, [src])

  const handleImageError = () => {
    setSource(undefined)
  }

  return source ? (
    <img src={source} alt="" className={style.image} onError={handleImageError} />
  ) : (
    <UserOutlined className={style.icon} style={{ display: "flex" }} />
  )
}
