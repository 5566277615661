import { Build } from "@state/services/types"

import { PERFORMANCE_REVIEW_URL } from "../urls"

export const createPerformanceReview = (build: Build) =>
  build.mutation<unknown, FormData>({
    query: body => ({
      url: PERFORMANCE_REVIEW_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["PerformanceReview"]
  })
