import React, { FC } from "react"
import { Table } from "antd"
import { ColumnType } from "antd/es/table"
import { PersonalDocument } from "@interfaces"

import { useDocumentsTable } from "./helpers/useDocumentsTable"
import { DocumentStatus } from "./const"

type DocumentsTableProps = {
  userId: string
  documents: Array<PersonalDocument>
  isEditMode: boolean
  isArchived: boolean
  dataTestId: string
  isLoading?: boolean
}

export const DocumentsTable: FC<DocumentsTableProps> = ({
  userId,
  documents,
  isEditMode,
  isArchived,
  dataTestId,
  isLoading
}) => {
  const { columns, components, footer, onRow } = useDocumentsTable(userId, isArchived, isEditMode, dataTestId)

  const showFooter = documents.filter(doc => doc.status !== DocumentStatus.deleted).length > 0

  return (
    <Table
      columns={columns as Array<ColumnType<PersonalDocument>>}
      components={components}
      footer={showFooter ? footer : undefined}
      dataSource={documents}
      rowKey="id"
      loading={isLoading}
      pagination={false}
      // @ts-ignore
      onRow={onRow}
    />
  )
}
