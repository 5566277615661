import { SalaryHistoryItem } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendUserSalariesHistory } from "@state/types/users"

import { transformSalaryHistory } from "../transformer"
import { generateUserSalaryChangesURL } from "../urls"

export const fetchUserSalariesChanges = (build: Build) =>
  build.query<Array<SalaryHistoryItem>, string>({
    query: userId => ({
      url: generateUserSalaryChangesURL(userId)
    }),
    transformResponse: (response: BackendUserSalariesHistory) => transformSalaryHistory(response),
    providesTags: ["User"]
  })
