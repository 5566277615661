import React, { FC, useState } from "react"
import { useAlert, useProfileHandlers, useUserProfileEmploymentInfoAbilities } from "@hooks"

import { SectionWrapper } from "@components/SectionWrapper"

import { ViewPanel } from "./ViewPanel"
import { EditPanel } from "./EditPanel"

type JobDescriptionProps = {
  text: string
}

export const JobDescription: FC<JobDescriptionProps> = ({ text }) => {
  const { update_all: canUpdateSection } = useUserProfileEmploymentInfoAbilities()

  const [isExpanded, setIsExpanded] = useState(false)

  const [isEditMode, setIsEditMode] = useState(false)

  const { saveUserProfile } = useProfileHandlers()
  const { success } = useAlert()

  const handleToggleExpanded = () => setIsExpanded(value => !value)

  const handleEditClick = canUpdateSection
    ? () => {
        setIsEditMode(true)
        setIsExpanded(true)
      }
    : undefined

  const handleSave = async (description: string) => {
    const result = await saveUserProfile({
      job_description: description
    })

    if (result) {
      setIsEditMode(false)
      success("Job description has been saved successfully")
    }
    setIsEditMode(false)
  }

  const handleCancel = () => {
    setIsEditMode(false)
  }

  if (!canUpdateSection && !text) return null

  return (
    <SectionWrapper>
      {isEditMode ? (
        <EditPanel
          text={text}
          isExpanded={isExpanded}
          onToggleExpanded={handleToggleExpanded}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) : (
        <ViewPanel
          text={text}
          isExpanded={isExpanded}
          onToggleExpanded={handleToggleExpanded}
          onEdit={handleEditClick}
        />
      )}
    </SectionWrapper>
  )
}
