import * as yup from "yup"
import { VacationsCategoryName } from "@enums"
import { AnySchema } from "yup"
import { NUM_DAYS_IN_YEAR } from "helpers/constants"

import { validationErrors } from "../validationErrors"
import { NUMBERS } from "../validationRegex"

const propValidation = yup
  .string()
  .trim()
  .required(validationErrors.REQUIRED_ERROR)
  .matches(NUMBERS, validationErrors.VALID_NUM_ERROR)

export const quotaSchema = yup.object().shape({
  [VacationsCategoryName.paid_time_off]: propValidation,
  [VacationsCategoryName.paid_time_off_half_day]: propValidation,
  [VacationsCategoryName.sick_day]: propValidation,
  [VacationsCategoryName.unpaid_sick_day]: propValidation,
  [VacationsCategoryName.unpaid_vacation]: propValidation,
  [VacationsCategoryName.working_remotely]: propValidation
})

type QuotaSchema = { [k: string]: AnySchema }

export const generateQuotaSchema = (fieldIds: Array<string>) => {
  const schemaObject: QuotaSchema = {}

  fieldIds.forEach(fieldId => {
    schemaObject[fieldId] = yup
      .mixed()
      .transform(originalValue =>
        originalValue === "Unlimited" ? -1 : originalValue === "" ? originalValue : Number(originalValue)
      )
      .nullable()
      .test("is-number-or-null", "Must be a number or a string which can converted to a number", value => {
        if (value === -1 || value === 0) return true
        if (!Number(value)) return false
        if (value > NUM_DAYS_IN_YEAR) return false
        return true
      })
      .required(validationErrors.VALID_NUM_ERROR)
  })

  return yup.object().shape(schemaObject)
}
