import { BackendPersonalDocumentsResponse } from "@state/types/personalDocuments"
import { PersonalDocumentsResponse, PersonalDocumentsParams } from "@interfaces"
import { Build } from "@state/services/types"

import { generateGetPersonalDocumentsURL } from "../urls"
import { personalDocumentsTransformer } from "../transformer"

export const fetchPersonalDocuments = (build: Build) =>
  build.query<PersonalDocumentsResponse, PersonalDocumentsParams>({
    query: params => ({
      url: generateGetPersonalDocumentsURL(params.userId)
    }),
    transformResponse: (data: BackendPersonalDocumentsResponse) => personalDocumentsTransformer(data),
    providesTags: ["PersonalDocument"]
  })
