import { BulkStatuses } from "@enums"

export const EDUCATIONAL_MATERIALS_URL = "/education_materials"

export const EDUCATIONAL_MATERIALS_BULK_ADD = "/education_material_bulks"

export const generateGetEducationalMaterialsURL = (userId: string) => {
  const searchParams = new URLSearchParams()
  const separator = "?"
  searchParams.set("filter[user_id]", userId)
  searchParams.set("include", "document")

  return `${EDUCATIONAL_MATERIALS_URL}${separator}${searchParams.toString()}`
}

export const generateEducationalMaterialURL = (id: string) => `${EDUCATIONAL_MATERIALS_URL}/${id}`

export const generateEducationalMaterialDownloadURL = (id: string) => `/api${EDUCATIONAL_MATERIALS_URL}/${id}/download`

export const generateDeactivateActiveBulkURL = (id: string) => `${EDUCATIONAL_MATERIALS_BULK_ADD}/${id}/deactivate`

export const generateEducationalMaterialsBulkLogs = (status: BulkStatuses) =>
  `${EDUCATIONAL_MATERIALS_BULK_ADD}/rules?scope=${status}`
