import { Build } from "@state/services/types"
import { BackendUnremovableLeaveTypesResponse } from "@state/types/vacations"

import { UNREMOVABLE_LEAVE_TYPES_URL } from "../urls"

export const fetchUnremovableLeaveTypes = (build: Build) =>
  build.query<Array<string>, string>({
    query: () => ({
      url: UNREMOVABLE_LEAVE_TYPES_URL
    }),
    transformResponse: (res: BackendUnremovableLeaveTypesResponse) =>
      res.unremovable_category_ids.map(id => String(id)),
    providesTags: ["LeaveType"]
  })
