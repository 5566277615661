import { BackendPoliciesResponse } from "@state/types/policies"
import { Policy } from "@interfaces"
import { Build } from "@state/services/types"

import { generatePoliciesURL } from "../urls"
import { transformPolicies } from "../transformer"

export const fetchPolicies = (build: Build) =>
  build.query<Array<Policy>, string | undefined>({
    query: userId => ({
      url: generatePoliciesURL(userId)
    }),
    transformResponse: (res: BackendPoliciesResponse) => transformPolicies(res),
    providesTags: ["Policy"]
  })
