import { JobHistoryItem } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendJobHistoryResponse } from "@state/types/users"

import { transformJobHistory } from "../transformer"
import { generateUserPositionChangesURL } from "../urls"

export const fetchUserPositionChanges = (build: Build) =>
  build.query<Array<JobHistoryItem>, string>({
    query: userId => ({
      url: generateUserPositionChangesURL(userId)
    }),
    transformResponse: (response: BackendJobHistoryResponse) => transformJobHistory(response),
    providesTags: ["User"]
  })
