import * as yup from "yup"

import { validationErrors } from "../validationErrors"
import { NUMBERS } from "../validationRegex"

export const availableHrSchema = yup.object().shape({
  availableHr: yup
    .string()
    .trim()
    .required(validationErrors.REQUIRED_ERROR)
    .matches(NUMBERS, validationErrors.VALID_SELECT_ERROR)
})
