import React from "react"
import { Form } from "antd"

import { requiredRule } from "../../../../../../helpers/validation"
import { formItemStyle } from "../../../../EditPanel/helpers/style"

import { ValidatedTextInput } from "@components/ValidatedInputs"

export const CommentEditor: React.VFC = () => (
  <Form.Item name="comment" style={formItemStyle} rules={[requiredRule]}>
    <ValidatedTextInput placeholder="Comment" data-testid="comment-input" />
  </Form.Item>
)
