import { LocationForSettings } from "@interfaces*"
import { BackendLocationsResponse } from "@state/types/locations"

export const transformLocations = (response: BackendLocationsResponse): Array<LocationForSettings> =>
  response.data.map(location => {
    const { id, attributes } = location

    return {
      id,
      location: attributes.name,
      country: attributes.country_name,
      dataStoredLocation: attributes.s3_region,
      employeesCount: attributes.users_count,
      docsStored: attributes.docs_stored
    }
  })
