import React from "react"

export const DidlogicIcon = () => (
  <svg viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.1563 20.4937L7.15661 12.4934L22.1908 4.49368L22.1563 20.4937Z" fill="#E41E26" />
    <path d="M22.1563 20.4937L7.15661 12.4934L22.1908 4.49368L22.1563 20.4937Z" fill="#E41E26" />
    <path d="M22.1562 4.49351L37.1564 12.4936L22.1563 20.4937L22.1562 4.49351Z" fill="#DE2226" />
    <path d="M22.1562 4.49351L37.1564 12.4936L22.1563 20.4937L22.1562 4.49351Z" fill="#DE2226" />
    <path d="M22.1562 4.49351L37.1564 12.4936L22.1563 20.4937L22.1562 4.49351Z" fill="#DE2226" />
    <path d="M22.1562 4.49351L37.1564 12.4936L22.1563 20.4937L22.1562 4.49351Z" fill="#DE2226" />
    <path d="M37.1037 28.5084L22.1563 20.4937L37.1561 12.4938L37.1037 28.5084Z" fill="#AC1E23" />
    <path d="M37.1037 28.5084L22.1563 20.4937L37.1561 12.4938L37.1037 28.5084Z" fill="#AC1E23" />
    <path d="M37.1037 28.5084L22.1563 20.4937L37.1561 12.4938L37.1037 28.5084Z" fill="#AC1E23" />
    <path d="M37.1037 28.5084L22.1563 20.4937L37.1561 12.4938L37.1037 28.5084Z" fill="#AC1E23" />
    <path d="M22.1547 36.495L22.1548 20.4151L37.1563 28.4937L22.1547 36.495Z" fill="#C92026" />
    <path d="M22.1547 36.495L22.1548 20.4151L37.1563 28.4937L22.1547 36.495Z" fill="#C92026" />
    <path d="M22.1547 36.495L22.1548 20.4151L37.1563 28.4937L22.1547 36.495Z" fill="#C92026" />
    <path d="M22.1547 36.495L22.1548 20.4151L37.1563 28.4937L22.1547 36.495Z" fill="#C92026" />
    <path d="M7.15604 28.4937L22.1546 20.4151L22.1548 36.4937L7.15604 28.4937Z" fill="#A51D21" />
    <path d="M7.15604 28.4937L22.1546 20.4151L22.1548 36.4937L7.15604 28.4937Z" fill="#A51D21" />
    <path d="M7.15604 28.4937L22.1546 20.4151L22.1548 36.4937L7.15604 28.4937Z" fill="#A51D21" />
    <path d="M7.15604 28.4937L22.1546 20.4151L22.1548 36.4937L7.15604 28.4937Z" fill="#A51D21" />
    <path d="M7.15606 12.4937L22.1543 20.415L7.15625 28.4937L7.15606 12.4937Z" fill="#BD2025" />
    <path d="M7.15606 12.4937L22.1543 20.415L7.15625 28.4937L7.15606 12.4937Z" fill="#BD2025" />
    <path d="M7.15606 12.4937L22.1543 20.415L7.15625 28.4937L7.15606 12.4937Z" fill="#BD2025" />
    <path d="M7.15606 12.4937L22.1543 20.415L7.15625 28.4937L7.15606 12.4937Z" fill="#BD2025" />
    <rect x="20.1562" y="10.4937" width="4" height="20" fill="white" />
    <rect x="30.4561" y="13.9741" width="4" height="20.4775" transform="rotate(62.7726 30.4561 13.9741)" fill="white" />
    <rect x="32.2363" y="23.4795" width="4" height="20.6879" transform="rotate(117.467 32.2363 23.4795)" fill="white" />
  </svg>
)
