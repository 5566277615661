import { UpdateEducationalMaterialParams } from "@interfaces"
import { Build } from "@state/services/types"

import { generateEducationalMaterialURL } from "../urls"

export const updateEducationalMaterial = (build: Build) =>
  build.mutation<unknown, UpdateEducationalMaterialParams>({
    query: params => {
      const { id, ...attributes } = params

      return {
        url: generateEducationalMaterialURL(id),
        method: "PUT",
        body: {
          data: {
            id,
            type: "education_materials",
            attributes
          }
        }
      }
    },
    invalidatesTags: ["EducationalMaterial"]
  })
