import React from "react"
import { useAlert } from "@hooks"
import { EducationalMaterial } from "@interfaces"
import { Link } from "react-router-dom"
import { MAX_FILE_NAME_LENGTH } from "@app/sections/Profile/helpers/constants"
import { educationalMaterialsApi } from "@state/services/subApis"
import { Button } from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import {
  generateEducationalMaterialDownloadURL,
  generateEducationalMaterialURL
} from "@state/services/subApis/educationalMaterials/urls"

import { DeleteButton } from "@components/buttons/DeleteButton"

import style from "./style.m.scss"

const nameForPopConfirm = (name: string) => {
  return name.length < MAX_FILE_NAME_LENGTH ? name : `${name.slice(0, MAX_FILE_NAME_LENGTH)}...`
}

export const useMaterialsTableColumns = (isEditMode?: boolean) => {
  const [deleteMaterial] = educationalMaterialsApi.endpoints.deleteEducationalMaterial.useMutation()

  const { error } = useAlert()

  const handleDeleteUserClick = async (id: string) => {
    try {
      await deleteMaterial(id).unwrap()
    } catch {
      error("Error deleting educational material")
    }
  }

  const basicColumns = [
    {
      key: "number",
      width: "30px",
      onCell: (_: EducationalMaterial, index: number) => ({
        "data-testid": `em-number-${index}`
      }),
      render: (_: unknown, __: EducationalMaterial, index: number) => (index + 1).toString()
    },
    {
      title: "Document",
      key: "document",
      width: "700px",
      onCell: (_: EducationalMaterial, index: number) => ({
        "data-testid": `em-document-${index}`
      }),
      render: (_: unknown, material: EducationalMaterial) => {
        if (material.type === "file") {
          return (
            <>
              <Link to={`/api${generateEducationalMaterialURL(material.id)}`} target="_blank" className={style.link}>
                {material.name}
              </Link>
              {!isEditMode && (
                <Button
                  href={generateEducationalMaterialDownloadURL(material.id)}
                  type="link"
                  icon={<DownloadOutlined />}
                />
              )}
            </>
          )
        }

        return (
          <a href={material.link} target="_blank" rel="noreferrer" className={style.link}>
            {material.name}
          </a>
        )
      }
    },
    {
      title: "Due date",
      dataIndex: "dueAt",
      key: "dueAt",
      onCell: (_: EducationalMaterial, index: number) => ({ "data-testid": `em-due-date-${index}` })
    },
    {
      title: "Read by user",
      dataIndex: "readByUser",
      key: "readByUser",
      onCell: (_: EducationalMaterial, index: number) => ({ "data-testid": `em-read-by-user-${index}` })
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      onCell: (_: EducationalMaterial, index: number) => ({ "data-testid": `em-status-${index}` })
    }
  ]

  const actionsColumn = {
    title: "Actions",
    key: "actions",
    width: "50px",
    render: (_: unknown, { id, name }: EducationalMaterial) => (
      <DeleteButton
        message={`You are about to delete ${nameForPopConfirm(name)}. Are you sure?`}
        onConfirm={() => handleDeleteUserClick(id)}
        data-testid="delete-education-material"
      />
    ),
    onCell: (_: EducationalMaterial, index: number) => ({ "data-testid": `em-actions-${index}` })
  }

  return isEditMode ? [...basicColumns, actionsColumn] : basicColumns
}
