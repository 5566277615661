import React, { FC, useState } from "react"
import { useAlert, useProfileHandlers } from "@hooks"

import { PhotoEditMode } from "../PhotoEditMode"
import { PhotoButton } from "../PhotoButton"
import style from "../style.m.scss"

import { SaveButton } from "@components/buttons/SaveButton"
import { CancelButton } from "@components/buttons/CancelButton"

type StandalonePhotoEditorProps = {
  src?: string
}

export const StandalonePhotoEditor: FC<StandalonePhotoEditorProps> = ({ src }) => {
  const [imageSrc, setImageSrc] = useState(src)
  const [photoFile, setPhotoFile] = useState<File>()

  const { saveUserPhoto } = useProfileHandlers()
  const { success } = useAlert()

  const handlePhotoChange = (file: File) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        setImageSrc(reader.result)
      }
    }
    reader.readAsDataURL(file)
    setPhotoFile(file)
  }

  const handleSave = async () => {
    if (!photoFile) return

    const result = await saveUserPhoto(photoFile)

    if (result) {
      setPhotoFile(undefined)
      success("Photo has been saved successfully")
    }
  }

  const handleCancel = () => {
    setPhotoFile(undefined)
    setImageSrc(src)
  }

  const buttons = photoFile ? (
    <div className={style.saveActions}>
      <SaveButton onClick={handleSave} type="default" data-testid="save-photo" />
      <CancelButton onClick={handleCancel} type="default" data-testid="cancel-save-photo" />
    </div>
  ) : (
    <PhotoButton onChange={handlePhotoChange} className={style.photoButton} />
  )

  return <PhotoEditMode imageSrc={imageSrc} buttons={buttons} />
}
