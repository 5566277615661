import { Checkbox, Flex, Form } from "antd"
import React, { FC } from "react"

import { NewBulkForm } from "../../helpers/types"

import { DepartmentFormListItem } from "./DepartmentFormListItem"
import style from "./style.m.scss"

type DepartmentFormListProps = {
  onDelete: (
    fieldName: number,
    remove: (index: number | Array<number>) => void,
    formFieldName: keyof NewBulkForm
  ) => void
  derivePickedDepts: () => void
  onIsSendToAllDepts: (isAllDepts: boolean) => void
  isSendToAllDepts: boolean
  pickedDepts: Array<string>
}

export const DepartmentFormList: FC<DepartmentFormListProps> = ({
  isSendToAllDepts,
  pickedDepts,
  onDelete,
  derivePickedDepts,
  onIsSendToAllDepts
}) => {
  const handleDeleteItem = (fieldName: number, remove: (index: number) => void) => {
    onDelete(fieldName, remove, "newDepts")
  }

  const handleIsAllDeptsChange = (isAllDepts: boolean) => {
    onIsSendToAllDepts(isAllDepts)
  }

  return (
    <Flex gap={50}>
      <Form.List name="newDepts">
        {(fields, { add, remove }) => (
          <Flex vertical>
            {fields.map((field, index) => (
              <DepartmentFormListItem
                key={field.key}
                deptField={field}
                disabled={isSendToAllDepts}
                pickedDepts={pickedDepts}
                derivePickedDepts={derivePickedDepts}
                onAddItem={add}
                onRemoveItem={() => handleDeleteItem(field.name, remove)}
                isLastItem={index === fields.length - 1}
              />
            ))}
          </Flex>
        )}
      </Form.List>
      <Flex align="end">
        <Form.Item
          className={style.isAllDeptsCheckboxContainer}
          name="isSendToAllDepts"
          valuePropName="checked"
          label="Send to all depts"
          labelAlign="left"
        >
          <Checkbox className={style.largeСheckbox} onChange={e => handleIsAllDeptsChange(e.target.checked)} />
        </Form.Item>
      </Flex>
    </Flex>
  )
}
