import React, { FC } from "react"

import { PhotoViewer } from "@components/PhotoViewer"

import { StandalonePhotoEditor } from "./StandalonePhotoEditor"

type StandalonePhotoProps = {
  isEditable?: boolean
  src?: string
}

export const StandalonePhoto: FC<StandalonePhotoProps> = ({ isEditable, src }) =>
  isEditable ? <StandalonePhotoEditor src={src} /> : <PhotoViewer src={src} />
