import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { useAlert, useAppDispatch, useAppSelector } from "@hooks"
import { newPolicyActions } from "@state/reducers/newPolicy"
import { Button, Collapse, CollapseProps, Tag, Tooltip } from "antd"
import { CollapsibleType } from "antd/es/collapse/CollapsePanel"
import React, { FC } from "react"
import { Permission, Policy } from "@interfaces*"
import { defaultError } from "@helpers"
import { newGroupActions } from "@state/reducers/newGroup"
import { policiesApi } from "@state/services/subApis"

import { formatedLongNames } from "../../helpers/formatedLongNames"

import { DeleteButton } from "@components/buttons/DeleteButton"

import style from "./style.m.scss"

type PoliciesCollapseProps = {
  policies: Array<Policy>
  isGroupsPage?: boolean
  isSecondary?: boolean
}

export const PoliciesCollapse: FC<PoliciesCollapseProps> = ({ policies, isGroupsPage, isSecondary }) => {
  const dispatch = useAppDispatch()
  const { success, error } = useAlert()
  const [deletePolicy] = policiesApi.endpoints.deletePolicy.useMutation()
  const currentPolicyName = useAppSelector(state => state.newPolicyReducer.currentPolicy.name)

  const generateHandleAddPolicyPermissionsClick = (
    allPermissions: { [k: string]: Array<Permission> },
    policyName: string,
    policyId: string
  ) => {
    const allPermisionsArray = Object.entries(allPermissions).map(([sectionName, permission]) => [
      sectionName,
      permission.map(permission => permission.name)
    ])
    const permissionsNames = Object.fromEntries(allPermisionsArray)

    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      dispatch(newPolicyActions.addPolicyPermission(permissionsNames))
      dispatch(newPolicyActions.setCurrentPolicy({ policyName, id: policyId }))
    }
  }

  const handleDeletePolicyClick = async (id: string) => {
    try {
      await deletePolicy(id).unwrap()
      success("Policy was succesfully delete")
    } catch {
      error(defaultError)
    }
  }

  const handleAddPolicyToNewGroup = (id: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      dispatch(newGroupActions.addPolicy({ id }))
    }
  }

  const handleRemovePolicyFromNewGroup = (id: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      dispatch(newGroupActions.removePolicy({ id }))
    }
  }

  const options: CollapseProps["items"] = policies
    .filter(policy => !!Object.entries(policy.permissions).length)
    .map(({ id, name, permissions: allPermissions, inUse }) => {
      const permissionsArray = Object.entries(allPermissions)
      if (!permissionsArray.length)
        return {
          key: id,
          label: formatedLongNames(name),
          showArrow: false,
          extra: <span>No permissions</span>,
          collapsible: "disabled" as CollapsibleType
        }

      const nestedOptions: CollapseProps["items"] = permissionsArray.map(([section, permissions]) => ({
        key: section,
        label: section,
        children: permissions.map(permission => (
          <Tag key={permission.name + id}>{formatedLongNames(permission.name)}</Tag>
        ))
      }))

      return {
        key: id,
        label: (
          <div>
            <Button
              type="text"
              size="small"
              shape="round"
              disabled={!!currentPolicyName}
              icon={!isSecondary ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
              onClick={
                isGroupsPage && !isSecondary
                  ? handleAddPolicyToNewGroup(id)
                  : isGroupsPage && isSecondary
                  ? handleRemovePolicyFromNewGroup(id)
                  : generateHandleAddPolicyPermissionsClick(allPermissions, name, id)
              }
              style={{ float: isGroupsPage ? "right" : "left" }}
            />
            <span>{formatedLongNames(name)}</span>
            {!isGroupsPage && (
              <Tooltip title={inUse ? "Used in group or user settings" : ""} placement="topLeft">
                <DeleteButton
                  disabled={inUse}
                  message={`You are about to delete ${formatedLongNames(name)}`}
                  onConfirm={() => handleDeletePolicyClick(id)}
                  className={style.deleteButton}
                />
              </Tooltip>
            )}
          </div>
        ),
        children: <Collapse bordered={false} size="small" items={nestedOptions} />
      }
    })

  return (
    <Collapse
      className={isGroupsPage && isSecondary ? style.policiesListGroupPage : style.policiesList}
      size="small"
      items={options}
    />
  )
}
