import { Button, Flex, Typography } from "antd"
import React, { FC, useState } from "react"

import style from "./style.m.scss"
import { ManageGroups } from "./ManageGroups"
import { ManageUsers } from "./ManageUsers"

const Manage: FC = () => {
  const [page, setPage] = useState<"groups" | "users">("groups")

  const handleChangePage = (pageName: "groups" | "users") => {
    setPage(pageName)
  }

  return (
    <>
      <Flex>
        <Typography.Title datatest-id="dept-name" level={4} className={style.header}>
          Group - User Assignment
        </Typography.Title>
      </Flex>
      <Flex className={style.buttonsContainer}>
        <Button
          type="link"
          onClick={() => {
            handleChangePage("groups")
          }}
        >
          Groups
        </Button>
        <Button
          type="link"
          onClick={() => {
            handleChangePage("users")
          }}
        >
          Users
        </Button>
      </Flex>
      {page === "groups" ? <ManageGroups /> : <ManageUsers />}
    </>
  )
}

export default Manage
