import { PermissionsSection } from "@state/reducers/newPolicy"

export const generatePoliciesPermissions = (sections: PermissionsSection) => {
  const policiesPemissionsEntries = Object.entries(sections)
    .filter(([, { chosen }]) => chosen.length)
    .map(([sectionsName, { permissions, chosen }]) => {
      const chosenPermissions = permissions.filter(({ utilId }) => chosen.includes(utilId)).map(ability => ability.name)

      return [sectionsName, chosenPermissions]
    })

  return Object.fromEntries(policiesPemissionsEntries)
}
