import { AssignmentHr } from "@interfaces*"
import { Button, Flex, Typography } from "antd"
import React, { FC, useMemo } from "react"
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { assignmentHrsApi } from "@state/services/subApis"

import { ValidatedSelect } from "@components/ValidatedInputs"

type Props = {
  hr: AssignmentHr | null
  hrIds: Array<string>
  setting_id: string
  isLast?: boolean
  isSingle?: boolean
  addLine: () => void
  deleteLine: () => void
  addNewHr: () => void
}

export const HRCard: FC<Props> = ({ hr, hrIds, setting_id, isLast, isSingle, addLine, deleteLine, addNewHr }) => {
  const { availableHrOptions } = assignmentHrsApi.endpoints.fetchAvailableHrOptions.useQuery(null, {
    selectFromResult: ({ data }) => ({ availableHrOptions: data?.available || [] })
  })

  const [addHr] = assignmentHrsApi.endpoints.createAssignmentHr.useMutation()
  const [deleteHr] = assignmentHrsApi.endpoints.deleteAssignedHr.useMutation()

  const handleSelectHr = (value: number) => {
    addHr({ user_id: value.toString(), setting_id })
    addNewHr()
  }

  const handleDeleteHr = () => {
    if (hr) {
      deleteHr(hr.id)
    } else {
      isSingle || deleteLine()
    }
  }

  const filteredHrsOptions = useMemo(
    () => availableHrOptions.filter(({ value }) => !hrIds.includes(value.toString())),
    [hrIds, availableHrOptions]
  )

  return (
    <Flex justify="space-between" align="center" gap="middle">
      {hr ? (
        <Typography.Text>{hr.attributes.full_name}</Typography.Text>
      ) : (
        <ValidatedSelect
          placeholder="Select a HR"
          options={filteredHrsOptions}
          style={{ width: 250 }}
          onChange={handleSelectHr}
        />
      )}
      <div style={{ whiteSpace: "nowrap" }}>
        {isSingle && !hr ? (
          <div style={{ width: 32, display: "inline-block" }} />
        ) : (
          <Button shape="circle" type="text" onClick={handleDeleteHr}>
            <DeleteOutlined />
          </Button>
        )}
        {isLast ? (
          <Button shape="circle" type="text" onClick={addLine}>
            <PlusCircleOutlined />
          </Button>
        ) : (
          <div style={{ width: 32, display: "inline-block" }} />
        )}
      </div>
    </Flex>
  )
}
