import { JobHistoryItem } from "@interfaces*"
import { Col, Flex, Row } from "antd"
import React, { FC } from "react"

import { Title } from "@components/Title"

import style from "./style.m.scss"

type PreviousJobsProps = {
  jobHistory: Array<JobHistoryItem>
}

const PreviousJobs: FC<PreviousJobsProps> = ({ jobHistory }) => (
  <Flex vertical className={style.container}>
    <Title>Job history</Title>

    {jobHistory.map(job => (
      <Row key={job.id}>
        <Row gutter={24} className={style.row}>
          <Col span={12}>Previous position start date:</Col>
          <Col span={8}>{job.previousPositionStartDate}</Col>
          <Col span={12}>Previous job title: </Col>
          <Col span={8}>{job.previousJobTitle}</Col>
          <Col span={12}>Previous department: </Col>
          <Col span={8}>{job.previousDept}</Col>
        </Row>
      </Row>
    ))}
  </Flex>
)

export default PreviousJobs
