import { UpdateUserProfilePhotoParams } from "@state/types/users"
import { Build } from "@state/services/types"

import { deriveFileFormData } from "../../users/utils"
import { PROFILE_PHOTO_URL } from "../urls"

export const updateUserProfilePhoto = (build: Build) =>
  build.mutation<unknown, UpdateUserProfilePhotoParams>({
    query: ({ photo }) => ({
      url: PROFILE_PHOTO_URL,
      method: "PUT",
      body: deriveFileFormData(photo)
    }),
    invalidatesTags: ["User"]
  })
