import { Option } from "@interfaces*"
import { RefSelectProps } from "antd"
import React, { FC, useRef } from "react"

import { ValidatedSelect } from "@components/ValidatedInputs"

import style from "./style.m.scss"

type Props = {
  options: Array<Option>
  placeholder?: string
  value?: string
  onChange?: (value: unknown) => void
  onClear?: () => void
  disabled?: boolean
  helperText?: string
  classNameProp?: string
}

export const SearchSelect: FC<Props> = ({
  options,
  placeholder = "",
  onChange,
  onClear,
  disabled = false,
  helperText,
  value,
  classNameProp
}) => {
  const selectRef = useRef<RefSelectProps>(null)

  const handleSelect = () => {
    if (selectRef.current) selectRef.current.blur()
  }

  const handleClear = () => {
    onClear && onClear()
  }

  return (
    <div className={style.wrapper}>
      {helperText && <span className={style.helperText}>{helperText}</span>}
      <ValidatedSelect
        className={classNameProp}
        ref={selectRef}
        disabled={disabled}
        allowClear
        showSearch
        value={value}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        onClear={handleClear}
        onSelect={handleSelect}
        filterOption={(input: string, option: Option<string | number, string>) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        filterSort={(optionA: Option<string | number, string>, optionB: Option<string | number, string>) =>
          optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
        }
        options={options}
      />
    </div>
  )
}
