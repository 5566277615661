export const POLICIES_URL = "/policies"

export const generatePoliciesURL = (userId?: string) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("extra_fields[policies]", "full_permissions,in_use")
  if (userId) searchParams.set("filter[user_id]", userId)

  return `${POLICIES_URL}${separator}${searchParams.toString()}`
}

export const generateMutationPolicyURL = (id: string) => `${POLICIES_URL}/${id}`
