import { BackendEducationalMaterialsResponse } from "@state/types/educationalMaterials"
import { EducationalMaterial, EducationalMaterialsParams } from "@interfaces"
import { Build } from "@state/services/types"

import { generateGetEducationalMaterialsURL } from "../urls"
import { educationalMaterialsTransformer } from "../transformer"

export const fetchEducationalMaterials = (build: Build) =>
  build.query<Array<EducationalMaterial>, EducationalMaterialsParams>({
    query: params => ({
      url: generateGetEducationalMaterialsURL(params.userId)
    }),
    transformResponse: (data: BackendEducationalMaterialsResponse) => educationalMaterialsTransformer(data),
    providesTags: ["EducationalMaterial"]
  })
