import backendApi from "@state/services/backendApi"

import { setUserPosition } from "./PATCH/setUserPosition"
import { fetchUserPositionChanges } from "./GET/fetchUserPositionChanges"

export const positionsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    setUserPosition: setUserPosition(build),
    fetchUserPositionChanges: fetchUserPositionChanges(build)
  })
})
