import { lazy } from "react"

import { DefaultWrapper } from "@components/PageWrappers"

import { RouteItem } from "./types"
import { NOTIFICATION_SETTINGS_ROUTE, SECURITY_SETTINGS_ROUTE } from "./inboundRoutes"

const NotificationSettings = lazy(() => import("@app/sections/PersonalSettings/NotificationsSettings"))
const SecuritySettings = lazy(() => import("@app/sections/PersonalSettings/SecuritySettings"))

export const personalSettingsMenuRoutes: Array<RouteItem> = [
  {
    path: NOTIFICATION_SETTINGS_ROUTE,
    title: "Notifications",
    slug: "notificationSettings",
    component: NotificationSettings,
    componentWrap: DefaultWrapper,
    hidden: false
  },
  {
    path: SECURITY_SETTINGS_ROUTE,
    title: "Security",
    slug: "securitySettings",
    component: SecuritySettings,
    componentWrap: DefaultWrapper,
    hidden: false
  }
]
