import { generateFullName, transformDescriptionValue, transformNamedValue } from "@helpers"
import {
  UserWithRoles,
  UsersDataFromBackend,
  IncludedDataUsersWithRolesBackend,
  ResponseDataWithIncluded,
  UserName,
  User,
  UserFullInfoWithAccesses,
  AnnualLeavesData,
  NotificationsSettings,
  UpdateNotificationsSettingsParams,
  BackendUsersForFilters,
  BackendUsersFromSearch,
  SearchingUser,
  UserEditOptions,
  UserLog,
  UserForFilter
} from "@interfaces"
import { BackendUserRoleByUserIdResponse } from "@state/types/userRoles"
import {
  AnnualLeavesDataFromBackend,
  BackendSearchingUser,
  BackendNotificationSettings,
  LeaveFromBackend,
  ManagerFromBackend,
  ProfileDataFromBackend,
  SubordinateFromBackend,
  UserFromBackend,
  UserWithRolesFromBackend,
  BackendUserEditOptions,
  BackendUserTeamResponse,
  BackendUserLogs,
  BackendUserIncludedLog
} from "@state/types/users"

import { generateUserPhotoURL } from "./urls"

const includedLogsMap = (included: Array<BackendUserIncludedLog>) => {
  const map: { [k: string]: BackendUserIncludedLog } = {}

  included.forEach(user => {
    map[user.id] = user
  })

  return map
}

export const usersTransformer = (responseData: UsersDataFromBackend) => {
  const transformData: Array<User> = responseData.data.map((user: UserFromBackend) => {
    const {
      first_name,
      last_name,
      department,
      position,
      email,
      working_hours,
      additional_emails,
      location,
      main_product,
      additional_products,
      closed_at,
      is_terminated,
      employment_status,
      gender,
      emergency_contacts,
      employee_type
    } = user.attributes

    return {
      id: user.id,
      firstName: first_name || "",
      lastName: last_name || "",
      corporateEmail: email || "",
      gender: gender || "",
      emergencyContacts: emergency_contacts || [],
      department: transformNamedValue(department),
      position: transformNamedValue(position),
      workingHours: working_hours || 0,
      additionalEmails: additional_emails || [],
      project: null,
      location: location || "",
      mainProduct: main_product || "",
      additionalProducts: additional_products || [],
      closedAt: closed_at || undefined,
      isTerminated: is_terminated ?? false,
      employmentStatus: transformDescriptionValue(employment_status),
      employeeType: employee_type || ""
    }
  })
  return transformData
}

export const userProfileTransformer = (responseData: ProfileDataFromBackend) => {
  const {
    first_name,
    second_name,
    last_name,
    department,
    position,
    email,
    additional_emails,
    working_hours,
    birth_date,
    work_begin_date,
    work_end_date,
    photo_id,
    gitlab,
    slacks,
    location,
    location_id,
    phone_number,
    accesses,
    grade,
    status,
    employment_status,
    work_format,
    work_schedule,
    main_product,
    additional_products,
    years_of_service,
    is_terminated,
    tags,
    leaves,
    direct_manager,
    job_description,
    subordinates,
    commission_rate,
    has_commission,
    available_systems,
    gender,
    emergency_contacts,
    employee_type
  } = responseData.data.attributes

  return {
    id: responseData.data.id,
    firstName: first_name,
    secondName: second_name,
    lastName: last_name,
    corporateEmail: email,
    additionalEmails: additional_emails,
    workingHours: working_hours,
    birthDate: birth_date,
    hireDate: work_begin_date,
    separationDate: work_end_date,
    photo: photo_id ? generateUserPhotoURL(photo_id) : undefined,
    gitlab,
    slack: slacks ? slacks.join(", ") : "-",
    location,
    locationId: location_id,
    phone: phone_number,
    grade,
    status,
    gender,
    emergencyContacts: emergency_contacts || [],
    employmentStatus: transformDescriptionValue(employment_status),
    workFormat: transformDescriptionValue(work_format),
    workSchedule: transformDescriptionValue(work_schedule),
    mainProduct: main_product,
    additionalProducts: additional_products,
    yearsOfService: years_of_service,
    isTerminated: is_terminated,
    tags: tags?.map(({ name }) => name),
    accesses: accesses
      ? {
          phone: accesses.phone_number,
          photo: accesses.photo,
          tags: accesses.tags
        }
      : undefined,
    leaves: leaves?.map(transformUserLeaveData),
    directManager: transformDirectManager(direct_manager),
    jobDescription: job_description || "",
    department: transformNamedValue(department),
    position: transformNamedValue(position),
    subordinates: subordinates?.map(transformSubordinate),
    hasCommission: has_commission,
    commissionRate: commission_rate,
    availableSystems: available_systems,
    employeeType: employee_type
  }
}

const transformLeave = (leave: LeaveFromBackend) => {
  const { days_count, finished_at, started_at } = leave

  return {
    daysCount: days_count,
    finishedAt: finished_at,
    startedAt: started_at
  }
}

const transformSubordinate = (subordinate: SubordinateFromBackend) => {
  const { id, full_name, department, job_title, photo_id } = subordinate

  return {
    id: id.toString(),
    fullName: full_name,
    department,
    jobTitle: job_title,
    photo: photo_id ? generateUserPhotoURL(photo_id) : null
  }
}

const transformUserLeaveData = (leaveData: AnnualLeavesDataFromBackend): AnnualLeavesData => {
  const { year, annual_leaves, annual_leaves_amount, annual_leaves_type, sick_leaves, sick_leaves_amount } = leaveData

  return {
    year,
    annualLeaves: annual_leaves.map(transformLeave),
    annualLeavesAmount: annual_leaves_amount,
    annualLeavesType: annual_leaves_type,
    sickLeaves: (sick_leaves || []).map(transformLeave),
    sickLeavesAmount: { spent: sick_leaves_amount.spent ?? 0 }
  }
}

const transformDirectManager = (manager: ManagerFromBackend | undefined) => {
  if (!manager) return undefined

  const { id, full_name } = manager

  if (id && full_name) return { id, fullName: full_name }

  return null
}

export type Profile = UserFullInfoWithAccesses

export const userWithRolesTransformer = (
  responseData: ResponseDataWithIncluded<UserWithRolesFromBackend, IncludedDataUsersWithRolesBackend>
) => {
  const transformData: Array<UserWithRoles> = responseData.data.map((user: UserWithRolesFromBackend) => {
    const { first_name: firstName, last_name: lastName, second_name: secondName } = user.attributes

    const developerRoleId = responseData.included.find(
      includedItem => includedItem.type === "roles" && includedItem.attributes?.name === "developer"
    )!.id

    const developerUserRoleId = responseData.included.find(
      includedItem =>
        includedItem.type === "user_roles" &&
        includedItem.attributes.role_id === developerRoleId &&
        includedItem.attributes.user_id === String(user.attributes.user_id)
    )!.id

    return {
      id: String(user.attributes.user_id),
      firstName,
      lastName,
      secondName,
      // TODO исправить, когда будет dropdown select с ролями для добавления
      userRoleId: Number(developerUserRoleId)
    }
  })

  return transformData
}

export const transformUserRoleToBaseInfo = (response: BackendUserRoleByUserIdResponse): UserName => ({
  firstName: response.included[0].attributes.first_name,
  lastName: response.included[0].attributes.last_name,
  secondName: response.included[0].attributes.second_name
})

export const transformUsersForFilters = (response: BackendUsersForFilters): Array<UserForFilter> =>
  response.data.map(user => ({
    id: user.id,
    name: generateFullName(user.attributes.last_name, user.attributes.first_name),
    products: user.attributes.products
  }))

export const transformUsersWithoutPosition = (response: BackendUsersFromSearch): Array<UserForFilter> =>
  response.users.map(user => ({
    id: String(user.id),
    name: user.fullname,
    products: [user.main_product, ...user.additional_products]
  }))

export const transformUserTeamResponse = (response: BackendUserTeamResponse) =>
  response.team.map(
    ({
      id,
      department,
      first_name,
      is_chief,
      last_name,
      photo_id,
      position,
      status,
      vacation_start,
      vacation_finish
    }) => ({
      id: id.toString(),
      firstName: first_name,
      lastName: last_name,
      isChief: is_chief,
      department: department || "",
      position: position || "",
      status: status || "",
      photo: photo_id ? photo_id.toString() : undefined,
      vacationStart: vacation_start,
      vacationFinish: vacation_finish
    })
  )

export const transformSerachingUser = (response: { users: Array<BackendSearchingUser> }): Array<SearchingUser> =>
  response.users.map(user => ({
    id: String(user.id),
    email: user.email,
    fullName: user.fullname,
    firstName: user.first_name,
    secondName: user.second_name,
    lastName: user.last_name
  }))

export const transformNotificationSettings = (response: BackendNotificationSettings): NotificationsSettings => {
  const { id, uid, notifications } = response.slack_settings.data

  return {
    id,
    email: uid,
    notifications
  }
}

export const deriveUserNotificationSettings = (params: UpdateNotificationsSettingsParams) => ({
  data: {
    id: params.id,
    type: "slack_settings",
    attributes: {
      notifications: Object.entries(params.notifications)
        .filter(([, value]) => Boolean(value))
        .reduce((acc, [name]) => ({ ...acc, [name]: {} }), {}),
      uid: params.email
    }
  },
  format: ":jsonapi"
})

export const transformUserEditOptions = (response: BackendUserEditOptions): UserEditOptions => {
  const {
    tags,
    departments,
    employment_statuses,
    grades,
    locations,
    products,
    work_formats,
    work_schedules,
    available_systems,
    genders,
    employee_types
  } = response

  return {
    tags: (tags || []).map(tag => tag.name),
    departments,
    employmentStatuses: employment_statuses,
    grades,
    genders,
    locations,
    products,
    workFormats: work_formats,
    workSchedules: work_schedules,
    availableSystems: available_systems,
    employeeTypes: employee_types
  }
}

export const transformUserLogs = (res: BackendUserLogs): Array<UserLog> => {
  const includedMap = includedLogsMap(res.included)

  return res.data.map(log => {
    const { id, attributes } = log
    const author = includedMap[attributes.author_id]

    return {
      id,
      userId: attributes.user_id,
      author: log.relationships.author.data
        ? {
            id: author.id,
            email: author.attributes.email
          }
        : null,
      record: attributes.record,
      createdAt: attributes.created_at
    }
  })
}
