import backendApi from "@state/services/backendApi"

import { fetchConferences } from "./GET/fetchConferences"
import { createConference } from "./POST/createConference"
import { deleteConference } from "./DELETE/deleteConference"

export const conferencesApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchConferences: fetchConferences(build),
    createConference: createConference(build),
    deleteConference: deleteConference(build)
  })
})
