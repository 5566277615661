import backendApi from "@state/services/backendApi"

import { fetchEmploymentSettings } from "./GET/fetchEmploymentSettings"
import { createEmploymentSetting } from "./POST/createEmploymentSetting"
import { updateEmploymentSetting } from "./PUT/updateEmploymentSetting"
import { deleteEmploymentSetting } from "./DELETE/deleteEmploymentSetting"

export const employmentSettingsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchEmploymentSettings: fetchEmploymentSettings(build),
    createEmploymentSetting: createEmploymentSetting(build),
    updateEmploymentSetting: updateEmploymentSetting(build),
    deleteEmploymentSetting: deleteEmploymentSetting(build)
  })
})
