import React, { FC } from "react"
import { Table } from "antd"
import { ColumnType } from "antd/es/table"
import { EducationalMaterial } from "@interfaces"
import { educationalMaterialsApi } from "@state/services/subApis"

import { useMaterialsTableColumns } from "./helpers/useMaterialsTableColumns"

type MaterialsTableProps = {
  userId: string
  isEditMode?: boolean
}

export const MaterialsTable: FC<MaterialsTableProps> = ({ userId, isEditMode }) => {
  const { data, isLoading, isFetching } = educationalMaterialsApi.endpoints.fetchEducationalMaterials.useQuery(
    { userId },
    {}
  )

  const columns = useMaterialsTableColumns(isEditMode)

  return (
    <Table
      columns={columns as Array<ColumnType<EducationalMaterial>>}
      dataSource={data}
      rowKey="id"
      loading={isLoading || isFetching}
      pagination={false}
    />
  )
}
