import { SetUserPositionParams } from "@state/types/positions"
import { Build } from "@state/services/types"

import { generateSetUserPositionURL } from "../urls"

export const setUserPosition = (build: Build) =>
  build.mutation<unknown, SetUserPositionParams>({
    query: params => {
      const { positionId, userId } = params
      return {
        url: generateSetUserPositionURL(positionId),
        method: "PATCH",
        body: {
          user_id: userId
        },
        format: ":jsonapi"
      }
    },
    invalidatesTags: ["Position", "User"]
  })
