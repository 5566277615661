import backendApi from "../../backendApi"

import { fetchUserById } from "./GET/fetchUserById"
import { fetchPagedUsers } from "./GET/fetchPagedUsers"
import { fetchUsersForFilters } from "./GET/fetchUsersForFilters"
import { fetchUsersWithoutPosition } from "./GET/fetchUsersWithoutPosition"
import { createUser } from "./POST/createUser"
import { editUser } from "./PUT/editUser"
import { updateUserHireDate } from "./PUT/updateUserHireDate"
import { updateUserPermissions } from "./PUT/updateUserPermissions"
import { updateUserPhoto } from "./PUT/updateUserPhoto"
import { deleteUserPhoto } from "./DELETE/deleteUserPhoto"
import { fetchUserTeam } from "./GET/fetchUserTeam"
import { searchUsers } from "./GET/searchUsers"
import { fetchNotificationSettings } from "./GET/fetchNotificationSettings"
import { fetchNotificationSettingsOptions } from "./GET/fetchNotificationSettingsOptions"
import { updateNotificationSettings } from "./PUT/updateNotificationSettings"
import { fetchUserEditOptions } from "./GET/fetchUserEditOptions"
import { fetchFiltersOptions } from "./GET/fetchFiltersOptions"
import { separateUser } from "./PUT/separateUser"
import { transferUser } from "./PUT/transferUser"
import { fetchUserLogs } from "./GET/fetchUserLogs"
import { fetchUserPersonalSettings } from "./GET/fetchUserPersonalSettings"
import { removeUser2fa } from "./POST/removeUser2fa"

export const usersApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchUserById: fetchUserById(build),
    fetchPagedUsers: fetchPagedUsers(build),
    fetchUsersForFilters: fetchUsersForFilters(build),
    fetchUsersWithoutPosition: fetchUsersWithoutPosition(build),
    fetchUserPersonalSettings: fetchUserPersonalSettings(build),
    createUser: createUser(build),
    editUser: editUser(build),
    updateUserHireDate: updateUserHireDate(build),
    updateUserPermissions: updateUserPermissions(build),
    updateUserPhoto: updateUserPhoto(build),
    deleteUserPhoto: deleteUserPhoto(build),
    fetchUserTeam: fetchUserTeam(build),
    searchUsers: searchUsers(build),
    fetchNotificationSettings: fetchNotificationSettings(build),
    fetchNotificationSettingsOptions: fetchNotificationSettingsOptions(build),
    updateNotificationSettings: updateNotificationSettings(build),
    fetchUserEditOptions: fetchUserEditOptions(build),
    fetchFiltersOptions: fetchFiltersOptions(build),
    separateUser: separateUser(build),
    transferUser: transferUser(build),
    fetchUserLogs: fetchUserLogs(build),
    removeUser2fa: removeUser2fa(build)
  })
})
