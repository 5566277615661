import { DepartmentJob, UpdateDeptBodyReq } from "@interfaces"
import { BackendUpdateDeptResponse } from "@state/types/departments"
import { Build } from "@state/services/types"

import { generateDepartmentByIdURL } from "../urls"
import { transformDepartmentUpdateResponse } from "../transformDepartments"

export const updateDepartment = (build: Build) =>
  build.mutation<Array<DepartmentJob>, UpdateDeptBodyReq>({
    query: body => ({
      url: generateDepartmentByIdURL(body.department.id),
      method: "PUT",
      body
    }),
    invalidatesTags: ["Department", "User", "Position", "Vacation"],
    transformResponse: (response: BackendUpdateDeptResponse) => transformDepartmentUpdateResponse(response)
  })
