import { DepartmentCard } from "@interfaces*"
import React, { FC, useState } from "react"

import DepartmentPreview from "./DepartmentPreview"
import style from "./style.m.scss"
import DepartmentForm from "./DepartmentForm"

type DepartmentInfoProps = {
  department: DepartmentCard
  isCEO: boolean
}

const DepartmentInfo: FC<DepartmentInfoProps> = ({ department, isCEO }) => {
  const [editMode, setEditMode] = useState(false)

  const onEditClick = () => {
    setEditMode(!editMode)
  }

  return (
    <div className={style.container}>
      {editMode ? (
        <DepartmentForm toggleEditMode={onEditClick} department={department} isCEO={isCEO} />
      ) : (
        <DepartmentPreview department={department} isCEO={isCEO} toggleEditMode={onEditClick} />
      )}
    </div>
  )
}

export default DepartmentInfo
