import React, { FC } from "react"

import { ExpandableSection } from "../../../components/ExpandableSection"

import { EditButton } from "@components/buttons/EditButton"

import style from "./style.m.scss"

type ViewPanelProps = {
  text: string
  isExpanded: boolean
  onToggleExpanded: () => void
  onEdit?: () => void
}

export const ViewPanel: FC<ViewPanelProps> = ({ text, isExpanded, onToggleExpanded, onEdit }) => (
  <ExpandableSection
    title="Job description"
    actions={onEdit && <EditButton onClick={onEdit} data-testid="edit-job-description" />}
    isExpanded={isExpanded}
    onToggleExpanded={onToggleExpanded}
  >
    <div className={style.text} data-testid="job-description">
      {text}
    </div>
  </ExpandableSection>
)
