export const debounce = (func: (x: unknown) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout

  return function (this: unknown, ...args: Array<unknown>) {
    const context = this

    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(context, args)
    }, delay)
  }
}
