import { EducationalMaterialsBulkAdd, StaffEducationalMaterials } from "@app/sections/StaffEducationalMaterials"
import { Abilities } from "@interfaces*"

import { DefaultWrapper } from "@components/PageWrappers"

import { RouteItem } from "./types"
import {
  EDUCATIONAL_MATERIALS_BULK_ADD_ROUTE,
  PERSONAL_EDUCATIONAL_MATERIALS_ROUTE
} from "./inboundRoutes/educationalMaterials"

export const educationalMaterialsMenuRoutes = (abilities: Abilities | null): Array<RouteItem> => [
  {
    path: PERSONAL_EDUCATIONAL_MATERIALS_ROUTE,
    title: "My educational materials",
    slug: "my-educational-materials",
    component: StaffEducationalMaterials,
    componentWrap: DefaultWrapper,
    hidden: false
  },
  {
    path: EDUCATIONAL_MATERIALS_BULK_ADD_ROUTE,
    title: "Bulk add",
    slug: "bulk-add",
    component: EducationalMaterialsBulkAdd,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.education_materials?.edit_all
  }
]
