import React, { FC } from "react"
import { Flex, List, Typography } from "antd"
import { useAppDispatch } from "@hooks"
import { manageUsersPermissionsActions } from "@state/reducers/manageUsersPermissions"
import { ManageUsersPermissionsEntity } from "@interfaces*"
import { AccessType } from "@enums"
import { formatedLongNames } from "@app/sections/PolicySystem/helpers/formatedLongNames"

import { DeleteButton } from "@components/buttons/DeleteButton"

import style from "./style.m.scss"

const DISPLAY_NAME_LENGTH = 40

type MainListProps = {
  userName: string
  groups: Array<ManageUsersPermissionsEntity>
  policies: Array<ManageUsersPermissionsEntity>
  children: React.ReactNode
}

const MainList: FC<MainListProps> = ({ userName, groups, policies, children }) => {
  const dispatch = useAppDispatch()
  const groupsOptions = groups
    .map(group => ({ ...group, type: AccessType.Group }))
    .sort((a, b) => a.name.localeCompare(b.name))
  const policiesOptions = policies
    .map(policy => ({ ...policy, type: AccessType.Policy }))
    .sort((a, b) => a.name.localeCompare(b.name))

  const handleDelete = (entity: ManageUsersPermissionsEntity & { type: AccessType }) => {
    dispatch(manageUsersPermissionsActions.deleteEntity({ entity, type: entity.type }))
  }

  return (
    <Flex vertical className={style.listWrapper}>
      {children}
      <Typography.Title level={5} className={style.name}>
        {formatedLongNames(userName, DISPLAY_NAME_LENGTH)}
      </Typography.Title>
      <List
        className={style.list}
        itemLayout="horizontal"
        dataSource={[...groupsOptions, ...policiesOptions]}
        renderItem={entity => (
          <List.Item
            actions={[
              <DeleteButton
                tooltipPlacement="left"
                onConfirm={() => {
                  handleDelete(entity)
                }}
              />
            ]}
          >
            <List.Item.Meta title={formatedLongNames(entity.name, DISPLAY_NAME_LENGTH)} />
          </List.Item>
        )}
      />
    </Flex>
  )
}

export default MainList
