import React, { FC } from "react"
import { Button, ButtonProps, Tooltip, Upload } from "antd"
import { UploadChangeParam, UploadFile } from "antd/es/upload"
import { UploadOutlined } from "@ant-design/icons"

export const SUPPORTED_FILES = "image/png, image/jpeg, image/webp"

type UploadButtonProps = Omit<ButtonProps, "icon" | "onChange"> & {
  onChange: (file: File) => void
  className?: string
}

export const UploadButton: FC<UploadButtonProps> = ({ onChange, className }) => {
  const handleFileChange = (info: UploadChangeParam<UploadFile<unknown>>) => {
    info.fileList[0]?.originFileObj && onChange(info.fileList[0].originFileObj)
  }

  return (
    <div className={className}>
      <Tooltip
        title={
          <>
            <div>Upload new logo</div>
            <div>max allowed size: 2 MB</div>
          </>
        }
      >
        <Upload
          onChange={handleFileChange}
          showUploadList={false}
          maxCount={1}
          accept={SUPPORTED_FILES}
          beforeUpload={() => false}
        >
          <Button icon={<UploadOutlined />} data-testid="upload-logo-button">
            Click to upload
          </Button>
        </Upload>
      </Tooltip>
    </div>
  )
}
