import { UserEditOptions } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendUserEditOptions } from "@state/types/users"

import { USER_EDIT_OPTIONS_URL } from "../urls"
import { transformUserEditOptions } from "../transformer"

export const fetchUserEditOptions = (build: Build) =>
  build.query<UserEditOptions, void>({
    query: () => ({
      url: USER_EDIT_OPTIONS_URL
    }),
    providesTags: ["EmploymentSettings", "Tag", "Department", "Position"],
    transformResponse: (data: BackendUserEditOptions) => transformUserEditOptions(data)
  })
