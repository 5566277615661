import backendApi from "../../backendApi"

import { fetchPersonalDocuments } from "./GET/fetchPersonalDocuments"
import { createPersonalDocument } from "./POST/createPersonalDocument"
import { updatePersonalDocument } from "./PUT/updatePersonalDocument"
import { deletePersonalDocument } from "./DELETE/deletePersonalDocument"
import { deleteAllPersonalDocuments } from "./DELETE/deleteAllPersonalDocuments"

export const personalDocumentsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchPersonalDocuments: fetchPersonalDocuments(build),
    createPersonalDocument: createPersonalDocument(build),
    updatePersonalDocument: updatePersonalDocument(build),
    deletePersonalDocument: deletePersonalDocument(build),
    deleteAllPersonalDocuments: deleteAllPersonalDocuments(build)
  })
})
