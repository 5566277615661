import backendApi from "../../backendApi"

import { fetchAssignmentHr } from "./GET/fetchAssignmentHr"
import { createAssignmentHr } from "./POST/createAssignmentHr"
import { changeAssignmentHr } from "./PUT/changeAssignmentHr"
import { deleteAssignedHr } from "./DELETE/deleteAssignedHr"
import { fetchAvailableHrOptions } from "./GET/fetchAvailableHrOptions"

export const assignmentHrsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchAssignmentHr: fetchAssignmentHr(build),
    createAssignmentHr: createAssignmentHr(build),
    changeAssignmentHr: changeAssignmentHr(build),
    deleteAssignedHr: deleteAssignedHr(build),
    fetchAvailableHrOptions: fetchAvailableHrOptions(build)
  })
})
