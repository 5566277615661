import React, { FC, useState } from "react"
import { UserFullInfoWithAccesses } from "@interfaces"
import { Button } from "antd"
import { useAlert, useProfileHandlers } from "@hooks"

import { TransferData } from "./types"
import { TransferModal } from "./TransferModal"

type TransferProps = {
  user: UserFullInfoWithAccesses
}

export const Transfer: FC<TransferProps> = ({ user }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { transferUser, isTransferUserLoading } = useProfileHandlers()

  const { success } = useAlert()

  const handleTransferClick = () => {
    setIsModalVisible(true)
  }

  const handleSave = async (transferData: TransferData) => {
    const result = await transferUser(user.id!, transferData.locationId)

    if (result) {
      setIsModalVisible(false)
      success("User transfered successfully")
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button onClick={handleTransferClick} data-testid="transfer-button">
        Transfer
      </Button>
      <TransferModal
        user={user}
        isOpen={isModalVisible}
        onSave={handleSave}
        onCancel={handleCancel}
        isLoading={isTransferUserLoading}
      />
    </>
  )
}
