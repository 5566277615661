import { SaveHolidayQuery } from "@interfaces"
import { Build } from "@state/services/types"

import { HOLIDAYS_CREATE_URL } from "../urls"

export const createHoliday = (build: Build) =>
  build.mutation<void, SaveHolidayQuery>({
    query: body => ({
      url: HOLIDAYS_CREATE_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["Holiday", "HolidayLogs"]
  })
