import { Flex, Typography } from "antd"
import React, { FC } from "react"
import { employmentSettingsApi } from "@state/services/subApis"
import { ACTIVE_EMPLOYMENT_STATUS, INACTIVE_EMPLOYMENT_STATUS } from "@helpers"

import EmploymentItem from "./EmploymentItem/EmploymentItem"

const order = ["work_formats", "employment_statuses", "work_schedules"]

const systemValuesMap: Record<string, Array<string>> = {
  work_formats: [],
  work_schedules: [],
  employment_statuses: [ACTIVE_EMPLOYMENT_STATUS, INACTIVE_EMPLOYMENT_STATUS]
}

const EmploymentSettings: FC = () => {
  const { employmentSettings } = employmentSettingsApi.endpoints.fetchEmploymentSettings.useQuery("", {
    selectFromResult: ({ data, isLoading }) => ({
      employmentSettings: data || [],
      isLoading
    })
  })

  const settingsSections = Object.entries(employmentSettings).sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))

  return (
    <>
      <Typography.Title level={4} style={{ marginTop: "0" }}>
        Employments settings
      </Typography.Title>
      <Flex justify="space-between" gap="large">
        {settingsSections.map(item => (
          <EmploymentItem key={item[0]} settings={item[1]} type={item[0]} systemValues={systemValuesMap[item[0]]} />
        ))}
      </Flex>
    </>
  )
}

export default EmploymentSettings
