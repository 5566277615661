export const STANDART_RES_SIZE = 30
export const PAGE_SIZE_10 = 10
export const PAGE_SIZE_50 = 50

export const PAGE_SIZE_OPTIONS = [PAGE_SIZE_10, STANDART_RES_SIZE, PAGE_SIZE_50]

export const MAX_RES_SIZE = 100

export const STANDART_PAGE_NUMBER = 1

export const defaultError = "Something went wrong"

export const ALERT_CUSTOM_EVENT = "erp-alert"

export const MAX_PHOTO_SIZE = 10485760
