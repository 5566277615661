import React, { FC } from "react"
import { Button, ButtonProps, Popconfirm, Tooltip } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { TooltipPlacement } from "antd/es/tooltip"

type DeleteButtonProps = Omit<ButtonProps, "icon" | "type" | "onClick"> & {
  message?: React.ReactNode
  tooltipText?: string
  onConfirm: () => void
  tooltipPlacement?: TooltipPlacement
}

export const DeleteButton: FC<DeleteButtonProps> = ({
  message,
  onConfirm,
  tooltipText = "Delete",
  tooltipPlacement = "top",
  ...restProps
}) => {
  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
  }

  const handleConfirm = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()
    onConfirm()
  }

  return (
    <Tooltip title={tooltipText} placement={tooltipPlacement}>
      {message ? (
        <Popconfirm title={message} onConfirm={handleConfirm} onCancel={handleClick} placement="topLeft">
          <Button icon={<DeleteOutlined />} type="text" onClick={handleClick} {...restProps} />
        </Popconfirm>
      ) : (
        <Button icon={<DeleteOutlined />} type="text" onClick={handleConfirm} {...restProps} />
      )}
    </Tooltip>
  )
}
