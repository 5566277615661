import { Permission } from "@interfaces*"

export const convertPermissions = (permissions: Array<Permission>) => {
  const convertedPermissions: { [k: string]: Array<string> } = {}

  permissions.forEach(permission => {
    if (!convertedPermissions[permission.section]) convertedPermissions[permission.section] = []
    convertedPermissions[permission.section].push(permission.name)
  })

  return convertedPermissions
}
