import backendApi from "@state/services/backendApi"

import { fetchUserProfile } from "./GET/fetchUserProfile"
import { editUserProfile } from "./PATCH/editUserProfile"
import { updateUserProfilePhoto } from "./PUT/updateUserProfilePhoto"
import { deleteUserProfilePhoto } from "./DELETE/deleteUserProfilePhoto"

export const userProfileApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchUserProfile: fetchUserProfile(build),
    editUserProfile: editUserProfile(build),
    updateUserProfilePhoto: updateUserProfilePhoto(build),
    deleteUserProfilePhoto: deleteUserProfilePhoto(build)
  })
})
