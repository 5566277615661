export enum Separators {
  Dot = ".",
  Dash = "-",
  BackSlash = "/",
  Space = " ",
  Colon = ":",
  Semicolon = ";"
}

export const generateDateTemplate = (signature: string, separator: Separators = Separators.Dot) => {
  let counter = 0

  return signature
    .split("")
    .reduce((acc: Array<string>, item, index, arr) => {
      counter += 1
      if (item !== arr[index + 1]) {
        const result = acc.concat(new Array(counter).fill(item).join(""))
        counter = 0
        return result
      }

      return acc
    }, [])
    .join(separator)
}
