import React, { FC, useMemo } from "react"
import { FormInstance } from "antd"
import { DepartmentCard, DepartmentOption, DepartmentProducts, UserFullInfoWithAccesses } from "@interfaces"
import { filterOption } from "@helpers"

import { UserFormFields } from "../../../types"

import { FormField } from "@components/FormField"
import { ValidatedSelect } from "@components/ValidatedInputs"

import { deriveProductsValidator } from "./utils"

type PositionProps = {
  form: FormInstance<UserFormFields>
  user: UserFullInfoWithAccesses
  departments: Array<DepartmentOption>
  departmentProducts?: DepartmentProducts
  selectedDepartment?: DepartmentCard
}

export const Position: FC<PositionProps> = ({ form, user, departments, departmentProducts, selectedDepartment }) => {
  const { departmentOptions, positionOptionsMapping } = useMemo(() => {
    const departmentOptions = departments.map(department => ({ label: department.label, value: department.value }))

    const positionOptionsMapping = departments.reduce<Record<string, Array<{ label: string; value: string }>>>(
      (acc, department) => {
        const basicAvailablePositions = department.positions
        const allAvailablePositions =
          department.value === user.department?.id && user.position
            ? [{ label: user.position.name, value: user.position.id }, ...basicAvailablePositions]
            : basicAvailablePositions
        return {
          ...acc,
          [department.value]: allAvailablePositions
        }
      },
      {}
    )
    return { departmentOptions, positionOptionsMapping }
  }, [departments, user])

  const positionOptions = selectedDepartment?.id ? positionOptionsMapping[selectedDepartment.id] || [] : []

  const handleDepartmentChange = () => {
    form.setFieldsValue({ position: null })
  }

  return (
    <>
      <FormField
        label="Department"
        fieldName="department"
        editor={
          <ValidatedSelect
            options={departmentOptions}
            showSearch
            filterOption={filterOption}
            onChange={handleDepartmentChange}
            data-testid="department-select"
          />
        }
      />
      <FormField
        label="Job title"
        fieldName="position"
        editor={<ValidatedSelect options={positionOptions} showSearch filterOption={filterOption} />}
        rules={[
          (form: FormInstance<UserFormFields>) => ({
            validator: deriveProductsValidator(
              form,
              [user.mainProduct ?? "", ...(user.additionalProducts ?? [])],
              selectedDepartment,
              departmentProducts
            ),
            warningOnly: true
          })
        ]}
        dependencies={["mainProduct", "additionalProducts"]}
        data-testid="position-select"
      />
    </>
  )
}
