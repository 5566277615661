import React, { FC } from "react"

export const PersonalSettingsIcon: FC = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 512 512" fill="none">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path
        d="M1720 5103 c-52 -8 -141 -29 -197 -48 -204 -68 -347 -157 -504 -314
-241 -241 -362 -514 -376 -846 -9 -227 34 -429 134 -630 78 -156 186 -294 308
-395 l64 -53 -92 -47 c-202 -102 -341 -203 -503 -364 -249 -250 -413 -536
-498 -871 -51 -202 -56 -286 -56 -936 l0 -599 1175 0 1175 0 -2 213 -3 212
-958 3 -958 2 4 433 c3 366 6 443 21 507 49 209 126 389 239 554 75 110 242
280 354 359 168 119 397 216 605 254 130 24 485 24 614 0 101 -18 230 -61 343
-113 41 -19 78 -31 82 -27 23 24 206 329 202 337 -3 5 -35 22 -71 38 -125 57
-125 44 -8 161 192 192 307 405 363 667 21 101 23 348 4 450 -52 278 -165 495
-360 691 -198 199 -415 312 -689 359 -133 23 -276 24 -412 3z m367 -427 c313
-67 566 -301 653 -606 30 -103 37 -279 17 -386 -59 -310 -287 -565 -592 -661
-83 -26 -102 -28 -250 -28 -153 0 -164 2 -255 32 -179 61 -335 176 -437 326
-105 151 -143 266 -151 449 -9 228 48 404 185 573 123 152 348 283 528 308 73
10 239 6 302 -7z"
      />
      <path
        d="M3610 2321 c0 -179 -3 -241 -12 -248 -7 -6 -31 -16 -53 -23 -22 -7
-66 -24 -98 -38 l-58 -25 -160 159 -159 159 -145 -145 c-80 -80 -145 -149
-145 -155 0 -5 70 -80 156 -166 l157 -157 -20 -34 c-12 -18 -31 -66 -44 -105
l-23 -73 -223 0 -223 0 0 -215 0 -215 235 -2 236 -3 23 -65 c12 -36 31 -82 41
-103 l18 -39 -159 -159 -159 -159 145 -145 c80 -80 150 -145 156 -145 6 0 79
70 163 156 l151 155 62 -29 c35 -16 84 -35 111 -42 l47 -13 0 -223 0 -224 210
0 210 0 0 234 0 234 53 17 c28 10 78 29 109 42 l58 26 142 -134 c79 -73 155
-144 171 -158 l28 -25 144 144 c80 80 145 149 145 155 0 5 -70 78 -157 162
l-157 152 26 53 c15 29 34 79 44 111 l18 57 223 0 223 0 0 210 0 210 -234 0
-234 0 -17 53 c-10 28 -29 78 -43 110 l-25 58 25 27 c44 48 243 276 257 295
13 17 2 31 -130 163 -79 79 -148 144 -154 144 -5 0 -80 -70 -166 -156 l-155
-155 -50 24 c-27 14 -75 33 -106 43 l-58 18 0 223 0 223 -210 0 -210 0 0 -239z
m301 -621 c181 -35 318 -176 349 -360 14 -79 -4 -179 -45 -260 -77 -151 -272
-250 -437 -221 -188 33 -326 171 -360 361 -37 211 115 428 332 476 70 16 95
16 161 4z"
      />
    </g>
  </svg>
)
