import { UserFiltersOptions } from "@interfaces"
import { Build } from "@state/services/types"

import { USER_FILTERS_OPTIONS_URL } from "../urls"

export const fetchFiltersOptions = (build: Build) =>
  build.query<UserFiltersOptions, null>({
    query: () => ({
      url: USER_FILTERS_OPTIONS_URL
    })
  })
