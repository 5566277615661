import { Form } from "antd"
import React, { FC } from "react"

import { NewBulkForm } from "../../helpers/types"

import { LinkFormListItem } from "./LinkFormListItem"

type LinkFormListprops = {
  onDelete: (
    fieldName: number,
    remove: (index: number | Array<number>) => void,
    formFieldName: keyof NewBulkForm
  ) => void
}

export const LinkFormList: FC<LinkFormListprops> = ({ onDelete }) => {
  const handleDeleteItem = (fieldName: number, remove: (index: number) => void) => {
    onDelete(fieldName, remove, "newLinks")
  }

  return (
    <Form.List name="newLinks">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <LinkFormListItem
              key={field.key}
              linkField={field}
              onAddItem={add}
              onRemoveItem={() => handleDeleteItem(field.name, remove)}
              isLastItem={index === fields.length - 1}
            />
          ))}
        </>
      )}
    </Form.List>
  )
}
