import { LeaveType } from "@interfaces"
import { BackendLeaveTypesReponse } from "@state/types/vacations"

export const transformLeaveTypes = (res: BackendLeaveTypesReponse): Array<LeaveType> =>
  res.data.map(leaveType => {
    const { id, attributes } = leaveType

    return {
      id,
      name: attributes.name,
      displayStatus: attributes.display_status,
      halfDay: attributes.half_day,
      calculable: attributes.half_day,
      permanent: attributes.permanent
    }
  })
