import { CreateLocationBodyReq } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendCreateOrUpdateLocationResponse, CreateLocationErrorResponse } from "@state/types/locations"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"

import { LOCATIONS_URL } from "../urls"

export const createLocation = (build: Build) =>
  build.mutation<BackendCreateOrUpdateLocationResponse, CreateLocationBodyReq>({
    query: body => ({
      url: LOCATIONS_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["Location"],
    transformErrorResponse: (errorResponse: FetchBaseQueryError) => {
      const res = errorResponse.data as CreateLocationErrorResponse
      return res.errors.map(err => err.detail).join(" ,")
    }
  })
