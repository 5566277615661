import React, { FC } from "react"
import { Button, Col, Flex, Form, Row, Typography } from "antd"
import { formatLongNames } from "@helpers"

import { ValidatedTextInput } from "@components/ValidatedInputs"
import { SaveButton } from "@components/buttons/SaveButton"
import { CancelButton } from "@components/buttons/CancelButton"
import { EditButton } from "@components/buttons/EditButton"
import { DeleteButton } from "@components/buttons/DeleteButton"

import { useLeaveType } from "./helpers/useLeaveTypes"
import style from "./style.m.scss"

const initialValues = {
  name: "",
  status: ""
}

const LeaveTypes: FC = () => {
  const {
    editingId,
    newLeaveForm,
    leaveTypes,
    unremovableLeaveTypesIds,
    newName,
    newStatus,
    handleCancel,
    handleDescriptionChange,
    handleEdit,
    handleStatusChange,
    handleSave,
    handleCreate,
    handleDelete
  } = useLeaveType()

  return (
    <Flex vertical justify="center">
      <Typography.Title level={4} className={style.header}>
        Leave types
      </Typography.Title>
      <Form form={newLeaveForm} initialValues={initialValues} onFinish={handleCreate} className={style.form}>
        <Row gutter={12} justify="space-between">
          <Col span={9}>
            <Form.Item name="name" label="Add new leave type" labelCol={{ span: 24 }}>
              <ValidatedTextInput />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name="status" label="Leave type displayed status" labelCol={{ span: 24 }}>
              <ValidatedTextInput />
            </Form.Item>
          </Col>
          <Col span={4} className={style.buttonWrapper}>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Col>
        </Row>
      </Form>
      <Flex vertical>
        <Typography.Title level={5} className={style.header}>
          Leave types
        </Typography.Title>
        <div className={style.listWrapper}>
          <Row className={style.itemContainer} justify="space-between">
            <Col span={8} className={style.headerCell}>
              Name
            </Col>
            <Col span={8} className={style.headerCell}>
              Displayed Status
            </Col>
            <Col span={2} className={style.headerCell}>
              Actions
            </Col>
          </Row>
          {leaveTypes.map(leaveType => {
            const isDisabled = leaveType.permanent || unremovableLeaveTypesIds.includes(leaveType.id)

            return (
              <Row className={style.itemContainer} justify="space-between" key={leaveType.id}>
                <Col span={8} className={style.listItem}>
                  {leaveType.id === editingId ? (
                    <div className={style.inputWrapper}>
                      <ValidatedTextInput value={newName} onChange={handleDescriptionChange} />
                    </div>
                  ) : (
                    <div className={style.text}>{formatLongNames(leaveType.name)}</div>
                  )}
                </Col>
                <Col span={8} className={style.listItem}>
                  {leaveType.id === editingId ? (
                    <div className={style.inputWrapper}>
                      <ValidatedTextInput value={newStatus} onChange={handleStatusChange} />
                    </div>
                  ) : (
                    <div className={style.text}>{formatLongNames(leaveType.displayStatus)}</div>
                  )}
                </Col>
                <Col span={2}>
                  <Flex justify="space-between" align="center">
                    {editingId === leaveType.id ? (
                      <>
                        <SaveButton onClick={handleSave} />
                        <CancelButton onClick={handleCancel} />
                      </>
                    ) : (
                      <>
                        <EditButton onClick={() => handleEdit(leaveType.id, leaveType.name, leaveType.displayStatus)} />
                        <DeleteButton
                          onConfirm={() => handleDelete(leaveType.id)}
                          tooltipText={
                            isDisabled ? "This value is used in someone’s planned or requested leave" : undefined
                          }
                          disabled={isDisabled}
                        />
                      </>
                    )}
                  </Flex>
                </Col>
              </Row>
            )
          })}
        </div>
      </Flex>
    </Flex>
  )
}

export default LeaveTypes
