export enum UsersColumn {
  Name = "Name",
  Position = "Position",
  Department = "Department",
  Actions = "Actions"
}

export enum DepartmentsColumns {
  DepartmentName = "Department",
  Status = "Status",
  HeadOfDepartment = "Head of Department",
  ParentDepartment = "Parent dept.",
  SubordinateDepartment = "Subordinate dept.",
  Jobs = "Jobs"
}

export enum SlackGroupColumn {
  Name = "Name",
  MemberCount = "Member count",
  Status = "Status",
  Actions = "Actions"
}

export enum LocationColumn {
  LocationName = "Location",
  DataStorage = "Personal data storage location",
  Country = "Country",
  EmployeesCount = "Employees in location",
  IsDocsStored = "Docs stored in location",
  Actions = "Actions"
}
