import React from "react"
import { AvailableSystem } from "@interfaces"
import { useAlert } from "@hooks"
import { deriveLogoSrc } from "@helpers"
import { availableSystemsApi } from "@state/services/subApis"

import { DeleteButton } from "@components/buttons/DeleteButton"
import { EditButton } from "@components/buttons/EditButton"
import { AvailableSystemCard } from "@components/dashboardCards"

import style from "./style.m.scss"

const deriveDeleteMessage = (hasUsers: boolean = false) => (
  <>
    <div>Are you sure you want to proceed with deleting the system?</div>
    {hasUsers && (
      <>
        <div>The system is currently referenced in employee profiles.</div>
        <div>Deleting this system will result in its removal from these profiles</div>
      </>
    )}
  </>
)

export const useAvailableSystemsTableColumns = (onEditClick: (item: AvailableSystem) => void) => {
  const { error, success } = useAlert()

  const [deleteAvailableSystem] = availableSystemsApi.endpoints.deleteAvailableSystem.useMutation()

  const handleDeleteClick = async (id: string) => {
    try {
      await deleteAvailableSystem(id).unwrap()
      success("Available system deleted successfully")
    } catch {
      error("Cannot delete available system")
    }
  }

  const columns = [
    {
      title: "System name",
      key: "systemName",
      dataIndex: "name",
      width: "150px",
      render: (value: string) => <div className={style.cell}>{value}</div>,
      onCell: (_: AvailableSystem, index: number) => ({ "data-testid": `name-${index}` })
    },
    {
      title: "System logo",
      key: "logo",
      width: "120px",
      render: (_: unknown, { iconId }: AvailableSystem) =>
        iconId ? <img src={deriveLogoSrc(iconId)} alt="" className={style.image} /> : null,
      onCell: (_: AvailableSystem, index: number) => ({ "data-testid": `logo-${index}` })
    },
    {
      title: "System link",
      dataIndex: "link",
      width: "180px",
      key: "link",
      render: (value: string) => (
        <a href={value} target="_blank" rel="noreferrer" className={style.cell}>
          {value}
        </a>
      ),
      onCell: (_: AvailableSystem, index: number) => ({ "data-testid": `link-${index}` })
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "200px",
      render: (value: string) => <div className={style.cell}>{value}</div>,
      onCell: (_: AvailableSystem, index: number) => ({ "data-testid": `description-${index}` })
    },
    {
      title: "Preview",
      key: "preview",
      width: "200px",
      render: (_: unknown, { iconId, link, name, showIcon, showName }: AvailableSystem) => (
        <div className={style.preview}>
          <AvailableSystemCard
            logo={iconId && showIcon ? deriveLogoSrc(iconId) : undefined}
            link={link}
            name={showName ? name : undefined}
          />
        </div>
      ),
      onCell: (_: AvailableSystem, index: number) => ({ "data-testid": `preview-${index}` })
    },
    {
      title: "Actions",
      key: "actions",
      width: "100px",
      render: (_: unknown, system: AvailableSystem) => (
        <>
          <EditButton onClick={() => onEditClick(system)} datatest-id="available-system-edit-button" />
          <DeleteButton
            message={deriveDeleteMessage(system.hasUsers)}
            onConfirm={() => handleDeleteClick(system.id)}
            data-testid="delete-available-system"
          />
        </>
      ),
      onCell: (_: AvailableSystem, index: number) => ({ "data-testid": `actions-${index}` })
    }
  ]

  return columns
}
