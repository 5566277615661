import { SearchOutlined } from "@ant-design/icons"
import { Button, InputRef, Space, TableColumnType } from "antd"
import React, { useRef } from "react"
import { COLORS } from "colors/colors"

import { ValidatedTextInput } from "@components/ValidatedInputs"

import style from "./style.m.scss"

const TIMEOUT = 100

export const useTableTextFilter = (): TableColumnType<unknown> => {
  const inputRef = useRef<InputRef>(null)

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div onKeyDown={e => e.stopPropagation()} className={style.wrapper}>
        <ValidatedTextInput
          ref={inputRef}
          placeholder="Search…"
          value={selectedKeys.toString()}
          onChange={value => {
            setSelectedKeys(value ? [value] : [])
          }}
          onPressEnter={() => confirm()}
          className={style.searchInput}
          data-testid="search-text-input"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            className={style.button}
            data-testid="search-text-filter"
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small" className={style.button} data-testid="reset-text-filter">
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? COLORS.BLUE_1 : undefined }} />,
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => inputRef.current?.select(), TIMEOUT)
      }
    }
  }
}
