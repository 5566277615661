import { AccessGroup, Policy } from "@interfaces*"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type newGroupState = {
  allPolicies: Array<Policy>
  policiesList: Array<Policy>
  addedPolicies: Array<Policy>
  currentGroup: {
    name: string
    id?: string
  }
}

const initialState: newGroupState = { allPolicies: [], policiesList: [], addedPolicies: [], currentGroup: { name: "" } }

const newGroupSlice = createSlice({
  name: "newGroup",
  initialState,
  reducers: {
    initPolicies(state, action: PayloadAction<Array<Policy>>) {
      state.allPolicies = action.payload
      state.policiesList = action.payload
    },

    addPolicy(state, action: PayloadAction<{ id: string }>) {
      const currentPolicy = state.policiesList.find(policy => policy.id === action.payload.id)!

      state.addedPolicies.push(currentPolicy)
      state.policiesList = state.policiesList.filter(policy => policy.id !== currentPolicy.id)
    },

    removePolicy(state, action: PayloadAction<{ id: string }>) {
      const currentPolicy = state.addedPolicies.find(policy => policy.id === action.payload.id)!

      state.addedPolicies = state.addedPolicies.filter(policy => policy.id !== currentPolicy.id)
      state.policiesList.push(currentPolicy)
    },

    clearAll(state) {
      state.addedPolicies = []
      state.currentGroup = { name: "" }
      state.policiesList = state.allPolicies
    },

    setCurrentGroup(state, { payload }: PayloadAction<{ groupName: string; id: string }>) {
      state.currentGroup.name = payload.groupName
      state.currentGroup.id = payload.id
    },

    moveGroupToMiddllecolumn(state, { payload: group }: PayloadAction<AccessGroup>) {
      const currentPoliciesIds = group.policies.map(policy => policy.id)
      state.addedPolicies = state.allPolicies.filter(policy => currentPoliciesIds.includes(policy.id))
      state.policiesList = state.allPolicies.filter(policy => !currentPoliciesIds.includes(policy.id))
      state.currentGroup = { name: group.name, id: group.id }
    }
  }
})

export const newGroupActions = newGroupSlice.actions

export default newGroupSlice.reducer
