import { Build } from "@state/services/types"
import { AvailableSystemMutationData } from "@interfaces"

import { AVAILABLE_SYSTEMS_URL } from "../urls"
import { deriveMutationParams } from "../transformer"

export const createAvailableSystem = (build: Build) =>
  build.mutation<unknown, AvailableSystemMutationData>({
    query: data => ({
      url: AVAILABLE_SYSTEMS_URL,
      method: "POST",
      body: deriveMutationParams(data)
    }),
    invalidatesTags: ["AvailableSystem"]
  })
