import React, { FC, useState } from "react"
import { availableSystemsApi } from "@state/services/subApis"
import { Button, Flex, Table } from "antd"
import { ColumnType } from "antd/es/table"
import { AvailableSystem } from "@interfaces"

import { Title } from "@components/Title"

import { useAvailableSystemsTableColumns } from "./helpers/useAvailableSystemsTableColumns"
import { AvailableSystemModal } from "./AvailableSystemModal"
import { AvailableSystemsLogs } from "./AvailableSystemsLogs"
import style from "./style.m.scss"

export const AvailableSystems: FC = () => {
  // object ==> existing item, null ==> new item, undefined ==> no modal
  const [activeItem, setActiveItem] = useState<AvailableSystem | null | undefined>()

  const handleModalCancel = () => {
    setActiveItem(undefined)
  }

  const handleModalSave = () => {
    setActiveItem(undefined)
  }

  const handleAddSystemClick = () => {
    setActiveItem(null)
  }

  const handleEditClick = (item: AvailableSystem) => {
    setActiveItem(item)
  }

  const columns = useAvailableSystemsTableColumns(handleEditClick)
  const {
    data = [],
    isLoading,
    isFetching
  } = availableSystemsApi.endpoints.fetchAvailableSystems.useQuery({ showHasUsers: true })

  return (
    <>
      <Title style={{ marginBottom: 0 }}>Available systems</Title>

      <Flex justify="flex-end" style={{ marginBottom: "10px" }}>
        <Button type="primary" onClick={handleAddSystemClick} data-testid="add-system-button">
          Add new
        </Button>
      </Flex>

      <Table
        columns={columns as Array<ColumnType<AvailableSystem>>}
        dataSource={data}
        rowKey="id"
        loading={isLoading || isFetching}
        pagination={false}
        className={style.table}
        data-testid="available-systems-table"
      />
      <AvailableSystemModal availableSystem={activeItem} onCancel={handleModalCancel} onSave={handleModalSave} />

      <AvailableSystemsLogs />
    </>
  )
}
