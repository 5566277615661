import React, { FC, useEffect, useState } from "react"
import { Popover } from "antd"

import style from "./style.m.scss"

type ValidateInputWrapProps = {
  regular: Regular
  value?: string
  isWarningHidden?: boolean
  renderInput: (value: string | undefined, onChange: (value: string) => void) => React.ReactNode
  onValueChange?: (value: string) => void
}

export enum Regular {
  Text = "text",
  Digit = "digit"
}

const RegularsExpressions = {
  [Regular.Text]: /[\u0400-\u04FF]+/g,
  [Regular.Digit]: /[^\d.]+/g
}

const PopoverContent = {
  [Regular.Text]: "Please switch language to English",
  [Regular.Digit]: "Only digits"
}

const WARNING_DISAPPEAR = 3000

export const ValidateInputWrap: FC<ValidateInputWrapProps> = props => {
  const { value, onValueChange, isWarningHidden, renderInput, regular } = props

  const [inputValue, setInputValue] = useState(value)
  const [isWarningVisible, setIsWarningVisible] = useState(false)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleInputChange = (text: string) => {
    const filteredValue = text.replace(RegularsExpressions[regular], "")
    setInputValue(filteredValue)

    const isInvalid = filteredValue !== text
    setIsWarningVisible(isInvalid)
    onValueChange?.(filteredValue)
    if (isInvalid) {
      setTimeout(() => {
        setIsWarningVisible(false)
      }, WARNING_DISAPPEAR)
    }
  }

  return (
    <Popover
      open={isWarningVisible && !isWarningHidden}
      overlayClassName={style.popover}
      content={PopoverContent[regular]}
    >
      {renderInput(inputValue, handleInputChange)}
    </Popover>
  )
}
