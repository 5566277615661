import { Coordinates } from "@interfaces"
import { useCallback, useEffect, useRef, useState } from "react"
import Tree from "react-d3-tree"
import { RawNodeDatum } from "react-d3-tree/lib/types/common"

import { DEFAULT_ZOOM, HALF, MAX_ZOOM, MIN_ZOOM, QUARTER } from "./constants"

const MIN_Y = 175
const RERENDER_FLAG_VALUE = 0.5
const KEYS_STEP = 10
const SHIFT_MULTIPLIER = 10

export const useCenteredTree = (tree: RawNodeDatum, defaultTranslate: Coordinates = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState<Coordinates>(defaultTranslate)
  const [rerenderFlag, setRerenderFlag] = useState(RERENDER_FLAG_VALUE)
  const [zoom, setZoom] = useState(DEFAULT_ZOOM)
  const containerRef = useRef<HTMLDivElement>(null)
  const treeRef = useRef<Tree>(null)

  const setCoords = ({ y }: Coordinates) => {
    if (containerRef.current !== null) {
      const { width, height } = containerRef.current.getBoundingClientRect()
      setRerenderFlag(prev => -prev)
      setTranslate(() => ({ x: width / HALF, y: height / QUARTER - y / QUARTER + rerenderFlag }))
    }
  }

  const onWheel = useCallback((e: WheelEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (containerRef.current !== null && treeRef.current !== null) {
      const { deltaX, deltaY } = e
      const isFloat = deltaY % 1 !== 0 && Object.is(deltaX, -0)

      isFloat
        ? setZoom(prev => {
            let sum = prev - (deltaY && deltaY > 0 ? 1 : -1)
            if (sum > MAX_ZOOM) sum = MAX_ZOOM
            if (sum < MIN_ZOOM) sum = MIN_ZOOM
            return sum
          })
        : setTranslate(prev => ({ x: prev.x - deltaX, y: prev.y - deltaY }))
    }
  }, [])

  const onKeyDown = (e: KeyboardEvent) => {
    const isSift = e.shiftKey
    const step = isSift ? KEYS_STEP * SHIFT_MULTIPLIER : KEYS_STEP

    switch (e.key) {
      case "ArrowUp":
        setTranslate(prev => ({ y: prev.y - step, x: prev.x }))
        break
      case "ArrowDown":
        setTranslate(prev => ({ y: prev.y + step, x: prev.x }))
        break
      case "ArrowLeft":
        setTranslate(prev => ({ y: prev.y, x: prev.x - step }))
        break
      case "ArrowRight":
        setTranslate(prev => ({ y: prev.y, x: prev.x + step }))
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (containerRef.current !== null) {
      const { width, height } = containerRef.current.getBoundingClientRect()

      setTranslate({ x: width / HALF, y: Math.max(height / QUARTER, MIN_Y) })
    }
  }, [tree])

  useEffect(() => {
    if (containerRef.current !== null) {
      containerRef.current.addEventListener("wheel", onWheel, { passive: false })
    }

    document.addEventListener("keydown", onKeyDown, false)

    return () => {
      if (containerRef.current !== null) {
        containerRef.current.removeEventListener("wheel", onWheel)
      }

      document.removeEventListener("keydown", onKeyDown, false)
    }
  }, [onWheel, onKeyDown])

  return { treeRef, containerRef, translate, zoom, setCoords, setZoom, setTranslate }
}
