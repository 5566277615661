import React from "react"
import { useAlert, useUserProfileEmploymentInfoAbilities } from "@hooks"
import { personalDocumentsApi } from "@state/services/subApis"
import { PersonalDocument } from "@interfaces"
import { Link } from "react-router-dom"
import { capitalizeFirstLetter } from "@helpers"
import { DownloadOutlined } from "@ant-design/icons"
import { Button, Flex } from "antd"
import {
  generatePersonalDocumentDownloadURL,
  generatePersonalDocumentViewURL
} from "@state/services/subApis/personalDocuments/urls"

import { formatFileName } from "../../../../../helpers/file"
import { DocumentStatus } from "../const"
import { CommentEditor } from "../components/CommentEditor"
import { ApprovalButtons } from "../components/ApprovalButtons"
import { DocumentRow } from "../components/DocumentRow"
import { Footer } from "../components/Footer"

import { DeleteButton } from "@components/buttons/DeleteButton"

import style from "./style.m.scss"

export const useDocumentsTable = (userId: string, isArchived: boolean, isEditMode: boolean, dataTestId: string) => {
  const { update_all: hasFullAccessToPersonalDocuments } = useUserProfileEmploymentInfoAbilities()

  const [deleteDocument] = personalDocumentsApi.endpoints.deletePersonalDocument.useMutation()

  const { error } = useAlert()

  const isTotalEditMode = hasFullAccessToPersonalDocuments && isEditMode

  const isInlineForm = isTotalEditMode && !isArchived

  const handleDeleteDocumentClick = async (id: string) => {
    try {
      await deleteDocument(id).unwrap()
    } catch {
      error("Error deleting document")
    }
  }

  const basicColumns = [
    {
      key: "number",
      width: "30px",
      onCell: (_: PersonalDocument, index: number) => ({
        "data-testid": `${dataTestId}-number-${index}`
      }),
      render: (_: unknown, __: PersonalDocument, index: number) => (index + 1).toString()
    },
    {
      title: "Document",
      key: "document",
      width: "350px",
      onCell: (_: PersonalDocument, index: number) => ({
        "data-testid": `${dataTestId}-document-${index}`
      }),
      render: (_: unknown, { fileName, id, status }: PersonalDocument) => {
        const title = formatFileName(fileName)

        if (status === DocumentStatus.deleted) return title

        return (
          <>
            <Link to={generatePersonalDocumentViewURL(id)} target="_blank" className={style.cell}>
              {title}
            </Link>
            {!isEditMode && (
              <Button href={generatePersonalDocumentDownloadURL(id)} type="link" icon={<DownloadOutlined />} />
            )}
          </>
        )
      }
    },
    {
      title: "Created date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "140px",
      onCell: (_: PersonalDocument, index: number) => ({ "data-testid": `${dataTestId}-created-date-${index}` })
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
      onCell: (_: PersonalDocument, index: number) => ({ "data-testid": `${dataTestId}-created-by-${index}` })
    },
    {
      title: "Status",
      key: "status",
      width: "120px",
      render: (_: unknown, document: PersonalDocument) => {
        const statusName = capitalizeFirstLetter(document.status)
        if (document.status !== DocumentStatus.deleted) return statusName

        return (
          <>
            <div>{statusName}</div>
            <div>{document.closedAt}</div>
          </>
        )
      },
      onCell: (_: PersonalDocument, index: number) => ({ "data-testid": `${dataTestId}-status-${index}` })
    },
    {
      title: "Comment",
      width: "350px",
      key: "comment",
      render: (_: unknown, document: PersonalDocument) => {
        if (isInlineForm && document.status === DocumentStatus.pending) return <CommentEditor />

        return <div className={style.cell}>{document.comment}</div>
      },
      onCell: (_: PersonalDocument, index: number) => ({ "data-testid": `${dataTestId}-comments-${index}` })
    }
  ]

  const actionsColumn = {
    title: "Actions",
    key: "actions",
    width: "50px",
    render: (_: unknown, { id, fileName, status }: PersonalDocument) =>
      status === DocumentStatus.deleted ? null : (
        <Flex>
          {isInlineForm && status === DocumentStatus.pending && <ApprovalButtons id={id} dataTestId={dataTestId} />}

          {(hasFullAccessToPersonalDocuments || status !== DocumentStatus.approved) && (
            <DeleteButton
              message={`You are about to delete ${formatFileName(fileName)}. Are you sure?`}
              onConfirm={() => handleDeleteDocumentClick(id)}
              data-testid={`${dataTestId}-delete`}
            />
          )}
        </Flex>
      ),
    onCell: (_: PersonalDocument, index: number) => ({ "data-testid": `${dataTestId}-actions-${index}` })
  }

  const columns = isEditMode ? [...basicColumns, actionsColumn] : basicColumns

  const components = isInlineForm
    ? {
        body: {
          row: DocumentRow
        }
      }
    : undefined

  const footer = isTotalEditMode ? () => <Footer userId={userId} /> : undefined

  const onRow = isInlineForm ? (document: PersonalDocument) => ({ document }) : undefined

  return {
    columns,
    components,
    onRow,
    footer
  }
}
