import { Build } from "@state/services/types"

import { EDUCATIONAL_MATERIALS_BULK_ADD } from "../urls"

export const createEducationalMaterialBulk = (build: Build) =>
  build.mutation<unknown, FormData>({
    query: body => ({
      url: EDUCATIONAL_MATERIALS_BULK_ADD,
      method: "POST",
      body
    }),
    invalidatesTags: ["EducationalMaterial"]
  })
