import { FetchProfileParams } from "@state/types/profile"
import { ProfileDataFromBackend } from "@state/types/users"
import { Build } from "@state/services/types"

import { Profile, userProfileTransformer } from "../../users/transformer"
import { generateGetProfileURL } from "../urls"

export const fetchUserProfile = (build: Build) =>
  build.query<Profile, FetchProfileParams>({
    query: params => ({
      url: generateGetProfileURL(params.includes)
    }),
    providesTags: () => [{ type: "User" }, "EmploymentSettings"],
    transformResponse: (response: ProfileDataFromBackend) => userProfileTransformer(response as ProfileDataFromBackend)
  })
