import { AvailableHrOptions } from "@interfaces"
import { Build } from "@state/services/types"

import { AVAILABLE_HR_OPTIONS_URL } from "../urls"

export const fetchAvailableHrOptions = (build: Build) =>
  build.query<AvailableHrOptions, null>({
    query: () => ({
      url: AVAILABLE_HR_OPTIONS_URL
    })
  })
