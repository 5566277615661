import backendApi from "@state/services/backendApi"

import { fetchPerformanceReview } from "./GET/fetchPerformanceReview"
import { createPerformanceReview } from "./POST/createPerformanceReview"
import { deletePerformanceReview } from "./DELETE/deletePerformanceReview"

export const performanceReviewApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchPerformanceReview: fetchPerformanceReview(build),
    createPerformanceReview: createPerformanceReview(build),
    deletePerformanceReview: deletePerformanceReview(build)
  })
})
