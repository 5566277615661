import { GetVacationsRequest, ResponseWithTotalCount } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendVacation, GetVacationsResponse } from "@state/types/vacations"
import { VacationsScope } from "@enums"

import { VACATIONS_URL } from "../urls"

export const fetchVacations = (build: Build) =>
  build.query<ResponseWithTotalCount<BackendVacation>, GetVacationsRequest>({
    query: ({
      page,
      pageSize,
      scope,
      status,
      project_id,
      startDate,
      finishDate,
      user_id,
      category,
      includeLeaveType,
      leaveType
    }) => ({
      url: VACATIONS_URL,
      params: {
        "page[number]": page,
        "page[size]": pageSize,
        "include": includeLeaveType ? "user,quotum.vacation_setting" : "user",
        "filter[type]": leaveType,
        "extra_fields[vacations]": "balances",
        "extra_fields[users]": "position,department",
        "fields[users]": "id,first_name,last_name,email",
        "fields[quotum]": includeLeaveType ? "" : undefined,
        "fields[quotum.vacation_setting]": includeLeaveType ? "leave_type" : undefined,
        "filter[quotum_id]": category?.length ? category : undefined,
        "filter[user_id]": scope === VacationsScope.All ? user_id : undefined,
        "filter[scope]": scope,
        "filter[status]": status,
        "filter[started_at][lte]": finishDate,
        "filter[finished_at][gte]": startDate,
        "filter[project_id]": project_id?.length ? project_id : undefined
      }
    }),
    transformResponse: (response: GetVacationsResponse, meta) => ({
      entities: response.data,
      totalCount: Number(meta?.response?.headers.get("X-Total-Count"))
    }),
    providesTags: result =>
      result ? result.entities.map(vacation => ({ type: "Vacation", id: vacation.id })) : ["Vacation"]
  })
