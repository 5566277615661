import React, { FC } from "react"
import { Flex } from "antd"

import DepartmentForm from "../DepartmentInfo/DepartmentForm"

const DepartmentCreate: FC = () => {
  return (
    <Flex vertical>
      <DepartmentForm createMode />
    </Flex>
  )
}

export default DepartmentCreate
