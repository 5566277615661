export const expirationOptions = [
  { label: "Expired", value: "expired" },
  { label: "Extended", value: "" }
]

export const leaveTypeOptions = [
  { label: "Working days", value: "Working days" },
  { label: "Calendar days", value: "Calendar days" }
]

export const workdaysOptions = [
  { label: "Monday", value: "Mo" },
  { label: "Tuesday", value: "Tu" },
  { label: "Wednesday", value: "We" },
  { label: "Thursday", value: "Th" },
  { label: "Friday", value: "Fr" },
  { label: "Saturday", value: "Sa" },
  { label: "Sunday", value: "Su" }
]
