import backendApi from "@state/services/backendApi"

import { fetchHolidays } from "./GET/fetchHolidays"
import { fetchHolidaysLogs } from "./GET/fetchHolidaysLogs"
import { fetchHolidaysOptions } from "./GET/fetchHolidaysOptions"
import { createHoliday } from "./POST/createHoliday"
import { activateHoliday } from "./PUT/activateHoliday"
import { deleteHoliday } from "./DELETE/deleteHoliday"

export const holidaysApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchHolidays: fetchHolidays(build),
    fetchHolidaysLogs: fetchHolidaysLogs(build),
    fetchHolidaysOptions: fetchHolidaysOptions(build),
    createHoliday: createHoliday(build),
    activateHoliday: activateHoliday(build),
    deleteHoliday: deleteHoliday(build)
  })
})
