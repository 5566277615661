import { JobHistoryItem, SalaryHistoryItem } from "@interfaces*"
import React, { FC } from "react"
import { Flex } from "antd"
import { useSalariesAbilities } from "hooks/query/useAbilities"

import { SectionWrapper } from "@components/SectionWrapper"

import { PreviousJobs } from "./PreviousJobs"
import { PreviousSalaries } from "./PreviousSalaries"

type ViewPanelProps = {
  jobHistory: Array<JobHistoryItem> | undefined
  salariesHistory: Array<SalaryHistoryItem> | undefined
  userId: string
}

const ViewPanel: FC<ViewPanelProps> = ({ jobHistory, salariesHistory = [], userId }) => {
  const { edit_all: editSalaries } = useSalariesAbilities()

  const jobHistoryVisibility = jobHistory && !!jobHistory.length
  const showSalariesHistory = salariesHistory.length > 0 || editSalaries

  if (!jobHistoryVisibility && !showSalariesHistory) return null

  return (
    <SectionWrapper>
      <Flex>
        {jobHistoryVisibility && <PreviousJobs jobHistory={jobHistory} />}
        {showSalariesHistory && (
          <PreviousSalaries
            salariesHistory={salariesHistory || []}
            userId={userId}
            jobHistoryVisibility={jobHistoryVisibility}
          />
        )}
      </Flex>
    </SectionWrapper>
  )
}

export default ViewPanel
