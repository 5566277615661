export const VACATIONS_SETTINGS_URL = "/vacation_settings"
export const VACATIONS_SETTINGS_OPTIONS_URL = `${VACATIONS_SETTINGS_URL}/options`
export const VACATIONS_LEAVE_TYPES_URL = "/vacation_categories"
export const UNREMOVABLE_LEAVE_TYPES_URL = "/vacation_categories/options"
export const VACATIONS_NOTIFICATION_SETTINGS = "/vacation_notifications_settings"

export const generateVacationSettingsIdURL = (id: number | string) => `${VACATIONS_SETTINGS_URL}/${id}`

export const generateLeaveTypeIdURL = (id: number | string) => `${VACATIONS_LEAVE_TYPES_URL}/${id}`

export const generateVacationSettingsURL = () => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("include", "location,assignment_hrs")
  searchParams.set("extra_fields[vacation_settings]", "quotas")

  return `${VACATIONS_SETTINGS_URL}${separator}${searchParams.toString()}`
}
