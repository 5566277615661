import React, { useContext } from "react"
import { useAlert } from "@hooks"
import { personalDocumentsApi } from "@state/services/subApis"
import { Button, Tooltip } from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"

import { DocumentFormContext } from "../../contexts/DocumentFormContext"
import { DocumentStatus } from "../../const"

type ApprovalButtonsProps = {
  id: string
  dataTestId: string
}

export const ApprovalButtons: React.FC<ApprovalButtonsProps> = ({ id, dataTestId }) => {
  const form = useContext(DocumentFormContext)
  const { error, success } = useAlert()
  const [updateDocument] = personalDocumentsApi.endpoints.updatePersonalDocument.useMutation()

  if (!form) return null

  const handleApprove = async () => {
    const { comment } = form.getFieldsValue()
    try {
      await updateDocument({ id, status: DocumentStatus.approved, comment }).unwrap()
      success("Document approved successfully")
    } catch {
      error("Error approving document")
    }
  }

  const handleReject = async () => {
    try {
      const { comment } = await form.validateFields()
      try {
        await updateDocument({ id, status: DocumentStatus.rejected, comment }).unwrap()
        success("Document rejected successfully")
      } catch {
        error("Error rejecting document")
      }
    } catch {
      // do nothing here
    }
  }

  return (
    <>
      <Tooltip title="Approve">
        <Button icon={<CheckOutlined />} type="text" onClick={handleApprove} data-testid={`${dataTestId}-approve`} />
      </Tooltip>
      <Tooltip title="Reject">
        <Button icon={<CloseOutlined />} type="text" onClick={handleReject} data-testid={`${dataTestId}-reject`} />
      </Tooltip>
    </>
  )
}
