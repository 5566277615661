import React, { FC } from "react"
import { Button, ButtonProps, Tooltip } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { TooltipPlacement } from "antd/es/tooltip"

type AddButtonProps = Omit<ButtonProps, "icon" | "type"> & { tooltipPlacement?: TooltipPlacement }

export const AddButton: FC<AddButtonProps> = ({ tooltipPlacement = "top", ...props }) => (
  <Tooltip title="Add" placement={tooltipPlacement}>
    <Button icon={<PlusOutlined />} type="text" {...props} />
  </Tooltip>
)
