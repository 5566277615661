import React, { FC, useState } from "react"
import { useAlert, useProfileHandlers } from "@hooks"
import { capitalizeFirstLetter } from "@helpers"

import { Field } from "@components/Field"
import { EditButton } from "@components/buttons/EditButton"

import { GenderForm } from "./GenderForm"
import style from "./style.m.scss"

type EditableGenderProps = {
  value?: string
  options: Array<string>
}

export const EditableGender: FC<EditableGenderProps> = ({ value, options }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const { saveUserProfile } = useProfileHandlers()
  const { success } = useAlert()

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSave = async (value: string) => {
    const result = await saveUserProfile({
      gender: value || null
    })

    if (result) {
      setIsEditMode(false)
      success("Gender has been saved successfully")
    }
  }

  const handleCancel = () => {
    setIsEditMode(false)
  }

  return isEditMode ? (
    <GenderForm value={value} options={options} onSave={handleSave} onCancel={handleCancel} />
  ) : (
    <Field
      title="Gender"
      value={value ? capitalizeFirstLetter(value) : ""}
      actions={<EditButton onClick={handleEditClick} className={style.editButton} data-testid="edit-gender" />}
      data-testid="phone"
    />
  )
}
