import { Build } from "@state/services/types"
import { GetVacationsRequestsResponse } from "@state/types/vacations"

import { VACATIONS_REQUESTS_URL } from "../urls"

export const fetchVacationsRequests = (build: Build) =>
  build.query<GetVacationsRequestsResponse, null>({
    query: () => ({
      url: VACATIONS_REQUESTS_URL
    }),
    providesTags: result => {
      const leaves =
        (result &&
          result.leaves.data.length &&
          result.leaves.data.map(vacation => ({ type: "Vacation", id: vacation.id }))) ||
        []

      const off_hours_work =
        (result &&
          result.off_hours_works.data.length &&
          result.off_hours_works.data.map(vacation => ({ type: "Vacation", id: vacation.id }))) ||
        []

      const allTags = [
        ...leaves,
        ...off_hours_work.filter(request => !leaves.find(vacation => vacation.id === request.id))
      ]

      return allTags.length > 0 ? allTags : ["Vacation"]
    }
  })
