import { Flex, List, Typography } from "antd"
import React, { FC, useState } from "react"
import { useAppDispatch, useAppSelector } from "@hooks"
import { manageUsersPermissionsActions } from "@state/reducers/manageUsersPermissions"
import { Search } from "@app/sections/PolicySystem/components/Search"
import { AccessEntity } from "@interfaces*"
import { AccessType } from "@enums"
import { formatedLongNames } from "@app/sections/PolicySystem/helpers/formatedLongNames"

import { AddButton } from "@components/buttons/AddButton"

import style from "./style.m.scss"

type SubListProps = {
  type: AccessType
  entities: Array<AccessEntity>
}

const DISPLAY_NAME_LENGTH = 40

const SubList: FC<SubListProps> = ({ type, entities }) => {
  const dispatch = useAppDispatch()

  const currentUserId = useAppSelector(state => state.manageUsersPermissionsSlice.userId)

  const [searchingValueId, setSearchingValueId] = useState<string | null>()

  const options = entities.map(entity => ({ value: entity.id, label: formatedLongNames(entity.name) }))

  const filteredEntities = searchingValueId
    ? entities.filter(entity => entity.id === searchingValueId)
    : [...entities].sort((a, b) => a.name.localeCompare(b.name))

  const handleAddClick = (entity: AccessEntity, type: AccessType) => {
    dispatch(manageUsersPermissionsActions.addEntity({ entity, type }))
  }

  const handleSearch = (value: string | null) => {
    setSearchingValueId(value)
  }

  const handleClearSearch = () => {
    setSearchingValueId(null)
  }

  return (
    <Flex vertical className={style.listWrapper}>
      <Typography.Title level={5}>{`Add ${type}`}</Typography.Title>
      <Search options={options} onSearch={handleSearch} onClear={handleClearSearch} placeholder={`Enter ${type}`} />
      <List
        className={style.list}
        itemLayout="horizontal"
        dataSource={filteredEntities}
        renderItem={entity => (
          <List.Item
            actions={[
              <AddButton
                tooltipPlacement="left"
                onClick={() => {
                  handleAddClick(entity, type)
                }}
                disabled={!currentUserId}
              />
            ]}
          >
            <List.Item.Meta title={formatedLongNames(entity.name, DISPLAY_NAME_LENGTH)} />
          </List.Item>
        )}
      />
    </Flex>
  )
}

export default SubList
