import { defaultError } from "@helpers"
import { useAlert } from "@hooks"
import { vacationsSettingsApi } from "@state/services/subApis"
import { Form } from "antd"
import { useState } from "react"

const initialValues = {
  name: "",
  status: ""
}

export const useLeaveType = () => {
  const [editingId, setEditingId] = useState<string | null>(null)
  const [newName, setNewName] = useState("")
  const [newStatus, setNewStatus] = useState("")

  const { error, success } = useAlert()

  const [form] = Form.useForm<typeof initialValues>()

  const { leaveTypes } = vacationsSettingsApi.endpoints.fetchLeaveTypes.useQuery("", {
    selectFromResult: ({ data }) => ({
      leaveTypes: data || []
    })
  })

  const { data: unremovableLeaveTypesIds = [] } = vacationsSettingsApi.endpoints.fetchUnremovableLeaveTypes.useQuery("")

  const [udpateLeaveType] = vacationsSettingsApi.endpoints.updateLeaveType.useMutation()
  const [createLeaveType] = vacationsSettingsApi.endpoints.createNewLeaveType.useMutation()
  const [deleteLeaveType] = vacationsSettingsApi.endpoints.deleteLeaveType.useMutation()

  const onEdit = (id: string, name: string, status: string) => {
    setEditingId(id)
    setNewName(name)
    setNewStatus(status)
  }

  const onDescriptionChange = (value: string) => {
    setNewName(value)
  }

  const onStatusChange = (value: string) => {
    setNewStatus(value)
  }

  const onCancel = () => {
    setEditingId(null)
  }

  const onSave = async () => {
    if (!editingId) return
    if (!newName && !newStatus) return

    const body = {
      data: {
        id: Number(editingId),
        type: "vacation_categories" as const,
        attributes: {
          name: newName || undefined,
          display_status: newStatus || undefined
        }
      },
      format: ":jsonapi" as const
    }

    try {
      await udpateLeaveType(body).unwrap()
      setEditingId(null)
      success("Leave type was succesfully update")
    } catch {
      error(defaultError)
    }
  }

  const onCreate = async (formData: typeof initialValues) => {
    const { name, status } = formData

    try {
      await createLeaveType({
        name,
        display_status: status
      }).unwrap()
      form.resetFields()
      success("New leave type was successfully created")
    } catch {
      error(defaultError)
    }
  }

  const onDelete = async (leaveTypeId: string) => {
    try {
      await deleteLeaveType(leaveTypeId)
      success("Leave type was successfully deleted")
    } catch {
      error(defaultError)
    }
  }

  return {
    leaveTypes,
    unremovableLeaveTypesIds,
    newName,
    newStatus,
    editingId,
    newLeaveForm: form,
    handleDescriptionChange: onDescriptionChange,
    handleStatusChange: onStatusChange,
    handleCancel: onCancel,
    handleEdit: onEdit,
    handleSave: onSave,
    handleCreate: onCreate,
    handleDelete: onDelete
  }
}
