import { UpdateNotificationsSettingsParams } from "@interfaces"
import { Build } from "@state/services/types"

import { generateUserNotificationSettingsURL } from "../urls"
import { deriveUserNotificationSettings } from "../transformer"

export const updateNotificationSettings = (build: Build) =>
  build.mutation<unknown, UpdateNotificationsSettingsParams>({
    query: params => ({
      url: generateUserNotificationSettingsURL(params.userId),
      method: "PUT",
      body: deriveUserNotificationSettings(params)
    }),
    invalidatesTags: ["NotificationSettings"]
  })
