import { DestroySalariesQuery } from "@interfaces"
import { DestroySalaryResponse } from "@state/types/users"
import { Build } from "@state/services/types"

import { generateDestroyUserSalaryURL } from "../urls"

export const deleteSalaryChange = (build: Build) =>
  build.mutation<DestroySalaryResponse, DestroySalariesQuery>({
    query: params => ({
      url: generateDestroyUserSalaryURL(params.user_id, params.id),
      method: "DELETE"
    }),
    invalidatesTags: ["User"]
  })
