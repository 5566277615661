import { UpdateUserPermissionsBodyRequest } from "@interfaces"
import { Build } from "@state/services/types"
import { UpdateUserPermissionsResponse } from "@state/types/users"

import { generateUpdateUserPermissionsURL } from "../urls"

export const updateUserPermissions = (build: Build) =>
  build.mutation<UpdateUserPermissionsResponse, UpdateUserPermissionsBodyRequest>({
    query: ({ id, body }) => ({
      url: generateUpdateUserPermissionsURL(id),
      method: "PUT",
      body
    })
  })
