import { AssignmentHr, LocationCardSettings } from "@interfaces"
import React, { FC, useMemo, useState } from "react"
import { Flex, Typography } from "antd"

import { HRCard } from "./HRCard/HRCard"

type Props = { settings: LocationCardSettings }

const HRForm: FC<Props> = ({ settings: { hrs, setting } }) => {
  const [linesNumber, setLinesNumber] = useState(hrs.length ? 0 : 1)
  const [isAddingHr, setIsAddingHr] = useState(false)

  const lines = useMemo(() => {
    let transitionalLinesNum = linesNumber
    if (!hrs.length && !transitionalLinesNum) transitionalLinesNum = 1

    if (isAddingHr) {
      setIsAddingHr(false)
      transitionalLinesNum -= 1
    }

    setLinesNumber(transitionalLinesNum)
    const emptyLines = new Array(transitionalLinesNum).fill(null)

    const allLines: Array<AssignmentHr | null> = [...hrs, ...emptyLines]

    return allLines
  }, [hrs, linesNumber])

  const addLine = () => {
    setLinesNumber(prev => prev + 1)
  }

  const addNewHr = () => {
    setIsAddingHr(true)
  }

  const deleteLine = () => {
    setLinesNumber(prev => {
      const newValue = prev - 1
      const minValue = hrs.length ? 0 : 1

      return newValue < 0 ? minValue : newValue
    })
  }

  const hrIds = hrs.map(hr => hr.attributes.user_id)

  return (
    <Flex vertical gap="middle" style={{ paddingBottom: "2rem" }}>
      <Typography.Title level={5}>Assigned HRs:</Typography.Title>
      {lines.map((hr, index) => {
        return (
          <HRCard
            key={hr ? hr.id + index : index}
            setting_id={setting.id}
            hr={hr}
            hrIds={hrIds}
            isSingle={lines.length === 1}
            isLast={lines.length - 1 === index}
            addLine={addLine}
            deleteLine={deleteLine}
            addNewHr={addNewHr}
          />
        )
      })}
    </Flex>
  )
}

export default HRForm
