import { Abilities } from "@interfaces*"
import { DepartmentCard, DepartmentCreate } from "@app/sections/Departments"

import { DefaultWrapper } from "@components/PageWrappers"

import { RouteItem } from "./types"
import { ADD_DEPARTMENT_ROUTE, DEPARTMENT_CARD_ROUTE } from "./inboundRoutes"

export const departmentsRoutes = (abilities: Abilities | null): Array<RouteItem> => [
  {
    path: DEPARTMENT_CARD_ROUTE,
    title: "Department card",
    slug: "department-card",
    component: DepartmentCard,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.departments?.show
  },
  {
    path: ADD_DEPARTMENT_ROUTE,
    title: "Department create",
    slug: "department-create",
    component: DepartmentCreate,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.departments?.create
  }
]
