import { EmergencyContact } from "@state/types/users"
import React, { FC, useState } from "react"

import { SectionWrapper } from "@components/SectionWrapper"

import { EmergencyContactsForm } from "./EmergencyContactsForm"
import { EmergencyContactsView } from "./EmergencyContactsView"

type EmergencyContactsProps = {
  emergencyContacts: Array<EmergencyContact>
}

export const EmergencyContacts: FC<EmergencyContactsProps> = ({ emergencyContacts }) => {
  const [editMode, setEditMode] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggleExpanded = () => setIsExpanded(value => !value)

  const handleEdit = () => {
    setIsExpanded(true)
    setEditMode(true)
  }

  const handleCancel = () => {
    setIsExpanded(false)
    setEditMode(false)
  }

  return (
    <SectionWrapper>
      {editMode ? (
        <EmergencyContactsForm
          emergencyContacts={emergencyContacts}
          onCancel={handleCancel}
          isExpanded={isExpanded}
          onToggleExpanded={handleToggleExpanded}
        />
      ) : (
        <EmergencyContactsView
          emergencyContacts={emergencyContacts}
          onEdit={handleEdit}
          isExpanded={isExpanded}
          onToggleExpanded={handleToggleExpanded}
        />
      )}
    </SectionWrapper>
  )
}
