import { Build } from "@state/services/types"

import { generateDeactivateActiveBulkURL } from "../urls"

export const deactivateActiveBulk = (build: Build) =>
  build.mutation<unknown, string>({
    query: bulkId => ({
      url: generateDeactivateActiveBulkURL(bulkId),
      method: "PATCH"
    }),
    invalidatesTags: ["EducationalMaterial"]
  })
