import { DescriptionValueFromBackend, NamedValueFromBackend } from "@interfaces"

export const transformNamedValue = (namedValue: NamedValueFromBackend | undefined) => {
  if (!namedValue) return undefined

  const { id, name } = namedValue

  if (id && name) return { id, name }

  return null
}

export const transformDescriptionValue = (descriptionValue: DescriptionValueFromBackend | null | undefined) => {
  if (descriptionValue === undefined) return undefined

  if (descriptionValue === null) return null

  const { id, description } = descriptionValue

  if (id && description) return { id, description }

  return null
}
