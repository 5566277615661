import React, { FC, useState } from "react"
import { UserFullInfoWithAccesses } from "@interfaces"
import { Button } from "antd"
import { useAlert, useProfileHandlers } from "@hooks"

import { TerminationModal } from "./TerminationModal"
import { TerminationData } from "./types"

type TerminationProps = {
  user: UserFullInfoWithAccesses
}

export const Termination: FC<TerminationProps> = ({ user }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { saveUserSeparationDate, isUpdateSeparationDateLoading } = useProfileHandlers()

  const { success } = useAlert()

  const handleTerminateClick = () => {
    setIsModalVisible(true)
  }

  const handleSave = async (terminationData: TerminationData) => {
    const result = await saveUserSeparationDate(user.id!, terminationData.separationDate)

    if (result) {
      setIsModalVisible(false)
      success("Separation date updated successfully")
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button onClick={handleTerminateClick} data-testid="terminate-button">
        Terminate
      </Button>
      <TerminationModal
        user={user}
        isOpen={isModalVisible}
        onSave={handleSave}
        onCancel={handleCancel}
        isLoading={isUpdateSeparationDateLoading}
      />
    </>
  )
}
