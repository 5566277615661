export const VACATIONS_URL = "/vacations"
export const VACATIONS_REQUESTS_URL = `${VACATIONS_URL}/requests?include=user&extra_fields[vacations]=balances`
export const VACATIONS_CREATE_URL = `${VACATIONS_URL}`
export const VACATIONS_OPTIONS_URL = `${VACATIONS_URL}/options`
export const VACATION_ACCUMULATIONS_URL = `/vacation_accumulations`

export const generateVacationCancelURL = (id: number | string) => `${VACATIONS_URL}/${id}/cancel`
export const generateVacationSolutionURL = (id: number | string) => `${VACATIONS_URL}/${id}/vote`
export const generateGetVacationLogsURL = (id: number | string) =>
  `${VACATIONS_URL}/${id}/logs?include=user&fields[users]=email`

export const generateVacationAccumulationsURL = (id: number | string) =>
  `${VACATION_ACCUMULATIONS_URL}?filter[user_id]=${id}`
export const generateVacationAccumulationsOptionsURL = (id: number | string) =>
  `${VACATION_ACCUMULATIONS_URL}/users/${id}/options`
export const generateVacationAccumulationCancelURL = (id: number | string) =>
  `${VACATION_ACCUMULATIONS_URL}/${id}/cancel`
