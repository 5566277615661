import { ResponseWithTotalCount, Department } from "@interfaces"
import { BackendDepartmentsResponse, GetDepartmentsParams } from "@state/types/departments"
import { Build } from "@state/services/types"

import { generateDepartmentsURL } from "../urls"
import { transformDepartments } from "../transformDepartments"

export const fetchDepartments = (build: Build) =>
  build.query<ResponseWithTotalCount<Department>, GetDepartmentsParams>({
    query: params => {
      const { filters, page, size } = params

      return { url: generateDepartmentsURL(page, size, filters) }
    },
    providesTags: res =>
      res?.entities
        ? [...res.entities.map(department => ({ type: "Department" as const, id: department.id }))]
        : ["Department"],
    transformResponse: (response: BackendDepartmentsResponse, meta) => ({
      entities: transformDepartments(response),
      totalCount: Number(meta?.response?.headers.get("X-Total-Count"))
    })
  })
