import { Build } from "@state/services/types"

import { generateMutationPolicyURL } from "../urls"

export const deletePolicy = (build: Build) =>
  build.mutation<{ message: string }, string>({
    query: id => ({
      url: generateMutationPolicyURL(id),
      method: "DELETE"
    }),
    invalidatesTags: ["Policy"]
  })
