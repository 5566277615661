export const RESET_PASSWORD_URL = "/reset-password"
export const CHANGE_PASSWORD_URL = "/change-password"
export const REQUEST_PASSWORD_RESET_URL = "/reset-password-request"
export const LOGIN_URL = "/login"
export const LOGOUT_URL = "/logout"
export const ME_URL = "/me"
export const SWITCH_USER_URL = "/switch"
export const OTP_SETUP_URL = "otp-setup"
export const OTP_AUTH_URL = "otp-auth"
export const REMOVE_OTP_URL = "otp_remove"
