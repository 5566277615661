import { AvailableSystem } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendAvailableSystemsResponse } from "@state/types/availableSystems"

import { transformAvailableSystems } from "../../availableSystems/transformer"
import { AVAILABLE_SYSTEMS_URL } from "../urls"

export const fetchDashboardAvailableSystems = (build: Build) =>
  build.query<Array<AvailableSystem>, void>({
    query: () => ({
      url: AVAILABLE_SYSTEMS_URL
    }),
    transformResponse: (res: BackendAvailableSystemsResponse) => transformAvailableSystems(res),
    providesTags: ["AvailableSystem", "User"]
  })
