import { AccessGroup, AccessGroupUser } from "@interfaces*"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type StateGroup = {
  name: string
  id: string
  users: Array<AccessGroupUser>
}

type Manage = {
  users_added?: Array<number>
  users_removed?: Array<number>
}

type manageGroupState = {
  allGroups: { [k: string]: StateGroup }
  groupsList: { [k: string]: StateGroup }
  manage: { [k: string]: Manage }
  firstGroupId: string | null
}

const initialState: manageGroupState = {
  allGroups: {},
  groupsList: {},
  manage: {},
  firstGroupId: null
}

const manageGroupsSlice = createSlice({
  name: "manageGroups",
  initialState,
  reducers: {
    initGroups(state, action: PayloadAction<Array<AccessGroup>>) {
      const allGroups: { [k: string]: StateGroup } = {}

      action.payload.forEach((group, index) => {
        if (index === 0) state.firstGroupId = group.id
        allGroups[group.id] = {
          id: group.id,
          name: group.name,
          users: group.users
        }
      })
      state.allGroups = allGroups
      state.groupsList = allGroups
    },

    addUser(state, { payload }: PayloadAction<{ user: AccessGroupUser; groupId: string }>) {
      if (state.groupsList[payload.groupId].users.find(user => user.id === payload.user.id)) return
      if (!state.manage[payload.groupId]) {
        state.manage[payload.groupId] = { users_added: [], users_removed: [] }
      }

      if (state.manage[payload.groupId].users_removed?.includes(Number(payload.user.id))) {
        state.manage[payload.groupId].users_removed = state.manage[payload.groupId].users_removed?.filter(
          userId => userId !== Number(payload.user.id)
        )
      }
      state.manage[payload.groupId].users_added!.push(Number(payload.user.id))

      state.groupsList[payload.groupId].users.push(payload.user)
    },

    deleteUser(state, { payload }: PayloadAction<{ user: AccessGroupUser; groupId: string }>) {
      const groupUsers = state.groupsList[payload.groupId].users

      if (!state.manage[payload.groupId]) {
        state.manage[payload.groupId] = { users_added: [], users_removed: [] }
      }
      if (state.manage[payload.groupId].users_added?.includes(Number(payload.user.id))) {
        state.manage[payload.groupId].users_added = state.manage[payload.groupId].users_added?.filter(
          userId => userId !== Number(payload.user.id)
        )
      }
      state.manage[payload.groupId].users_removed!.push(Number(payload.user.id))

      state.groupsList[payload.groupId].users = groupUsers.filter(user => user.id !== payload.user.id)
    },

    clearManage(state) {
      state.manage = {}
    }
  }
})

export const manageGroupsActions = manageGroupsSlice.actions

export default manageGroupsSlice.reducer
