import { Build } from "@state/services/types"

import { generateDeleteCertificatesURL } from "../urls"

export const deleteCertificate = (build: Build) =>
  build.mutation<{ message: string }, string>({
    query: id => ({
      url: generateDeleteCertificatesURL(id),
      method: "DELETE"
    }),
    invalidatesTags: ["Certificate"]
  })
