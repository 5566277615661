import { Build } from "@state/services/types"
import { UpdateSlackGroupParams } from "@interfaces"

import { generateSlackGroupUrl } from "../urls"

export const updateSlackGroup = (build: Build) =>
  build.mutation<unknown, UpdateSlackGroupParams>({
    query: params => {
      const { id, ...body } = params
      return {
        url: generateSlackGroupUrl(id),
        method: "PUT",
        body
      }
    },
    invalidatesTags: ["SlackGroup"]
  })
