import { BAD_REQUEST_CODE, dispatchAlert } from "@helpers"
import { SlackSettingsState } from "@interfaces"
import { createSlice } from "@reduxjs/toolkit"
import { slackSettingsApi, usersApi } from "@state/services/subApis"

const initialState: SlackSettingsState = { slackError: undefined }

const personalSettingsSlice = createSlice({
  name: "personalSettings",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(usersApi.endpoints.fetchNotificationSettings.matchRejected, () => {
      dispatchAlert("error", "Error loading user notification settings")
    })

    builder.addMatcher(usersApi.endpoints.fetchNotificationSettingsOptions.matchRejected, () => {
      dispatchAlert("error", "Error loading user notification settings options")
    })

    builder.addMatcher(slackSettingsApi.endpoints.fetchSlackSettingsOptions.matchRejected, (state, { payload }) => {
      const errorMessage = (payload?.data as { error?: string })?.error || "Error loading slack settings options"

      if (payload?.status === BAD_REQUEST_CODE) {
        state.slackError = errorMessage
      } else {
        state.slackError = undefined
        dispatchAlert("error", errorMessage)
      }
    })

    builder.addMatcher(slackSettingsApi.endpoints.fetchSlackSettingsOptions.matchFulfilled, state => {
      state.slackError = undefined
    })
  }
})

export const personalSettingsSliceActions = personalSettingsSlice.actions

export default personalSettingsSlice.reducer
