import { GetMeResponse } from "@interfaces"
import { Build } from "@state/services/types"

import { ME_URL } from "../urls"

export const fetchMe = (build: Build) =>
  build.query<GetMeResponse, void>({
    query: () => ({ url: ME_URL, method: "GET" }),
    providesTags: ["Vacation"]
  })
