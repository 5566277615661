import { CreateSalariesQuery } from "@interfaces"
import { Build } from "@state/services/types"
import { CreateSalaryResponse } from "@state/types/users"

import { generateUserSalaryChangesURL } from "../urls"

export const createSalaryChanges = (build: Build) =>
  build.mutation<CreateSalaryResponse, CreateSalariesQuery>({
    query: params => ({
      url: generateUserSalaryChangesURL(params.id),
      method: "POST",
      body: { salaries_data: params.body, format: ":jsonapi" }
    }),
    invalidatesTags: ["User"]
  })
