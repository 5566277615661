import React, { FC, useState } from "react"
import { CreateLocationBodyReq, Option } from "@interfaces"
import { Button, Col, Form, Row, Select } from "antd"
import { useAlert } from "@hooks"
import { locationsApi } from "@state/services/subApis"

import { ValidatedSelect, ValidatedTextInput } from "@components/ValidatedInputs"

import style from "./style.m.scss"

type AddNewLocationProps = {
  dataStorageOptions: Array<Option>
  locationsOptions: Array<Option>
}

type LocationFormData = {
  name: string
  dataStorageLocation?: string
  country?: string
}

const initialFormValues = {
  name: ""
}

const AddNewLocation: FC<AddNewLocationProps> = ({ dataStorageOptions, locationsOptions }) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const { success, error } = useAlert()
  const [createLocation] = locationsApi.endpoints.createLocation.useMutation()

  const [form] = Form.useForm()

  const checkFields = () => {
    const formValues = form.getFieldsValue()
    const allFieldsFilled = Object.values(formValues).every(value => !!value)

    setIsSubmitDisabled(!allFieldsFilled)
  }

  const handleSubmit = async (formData: LocationFormData) => {
    const bodyRequest: CreateLocationBodyReq = {
      data: {
        type: "locations" as const,
        attributes: {
          name: formData.name,
          s3_region: formData.dataStorageLocation,
          short_name: formData.country
        }
      },
      format: ":jsonapi" as const
    }

    try {
      await createLocation(bodyRequest).unwrap()
      form.resetFields()
      checkFields()
      success("Location was succesfully created")
    } catch (err) {
      const errorText = err as string
      error(errorText)
    }
  }

  return (
    <Form form={form} initialValues={initialFormValues} onFinish={handleSubmit} onFieldsChange={checkFields}>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name="name" label="Location" labelCol={{ span: 24 }}>
            <ValidatedTextInput />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="country" label="Country" labelCol={{ span: 24 }}>
            <ValidatedSelect showSearch optionFilterProp="label" options={locationsOptions} />
          </Form.Item>
        </Col>
        <Col span={4} style={{ minWidth: 250 }}>
          <Form.Item name="dataStorageLocation" label="Personal data storage location" labelCol={{ span: 24 }}>
            <Select options={dataStorageOptions} />
          </Form.Item>
        </Col>
        <Col span={2} className={style.buttonWrapper}>
          <Button type="primary" htmlType="submit" disabled={isSubmitDisabled}>
            Add
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default AddNewLocation
