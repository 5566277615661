import { TeamMember, UserTeamParams } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendUserTeamResponse } from "@state/types/users"

import { generateUserTeamURL } from "../urls"
import { transformUserTeamResponse } from "../transformer"

export const fetchUserTeam = (build: Build) =>
  build.query<Array<TeamMember>, UserTeamParams>({
    query: params => ({
      url: generateUserTeamURL(params.userId)
    }),
    providesTags: ["User"],
    transformResponse: (data: BackendUserTeamResponse) => transformUserTeamResponse(data)
  })
