import { EmergencyContact } from "@state/types/users"
import { Col, Row } from "antd"
import React, { FC } from "react"
import { ExpandableSection } from "@app/sections/Profile/components/ExpandableSection"

import { EditButton } from "@components/buttons/EditButton"

import style from "./style.m.scss"

type EmergencyContactsViewProps = {
  emergencyContacts: Array<EmergencyContact>
  onEdit: () => void
  isExpanded: boolean
  onToggleExpanded: () => void
}

export const EmergencyContactsView: FC<EmergencyContactsViewProps> = ({
  emergencyContacts,
  onEdit,
  isExpanded,
  onToggleExpanded
}) => {
  return (
    <ExpandableSection
      title="Emergency contacts"
      actions={<EditButton onClick={onEdit} data-testid="edit-emergency-contacts-description" />}
      isExpanded={isExpanded}
      onToggleExpanded={onToggleExpanded}
    >
      <Row gutter={16} className={style.header}>
        <Col span={6} className={style.text}>
          Name
        </Col>
        <Col span={6} className={style.text}>
          Relationship
        </Col>
        <Col span={6} className={style.text}>
          Phone number
        </Col>
        <Col span={6} className={style.text}>
          E-mail
        </Col>
      </Row>
      {emergencyContacts.map(contact => (
        <Row gutter={16} key={contact.phone_number} className={style.row}>
          <Col span={6}>{contact.name}</Col>
          <Col span={6}>{contact.relationship}</Col>
          <Col span={6}>+ {contact.phone_number}</Col>
          <Col span={6}>{contact.email}</Col>
        </Row>
      ))}
    </ExpandableSection>
  )
}
