import { AccessType } from "@enums"
import { AccessEntity, ManageUsersPermissionsEntity } from "@interfaces*"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

type ManageUsersPermissionsState = {
  userId: string | undefined
  all: {
    groups: Array<ManageUsersPermissionsEntity>
    policies: Array<ManageUsersPermissionsEntity>
  }
  user: {
    groups: Array<ManageUsersPermissionsEntity>
    policies: Array<ManageUsersPermissionsEntity>
  }
  list: {
    groups: Array<ManageUsersPermissionsEntity>
    policies: Array<ManageUsersPermissionsEntity>
  }
  removed: {
    groups: Array<string>
    policies: Array<string>
  }
  added: {
    groups: Array<string>
    policies: Array<string>
  }
  inUse: {
    groups: Array<string>
    policies: Array<string>
  }
}

type DispatchedEntity = {
  entity: AccessEntity
  type: AccessType
}

const initialState: ManageUsersPermissionsState = {
  userId: undefined,
  all: {
    groups: [],
    policies: []
  },
  user: {
    groups: [],
    policies: []
  },
  list: {
    groups: [],
    policies: []
  },
  removed: {
    groups: [],
    policies: []
  },
  added: {
    groups: [],
    policies: []
  },
  inUse: {
    groups: [],
    policies: []
  }
}

const makeEntitiesMap = (entities: Array<ManageUsersPermissionsEntity>) => {
  const entitiesMap: { [k: string]: ManageUsersPermissionsEntity } = {}

  entities.forEach(entity => {
    entitiesMap[entity.id] = entity
  })

  return entitiesMap
}

const manageUsersPermissionsSlice = createSlice({
  name: "manageUsersPermissions",
  initialState,
  reducers: {
    initAllGroupsAndPolicies(
      state,
      action: PayloadAction<{
        groups: Array<ManageUsersPermissionsEntity>
        policies: Array<ManageUsersPermissionsEntity>
      }>
    ) {
      state.userId = undefined
      state.user.groups = []
      state.user.policies = []
      state.all.groups = action.payload.groups
      state.all.policies = action.payload.policies
      state.list.groups = action.payload.groups
      state.list.policies = action.payload.policies
    },

    initUserGroupsAndPolicies(
      state,
      action: PayloadAction<{
        groups: Array<ManageUsersPermissionsEntity>
        policies: Array<ManageUsersPermissionsEntity>
      }>
    ) {
      state.user.groups = action.payload.groups
      state.user.policies = action.payload.policies
      state.list.groups = state.all.groups
      state.list.policies = state.all.policies
      state.inUse.groups = action.payload.groups.map(group => group.id)
      state.inUse.policies = action.payload.policies.map(policy => policy.id)
      state.added.groups = []
      state.added.policies = []
      state.removed.groups = []
      state.removed.policies = []

      if (action.payload.groups.length) {
        const currentGroupsMap = makeEntitiesMap(action.payload.groups)
        state.list.groups = state.all.groups.filter(group => !currentGroupsMap[group.id])
      }

      if (action.payload.policies.length) {
        const currentPoliciesMap = makeEntitiesMap(action.payload.policies)
        state.list.policies = state.all.policies.filter(policies => !currentPoliciesMap[policies.id])
      }
    },

    setCurrentUserId(state, action: PayloadAction<string | undefined>) {
      state.userId = action.payload
    },

    addEntity(state, action: PayloadAction<DispatchedEntity>) {
      switch (action.payload.type) {
        case "group": {
          if (!state.inUse.groups.includes(action.payload.entity.id)) {
            state.added.groups = state.added.groups.concat(action.payload.entity.id)
          }
          state.user.groups = state.user.groups.concat(action.payload.entity)
          state.list.groups = state.list.groups.filter(group => group.id !== action.payload.entity.id)
          if (state.removed.groups.includes(action.payload.entity.id))
            state.removed.groups = state.removed.groups.filter(groupId => groupId !== action.payload.entity.id)
          break
        }
        case "policy": {
          if (!state.inUse.policies.includes(action.payload.entity.id)) {
            state.added.policies = state.added.policies.concat(action.payload.entity.id)
          }
          state.user.policies = state.user.policies.concat(action.payload.entity)
          state.list.policies = state.list.policies.filter(policy => policy.id !== action.payload.entity.id)
          if (state.removed.policies.includes(action.payload.entity.id))
            state.removed.policies = state.removed.policies.filter(policyId => policyId !== action.payload.entity.id)
          break
        }
        default:
      }
    },

    deleteEntity(state, action: PayloadAction<DispatchedEntity>) {
      switch (action.payload.type) {
        case "group": {
          if (state.inUse.groups.includes(action.payload.entity.id)) {
            state.removed.groups = state.removed.groups.concat(action.payload.entity.id)
          }
          state.user.groups = state.user.groups.filter(group => group.id !== action.payload.entity.id)
          state.list.groups = state.list.groups.concat(action.payload.entity)
          if (state.added.groups.includes(action.payload.entity.id))
            state.added.groups = state.added.groups.filter(groupId => groupId !== action.payload.entity.id)
          break
        }
        case "policy": {
          if (state.inUse.policies.includes(action.payload.entity.id)) {
            state.removed.policies = state.removed.policies.concat(action.payload.entity.id)
          }
          state.user.policies = state.user.policies.filter(policy => policy.id !== action.payload.entity.id)
          state.list.policies = state.list.policies.concat(action.payload.entity)
          if (state.added.policies.includes(action.payload.entity.id))
            state.added.policies = state.added.policies.filter(policyId => policyId !== action.payload.entity.id)
          break
        }
        default:
      }
    }
  }
})

export const manageUsersPermissionsActions = manageUsersPermissionsSlice.actions

export default manageUsersPermissionsSlice.reducer
