import { Button, Divider, Flex, Form, Tooltip } from "antd"
import React, { FC } from "react"

import { useEducationalMaterialsNewBulk } from "../helpers/useEducationalMaterialsNewBulk"

import { Title } from "@components/Title"

import { FileFormList } from "./FileFormList"
import { LinkFormList } from "./LinkFormList"
import { DepartmentFormList } from "./DepartmentFormList"
import { EmployeesFormList } from "./EmployeeFormList/EmployeeFormList"

export const AddNewBulkForm: FC = () => {
  const {
    form,
    initialFormValues,
    isBulkValid,
    isSendToAllDepts,
    pickedDepts,
    onDelete,
    onCreateBulk,
    validateForm,
    derivePickedUsers,
    derivePickedDepts,
    onIsSendToAllDepts
  } = useEducationalMaterialsNewBulk()

  return (
    <Form form={form} initialValues={initialFormValues} onFieldsChange={validateForm} onFinish={onCreateBulk}>
      <Flex align="flex-end" justify="space-between">
        <Title datatest-id="add-new-bulk-title" level={5}>
          Add new
        </Title>
        {isBulkValid ? (
          <Button datatest-id="add-new-bulk-button" htmlType="submit" type="primary">
            Create
          </Button>
        ) : (
          <Tooltip title="Please add one or more educational materials and assign them to specific departments or employees">
            <Button disabled>Create</Button>
          </Tooltip>
        )}
      </Flex>
      <Divider />

      <Flex vertical style={{ marginBottom: "30px" }}>
        <LinkFormList onDelete={onDelete} />
        <FileFormList onDelete={onDelete} />
        <DepartmentFormList
          onDelete={onDelete}
          pickedDepts={pickedDepts}
          derivePickedDepts={derivePickedDepts}
          isSendToAllDepts={isSendToAllDepts}
          onIsSendToAllDepts={onIsSendToAllDepts}
        />
        <EmployeesFormList onDelete={onDelete} derivePickedUsers={derivePickedUsers} />
      </Flex>
    </Form>
  )
}
