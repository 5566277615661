import { Button, Flex, Popconfirm, Table } from "antd"
import React, { FC, useState } from "react"
import { educationalMaterialsApi } from "@state/services/subApis"
import { capitalizeFirstLetter, defaultError } from "@helpers"
import { BulkStatuses } from "@enums"
import dayjs from "dayjs"
import { EducationalMaterialBulkLog } from "@interfaces*"
import { useAlert } from "@hooks"

import {
  deactivatedColumns,
  deriveDepartmentsColumns,
  employeesColumns,
  materialsTableColumns
} from "../helpers/logTableColumns"
import { ExpandableContainer } from "../components/ExpandableContainer"

import { Title } from "@components/Title"

import style from "./style.m.scss"

type BulksLogsProps = {
  status: BulkStatuses
}

const message = "You are about to deactivate current bulk. Are you sure?"

export const BulksLogs: FC<BulksLogsProps> = ({ status }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { success, error } = useAlert()

  const {
    data: logs,
    isLoading,
    isFetching
  } = educationalMaterialsApi.endpoints.fetchEducationalBulkLogs.useQuery(status)

  const [deactivateBulk] = educationalMaterialsApi.endpoints.deactivateActiveBulk.useMutation()

  const deriveDeactivatedInfo = (info: EducationalMaterialBulkLog) => [info]

  const handleDeactivate = async (bulkId: string) => {
    try {
      await deactivateBulk(bulkId).unwrap()
      success("Bulk was succesfully deactivated")
    } catch {
      error(defaultError)
    }
  }

  const handleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const title = `${capitalizeFirstLetter(status)} ${status === BulkStatuses.Active ? "rules" : ""}`

  if (!logs?.length) return null

  return (
    <ExpandableContainer isExpanded={isExpanded} title={title} onToggleExpanded={handleExpand}>
      {logs.map(log => (
        <div key={log.id} className={style.wrapper}>
          <Flex justify="space-between" align="center" className={style.header}>
            <Title datatest-id="bulks-active-rules" level={5}>
              Rule created by {log.createdBy} at {dayjs(log.createdAt).format("DD.MM.YYYY HH:mm")}
            </Title>
            {status === BulkStatuses.Active && (
              <Popconfirm title={message} onConfirm={() => handleDeactivate(log.id)} placement="topLeft">
                <Button datatest-id="deactivate-bulk-button" type="primary">
                  Deactivate
                </Button>
              </Popconfirm>
            )}
          </Flex>
          <Flex vertical gap={10}>
            <Table
              columns={materialsTableColumns}
              dataSource={log.educationalMaterials}
              rowKey="id"
              loading={isLoading || isFetching}
              pagination={false}
            />
            <Flex justify="space-between" gap={20}>
              {!!log.departments?.length && (
                <Table
                  style={{ width: status === BulkStatuses.Active ? "40%" : "70%" }}
                  columns={deriveDepartmentsColumns(status)}
                  dataSource={log.departments}
                  rowKey="name"
                  loading={isLoading || isFetching}
                  pagination={false}
                />
              )}
              {status === BulkStatuses.History && (
                <Table
                  columns={deactivatedColumns}
                  dataSource={deriveDeactivatedInfo(log)}
                  rowKey="id"
                  loading={isLoading || isFetching}
                  pagination={false}
                />
              )}
            </Flex>
            {!!log.employees?.length && (
              <Table
                style={{ width: "25%" }}
                columns={employeesColumns}
                dataSource={log.employees}
                rowKey="name"
                loading={isLoading || isFetching}
                pagination={false}
              />
            )}
          </Flex>
        </div>
      ))}
    </ExpandableContainer>
  )
}
