import { AvailableSystem, AvailableSystemsParams } from "@interfaces"
import { BackendAvailableSystemsResponse } from "@state/types/availableSystems"
import { Build } from "@state/services/types"

import { generateAvailableSystemsUrl } from "../urls"
import { transformAvailableSystems } from "../transformer"

export const fetchAvailableSystems = (build: Build) =>
  build.query<Array<AvailableSystem>, AvailableSystemsParams>({
    query: params => ({ url: generateAvailableSystemsUrl(params.showHasUsers) }),
    transformResponse: (data: BackendAvailableSystemsResponse) => transformAvailableSystems(data),
    providesTags: ["AvailableSystem"]
  })
