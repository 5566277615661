import React from "react"
import { Certificate } from "@interfaces*"
import { Button, Popconfirm } from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import { useAlert } from "@hooks"
import { Link } from "react-router-dom"
import { formatedLongFileNames } from "@app/sections/Profile/helpers/formatedLongFileNames"
import { certificatesApi } from "@state/services/subApis"
import { generateDownloadCertificateURL, generateShowCertificateURL } from "@state/services/subApis/certificates/urls"

export const useCertificatesTableColumns = (editMode: boolean) => {
  const { success, error } = useAlert()

  const [deleteCertificate] = certificatesApi.endpoints.deleteCertificate.useMutation()

  const handleDeleteUserClick = async (id: string) => {
    try {
      const res = await deleteCertificate(id).unwrap()
      success(res.message)
    } catch {
      error("Error while deliting certificate")
    }
  }

  const basicColumns = [
    {
      key: "number",
      width: "30px",
      render: (_: unknown, __: Certificate, index: number) => (index + 1).toString()
    },
    {
      title: "Training / Certificate name",
      key: "name",
      render: (_: unknown, { name }: Certificate) => name
    },
    {
      title: "Document",
      key: "document",
      render: (_: unknown, { certificateId, fileName }: Certificate) => {
        return fileName ? (
          <>
            <Link to={generateShowCertificateURL(certificateId)} target="_blank">
              {formatedLongFileNames(fileName)}
            </Link>
            <a href={generateDownloadCertificateURL(certificateId)}>
              <Button type="link" icon={<DownloadOutlined />} href={generateDownloadCertificateURL(certificateId)} />
            </a>
          </>
        ) : (
          <div />
        )
      }
    },
    {
      title: "Training / Certificate dates",
      key: "createdDate",
      render: (_: unknown, { startedAt, endedAt }: Certificate) =>
        `${startedAt?.split("-").join(".")} - ${endedAt?.split("-").join(".")}`
    }
  ]

  const actionsColumn = {
    title: "Actions",
    key: "actions",
    width: "100px",
    render: (_: unknown, { certificateId, fileName }: Certificate) => (
      <Popconfirm
        title={`Are you about to delete ${fileName}. Are you sure?`}
        onConfirm={() => handleDeleteUserClick(certificateId)}
        placement="topLeft"
      >
        <Button type="link" style={{ padding: 0 }}>
          Delete
        </Button>
      </Popconfirm>
    )
  }

  return editMode ? [...basicColumns, actionsColumn] : basicColumns
}
