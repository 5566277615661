import { AccessGroups, Policies } from "@app/sections/PolicySystem"
import Manage from "@app/sections/PolicySystem/Manage/Manage"

import { RouteItem } from "./types"
import { POLICIES, ACCESS_GROUPS, ACCESS_GROUPS_MANAGE } from "./inboundRoutes"

export const permissionsMenuRoutes = (): Array<RouteItem> => [
  {
    path: POLICIES,
    title: "Policies",
    slug: "policies",
    component: Policies,
    hidden: false
  },
  {
    path: ACCESS_GROUPS,
    title: "Groups",
    slug: "groups",
    component: AccessGroups,
    hidden: false
  },
  {
    path: ACCESS_GROUPS_MANAGE,
    title: "Users",
    slug: "manage-groups",
    component: Manage,
    hidden: false
  }
]
