import { Form } from "antd"
import React, { FC } from "react"

import { NewBulkForm } from "../../helpers/types"

import { EmployeeFormListItem } from "./EmployeeFormListItem"

type DepartmentFormListProps = {
  onDelete: (
    fieldName: number,
    remove: (index: number | Array<number>) => void,
    formFieldName: keyof NewBulkForm
  ) => void
  derivePickedUsers: () => Array<string>
}

export const EmployeesFormList: FC<DepartmentFormListProps> = ({ onDelete, derivePickedUsers }) => {
  const handleDeleteItem = (fieldName: number, remove: (index: number) => void) => {
    onDelete(fieldName, remove, "newEmployees")
  }

  return (
    <Form.List name="newEmployees">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <EmployeeFormListItem
              key={field.key}
              employeeField={field}
              onAddItem={add}
              onRemoveItem={() => handleDeleteItem(field.name, remove)}
              isLastItem={index === fields.length - 1}
              derivePickedUsers={derivePickedUsers}
            />
          ))}
        </>
      )}
    </Form.List>
  )
}
