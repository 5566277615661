import { Flex } from "antd"
import React, { FC, useEffect } from "react"
import { useAlert, useAppDispatch, useAppSelector } from "@hooks"
import { newPolicyActions } from "@state/reducers/newPolicy"
import { AccessGroup } from "@interfaces*"
import { defaultError } from "@helpers"
import { newGroupActions } from "@state/reducers/newGroup"
import { groupsApi } from "@state/services/subApis"

import { PoliciesCollapse } from "../../components/PoliciesCollapse"
import { PermissionsCollapse } from "../../components/PermissionsCollapse"
import { ListWrapper } from "../../components/ListWrapper"
import { convertPermissions } from "../../helpers/convertPermissions"
import { CreateForm } from "../../components/CreateForm"
import { generatePoliciesPermissions } from "../../Policies/helpers"

import style from "./style.m.scss"

type CreateGroupProps = {
  groups: Array<AccessGroup>
}

type FormData = { name: string }

const CreateGroup: FC<CreateGroupProps> = ({ groups }) => {
  const dispatch = useAppDispatch()
  const { error, success } = useAlert()

  const { sections: extraPermissionsSections } = useAppSelector(state => state.newPolicyReducer)
  const { addedPolicies, currentGroup } = useAppSelector(state => state.newGroupReducer)

  const [createGroup] = groupsApi.endpoints.createGroup.useMutation()
  const [updateGroup] = groupsApi.endpoints.updateGroup.useMutation()

  useEffect(() => {
    dispatch(newPolicyActions.clearAllSections())
  }, [])

  const generateHandleClearSection = (section: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      dispatch(newPolicyActions.clearSection({ section }))
    }
  }

  const generateHandleTogglePermission = (section: string, permissionListId: number) => {
    return () => {
      dispatch(newPolicyActions.togglePermission({ section, permissionListId }))
    }
  }

  const checkGroupExist = (currentGroupName: string) => {
    const currentGroup = groups.find(group => group.name === currentGroupName)

    if (currentGroup) {
      dispatch(newGroupActions.setCurrentGroup({ groupName: currentGroup.name, id: currentGroup.id }))
      const currentPermissions = convertPermissions(currentGroup.extraPermissions)

      dispatch(newPolicyActions.addPolicyPermission(currentPermissions))
      currentGroup.policies.forEach(policy => {
        dispatch(newGroupActions.addPolicy(policy))
      })
    }
  }

  const handleGroupReset = () => {
    dispatch(newPolicyActions.clearAllSections())
    dispatch(newGroupActions.clearAll())
  }

  const handleCreateGroupSubmit = async ({ name }: FormData, operation: "Create" | "Update") => {
    const pickedPermissions = generatePoliciesPermissions(extraPermissionsSections)

    const bodyReq = {
      id: currentGroup.id ? currentGroup.id : undefined,
      name,
      permissions: pickedPermissions,
      policy_ids: addedPolicies.map(policy => Number(policy.id)),
      format: ":jsonapi" as const
    }

    try {
      if (operation === "Create") {
        await createGroup(bodyReq).unwrap()
        handleGroupReset()
        success("Group was succesfully created")
      }

      if (operation === "Update") {
        await updateGroup(bodyReq).unwrap()
        handleGroupReset()
        success("Group was succesfully updated")
      }
    } catch {
      error(defaultError)
    }
  }

  const disabledCondition =
    !Object.entries(extraPermissionsSections).some(([, { chosen }]) => chosen.length) && !addedPolicies.length
  const options = groups.map(group => ({ label: group.name, value: group.name }))

  return (
    <ListWrapper title="Create groups" wrapperClassName={style.wrapper}>
      <CreateForm
        title="Group"
        currentValue={currentGroup}
        options={options}
        disabled={disabledCondition}
        handleSubmit={handleCreateGroupSubmit}
        setCurrentValue={checkGroupExist}
        reset={handleGroupReset}
      />
      <Flex vertical gap="middle" style={{ height: "100%", maxHeight: "100vh" }}>
        <PoliciesCollapse policies={addedPolicies} isGroupsPage isSecondary />
        <PermissionsCollapse
          sections={extraPermissionsSections}
          isSecondary
          isGroupPage
          generateSectionsCallback={generateHandleClearSection}
          generatePermissionCallback={generateHandleTogglePermission}
        />
      </Flex>
    </ListWrapper>
  )
}

export default CreateGroup
