import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"

const UNPROCESSABLE = 422
const DEFAULT_CREATION_ERROR_MESSAGE = "Error creating personal document"

export const deriveErrorMessages = (errorResponse: FetchBaseQueryError) => {
  if (errorResponse.status === UNPROCESSABLE) {
    const { errors = {} } = errorResponse.data as { errors: { [key in string]: Array<string> } }
    const allErrors: Array<string> = []
    Object.entries(errors).forEach(([key, value]) => {
      allErrors.push(`${key} ${value.join(", ")}`)
    })
    return allErrors.length > 0 ? allErrors : [DEFAULT_CREATION_ERROR_MESSAGE]
  }

  return [DEFAULT_CREATION_ERROR_MESSAGE]
}
