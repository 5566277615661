import { AvailableSystemMutationData } from "@interfaces*"
import { BackendAvailableSystemsLogsResponse, BackendAvailableSystemsResponse } from "@state/types/availableSystems"
import { UserFromBackend } from "@state/types/users"

export const transformAvailableSystems = (response: BackendAvailableSystemsResponse) =>
  response.data.map(item => ({
    id: item.id,
    name: item.attributes.name,
    link: item.attributes.link,
    description: item.attributes.description,
    iconId: item.attributes.icon_id ? item.attributes.icon_id.toString() : null,
    showIcon: item.attributes.settings.show_icon,
    showName: item.attributes.settings.show_name,
    hasUsers: item.attributes.has_users
  }))

export const deriveMutationParams = (data: AvailableSystemMutationData) => {
  const { name, description, link, logo, showLogoOnBadge, showNameOnBadge } = data

  const formData = new FormData()
  formData.append("available_system[name]", name)
  formData.append("available_system[link]", link)
  formData.append("available_system[description]", description)
  formData.append(
    "available_system[settings]",
    JSON.stringify({ show_name: showNameOnBadge, show_icon: showLogoOnBadge })
  )
  if (logo) {
    formData.append("available_system[icon_attributes][file]", logo)
  }

  return formData
}

export const transformAvailableSystemsLogs = (response: BackendAvailableSystemsLogsResponse) => {
  const userMapping = response.included.reduce<Record<string, UserFromBackend>>(
    (acc, user) => ({ ...acc, [user.id]: user }),
    {}
  )

  return response.data.map(item => {
    const user = userMapping[item.relationships.author.data.id]

    return {
      id: item.id,
      event: item.attributes.record,
      date: item.attributes.created_at,
      userId: item.relationships.author.data.id,
      userEmail: user?.attributes?.email || ""
    }
  })
}
