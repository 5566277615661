import { HALF, OFFSET_PADDING } from "./constants"

export const offsetCalc = (value: number, sizes: { content: number; container: number }) => {
  let offset = value

  const halfContent = sizes.content / HALF

  const minOffset = -halfContent + OFFSET_PADDING
  const maxOffset = sizes.container + halfContent - OFFSET_PADDING

  if (value < minOffset) offset = minOffset
  if (value > maxOffset) offset = maxOffset
  return offset
}
