import React, { FC, useState } from "react"
import { LocationCardSettings, QuotaCategoryOption } from "@interfaces"
import { useVacationsAbilities } from "@hooks"
import { Divider, Flex, Modal, Typography } from "antd"
import { formatLongNames } from "@helpers"

import HRFrom from "../HRForm"
import EditLocationForm from "../../EditLocationForm"
import modalStyle from "../../style.m.scss"

import SettingsCard from "./SettingsCard"
import style from "./style.m.scss"

const LocationCard: FC<{ settings: LocationCardSettings; categories: Array<QuotaCategoryOption> }> = ({
  settings,
  categories
}) => {
  const { location, hrs, setting } = settings

  const { manage } = useVacationsAbilities()

  const [openLocation, setOpenLocation] = useState(false)
  const [openHRs, setOpenHRs] = useState(false)

  const handleOpenLocations = () => {
    setOpenLocation(true)
  }

  const handleCloseLocations = () => {
    setOpenLocation(false)
  }

  const handleOpenHRs = () => {
    setOpenHRs(true)
  }

  const handleCloseHRs = () => {
    setOpenHRs(false)
  }

  return (
    <div className={style.cardWrap}>
      <Typography.Title level={5} className={style.locationName}>
        <b>{formatLongNames(location.attributes.name)}</b>
      </Typography.Title>
      <SettingsCard
        quotas={setting.attributes.quotas}
        locationSettings={setting?.attributes || {}}
        onOpenLocations={handleOpenLocations}
      />
      <div className={style.hrWrap} onClick={manage ? handleOpenHRs : undefined}>
        <div className={style.hrPrefix}>Assigned HRs:</div>
        {hrs.length ? (
          hrs.map(hr => (
            <Flex key={hr.id} className={style.hrNameWrap} vertical>
              <Typography.Text>{formatLongNames(hr.attributes.full_name)}</Typography.Text>
              <Divider style={{ margin: "0.3rem" }} />
            </Flex>
          ))
        ) : (
          <Flex className={style.hrNameWrap} vertical>
            No assigned HRs
            <Divider style={{ margin: "0.3rem" }} />
          </Flex>
        )}
      </div>

      <Modal
        className={modalStyle.modalContainer}
        width={440}
        styles={{ content: { paddingTop: 0, paddingBottom: 1 }, footer: { margin: 0 } }}
        destroyOnClose
        open={openLocation}
        onCancel={handleCloseLocations}
        footer=""
      >
        <EditLocationForm quotaRow={settings} categories={categories} />
      </Modal>

      {manage && (
        <Modal
          width={440}
          styles={{ content: { paddingTop: 0, paddingBottom: 1 }, footer: { margin: 0 } }}
          destroyOnClose
          open={openHRs}
          onCancel={handleCloseHRs}
          footer=""
        >
          <HRFrom settings={settings} />
        </Modal>
      )}
    </div>
  )
}

export default LocationCard
