import { Form } from "antd"
import { educationalMaterialsApi } from "@state/services/subApis"
import { defaultError } from "@helpers"
import { useEffect, useState } from "react"
import { useAlert } from "@hooks"

import { DepartmentField, EmployeeField, FileField, LinkField, NewBulkForm } from "./types"

const allDepartmentsField: DepartmentField = {
  deptId: "all",
  isAppliesToCurrentEmployees: false,
  isAppliesToNewEmployees: false,
  isExcludeHeadOfDept: false
}

const emptyLinkField: LinkField = { link: undefined, name: "", reviewTime: undefined }
const emptyFileField: FileField = { file: undefined, name: "", reviewTime: undefined }
const emptyDepartmentField: DepartmentField = {
  deptId: undefined,
  isAppliesToCurrentEmployees: false,
  isAppliesToNewEmployees: false,
  isExcludeHeadOfDept: false
}
const emptyEmployeeField: EmployeeField = { userId: undefined }

const emptyFieldsMap: {
  [k in keyof Omit<NewBulkForm, "isSendToAllDepts">]: LinkField | FileField | DepartmentField | EmployeeField
} = {
  newLinks: emptyLinkField,
  newFiles: emptyFileField,
  newDepts: emptyDepartmentField,
  newEmployees: emptyEmployeeField
}

const initialFormValues: NewBulkForm = {
  newLinks: [emptyLinkField],
  newFiles: [emptyFileField],
  newDepts: [emptyDepartmentField],
  newEmployees: [emptyEmployeeField],
  isSendToAllDepts: false
}

export const useEducationalMaterialsNewBulk = () => {
  const [isBulkValid, setIsBulkValid] = useState(false)
  const [isSendToAllDepts, setIsSendToAllDepts] = useState(false)
  const [pickedDepts, setPickeDepts] = useState<Array<string>>([])

  const { success, error } = useAlert()

  const [createEducationalMaterialBulk] = educationalMaterialsApi.endpoints.createEducationalMaterialBulk.useMutation()

  const [form] = Form.useForm<NewBulkForm>()

  const derivePickedUsers = () =>
    form
      .getFieldsValue()
      .newEmployees.filter(newEmployee => !!newEmployee.userId)
      .map(newEmployee => newEmployee.userId) as Array<string>

  const derivePickedDepts = () => {
    const newPickedDepartments = form
      .getFieldsValue()
      .newDepts.filter(newDept => !!newDept?.deptId)
      .map(newDept => newDept.deptId) as Array<string>

    setPickeDepts(newPickedDepartments)
  }

  const deriveCreateBulkBody = (): FormData => {
    const formData = new FormData()
    const formValues = form.getFieldsValue()

    const newLinks = formValues.newLinks
      .filter(newLink => !!newLink?.link)
      .map(newLink => ({
        name: newLink.name,
        external_url: newLink.link,
        bulk_review_days: newLink.reviewTime ?? undefined
      }))

    newLinks.forEach(newLink => {
      const { name, external_url, bulk_review_days } = newLink

      formData.append("data[education_materials][][name]", name)
      if (external_url) formData.append("data[education_materials][][external_url]", external_url)
      if (bulk_review_days) formData.append("data[education_materials][][bulk_review_days]", String(bulk_review_days))
    })

    formValues.newFiles
      .filter(newFile => !!newFile?.file)
      .forEach(newFile => {
        const { name, reviewTime, file } = newFile

        formData.append("data[education_materials][][name]", name)
        if (file) formData.append(`data[education_materials][][document_attributes][file]`, file[0].originFileObj!)
        if (reviewTime) {
          formData.append("data[education_materials][][bulk_review_days]", String(reviewTime))
        }
      })

    const newEmployees = formValues.newEmployees
      .filter(newEmployee => !!newEmployee?.userId)
      .map(newEmployee => newEmployee.userId)

    newEmployees.forEach(newEmployee => {
      formData.append("data[employees_ids][]", String(newEmployee))
    })

    const newDepartments = formValues.newDepts
      .filter(dept => !!dept?.deptId)
      .map(newDept => {
        const { deptId, isAppliesToCurrentEmployees, isExcludeHeadOfDept, isAppliesToNewEmployees } = newDept

        return {
          id: deptId,
          rules: {
            exclude_head_of_department: isExcludeHeadOfDept ?? false,
            applies_to_current_employees: isAppliesToCurrentEmployees ?? false,
            applies_to_new_employees: isAppliesToNewEmployees ?? false
          }
        }
      })

    newDepartments.forEach(newDept => {
      formData.append("data[departments][][id]", String(newDept.id))
      formData.append(
        "data[departments][][rules][exclude_head_of_department]",
        String(newDept.rules.exclude_head_of_department)
      )
      formData.append(
        "data[departments][][rules][applies_to_current_employees]",
        String(newDept.rules.applies_to_current_employees)
      )
      formData.append(
        "data[departments][][rules][applies_to_new_employees]",
        String(newDept.rules.applies_to_new_employees)
      )
    })

    return formData
  }

  const validateForm = () => {
    const formData = form.getFieldsValue()

    const isAnyFilesAdded = formData.newFiles.filter(newFile => !!newFile?.file).some(fileField => !!fileField.name)
    const isAnyLinksAdded = formData.newLinks.filter(newLink => !!newLink?.link).some(linkField => !!linkField.name)

    const isAnyEmployeeAdded = formData.newEmployees.filter(newEmployee => !!newEmployee?.userId).length > 0
    const isAnyDeptAdded =
      formData.newDepts.filter(newDept => !!newDept?.deptId && newDept?.deptId !== "all").length > 0

    const isAnyMaterialAdded = isAnyFilesAdded || isAnyLinksAdded
    const isAnyConsumerAdded = isAnyEmployeeAdded || isAnyDeptAdded || formData.isSendToAllDepts

    setIsBulkValid(isAnyMaterialAdded && isAnyConsumerAdded)
  }

  useEffect(() => {
    validateForm()
  }, [])

  const handleCreateBulk = async () => {
    try {
      await createEducationalMaterialBulk(deriveCreateBulkBody()).unwrap()
      form.resetFields()
      setIsSendToAllDepts(false)
      success("Educational materials have been successfully added to employee profiles")
    } catch {
      error(defaultError)
    }
  }

  const handleIsSendToAllDepts = (isAllDepts: boolean) => {
    isAllDepts
      ? form.setFieldValue("newDepts", [allDepartmentsField])
      : form.setFieldValue("newDepts", [emptyDepartmentField])
    setPickeDepts([])
    setIsSendToAllDepts(isAllDepts)
  }

  const handleDelete = (
    fieldName: number,
    remove: (index: number | Array<number>) => void,
    formFieldName: keyof Omit<NewBulkForm, "isSendToAllDepts">
  ) => {
    const currentBulk = form.getFieldsValue()

    if (currentBulk[formFieldName].length === 1) {
      remove(fieldName)
      form.setFieldsValue({ [formFieldName]: [emptyFieldsMap[formFieldName]] })
      return
    }
    remove(fieldName)
  }

  return {
    form,
    initialFormValues,
    isBulkValid,
    isSendToAllDepts,
    pickedDepts,
    onIsSendToAllDepts: handleIsSendToAllDepts,
    derivePickedUsers,
    derivePickedDepts,
    onDelete: handleDelete,
    onCreateBulk: handleCreateBulk,
    validateForm
  }
}
