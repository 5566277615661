import { Build } from "@state/services/types"
import { AccessGroup } from "@interfaces"
import { BackendAccessGroupsResponse } from "@state/types/accessGroups"

import { generateGetGroupsURL } from "../urls"
import { transformGroups } from "../transformer"

export const fetchGroups = (build: Build) =>
  build.query<Array<AccessGroup>, string | undefined>({
    query: userId => ({
      url: generateGetGroupsURL(userId)
    }),
    transformResponse: (res: BackendAccessGroupsResponse) => transformGroups(res),
    providesTags: ["AccessGroup"]
  })
