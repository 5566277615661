import React, { FC } from "react"
import { Link } from "react-router-dom"
import cn from "classnames"

import style from "./style.m.scss"

type TagsViewerProps = {
  tags: Array<string>
  actions?: React.ReactNode
  className?: string
}

export const TagsViewer: FC<TagsViewerProps> = ({ tags, actions, className }) => (
  <div className={cn(style.wrapper, className)} data-testid="tags">
    {tags.length === 0 && actions && "No tags"}
    {tags.map(tag => (
      <Link to="#" key={tag} className={style.tag}>
        #{tag}
      </Link>
    ))}
    {actions}
  </div>
)
