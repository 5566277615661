import { SlackGroupParams, SlackGroupsParams } from "@interfaces"

export const SLACK_GROUPS_URL = "/slack_groups"

export const generateSlackGroupUrl = (groupId: string) => `${SLACK_GROUPS_URL}/${groupId}`

export const generateSlackGroupWithParamsUrl = (params: SlackGroupParams) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  if (params.withMembers) {
    searchParams.set("with_members", "true")
  }

  return `${SLACK_GROUPS_URL}/${params.id}${separator}${searchParams.toString()}`
}

export const generateSlackGroupsUrl = (params: SlackGroupsParams) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  if (params.isActive !== undefined) {
    searchParams.set("active", String(params.isActive))
  }

  if (params.withMembers) {
    searchParams.set("with_members", "true")
  }

  return `${SLACK_GROUPS_URL}${separator}${searchParams.toString()}`
}
