import React, { FC } from "react"
import { Col, Row } from "antd"

import { Align } from "../types"

import style from "./style.m.scss"

type EditModeFieldProps = {
  title: React.ReactNode
  value: React.ReactNode
  align?: Align
  actions?: React.ReactNode
}

export const EditModeField: FC<EditModeFieldProps> = ({ title, value, actions, align = "middle" }) => (
  <Row className={style.row} align={align}>
    <Col span={8} className={style.title}>
      {title}
      {title && ":"}
    </Col>
    <Col span={16}>{value}</Col>
    {actions && <div className={style.actions}>{actions}</div>}
  </Row>
)
