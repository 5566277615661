import { VacationsCategoryName } from "@enums"

export const vacationCategoriesKeys = {
  paid_time_off: VacationsCategoryName.paid_time_off,
  paid_time_off_half_day: VacationsCategoryName.paid_time_off_half_day,
  sick_day: VacationsCategoryName.sick_day,
  unpaid_sick_day: VacationsCategoryName.unpaid_sick_day,
  working_remotely: VacationsCategoryName.working_remotely,
  unpaid_vacation: VacationsCategoryName.unpaid_vacation,
  compensated_time_off: VacationsCategoryName.compensated_time_off,
  off_hours_work: VacationsCategoryName.off_hours_work
}

export const vacationStatusKeys = {
  approved: "approved",
  chiefReview: "chief_review",
  hrReview: "hr_review",
  rejected: "rejected",
  canceled: "canceled"
}
