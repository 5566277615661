import React, { FC } from "react"
import { Form, FormInstance } from "antd"

import { UserFormFields } from "../../../types"
import { emailRule, requiredRule } from "../../../helpers/validation"

import { FormField } from "@components/FormField"
import { ValidatedTextInput } from "@components/ValidatedInputs/ValidatedTextInput"
import { DeleteButton } from "@components/buttons/DeleteButton"
import { AddButton } from "@components/buttons/AddButton"

type EmailsProps = {
  form: FormInstance<UserFormFields>
}

export const Emails: FC<EmailsProps> = ({ form }) => {
  const handleAddEmailClick = () => {
    const { additionalEmails } = form.getFieldsValue()
    form.setFieldsValue({ additionalEmails: [...additionalEmails, ""] })
  }

  const handleDeleteEmailClick = (index: number) => {
    const { additionalEmails } = form.getFieldsValue()
    additionalEmails.splice(index, 1)
    form.setFieldsValue({ additionalEmails })
  }

  return (
    <>
      <FormField
        label="Email"
        fieldName="email"
        rules={[emailRule, requiredRule]}
        editor={<ValidatedTextInput data-testid="email-input" />}
        actions={
          <Form.List name="additionalEmails">
            {fields => fields.length === 0 && <AddButton onClick={handleAddEmailClick} data-testid="add-email" />}
          </Form.List>
        }
      />

      <Form.List name="additionalEmails">
        {fields =>
          fields.map((field, index) => (
            <FormField
              label={index === 0 ? "Additional emails" : undefined}
              editor={<ValidatedTextInput data-testid={`additional-email-${index}`} />}
              fieldName={field.name}
              actions={
                <>
                  <DeleteButton
                    onConfirm={() => handleDeleteEmailClick(index)}
                    data-testid={`delete-additional-email-${index}`}
                  />
                  {index === fields.length - 1 && <AddButton onClick={handleAddEmailClick} data-testid="add-email" />}
                </>
              }
              key={field.key}
            />
          ))
        }
      </Form.List>
    </>
  )
}
