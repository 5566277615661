import { Build } from "@state/services/types"

import { CERTIFICATES_URL } from "../urls"

export const createCertificate = (build: Build) =>
  build.mutation<unknown, FormData>({
    query: body => ({
      url: CERTIFICATES_URL,
      body,
      method: "POST"
    }),
    invalidatesTags: ["Certificate"]
  })
