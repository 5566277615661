import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RoleAbilities } from "@state/types/roles"
import { ResponsePermission } from "interfaces/abilities"

export type PermissionsSection = {
  [name: string]: {
    permissions: Array<ResponsePermission & { utilId: number }>
    idList: Array<number>
    chosen: Array<number>
  }
}

type newPolicyState = {
  sections: PermissionsSection
  currentPolicy: {
    name: string
    id?: string
  }
}

const initialState: newPolicyState = { sections: {}, currentPolicy: { name: "" } }

const newPolicySlice = createSlice({
  name: "newPolicy",
  initialState,
  reducers: {
    initPermissions(state, action: PayloadAction<Array<ResponsePermission>>) {
      action.payload.forEach((permission, index) => {
        if (!state.sections[permission.section])
          state.sections[permission.section] = { permissions: [], idList: [], chosen: [] }
        state.sections[permission.section].permissions.push({ ...permission, utilId: index })
        state.sections[permission.section].idList.push(index)
      })
    },

    togglePermission({ sections }, action: PayloadAction<{ section: string; permissionListId: number }>) {
      const { section, permissionListId } = action.payload
      sections[section].chosen.includes(permissionListId)
        ? (sections[section].chosen = sections[section].chosen.filter(id => id !== permissionListId))
        : sections[section].chosen.push(permissionListId)
    },

    enableSection({ sections }, action: PayloadAction<{ section: string }>) {
      const { section } = action.payload
      sections[section].chosen = [...sections[section].idList]
    },

    clearSection({ sections }, action: PayloadAction<{ section: string }>) {
      const { section } = action.payload
      sections[section].chosen = []
    },

    clearAllSections(state) {
      Object.keys(state.sections).forEach(name => {
        state.sections[name].chosen = []
      })
      state.currentPolicy.name = ""
      state.currentPolicy.id = undefined
    },

    clearAll(state) {
      state.sections = {}
      state.currentPolicy = { name: "" }
    },

    addPolicyPermission({ sections }, { payload }: PayloadAction<RoleAbilities>) {
      Object.entries(payload).forEach(([sectionName, permissionsNames]) =>
        permissionsNames.forEach(premissionName => {
          const ability = sections[sectionName].permissions.find(({ name }) => name === premissionName)
          if (!ability) return
          const isChosen = sections[sectionName].chosen.includes(ability.utilId)
          if (!isChosen) {
            sections[sectionName].chosen.push(ability.utilId)
          }
        })
      )
    },

    setCurrentPolicy(state, { payload }: PayloadAction<{ policyName: string; id: string }>) {
      state.currentPolicy.name = payload.policyName
      state.currentPolicy.id = payload.id
    }
  }
})

export const newPolicyActions = newPolicySlice.actions

export default newPolicySlice.reducer
