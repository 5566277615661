import React, { FC } from "react"
import { Input, InputProps, InputRef } from "antd"

import { ValidateInputWrap } from "../ValidateInputWrap"
import { Regular } from "../ValidateInputWrap/ValidateInputWrap"

type TextInputProps = {
  ref?: React.RefObject<InputRef>
  value?: string
  onChange?: (value: string) => void
  isWarningHidden?: boolean
} & Omit<InputProps, "value" | "onChange">

export const ValidatedTextInput: FC<TextInputProps> = props => {
  const { ref, value, onChange, isWarningHidden, ...restProps } = props

  return (
    <ValidateInputWrap
      regular={Regular.Text}
      value={value}
      onValueChange={onChange}
      renderInput={(internalValue, onInternalChange) => (
        <Input ref={ref} value={internalValue} onChange={e => onInternalChange(e.target.value)} {...restProps} />
      )}
      isWarningHidden={isWarningHidden}
    />
  )
}
