import { UpdateVacationsNotificationSettingsRequest } from "@state/types/vacations"
import { Build } from "@state/services/types"

import { VACATIONS_NOTIFICATION_SETTINGS } from "../urls"

export const changeVacationNotificationsSettings = (build: Build) =>
  build.mutation<void, UpdateVacationsNotificationSettingsRequest>({
    query: arg => ({
      url: VACATIONS_NOTIFICATION_SETTINGS,
      method: "PUT",
      body: arg
    }),
    invalidatesTags: ["VacationsNotificationsSetting"]
  })
