import React, { FC } from "react"
import { Flex } from "antd"

import { Title } from "@components/Title"

import style from "./style.m.scss"

type GroupHeaderProps = {
  actions: React.ReactNode
  isArchived: boolean
  location?: string
}

export const GroupHeader: FC<GroupHeaderProps> = ({ actions, isArchived, location }) => (
  <Title>
    <Flex align="flex-start" justify="space-between" className={style.header}>
      <div>
        <Flex align="center" gap="15px">
          Personal documents {isArchived && <div className={style.archived}>Archived</div>}
        </Flex>
        {location && (
          <Title level={5} style={{ marginTop: "10px", marginBottom: 0 }}>
            Location: {location}
          </Title>
        )}
      </div>
      {actions}
    </Flex>
  </Title>
)
