import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Button, Checkbox, Col, Flex, Form, FormListFieldData, Row, Space } from "antd"
import React, { FC, useState } from "react"
import { departmentsApi } from "@state/services/subApis"

import style from "../style.m.scss"

import { SearchSelect } from "@components/SearchSelect"

type DepartmentFormListItemProps = {
  deptField: FormListFieldData
  pickedDepts: Array<string>
  isLastItem: boolean
  disabled: boolean
  onAddItem: () => void
  onRemoveItem: (index: number) => void
  derivePickedDepts: () => void
}

const allOption = { value: "all", label: "All" }

export const DepartmentFormListItem: FC<DepartmentFormListItemProps> = ({
  deptField,
  isLastItem,
  disabled,
  pickedDepts,
  onAddItem,
  onRemoveItem,
  derivePickedDepts
}) => {
  const [currentDeptId, setCurrentDeptId] = useState(-1)

  const { departmentsOptions } = departmentsApi.endpoints.fetchDepartmentsOptions.useQuery(
    { exclude: pickedDepts.filter(deptId => Number(deptId) !== currentDeptId) },
    {
      selectFromResult: ({ data }) => ({
        departmentsOptions: data?.options || []
      })
    }
  )

  const handleAdd = () => {
    onAddItem()
  }

  const handleRemove = () => {
    onRemoveItem(deptField.name)
    derivePickedDepts()
  }

  const handleChange = (value: number) => {
    setCurrentDeptId(value)
    derivePickedDepts()
  }

  return (
    <Row gutter={8} key={deptField.key} className={style.deptRow}>
      <Col span={10}>
        <Form.Item
          label="Applied to department"
          name={[deptField.name, "deptId"]}
          style={{ marginBottom: 0, width: "370px" }}
        >
          <SearchSelect
            datatest-id="applied-to-dept"
            options={disabled ? [allOption] : departmentsOptions}
            disabled={disabled}
            onChange={handleChange}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Flex>
          <Form.Item
            valuePropName="checked"
            label={
              <span className={style.label}>
                Exclude
                <br />
                Head of dept
              </span>
            }
            name={[deptField.name, "isExcludeHeadOfDept"]}
            className={style.checkboxContainer}
            labelCol={{ span: 24 }}
          >
            <Checkbox className={style.largeСheckbox} />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            label={
              <span className={style.label}>
                Applies to
                <br />
                current employees
              </span>
            }
            name={[deptField.name, "isAppliesToCurrentEmployees"]}
            className={style.checkboxContainer}
            labelCol={{ span: 24 }}
          >
            <Checkbox className={style.largeСheckbox} />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            label={
              <span className={style.label}>
                Applies to
                <br />
                new eployees
              </span>
            }
            name={[deptField.name, "isAppliesToNewEmployees"]}
            className={style.checkboxContainer}
            labelCol={{ span: 24 }}
          >
            <Checkbox className={style.largeСheckbox} />
          </Form.Item>
        </Flex>
      </Col>
      <Col span={2} className={style.btnContainer}>
        <Space>
          {isLastItem && <Button disabled={disabled} icon={<PlusCircleOutlined />} onClick={handleAdd} />}
          <Button disabled={disabled} icon={<DeleteOutlined />} onClick={handleRemove} />
        </Space>
      </Col>
    </Row>
  )
}
