import { DepartmentCard, UpdateDeptBodyReq } from "@interfaces"
import { generateFullName } from "@helpers"

export const emptyPosition = { position: { id: undefined, name: "" }, userId: undefined }

export const deriveInitialFormValues = (department: DepartmentCard) => {
  const positions = department.positions.map(position => ({
    position: { id: position.positionId, name: position.positionName, hasCommission: position.hasCommission },
    userId: position.userId ?? undefined
  }))

  const subordinateChiefs = department.subordinateChiefs.map(chief => ({
    position: {
      id: chief.positionId,
      name: chief.positionName,
      hasCommission: chief.hasCommission,
      department: chief.departmentName
    },
    userName: chief.userId ? generateFullName(chief.userName?.lastName, chief.userName?.lastName) : "",
    userId: chief.userId ?? undefined
  }))

  return {
    positionsList: positions.length > 0 ? positions : [emptyPosition],
    subordinateChiefs,
    chiefPosition: department.chiefPosition
  }
}

export const checkIfUpdateRequestContainsAnyChanges = (updateRequest: UpdateDeptBodyReq) =>
  Boolean(
    updateRequest.chief_position ||
      updateRequest.positions ||
      updateRequest.subordinate_chief_positions ||
      Object.entries(updateRequest.department).length > 1
  )
