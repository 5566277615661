import React, { FC, useState } from "react"
import { Button } from "antd"
import { Option } from "@interfaces*"

import { ValidatedSelect } from "@components/ValidatedInputs"

import style from "./style.m.scss"

type SearchProps = {
  onSearch: (value: string) => void
  onClear: () => void
  options: Array<Option>
  placeholder?: string
}

const Search: FC<SearchProps> = ({ options, onSearch, onClear, placeholder }) => {
  const [searchValue, setSearchValue] = useState<string | null>(null)

  const handleChange = (value: string) => {
    setSearchValue(value)
  }

  const handleClear = () => {
    onClear()
  }

  const handleSearch = () => {
    searchValue && onSearch(searchValue)
  }

  return (
    <div className={style.wrapper}>
      <ValidatedSelect
        className={style.select}
        allowClear
        showSearch
        optionFilterProp="children"
        value={searchValue}
        placeholder={placeholder}
        onChange={handleChange}
        onClear={handleClear}
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        filterSort={(optionA, optionB) =>
          optionA.label
            ?.toString()
            .toLowerCase()
            .localeCompare((optionB.label?.toString() ?? "").toLowerCase())!
        }
        options={options}
      />
      <Button type="primary" onClick={handleSearch} disabled={!searchValue}>
        Search
      </Button>
    </div>
  )
}

export default Search
