import { Button, Form, InputNumber } from "antd"
import React, { FC } from "react"
import { CloseOutlined, SyncOutlined } from "@ant-design/icons"

import { QUOTA_INPUT_SEPARATOR } from "../LocationFormFields/QuotaInput"
import { MAX_QUOTA, MIN_QUOTA } from "../helpers/constants"

type QuotaFormItemProps = {
  name: Array<string>
  label: string | React.ReactNode
  placeholder: string
  disabled: boolean
  permanent: boolean
  calculable: boolean
  addon?: React.ReactNode
  initialValue?: number | null
  handleDisabled: () => void
  handleClickIcon: () => void
}

export const QuotaFormItem: FC<QuotaFormItemProps> = ({
  name,
  label,
  placeholder,
  disabled,
  permanent,
  calculable,
  addon,
  initialValue,
  handleDisabled,
  handleClickIcon
}) => {
  return (
    <Form.Item name={name.join(QUOTA_INPUT_SEPARATOR)} label={label} initialValue={initialValue}>
      <InputNumber
        disabled={disabled}
        style={{ width: "100%" }}
        placeholder={placeholder}
        min={MIN_QUOTA}
        max={MAX_QUOTA}
        addonAfter={
          <>
            {addon || (
              <>
                {calculable ? undefined : (
                  <Button shape="circle" size="small" type="text" onClick={handleClickIcon}>
                    <SyncOutlined />
                  </Button>
                )}
              </>
            )}
            <Button shape="circle" size="small" type="text" disabled={permanent} onClick={handleDisabled}>
              <CloseOutlined />
            </Button>
          </>
        }
      />
    </Form.Item>
  )
}
