import React, { FC, useState } from "react"
import { Button, Checkbox, Flex, Tooltip } from "antd"
import { CheckboxChangeEvent } from "antd/es/checkbox"
import { useAlert } from "@hooks"
import { educationalMaterialsApi } from "@state/services/subApis"

import style from "./style.m.scss"

const CONFIRMED_STATUS = "confirmed"

type ConfirmationActionProps = {
  materialId: string
}

export const ConfirmationAction: FC<ConfirmationActionProps> = ({ materialId }) => {
  const { error } = useAlert()

  const [isChecked, setIsChecked] = useState(false)

  const [updateMaterial] = educationalMaterialsApi.endpoints.updateEducationalMaterial.useMutation()

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked)
  }

  const handleConfirmClick = async () => {
    try {
      await updateMaterial({ id: materialId, status: CONFIRMED_STATUS }).unwrap()
    } catch {
      error("Error confirming educational material")
    }
  }

  return (
    <Flex gap={12}>
      <Checkbox
        checked={isChecked}
        onChange={handleCheckboxChange}
        data-testid="confirm-checkbox"
        className={style.customCheckbox}
      />
      <Tooltip title={!isChecked ? "Please check the box first" : ""}>
        <Button type="default" disabled={!isChecked} onClick={handleConfirmClick} data-testid="confirm-button">
          Confirm
        </Button>
      </Tooltip>
    </Flex>
  )
}
