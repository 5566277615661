import {
  EducationalMaterial,
  EducationalMaterialBulkLog,
  FileEducationalMaterial,
  LinkEducationalMaterial,
  NewMaterial
} from "@interfaces"
import {
  BackendEducationalMaterialsResponse,
  BackendEducationMaterialsBulkLogs
} from "@state/types/educationalMaterials"

export const educationalMaterialsTransformer = ({
  data
}: BackendEducationalMaterialsResponse): Array<EducationalMaterial> =>
  data.map(({ attributes, id }) => {
    const { external_url, due_at, name, read_at, status, user_id } = attributes
    if (external_url) {
      return {
        id,
        type: "link",
        link: external_url,
        userId: user_id.toString(),
        name,
        dueAt: due_at ?? undefined,
        readByUser: read_at ?? undefined,
        status
      } as LinkEducationalMaterial
    }

    return {
      id,
      type: "file",
      userId: user_id.toString(),
      name,
      dueAt: due_at ?? undefined,
      readByUser: read_at ?? undefined,
      status
    } as FileEducationalMaterial
  })

export const deriveCreateParams = (data: NewMaterial) => {
  if (data.type === "file") {
    const { name, userId, dueAt, file } = data

    const formData = new FormData()
    formData.append("data[name]", name)
    formData.append("data[user_id]", userId)
    if (dueAt) {
      formData.append("data[due_at]", dueAt)
    }
    formData.append("data[document_attributes][file]", file)

    return formData
  }

  const { name, userId, dueAt, link } = data

  return {
    data: {
      name,
      user_id: userId,
      external_url: link,
      due_at: dueAt ?? null
    }
  }
}

export const educationalMaterialsBulkLogTransformer = (
  data: BackendEducationMaterialsBulkLogs
): Array<EducationalMaterialBulkLog> => {
  return data.rules.result.map(bulkLog => {
    const { id, created_at, created_by, deactivated_at, deactivated_by, departments, education_materials, employees } =
      bulkLog

    const transformedDepartments = departments.map(dept => ({
      name: dept.name,
      rules: {
        appliesToCurrentEmployees: dept.rules.applies_to_current_employees,
        appliesToNewEmployees: dept.rules.applies_to_new_employees,
        excludeHeadOfDepartment: dept.rules.exclude_head_of_department
      }
    }))

    const transformedMaterials = education_materials.map(material => ({
      id: String(material.id),
      name: material.name,
      externalUrl: material.external_url,
      reviewDays: material.bulk_review_days
    }))

    return {
      id: String(id),
      employees,
      createdAt: created_at,
      createdBy: created_by,
      deactivatedAt: deactivated_at,
      deactivatedBy: deactivated_by,
      departments: transformedDepartments,
      educationalMaterials: transformedMaterials
    }
  })
}
