import { Build } from "@state/services/types"

import { generateAvailableSystemUrl } from "../urls"

export const deleteAvailableSystem = (build: Build) =>
  build.mutation<unknown, string>({
    query: id => ({
      url: generateAvailableSystemUrl(id),
      method: "DELETE"
    }),
    invalidatesTags: ["AvailableSystem"]
  })
