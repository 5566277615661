import React from "react"
import { App } from "antd"

import { AlertWrapper } from "@components/AlertWrapper"

export const useAlert = () => {
  const { notification } = App.useApp()

  const info = (message: string) => {
    notification.info({ message: <AlertWrapper>{message}</AlertWrapper> })
  }

  const error = (message: string) => {
    notification.error({ message: <AlertWrapper>{message}</AlertWrapper> })
  }

  const success = (message: string) => {
    notification.success({ message: <AlertWrapper>{message}</AlertWrapper> })
  }

  const warning = (message: string) => {
    notification.warning({ message: <AlertWrapper>{message}</AlertWrapper> })
  }

  return { info, error, success, warning }
}
