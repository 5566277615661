import { BackendSlackGroupResponse } from "@state/types/slackGroups"
import { Build } from "@state/services/types"
import { SlackGroupParams, SlackGroupWithMembers } from "@interfaces"

import { slackGroupTransformer } from "../transformer"
import { generateSlackGroupWithParamsUrl } from "../urls"

export const fetchSlackGroup = (build: Build) =>
  build.query<SlackGroupWithMembers, SlackGroupParams>({
    query: params => ({ url: generateSlackGroupWithParamsUrl(params) }),
    transformResponse: (data: BackendSlackGroupResponse) => slackGroupTransformer(data),
    providesTags: ["SlackGroup"]
  })
