import React, { FC, useState } from "react"

import { PhotoButton } from "./PhotoButton"
import { PhotoEditMode } from "./PhotoEditMode"
import style from "./style.m.scss"

type PhotoFieldProps = {
  initialImageSrc?: string
  onChange?: (value: File) => void
}

export const PhotoField: FC<PhotoFieldProps> = ({ onChange, initialImageSrc }) => {
  const [imageSrc, setImageScr] = useState(initialImageSrc)

  const handlePhotoChange = (file: File) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        setImageScr(reader.result)
      }
    }
    reader.readAsDataURL(file)
    onChange?.(file)
  }

  return (
    <PhotoEditMode
      imageSrc={imageSrc}
      buttons={<PhotoButton onChange={handlePhotoChange} className={style.photoButton} />}
    />
  )
}
