import React, { FC } from "react"

import { Field } from "@components/Field"

import { EditableGender } from "./EditableGender"

type PhoneProps = {
  value?: string
  options: Array<string>
  isEditable: boolean
}

export const Gender: FC<PhoneProps> = ({ value, isEditable, options }) =>
  isEditable ? (
    <EditableGender value={value} options={options} />
  ) : (
    <Field title="Gender" value={value ?? ""} data-testid="gender" />
  )
