import React, { FC, useEffect } from "react"
import cn from "classnames"

import { offsetCalc } from "../../helpers/offsetCalc"

import style from "./style.m.scss"

type Props = {
  className: string
  position: "bottom" | "right"
  value: number
  sizes: { content: number; container: number }
  onClick: () => void
}

export const Scroll: FC<Props> = ({ className, position, value, sizes, onClick }) => {
  const offset = offsetCalc(value, sizes)

  const styles = position === "bottom" ? { left: offset, width: sizes.content } : { top: offset, height: sizes.content }

  useEffect(() => {
    const onBottomDown = (e: PointerEvent) => {
      if (e.target instanceof Element && e.target.className.toString().includes(position)) {
        e.preventDefault()
        onClick()
      }
    }

    document.addEventListener("pointerdown", onBottomDown)

    return () => {
      document.removeEventListener("pointerdown", onBottomDown)
    }
  }, [])

  return (
    <div
      className={cn(className, style.wrap, {
        [style.bottom]: position === "bottom",
        [style.right]: position === "right"
      })}
    >
      <div className={cn(position, style.slider)} style={styles} />
    </div>
  )
}
