export const PERSONAL_DOCUMENTS_URL = "/personal_documents"

export const generateGetPersonalDocumentsURL = (userId: string) => {
  const searchParams = new URLSearchParams()
  const separator = "?"
  searchParams.set("filter[user_id]", userId)
  searchParams.set("include", "document")

  return `${PERSONAL_DOCUMENTS_URL}${separator}${searchParams.toString()}`
}

export const generatePersonalDocumentURL = (id: string) => `${PERSONAL_DOCUMENTS_URL}/${id}`

export const generatePersonalDocumentsDeleteAllURL = (userId: string) => {
  const searchParams = new URLSearchParams()
  const separator = "?"
  searchParams.set("filter[user_id]", userId)

  return `${PERSONAL_DOCUMENTS_URL}/destroy_all${separator}${searchParams.toString()}`
}

export const generatePersonalDocumentDownloadURL = (id: string) => `/api${PERSONAL_DOCUMENTS_URL}/${id}/download`

export const generatePersonalDocumentViewURL = (id: string) => `/api${PERSONAL_DOCUMENTS_URL}/${id}`
