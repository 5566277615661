import { Form } from "antd"
import React, { FC } from "react"

import { NewBulkForm } from "../../helpers/types"

import { FileFormListItem } from "./FileFormListItem"

type FileFormListProps = {
  onDelete: (
    fieldName: number,
    remove: (index: number | Array<number>) => void,
    formFieldName: keyof NewBulkForm
  ) => void
}

export const FileFormList: FC<FileFormListProps> = ({ onDelete }) => {
  const handleDeleteItem = (fieldName: number, remove: (index: number) => void) => {
    onDelete(fieldName, remove, "newFiles")
  }

  return (
    <Form.List name="newFiles">
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <FileFormListItem
              key={field.key}
              fileField={field}
              onAddItem={add}
              onRemoveItem={() => handleDeleteItem(field.name, remove)}
              isLastItem={index === fields.length - 1}
            />
          ))}
        </>
      )}
    </Form.List>
  )
}
