import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { SalaryHistoryItem } from "@interfaces*"
import { Button, Col, DatePicker, Flex, Row, Space, Tooltip } from "antd"
import React, { FC, useEffect, useState } from "react"
import dayjs, { Dayjs } from "dayjs"
import nextId from "react-id-generator"
import { Separators, generateDateTemplate } from "@helpers"
import { useSalariesAbilities } from "hooks/query/useAbilities"
import { salariesApi } from "@state/services/subApis"

import { SaveButton } from "@components/buttons/SaveButton"
import { EditButton } from "@components/buttons/EditButton"
import { Title } from "@components/Title"

import style from "./style.m.scss"

type PreviousSalariesProps = {
  salariesHistory: Array<SalaryHistoryItem>
  userId: string
  jobHistoryVisibility?: boolean
}

const PreviousSalaries: FC<PreviousSalariesProps> = ({ salariesHistory, userId, jobHistoryVisibility }) => {
  const { edit_all } = useSalariesAbilities()
  const [createNewSalaries] = salariesApi.endpoints.createSalaryChanges.useMutation()
  const [destroySalaries] = salariesApi.endpoints.deleteSalaryChange.useMutation()

  const [editMode, setEditMode] = useState(false)
  const [newDate, setNewDate] = useState<Dayjs | null>(null)
  const [dates, setDates] = useState<Array<SalaryHistoryItem>>(salariesHistory)
  const [newDates, setNewDates] = useState<Array<string>>([])
  const [deletedDates, setDeletedDates] = useState<Array<string>>([])

  useEffect(() => {
    setDates([...salariesHistory])
  }, [salariesHistory])

  const handleEdit = () => {
    setEditMode(true)
  }

  const handleDateChange = (date: Dayjs) => {
    setNewDate(date)
  }

  const handleAdd = () => {
    const id = nextId()
    const formatedDate = dayjs(newDate).format(generateDateTemplate("YYYYMMDD", Separators.Dash))
    setNewDates([...newDates, id])
    setDates([{ id, dateOfAcceptance: formatedDate }, ...dates])
    setNewDate(null)
  }

  const handleDelete = (id: string) => {
    const allDates = dates.filter(item => item.id !== id)
    const filterdNewDates = newDates.filter(newDateId => newDateId !== id)

    setDeletedDates([...deletedDates, id])
    setDates(allDates)
    setNewDates(filterdNewDates)
  }

  const handleSave = () => {
    if (!deletedDates.length && !newDates.length) {
      setEditMode(false)
      return
    }

    if (newDates.length) {
      const bodyForCreate = newDates.map(dateId => ({
        data: {
          type: "salaries" as const,
          attributes: {
            date_of_acceptance: dates.find(date => date.id === dateId)!.dateOfAcceptance,
            user_id: Number(userId)
          }
        }
      }))
      createNewSalaries({ id: userId, body: bodyForCreate })
    }

    if (deletedDates.length) {
      deletedDates.forEach(dateId => {
        if (!salariesHistory.map(item => item.id).includes(dateId)) return
        destroySalaries({ id: dateId, user_id: userId })
      })
    }

    setNewDates([])
    setDeletedDates([])
    setEditMode(false)
  }

  return (
    <Flex vertical className={jobHistoryVisibility ? style.halfWidthContainer : style.fullWidthContainer}>
      <Flex justify="space-between" align="center" className={style.headerContainer}>
        <Title>
          Salary changes
          <Tooltip
            placement="right"
            title="For additional information regarding salary changes, please contact the accounting department."
          >
            <InfoCircleOutlined className={style.headerTooltip} />
          </Tooltip>
        </Title>
        {!!edit_all && (
          <Space align="center">
            {editMode ? <SaveButton onClick={handleSave} /> : <EditButton onClick={handleEdit} />}
          </Space>
        )}
      </Flex>
      {editMode && (
        <Row>
          <Col span={10}>
            <DatePicker className={style.datePicker} value={newDate} onChange={handleDateChange} />
          </Col>
          <Col span={3}>
            <Button onClick={handleAdd} disabled={!newDate || !dayjs(newDate).format()}>
              Add
            </Button>
          </Col>
        </Row>
      )}
      {dates.map(salary => (
        <Row key={salary.id} align="middle" className={style.row}>
          <Col span={7}>{salary.dateOfAcceptance}</Col>
          {editMode && (
            <Col span={3}>
              <Button type="text" onClick={() => handleDelete(salary.id)} icon={<CloseOutlined />} />
            </Col>
          )}
        </Row>
      ))}
    </Flex>
  )
}

export default PreviousSalaries
