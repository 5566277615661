import React, { FC, useEffect, useRef, useState } from "react"
import { Button, Flex, Typography } from "antd"
import { groupsApi, policiesApi, usersApi } from "@state/services/subApis"
import { AccessGroup, Option, Policy, SearchingUser, UpdateUserPermissionsBodyRequest } from "@interfaces*"
import { useAlert, useAppDispatch, useAppSelector } from "@hooks"
import { manageUsersPermissionsActions } from "@state/reducers/manageUsersPermissions"
import { defaultError } from "@helpers"
import { AccessType } from "@enums"

import { Logs } from "../../components/Logs"
import { formatedLongNames } from "../../helpers/formatedLongNames"

import { DebounceSelect } from "@components/DebounceSelect"

import style from "./style.m.scss"
import { MainList } from "./MainList"
import SubList from "./SubList/SubList"

const MAX_OPTION_LABEL_LENGTH = 50

const createOptions = (entites?: Array<AccessGroup> | Array<Policy>) => {
  if (!entites) return []
  return entites.map(entity => ({ id: entity.id, name: entity.name }))
}

const convertOptions = (users: Array<SearchingUser>) =>
  users
    ? users.map(user => ({
        label: formatedLongNames(`${user.email} - ${user.fullName}`, MAX_OPTION_LABEL_LENGTH),
        value: user.id
      }))
    : []

const ManageUsers: FC = () => {
  const dispatch = useAppDispatch()
  const { error, success } = useAlert()
  const { userId, list, user, removed, added } = useAppSelector(state => state.manageUsersPermissionsSlice)

  const userName = useRef("")

  const [currentUserIdForSearch, setCurrentUserIdForSearch] = useState<string | null>(null)
  const [currentUserNameForView, setCurrentUserNameForView] = useState<string>("")

  const [updateUserPermissions] = usersApi.endpoints.updateUserPermissions.useMutation()

  const [fetchUsers, { isFetching }] = usersApi.endpoints.searchUsers.useLazyQuery()
  const [fetchGroups] = groupsApi.endpoints.fetchGroups.useLazyQuery()
  const [fetchPolicies] = policiesApi.endpoints.fetchPolicies.useLazyQuery()

  const disabledSaveAll =
    !added.groups.length && !added.policies.length && !removed.groups.length && !removed.policies.length

  useEffect(() => {
    const fetchGroupsAndPolicies = async () => {
      try {
        const groups = await fetchGroups("").unwrap()
        const policies = await fetchPolicies("").unwrap()

        const groupOptions = createOptions(groups.filter(group => !!group.policies.length))
        const policiesOptions = createOptions(policies.filter(policy => !!Object.values(policy.permissions).length))

        dispatch(
          manageUsersPermissionsActions.initAllGroupsAndPolicies({
            groups: groupOptions,
            policies: policiesOptions
          })
        )
      } catch {
        error(defaultError)
      }
    }

    fetchGroupsAndPolicies()
  }, [])

  const optionsFetcher = (searchingUser: string) =>
    fetchUsers({
      type: "email",
      value: searchingUser
    })

  const handleChangeUser = (userId: string | null, option?: Option<string | number, string>) => {
    setCurrentUserIdForSearch(userId)
    if (option?.label) userName.current = option.label
  }

  const handleSaveAll = async () => {
    if (!userId) return
    const formatedAddedGroups = added.groups.map(groupId => Number(groupId))
    const formatedDeletedGroups = removed.groups.map(groupId => Number(groupId))
    const formatedAddedPolicies = added.policies.map(policyId => Number(policyId))
    const formatedDeletedPolicies = removed.policies.map(groupId => Number(groupId))

    const requestParams: UpdateUserPermissionsBodyRequest = {
      id: userId,
      body: {
        access_groups_added: formatedAddedGroups,
        access_groups_removed: formatedDeletedGroups,
        direct_policies_added: formatedAddedPolicies,
        direct_policies_removed: formatedDeletedPolicies,
        format: ":jsonapi" as const
      }
    }

    try {
      const res = await updateUserPermissions(requestParams).unwrap()
      const newUserGroups = res.access_groups.map(group => ({ ...group, id: String(group.id) }))
      const newUserPolicies = res.direct_policies.map(policy => ({ ...policy, id: String(policy.id) }))
      dispatch(
        manageUsersPermissionsActions.initUserGroupsAndPolicies({ groups: newUserGroups, policies: newUserPolicies })
      )
      success("User permissions was succesfully updated")
    } catch {
      error(defaultError)
    }
  }

  const handleSearchUser = async () => {
    setCurrentUserNameForView(userName.current)
    try {
      const userGroups = await fetchGroups(currentUserIdForSearch ?? undefined).unwrap()
      const userPolicies = await fetchPolicies(currentUserIdForSearch ?? undefined).unwrap()

      dispatch(
        manageUsersPermissionsActions.initUserGroupsAndPolicies({
          groups: userGroups ? createOptions(userGroups) : [],
          policies: userPolicies
            ? createOptions(userPolicies?.filter(policy => !!Object.keys(policy.permissions).length))
            : []
        })
      )
      dispatch(manageUsersPermissionsActions.setCurrentUserId(currentUserIdForSearch ?? undefined))

      setCurrentUserIdForSearch(null)
    } catch {
      error(defaultError)
    }
  }

  return (
    <div className={style.container}>
      <Flex align="flex-end" justify="space-between">
        <Typography.Title level={4}>Users</Typography.Title>
        <Button type="primary" onClick={handleSaveAll} disabled={disabledSaveAll}>
          Save all changes
        </Button>
      </Flex>
      <Flex align="flex-end">
        <MainList userName={currentUserNameForView} groups={user.groups} policies={user.policies}>
          <Flex justify="space-between">
            <div className={style.searchWrapper}>
              <DebounceSelect
                value={currentUserIdForSearch}
                optionsFetcher={optionsFetcher}
                onChange={handleChangeUser}
                isFetching={isFetching}
                convertOptions={convertOptions}
              >
                <Button type="primary" onClick={handleSearchUser} disabled={!currentUserIdForSearch}>
                  Search
                </Button>
              </DebounceSelect>
            </div>
          </Flex>
        </MainList>
        <SubList type={AccessType.Group} entities={list.groups} />
        <SubList type={AccessType.Policy} entities={list.policies} />
      </Flex>
      <Logs type="user_permissions" />
    </div>
  )
}

export default ManageUsers
