import { SaveVacationQuery, SaveVacationResponse } from "@interfaces"
import { Build } from "@state/services/types"

import { VACATIONS_CREATE_URL } from "../urls"

export const createVacation = (build: Build) =>
  build.mutation<SaveVacationResponse, SaveVacationQuery>({
    query: body => ({
      url: VACATIONS_CREATE_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["Vacation", "Balance", "Quota", "LeaveType"]
  })
