import React from "react"
import { EducationalMaterial } from "@interfaces"
import { Link } from "react-router-dom"
import { Button } from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import {
  generateEducationalMaterialDownloadURL,
  generateEducationalMaterialURL
} from "@state/services/subApis/educationalMaterials/urls"

import { ConfirmationAction } from "../ConfirmationAction"

import style from "./style.m.scss"

const NO_DUE_DATE_LABEL = "Anytime"
const READ_PROMPT = "You are required to read this document. After reading the document tick the box and click Confirm"

export const useMaterialsTableColumns = () => {
  const columns = [
    {
      key: "number",
      width: "30px",
      onCell: (_: EducationalMaterial, index: number) => ({
        "data-testid": `number-${index}`
      }),
      render: (_: unknown, __: EducationalMaterial, index: number) => (index + 1).toString()
    },
    {
      title: "Document",
      key: "document",
      width: "500px",
      onCell: (_: EducationalMaterial, index: number) => ({
        "data-testid": `document-${index}`
      }),
      render: (_: unknown, material: EducationalMaterial) => {
        if (material.type === "file") {
          return (
            <>
              <Link
                to={`/api${generateEducationalMaterialURL(material.id)}`}
                target="_blank"
                className={style.link}
                data-testid="document-link"
              >
                {material.name}
              </Link>
              <Button
                type="link"
                href={generateEducationalMaterialDownloadURL(material.id)}
                icon={<DownloadOutlined />}
                data-testid="document-download-link"
              />
            </>
          )
        }

        return (
          <a
            href={material.link}
            target="_blank"
            rel="noreferrer"
            className={style.link}
            data-testid="external-document-link"
          >
            {material.name}
          </a>
        )
      }
    },
    {
      title: "Due date",
      dataIndex: "dueAt",
      key: "dueAt",
      width: "120px",
      render: (dueDate: string) => dueDate || NO_DUE_DATE_LABEL,
      onCell: (_: EducationalMaterial, index: number) => ({ "data-testid": `due-date-${index}` })
    },
    {
      title: "Read by user",
      key: "readByUser",
      render: (_: unknown, { dueAt, readByUser }: EducationalMaterial) => {
        if (!dueAt && !readByUser) return ""
        if (readByUser) return readByUser
        return READ_PROMPT
      },
      onCell: (_: EducationalMaterial, index: number) => ({ "data-testid": `read-by-user-${index}` })
    },
    {
      title: "Actions",
      key: "actions",
      width: "50px",
      render: (_: unknown, { readByUser, id }: EducationalMaterial) => {
        if (readByUser) return ""

        return <ConfirmationAction materialId={id} />
      },
      onCell: (_: EducationalMaterial, index: number) => ({ "data-testid": `actions-${index}` })
    }
  ]

  return columns
}
