import * as yup from "yup"

import { validationErrors } from "../validationErrors"
import { emailReg, passwordReg } from "../validationRegex"

export const authSchema = yup.object().shape({
  login: yup.string().matches(emailReg, validationErrors.VALID_EMAIL_ERROR).required(validationErrors.REQUIRED_ERROR),
  password: yup
    .string()
    .matches(passwordReg, validationErrors.VALID_PASSWORD_ERROR)
    .required(validationErrors.REQUIRED_ERROR)
})
