import { CalculateBalanceParams } from "@interfaces*"

export const VACATIONS_QUOTAS_URL = "/vacation_quota"

export const generateVacationQuotaIdURL = (id: number | string) => `${VACATIONS_QUOTAS_URL}/${id}`

export const generateCalculateBalanceURL = (params: CalculateBalanceParams) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("finished_at", params.finishedAt)
  searchParams.set("started_at", params.startedAt)
  searchParams.set("vacation_quotum_id", params.vacationQuotumId)
  if (params.halfDay) searchParams.set("half_day", String(params.halfDay))

  return `${VACATIONS_QUOTAS_URL}/${params.vacationQuotumId}/calculate${separator}${searchParams.toString()}`
}
