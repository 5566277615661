import { UpdatePolicyBody } from "@interfaces"
import { Build } from "@state/services/types"

import { generateMutationPolicyURL } from "../urls"

export const updatePolicy = (build: Build) =>
  build.mutation<unknown, UpdatePolicyBody>({
    query: body => ({
      url: generateMutationPolicyURL(String(body.data.id)),
      method: "PUT",
      body
    }),
    invalidatesTags: ["Policy"]
  })
