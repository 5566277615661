export const generatePagingParams = (page?: number, size?: number) => {
  const pagingParams: Record<string, string> = {}
  if (page) {
    pagingParams["page[number]"] = String(page)
  }
  if (size) {
    pagingParams["page[size]"] = String(size)
  }
  return pagingParams
}

type ParamValue = number | string | Array<string> | undefined | boolean | null

type ExtendedFilter = {
  [key: string]: any
}

const paramToQueryString = (key: string, value: ParamValue) => {
  const isArray = Array.isArray(value)

  if (typeof value === "undefined") {
    return ""
  }

  return isArray
    ? `${key}=${encodeURIComponent((value as Array<string>).join(","))}`
    : `${key}=${encodeURIComponent(value as string)}`
}

export const queryString = <T extends Object>(query: T) => {
  const queryObj = { ...query } as T & ExtendedFilter
  return Object.keys(query)
    .reduce((acc, key: string) => (queryObj[key] !== "" ? [...acc, paramToQueryString(key, queryObj[key])] : acc), [])
    .filter(param => param)
    .join("&")
}

export const generateFiltersRoute = (filters: { [k: string]: string }, route: string) => {
  const separator = "?"
  const queryString = Object.entries(filters)
    .map(item => `${paramToQueryString(item[0], item[1])}&`)
    .join("")
    .slice(0, -1)

  return `${route}${separator}${queryString}`
}
