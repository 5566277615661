import React, { FC } from "react"
import { Button, ButtonProps, Tooltip } from "antd"
import { SaveOutlined } from "@ant-design/icons"
import { TooltipPlacement } from "antd/es/tooltip"

type SaveButtonProps = Omit<ButtonProps, "icon"> & { tooltipPlacement?: TooltipPlacement }

export const SaveButton: FC<SaveButtonProps> = ({ type = "text", tooltipPlacement = "top", ...props }) => (
  <Tooltip title="Save" placement={tooltipPlacement}>
    <Button icon={<SaveOutlined />} type={type} {...props} />
  </Tooltip>
)
