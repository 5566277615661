import { Flex, Table, Typography } from "antd"
import React, { FC } from "react"
import { useLocationsAbilities } from "hooks/query/useAbilities"
import { locationsApi } from "@state/services/subApis"

import AddNewLocation from "./AddNewLocation/AddNewLocation"
import { useLocations } from "./helpers/useLocations"

const LocationsSettings: FC = () => {
  const { create: createNewLocation } = useLocationsAbilities()

  const { dataStorageOptions, locationsOptions, isOptionsLoading } =
    locationsApi.endpoints.fetchDataStorageOptions.useQuery("", {
      selectFromResult: ({ data, isLoading }) => ({
        dataStorageOptions: data ? data.s3.map(option => ({ ...option, value: String(option.value) })) : [],
        locationsOptions: data ? data.short_names.map(option => ({ ...option, value: String(option.value) })) : [],
        isOptionsLoading: isLoading
      })
    })

  const { locations, isLoading, isFetching, columns } = useLocations()

  return (
    <Flex vertical>
      <Typography.Title level={4} style={{ marginTop: "0" }}>
        Locations
      </Typography.Title>
      {createNewLocation && !isOptionsLoading && (
        <>
          <Typography.Title level={5} style={{ marginBottom: "15px" }}>
            Add new location
          </Typography.Title>
          <AddNewLocation dataStorageOptions={dataStorageOptions} locationsOptions={locationsOptions} />
        </>
      )}
      <Table
        columns={columns}
        dataSource={locations}
        rowKey="id"
        loading={isLoading || isFetching}
        pagination={false}
      />
    </Flex>
  )
}

export default LocationsSettings
