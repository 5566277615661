import React, { FC, useState } from "react"
import { Button, Flex, Modal, Typography } from "antd"
import { useVacationsAbilities } from "@hooks"

import NewVacationsSettingsForm from "./NewVacationsSettingsForm"
import LocationsGrid from "./Grid"
import style from "./style.m.scss"

const VacationsSettings: FC = () => {
  const { manage } = useVacationsAbilities()

  const [isOpen, setIsOpen] = useState(false)

  const handleModalOpen = () => {
    setIsOpen(true)
  }

  const handleModalClose = () => {
    setIsOpen(false)
  }

  return (
    <Flex vertical>
      <Modal
        className={style.modalContainer}
        open={isOpen}
        onCancel={handleModalClose}
        destroyOnClose
        footer=""
        width={440}
        styles={{ content: { paddingTop: 0, paddingBottom: 1 }, footer: { margin: 0 } }}
      >
        <NewVacationsSettingsForm modalClose={handleModalClose} />
      </Modal>
      <Flex align="center" justify="space-between">
        <Typography.Title level={4} style={{ margin: "10px 0" }}>
          Locations
        </Typography.Title>
        <Button type="primary" hidden={!manage} onClick={handleModalOpen}>
          Add new
        </Button>
      </Flex>
      <LocationsGrid />
    </Flex>
  )
}

export default VacationsSettings
