import { UserFullInfoWithAccesses } from "@interfaces"
import dayjs, { Dayjs } from "dayjs"

import { TerminationFormFields } from "./types"

export const DATE_FORMAT = "YYYY-MM-DD"

export const deriveFormValues = (user: UserFullInfoWithAccesses) => ({
  separationDate: user.separationDate ? dayjs(user.separationDate) : undefined
})

export const deriveTerminationData = (fieldsValues: TerminationFormFields) => ({
  separationDate: fieldsValues.separationDate ? fieldsValues.separationDate.format("YYYY-MM-DD") : null
})

export const deriveConfirmationMessage = (user: UserFullInfoWithAccesses, separationDate: Dayjs) =>
  `Are you sure you want to terminate the employee as of \
  ${separationDate.format(DATE_FORMAT)}? The day after this date, the employee will be removed from the position of ${
    user.position?.name
  } \
  in the ${
    user.department?.name
  } department, their scheduled vacations will be canceled, their system access will be blocked, \
  and all their access rights will be revoked.`
