import React, { FC } from "react"
import { Typography } from "antd"
import { TitleProps as BasicTitleProps } from "antd/es/typography/Title"
import cn from "classnames"

import styles from "./style.m.scss"

type TitleProps = BasicTitleProps

export const Title: FC<TitleProps> = ({ children, className, level = 4, ...props }) => (
  <Typography.Title {...props} level={level} className={cn(styles.header, className)}>
    {children}
  </Typography.Title>
)
