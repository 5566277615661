import { Abilities } from "@interfaces"

import { mainMenuRoutes } from "./mainMenuRoutes"
import usersRoutes from "./usersRoutes"
import { RedirectType, RouteItem } from "./types"
import profileRoutes from "./profileRoutes"
import { departmentsRoutes } from "./departments"
import { DASHBOARD_ROUTE } from "./inboundRoutes"
import { slackRoutes } from "./slackRoutes"

export const redirects = (): Array<RedirectType> => [
  {
    from: "/*",
    to: DASHBOARD_ROUTE
  }
]

export const routes = (
  abilities: Abilities | null,
  isChief: boolean,
  isSuperaccess: boolean,
  withSubordinates: boolean
): Array<RouteItem> => {
  return [
    ...mainMenuRoutes(abilities, isChief, isSuperaccess, withSubordinates),
    ...usersRoutes(abilities),
    ...profileRoutes(abilities),
    ...departmentsRoutes(abilities),
    ...slackRoutes(abilities)
  ]
}
