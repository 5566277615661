import { PersonalDocumentsParams } from "@interfaces"
import { Build } from "@state/services/types"

import { generatePersonalDocumentsDeleteAllURL } from "../urls"

export const deleteAllPersonalDocuments = (build: Build) =>
  build.mutation<unknown, PersonalDocumentsParams>({
    query: ({ userId }) => ({
      url: generatePersonalDocumentsDeleteAllURL(userId),
      method: "DELETE"
    }),
    invalidatesTags: ["PersonalDocument"]
  })
