import React, { FC } from "react"
import { PersonalDocument } from "@interfaces"

import { DocumentsTable } from "../components/DocumentsTable"
import { GroupHeader } from "../components/GroupHeader"

import { SaveButton } from "@components/buttons/SaveButton"

import { FileForm } from "./FileForm"

type EditPanelProps = {
  userId: string
  documents: Array<PersonalDocument>
  isArchived: boolean
  location?: string
  dataTestId: string
  isLoading: boolean
  onSave: () => void
}

export const EditPanel: FC<EditPanelProps> = ({
  userId,
  documents,
  isArchived,
  location,
  dataTestId,
  isLoading,
  onSave
}) => (
  <>
    <GroupHeader
      isArchived={isArchived}
      location={location}
      actions={<SaveButton onClick={onSave} data-testid={`${dataTestId}-save-button`} />}
    />

    {!isArchived && <FileForm userId={userId} />}

    <DocumentsTable
      userId={userId}
      documents={documents}
      isLoading={isLoading}
      isArchived={isArchived}
      isEditMode
      dataTestId={dataTestId}
    />
  </>
)
