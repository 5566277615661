import React, { FC } from "react"
import { Tooltip } from "antd"
import { DepartmentCard } from "@interfaces*"
import { DepartmentStatus } from "@enums"

import { ValidatedSelect } from "@components/ValidatedInputs"

const DATA_TEST_ID = "form-status"

const selectStyle = { width: "200px" }

const statusSelectOptions = [
  { value: DepartmentStatus.Active, label: DepartmentStatus.Active },
  { value: DepartmentStatus.Inactive, label: DepartmentStatus.Inactive }
]

type StatusFieldProps = {
  value?: string
  department?: DepartmentCard
  isCEO?: boolean
  onChange?: (newValue: string) => void
}

export const StatusField: FC<StatusFieldProps> = ({ value, department, isCEO, onChange }) => {
  if (!department)
    return (
      <ValidatedSelect
        value={value}
        datatest-id={DATA_TEST_ID}
        style={selectStyle}
        options={statusSelectOptions}
        onChange={onChange}
      />
    )

  if (department.status === DepartmentStatus.Active && !department.isClosable)
    return (
      <Tooltip title="The department cannot be made inactive if it has active subordinate divisions">
        <ValidatedSelect
          value={value}
          datatest-id={DATA_TEST_ID}
          style={selectStyle}
          options={statusSelectOptions}
          disabled
        />
      </Tooltip>
    )

  if (department.status === DepartmentStatus.Inactive && !department.isActivatable)
    return (
      <Tooltip title="The department cannot be made active if it has inactive parent department">
        <ValidatedSelect
          value={value}
          datatest-id={DATA_TEST_ID}
          style={selectStyle}
          options={statusSelectOptions}
          disabled
        />
      </Tooltip>
    )

  return (
    <ValidatedSelect
      value={value}
      onChange={onChange}
      datatest-id={DATA_TEST_ID}
      style={selectStyle}
      options={statusSelectOptions}
      disabled={isCEO}
    />
  )
}
