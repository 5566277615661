import { Build } from "@state/services/types"
import { UpdateAccessGroupBody } from "@interfaces"

import { generateAccessGroupURL } from "../urls"

export const updateGroup = (build: Build) =>
  build.mutation<unknown, UpdateAccessGroupBody>({
    query: body => ({
      url: generateAccessGroupURL(String(body.id)),
      method: "PUT",
      body: { ...body, id: undefined }
    }),
    invalidatesTags: ["AccessGroup"]
  })
