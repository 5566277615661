import { VacationSettings, AssignmentHr, Location, LocationCardSettings } from "@interfaces"
import { VacationsSettingsIncluded } from "@state/types/vacations"

type Tag = "locations" | "vacation_quota" | "assignment_hrs"

const filterIncluded = <T extends VacationsSettingsIncluded>(included: Array<VacationsSettingsIncluded>, tag: Tag) => {
  return included.filter(includedEntity => includedEntity.type === tag) as Array<T>
}

export const transformVacationsSettingsToCards = ({
  vacationsSettings,
  vacationsSettingsIncluded
}: {
  vacationsSettings: Array<VacationSettings>
  vacationsSettingsIncluded: Array<VacationsSettingsIncluded>
}): Array<LocationCardSettings> => {
  const locations = filterIncluded<Location>(vacationsSettingsIncluded, "locations")
  const hrs = filterIncluded<AssignmentHr>(vacationsSettingsIncluded, "assignment_hrs")

  return vacationsSettings.map(setting => {
    const location = locations.find(location => setting.attributes.location_id === location.id)!
    const settingsHRs = hrs.filter(hr => hr.attributes.setting_id === setting.id)

    return {
      location,
      setting,
      hrs: settingsHRs
    }
  })
}
