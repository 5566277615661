export const validationErrors = {
  VALID_NAME_ERROR: "Field must contain English alphabet characters and only one word",
  VALID_LANGUAGE_ERROR: "Field must contain English alphabet characters",
  VALID_NICKNAME_ERROR: "Field must contain English alphabet characters and @ symbol",
  VALID_AGE_ERROR: "Field must contain only numbers",
  REQUIRED_ERROR: "This is required field",
  VALID_EMAIL_ERROR: "Must be a valid email",
  VALID_PASSWORD_ERROR: "Password should be longer than 6 symbols",
  VALID_AUTHENTICATION_CODE: "Field must contain only 6 numbers",
  VALID_CONFIRM_PASSWORD_ERROR: "Password doesn't match",
  VALID_ID_ERROR: "Wrong variant",
  VALID_NUM_ERROR: "Only numbers",
  VALID_SELECT_ERROR: "Must be choosing variant",
  VALID_REASON_ERROR: "Reason should contain less than 300 symbols"
} as const
