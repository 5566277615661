import { ChangeVacationSettingsResponse, ChangeVacationSettingsRequest } from "@state/types/vacations"
import { Build } from "@state/services/types"

import { generateVacationSettingsIdURL } from "../urls"

export const changeVacationSettings = (build: Build) =>
  build.mutation<ChangeVacationSettingsResponse, ChangeVacationSettingsRequest>({
    query: arg => ({
      url: generateVacationSettingsIdURL(arg.data.id),
      method: "PUT",
      body: arg
    }),
    invalidatesTags: result =>
      result?.data ? [{ type: "VacationSettings", id: result.data.id }, "Quota"] : ["VacationSettings", "Quota"]
  })
