import React, { FC, lazy, useEffect } from "react"
import { BrandProduct } from "enums/products"
import { deriveProductFromUrl } from "@helpers"

import { useAuthView } from "../helpers/useAuthView"

const AuthDidlogic = lazy(() => import("@app/sections/App/BrandedAuth/Didlogic"))
const AuthVoiso = lazy(() => import("@app/sections/App/BrandedAuth/Voiso"))
const AuthWavix = lazy(() => import("@app/sections/App/BrandedAuth/Wavix"))

type BrandedAuthPageProps = {
  isRestorePasswordPage?: boolean
}

export const BrandedAuthPage: FC<BrandedAuthPageProps> = ({ isRestorePasswordPage }) => {
  const hostBrand = deriveProductFromUrl(window.location.hostname)
  const { initLoginPage, initRestorePasswordPage } = useAuthView()

  useEffect(() => {
    if (isRestorePasswordPage) {
      initRestorePasswordPage()
    } else {
      initLoginPage()
    }
  }, [])

  switch (hostBrand) {
    case BrandProduct.Didlogic:
      return <AuthDidlogic />
    case BrandProduct.Voiso:
      return <AuthVoiso />
    case BrandProduct.Wavix:
      return <AuthWavix />
    default:
      return null
  }
}
