import React, { FC, useEffect } from "react"
import { useAppDispatch } from "@hooks"
import { newPolicyActions } from "@state/reducers/newPolicy"
import { Flex } from "antd"
import { abilitiesApi, policiesApi } from "@state/services/subApis"

import { Logs } from "../components/Logs"

import style from "./style.m.scss"
import { CreatePolicy } from "./CreatePolicy"
import { PoliciesList } from "./PoliciesList"
import { PermissionsList } from "./PermissionsList"

const Policies: FC = () => {
  const dispatch = useAppDispatch()

  const { permissions } = abilitiesApi.endpoints.fetchAbilities.useQuery("", {
    selectFromResult: ({ data }) => ({ permissions: data?.permissions || [] })
  })

  const { policies } = policiesApi.endpoints.fetchPolicies.useQuery("", {
    selectFromResult: res => ({ policies: res.data || [] })
  })

  useEffect(() => {
    dispatch(newPolicyActions.initPermissions(permissions))

    return () => {
      dispatch(newPolicyActions.clearAll())
    }
  }, [permissions])

  return (
    <>
      <Flex className={style.container}>
        <Flex className={style.wrapper} justify="space-between" gap="middle">
          <PermissionsList />
          <CreatePolicy policies={policies} />
          <PoliciesList policies={policies} isSecondary />
        </Flex>
      </Flex>
      <Logs type="policies" />
    </>
  )
}

export default Policies
