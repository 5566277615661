import { AvailableSystem, AvailableSystemMutationData } from "@interfaces"
import { deriveLogoSrc } from "@helpers"

import { AvailableSystemFormValues } from "../types"

export const deriveAvailableSystemMutationData = (
  formValues: AvailableSystemFormValues
): AvailableSystemMutationData => {
  const { logo, ...fields } = formValues
  if (logo && typeof logo === "object") {
    return { ...fields, logo }
  }

  return { ...fields }
}

export const deriveInitialValues = (availableSystem: AvailableSystem | null | undefined) =>
  availableSystem
    ? {
        name: availableSystem.name ?? "",
        description: availableSystem.description ?? "",
        logo: deriveLogoSrc(availableSystem.iconId),
        link: availableSystem.link ?? "",
        showLogoOnBadge: availableSystem.showIcon ?? false,
        showNameOnBadge: availableSystem.showName ?? false
      }
    : {
        name: "",
        description: "",
        logo: undefined,
        link: "",
        showLogoOnBadge: false,
        showNameOnBadge: false
      }
