import { Build } from "@state/services/types"

import { generateHrAssignmentURL } from "../urls"

export const deleteAssignedHr = (build: Build) =>
  build.mutation<null, string>({
    query: hrId => ({
      url: generateHrAssignmentURL(hrId),
      method: "DELETE"
    }),
    invalidatesTags: ["AssignmentHr", "VacationSettings"]
  })
