import { AvailableSystemLogItem } from "@interfaces"
import { BackendAvailableSystemsLogsResponse } from "@state/types/availableSystems"
import { Build } from "@state/services/types"

import { generateAvailableSystemsLogsUrl } from "../urls"
import { transformAvailableSystemsLogs } from "../transformer"

export const fetchAvailableSystemsLogs = (build: Build) =>
  build.query<Array<AvailableSystemLogItem>, void>({
    query: () => ({ url: generateAvailableSystemsLogsUrl() }),
    transformResponse: (data: BackendAvailableSystemsLogsResponse) => transformAvailableSystemsLogs(data),
    providesTags: ["AvailableSystem"]
  })
