import React, { FC } from "react"
import { Link } from "react-router-dom"
import { generateUserProfileRoute } from "@routes/inboundRoutes"
import { UserManager } from "@interfaces"

type ManagerProps = {
  manager: UserManager | null | undefined
}

export const Manager: FC<ManagerProps> = ({ manager }) => {
  if (!manager) return null

  const { id, fullName } = manager

  return (
    <Link to={generateUserProfileRoute(id)} data-testid="manager-link">
      {fullName}
    </Link>
  )
}
