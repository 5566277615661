import { Build } from "@state/services/types"
import { DestroyConferenceResponse } from "@state/types/conferences"

import { generateDeleteConferenceURL } from "../urls"

export const deleteConference = (build: Build) =>
  build.mutation<DestroyConferenceResponse, string>({
    query: id => ({
      url: generateDeleteConferenceURL(id),
      method: "DELETE"
    }),
    invalidatesTags: ["Conference"]
  })
