import React from "react"
import { PersonalDocument } from "@interfaces"
import { Form } from "antd"

import { DocumentFormContext } from "../../contexts/DocumentFormContext"

type FormRowProps = {
  document: PersonalDocument
}

export const FormRow: React.FC<FormRowProps> = ({ document, ...props }) => {
  const [form] = Form.useForm()

  return (
    <Form form={form} component={false} initialValues={{ comment: document.comment || "" }}>
      <DocumentFormContext.Provider value={form}>
        <tr {...props} />
      </DocumentFormContext.Provider>
    </Form>
  )
}
