import React, { FC } from "react"
import { Link } from "react-router-dom"
import { generateUserProfileRoute } from "@routes/inboundRoutes"
import { Subordinate } from "@interfaces"

import { PhotoViewer } from "@components/PhotoViewer"

import style from "./style.m.scss"

type SubordinateCardProps = {
  subordinate: Subordinate
  dataTestId?: string
}

export const SubordinateCard: FC<SubordinateCardProps> = ({
  subordinate: { id, department, fullName, jobTitle, photo },
  dataTestId = ""
}) => (
  <div className={style.card} data-testid={dataTestId}>
    <div className={style.photo} data-testid={`${dataTestId}-photo`}>
      <PhotoViewer src={photo ?? undefined} />
    </div>
    <div className={style.details}>
      <Link to={generateUserProfileRoute(id)} className={style.profileField} data-testid={`${dataTestId}-link`}>
        {fullName}
      </Link>
      <div data-testid={`${dataTestId}-job-title`} className={style.profileField}>
        {jobTitle}
      </div>
      <div data-testid={`${dataTestId}-department`} className={style.profileField}>
        {department}
      </div>
    </div>
  </div>
)
