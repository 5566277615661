import React, { FC } from "react"
import cn from "classnames"
import { useNavigate } from "react-router"

import style from "./style.m.scss"

type CardProps = {
  className?: string
  route?: string
}

export const Card: FC<CardProps> = ({ className, children, route }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    route && navigate(route)
  }

  return (
    <div className={cn(style.card, className)} onClick={handleClick}>
      {children}
    </div>
  )
}
