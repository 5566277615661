import React, { FC, useEffect, useState } from "react"

import style from "./style.m.scss"

type ImageViewerProps = {
  src?: string | undefined
}

export const ImageViewer: FC<ImageViewerProps> = ({ src }) => {
  const [source, setSource] = useState(src)

  useEffect(() => {
    if (src !== source) {
      setSource(src)
    }
  }, [src])

  const handleImageError = () => {
    setSource(undefined)
  }

  return source ? <img src={source} alt="" className={style.image} onError={handleImageError} /> : null
}
