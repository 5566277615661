import React, { FC, useEffect, useState } from "react"
import { Checkbox, Col, Form, FormInstance, Row } from "antd"

import { AvailableSystemFormValues } from "../types"

import { AvailableSystemCard } from "@components/dashboardCards"
import { ValidatedTextArea, ValidatedTextInput } from "@components/ValidatedInputs"

import { checkedLogoRule, checkedRule, fileSizeRule, requiredRule, urlRule } from "./utils"
import { LogoField } from "./LogoField"
import style from "./style.m.scss"

const FIRST_COLUMN_SIZE = 5
const SECOND_COLUMN_SIZE = 14
const THIRD_COLUMN_SIZE = 5

const GUTTER = 16

type AvailableSystemFormProps = {
  form: FormInstance<AvailableSystemFormValues>
}

export const AvailableSystemForm: FC<AvailableSystemFormProps> = ({ form }) => {
  const name = Form.useWatch("name", form)
  const logo = Form.useWatch("logo", form)
  const link = Form.useWatch("link", form)
  const showNameOnBadge = Form.useWatch("showNameOnBadge", form)
  const showLogoOnBadge = Form.useWatch("showLogoOnBadge", form)

  const [logoSrc, setLogoSrc] = useState<string>()

  useEffect(() => {
    if (typeof logo === "object") {
      const reader = new FileReader()

      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setLogoSrc(reader.result)
        }
      }
      reader.readAsDataURL(logo)
    } else {
      setLogoSrc(logo)
    }
  }, [logo])

  const handleNameChange = (value: string) => {
    if (value && !name) form.setFieldsValue({ showNameOnBadge: true })
  }

  const handleLogoChange = (value: File) => {
    if (value && !logo) form.setFieldsValue({ showLogoOnBadge: true })
  }

  const isPreviewAvailable = (logoSrc && showLogoOnBadge) || (name && showNameOnBadge)

  return (
    <Form form={form} preserve={false}>
      <Row gutter={GUTTER}>
        <Col span={FIRST_COLUMN_SIZE}>System name </Col>
        <Col span={SECOND_COLUMN_SIZE}>
          <Form.Item name="name" rules={[requiredRule]}>
            <ValidatedTextInput data-testid="name-field" onChange={handleNameChange} />
          </Form.Item>
        </Col>
        <Col span={THIRD_COLUMN_SIZE}>
          <Form.Item name="showNameOnBadge" valuePropName="checked" rules={[checkedRule]}>
            <Checkbox data-testid="show-name-on-badge-checkbox">Show on badge</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={FIRST_COLUMN_SIZE}>System logo</Col>
        <Col span={SECOND_COLUMN_SIZE}>
          <Form.Item name="logo" rules={[checkedLogoRule, fileSizeRule]}>
            <LogoField onChange={handleLogoChange} />
          </Form.Item>
        </Col>
        <Col span={THIRD_COLUMN_SIZE}>
          <Form.Item name="showLogoOnBadge" valuePropName="checked" rules={[checkedRule]}>
            <Checkbox data-testid="show-logo-on-badge-checkbox">Show on badge</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={FIRST_COLUMN_SIZE}>System link</Col>
        <Col span={SECOND_COLUMN_SIZE}>
          <Form.Item name="link" rules={[requiredRule, urlRule]}>
            <ValidatedTextInput data-testid="link-field" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={FIRST_COLUMN_SIZE}>Description</Col>
        <Col span={SECOND_COLUMN_SIZE}>
          <Form.Item name="description">
            <ValidatedTextArea data-testid="description-field" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={FIRST_COLUMN_SIZE}>Preview</Col>
        <Col span={SECOND_COLUMN_SIZE} className={style.previewContainer}>
          {isPreviewAvailable ? (
            <div className={style.preview}>
              <AvailableSystemCard
                logo={logoSrc && showLogoOnBadge ? logoSrc : undefined}
                link={link}
                name={showNameOnBadge ? name : undefined}
              />
            </div>
          ) : (
            "Preview is not available"
          )}
        </Col>
      </Row>
    </Form>
  )
}
