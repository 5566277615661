import { EditUserBody } from "@state/types/users"
import { Build } from "@state/services/types"

import { PROFILE_EDIT_URL } from "../urls"

export const editUserProfile = (build: Build) =>
  build.mutation<void, EditUserBody>({
    query: body => ({
      url: PROFILE_EDIT_URL,
      method: "PATCH",
      body
    }),
    invalidatesTags: ["User", "Tag", "SlackSettingsOptions"]
  })
