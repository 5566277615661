import { UserLog } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendUserLogs } from "@state/types/users"

import { generateUserLogURL } from "../urls"
import { transformUserLogs } from "../transformer"

export const fetchUserLogs = (build: Build) =>
  build.query<Array<UserLog>, string>({
    query: userId => ({
      url: generateUserLogURL(userId)
    }),
    transformResponse: (res: BackendUserLogs) => transformUserLogs(res),
    providesTags: ["User"]
  })
