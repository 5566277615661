import { Build } from "@state/services/types"
import { VacationQuotaResponse } from "@state/types/vacations"

import { VACATIONS_QUOTAS_URL } from "../urls"

export const fetchVacationsQuotas = (build: Build) =>
  build.query<VacationQuotaResponse, null>({
    query: () => ({
      url: VACATIONS_QUOTAS_URL
    }),
    providesTags: result =>
      result && result.data.length ? result.data.map(quota => ({ type: "Quota", id: quota.id })) : ["Quota"]
  })
