import { Flex } from "antd"
import React, { FC, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@hooks"
import { newPolicyActions } from "@state/reducers/newPolicy"
import { newGroupActions } from "@state/reducers/newGroup"
import { abilitiesApi, groupsApi, policiesApi } from "@state/services/subApis"

import { PoliciesList } from "../Policies/PoliciesList"
import { PermissionsList } from "../Policies/PermissionsList"
import { Logs } from "../components/Logs"

import { CreateGroup } from "./CreateGroup"
import style from "./style.m.scss"
import { GroupsList } from "./GroupsList"

const AccessGroups: FC = () => {
  const dispatch = useAppDispatch()
  const { policiesList } = useAppSelector(state => state.newGroupReducer)

  const { policies } = policiesApi.endpoints.fetchPolicies.useQuery("", {
    selectFromResult: res => ({ policies: res.data || [] })
  })

  const { groups } = groupsApi.endpoints.fetchGroups.useQuery("", {
    selectFromResult: res => ({ groups: res.data || [] })
  })

  const { permissions } = abilitiesApi.endpoints.fetchAbilities.useQuery("", {
    selectFromResult: ({ data }) => ({ permissions: data?.permissions || [] })
  })

  useEffect(() => {
    dispatch(newGroupActions.initPolicies(policies))
  }, [policies])

  useEffect(() => {
    dispatch(newPolicyActions.initPermissions(permissions))

    return () => {
      dispatch(newPolicyActions.clearAll())
    }
  }, [permissions])

  return (
    <>
      <Flex className={style.container}>
        <Flex className={style.wrapper} justify="space-between" gap="middle">
          <Flex vertical gap="middle" style={{ width: "33%" }}>
            <PoliciesList policies={policiesList} isGroupsPage />
            <PermissionsList isGroupPage />
          </Flex>
          <CreateGroup groups={groups} />
          <GroupsList groups={groups} />
        </Flex>
      </Flex>
      <Logs type="access_groups" />
    </>
  )
}

export default AccessGroups
