import React, { FC } from "react"
import { Button, ButtonProps, Tooltip } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { TooltipPlacement } from "antd/es/tooltip"

type EditButtonProps = Omit<ButtonProps, "icon" | "type"> & { tooltipPlacement?: TooltipPlacement }

export const EditButton: FC<EditButtonProps> = ({ tooltipPlacement = "top", ...props }) => (
  <Tooltip title="Edit" placement={tooltipPlacement}>
    <Button icon={<EditOutlined />} type="text" {...props} />
  </Tooltip>
)
