import backendApi from "@state/services/backendApi"

import { fetchPolicies } from "./GET/fetchPolicies"
import { createPolicy } from "./POST/createPolicy"
import { updatePolicy } from "./PUT/updatePolicy"
import { deletePolicy } from "./DELETE/deletePolicy"

export const policiesApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchPolicies: fetchPolicies(build),
    createPolicy: createPolicy(build),
    updatePolicy: updatePolicy(build),
    deletePolicy: deletePolicy(build)
  })
})
