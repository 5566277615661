import React, { FC } from "react"

export const WavixLogo: FC = () => (
  <svg width="130" height="19" viewBox="0 0 132 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.462097 0.309601L7.6055 0.314192L16.57 19.6675H13.0461C10.8061 19.6675 8.77102 18.3546 7.82859 16.3073L0.462097 0.309601Z"
      fill="#34C1CA"
    />
    <path
      d="M10.5511 0.31427L17.6944 0.318861L26.659 19.6722H21.8785C20.408 19.6722 19.0695 18.8092 18.4457 17.4642L10.5511 0.31427Z"
      fill="#34CA5E"
    />
    <path d="M20.7812 0.318878L27.8427 0.323468L26.6635 12.9976L20.7812 0.318878Z" fill="#34CA5E" />
    <path
      d="M33.0149 0.32782L38.6331 0.33241L40.7137 11.1934L43.6867 0.33241L49.3095 0.337L52.3463 11.2256L54.3586 0.341591L59.9176 0.346181L56.6213 15.3845C56.0704 17.9046 53.8533 19.6949 51.2991 19.6949H49.8695L46.5413 7.47055L44.2832 15.6691C43.6275 18.0469 41.4786 19.6949 39.0292 19.6949H37.3629L33.0149 0.32782Z"
      fill="white"
    />
    <path
      d="M73.5943 0.346222H70.4164H67.2385L59.9677 19.6904L66.0001 19.6949L66.9699 16.5H70.4073H73.8447L74.8098 19.6949L80.8424 19.6995L73.5943 0.346222ZM68.231 12.3503L70.4118 5.16619L72.5836 12.3548L68.231 12.3503Z"
      fill="white"
    />
    <path
      d="M94.2691 0.355482L89.9298 14.2829L85.6001 0.350891H79.5266L86.6609 19.7042H89.9253H93.19L100.342 0.360073L94.2691 0.355482Z"
      fill="white"
    />
    <path d="M108.342 0.359985H102.509V19.7087H108.342V0.359985Z" fill="white" />
    <path
      d="M125.051 9.62818L130.983 0.373835L124.623 0.369245L121.19 6.36436L117.762 0.369245L111.405 0.364655L117.329 9.62354L110.745 19.7134L117.252 19.718L121.19 13.3602L125.119 19.7225H131.62L125.051 9.62818Z"
      fill="white"
    />
  </svg>
)
