import { Build } from "@state/services/types"

import { generateRemoveUserTwoFaUrl } from "../urls"

export const removeUser2fa = (build: Build) =>
  build.mutation<unknown, string>({
    query: userId => ({
      url: generateRemoveUserTwoFaUrl(userId),
      method: "POST"
    }),
    invalidatesTags: ["2FA"]
  })
