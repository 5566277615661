import { Build } from "@state/services/types"
import { BulkStatuses } from "@enums"
import { BackendEducationMaterialsBulkLogs } from "@state/types/educationalMaterials"
import { EducationalMaterialBulkLog } from "@interfaces*"

import { generateEducationalMaterialsBulkLogs } from "../urls"
import { educationalMaterialsBulkLogTransformer } from "../transformer"

export const fetchEducationalBulkLogs = (build: Build) =>
  build.query<Array<EducationalMaterialBulkLog>, BulkStatuses>({
    query: status => ({
      url: generateEducationalMaterialsBulkLogs(status)
    }),
    transformResponse: (data: BackendEducationMaterialsBulkLogs) => educationalMaterialsBulkLogTransformer(data),
    providesTags: ["EducationalMaterial"]
  })
