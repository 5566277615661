import { Button, Col, Form, Row, Space } from "antd"
import React, { FC } from "react"
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons"
import { EmergencyContact } from "@state/types/users"
import { useAlert, useProfileData, useProfileHandlers } from "@hooks"
import { EmergencyContactsData } from "@interfaces*"
import { emailRule } from "@app/sections/Profile/helpers/validation"
import { ExpandableSection } from "@app/sections/Profile/components/ExpandableSection"

import { ValidatedDigitInput, ValidatedTextInput } from "@components/ValidatedInputs"
import { CancelButton } from "@components/buttons/CancelButton"
import { SaveButton } from "@components/buttons/SaveButton"

import style from "./style.m.scss"

type EmergencyContactsFormProps = {
  emergencyContacts: Array<EmergencyContact>
  onCancel: () => void
  isExpanded: boolean
  onToggleExpanded: () => void
}

const emptyContact: EmergencyContact = { name: "", relationship: "", phone_number: "", email: "" }

export const EmergencyContactsForm: FC<EmergencyContactsFormProps> = ({
  emergencyContacts,
  onCancel,
  isExpanded,
  onToggleExpanded
}) => {
  const [form] = Form.useForm()
  const { saveEmergencyContacts } = useProfileHandlers()
  const { user } = useProfileData()
  const { error } = useAlert()

  const onFinish = async (formData: EmergencyContactsData) => {
    const { emergencyContacts } = formData
    if (!user || !user.id) return

    if (emergencyContacts.length === 1 && !Object.values(emergencyContacts[0]).some(value => !!value)) {
      const result = await saveEmergencyContacts({ emergencyContacts: [] }, user.id)
      if (result) onCancel()
      return
    }

    if (emergencyContacts.length === 1 && (!emergencyContacts[0].name || !emergencyContacts[0].phone_number)) {
      error("The name and phone number fields are required")
      return
    }

    saveEmergencyContacts(formData, user.id)
    onCancel()
  }

  const onDelete = (fieldName: number, remove: (index: number | Array<number>) => void) => {
    const currentContacts = form.getFieldsValue()
    if (currentContacts.emergencyContacts.length === 1) {
      remove(fieldName)
      form.setFieldsValue({ emergencyContacts: [emptyContact] })
      return
    }
    remove(fieldName)
  }

  return (
    <Form
      form={form}
      initialValues={{ emergencyContacts: emergencyContacts.length ? emergencyContacts : [emptyContact] }}
      onFinish={onFinish}
    >
      <ExpandableSection
        title="Emergency contacts"
        actions={
          <>
            <CancelButton onClick={onCancel} data-testid="save-emergency-contacts" />
            <SaveButton htmlType="submit" data-testid="cancel-save-emergency-contacts" />
          </>
        }
        isExpanded={isExpanded}
        onToggleExpanded={onToggleExpanded}
      >
        <Form.List name="emergencyContacts">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row gutter={16} key={field.key} justify="space-between">
                  <Col span={5}>
                    <Form.Item
                      label="Name"
                      labelCol={{ span: 24 }}
                      name={[field.name, "name"]}
                      rules={[{ required: true, message: "can't be blank", warningOnly: fields.length === 1 }]}
                    >
                      <ValidatedTextInput data-testid="emergency-name-input" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item label="Relationship" labelCol={{ span: 24 }} name={[field.name, "relationship"]}>
                      <ValidatedTextInput data-testid="emergency-relationship-input" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      label="Phone number"
                      labelCol={{ span: 24 }}
                      name={[field.name, "phone_number"]}
                      rules={[{ required: true, message: "can't be blank", warningOnly: fields.length === 1 }]}
                    >
                      <ValidatedDigitInput prefix="+" data-testid="emergency-phone-input" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item label="Email" labelCol={{ span: 24 }} rules={[emailRule]} name={[field.name, "email"]}>
                      <ValidatedTextInput data-testid="emergency-email-input" />
                    </Form.Item>
                  </Col>
                  <Col span={2} className={style.buttonsContainer}>
                    <Space>
                      <Button
                        datatest-id="delete-contact-button"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          onDelete(field.name, remove)
                        }}
                      />
                      {fields.length - 1 === index && (
                        <Button
                          datatest-id="add-contact-button"
                          icon={<UserAddOutlined />}
                          onClick={() => {
                            add()
                          }}
                        />
                      )}
                    </Space>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
      </ExpandableSection>
    </Form>
  )
}
