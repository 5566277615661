import { Build } from "@state/services/types"

import { PROFILE_PHOTO_URL } from "../urls"

export const deleteUserProfilePhoto = (build: Build) =>
  build.mutation<unknown, void>({
    query: () => ({
      url: PROFILE_PHOTO_URL,
      method: "DELETE"
    }),
    invalidatesTags: ["User"]
  })
