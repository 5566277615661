export enum ThingsToDoType {
  EducationMaterials = "education_materials",
  InboxTickets = "inbox_tickets",
  VacationBalance = "vacation_balance"
}

export enum ThingsToDoStatus {
  Todo = "todo",
  Done = "done",
  Failed = "failed",
  NotFound = "not_found"
}
