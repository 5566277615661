import { EmploymentSettings } from "@interfaces*"
import { BackendEmploymentSettingsResponse } from "@state/types/employmentSettings"

export const transformEmploymentSettings = (response: BackendEmploymentSettingsResponse): EmploymentSettings => {
  const settings: EmploymentSettings = {
    employment_statuses: [],
    work_formats: [],
    work_schedules: []
  }

  Object.keys(response).forEach(key => {
    const typedKey = key as keyof EmploymentSettings

    settings[typedKey] = response[typedKey].data.map(entity => {
      const { id, description, in_use } = entity

      return {
        id,
        description,
        inUse: in_use,
        type: typedKey
      }
    })
  })

  return settings
}
