import { BackendUpdateLeaveTypeResponse, UpdateLeaveTypeRequest } from "@state/types/vacations"
import { Build } from "@state/services/types"

import { generateLeaveTypeIdURL } from "../urls"

export const updateLeaveType = (build: Build) =>
  build.mutation<BackendUpdateLeaveTypeResponse, UpdateLeaveTypeRequest>({
    query: body => ({
      url: generateLeaveTypeIdURL(body.data.id),
      method: "PUT",
      body
    }),
    invalidatesTags: ["LeaveType"]
  })
