import { Build } from "@state/services/types"
import { HolidaysOptionsResponse } from "@state/types/vacations"

import { HOLIDAYS_OPTIONS_URL } from "../urls"

export const fetchHolidaysOptions = (build: Build) =>
  build.query<HolidaysOptionsResponse, null>({
    query: () => ({
      url: HOLIDAYS_OPTIONS_URL
    }),
    providesTags: ["HolidaysOptions"]
  })
