import React, { FC, useState } from "react"
import { useAlert, useProfileHandlers } from "@hooks"

import { TagsViewer } from "../TagsViewer"

import { EditButton } from "@components/buttons/EditButton"

import { TagsForm } from "./TagsForm"

type EditableTagsProps = {
  value: Array<string>
}

export const EditableTags: FC<EditableTagsProps> = ({ value }) => {
  const [isEditMode, setIsEditMode] = useState(false)

  const { saveUserProfile } = useProfileHandlers()
  const { success } = useAlert()

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSave = async (tags: Array<string>) => {
    const result = await saveUserProfile({
      tags: tags.map(tag => ({ name: tag }))
    })

    if (result) {
      setIsEditMode(false)
      success("Tags have been saved successfully")
    }
  }

  const handleCancel = () => {
    setIsEditMode(false)
  }

  return isEditMode ? (
    <TagsForm value={value} onSave={handleSave} onCancel={handleCancel} />
  ) : (
    <TagsViewer tags={value} actions={<EditButton onClick={handleEditClick} data-testid="edit-tags" />} />
  )
}
