import { Build } from "@state/services/types"
import { UpdateSlackUserParams } from "@interfaces"

import { generateSlackUserUrl } from "../urls"

export const updateSlackUser = (build: Build) =>
  build.mutation<unknown, UpdateSlackUserParams>({
    query: params => {
      const { id, ...body } = params
      return {
        url: generateSlackUserUrl(id),
        method: "PUT",
        body
      }
    },
    invalidatesTags: ["SlackUser"]
  })
