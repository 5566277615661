import { Build } from "@state/services/types"
import { UpdateUserHireDate } from "@state/types/users"

import { generateUpdateUserHireDateURL } from "../urls"

export const updateUserHireDate = (build: Build) =>
  build.mutation<unknown, UpdateUserHireDate>({
    query: ({ userId, hireDate }) => ({
      url: generateUpdateUserHireDateURL(userId),
      method: "PUT",
      body: { date: hireDate }
    }),
    invalidatesTags: ["User"]
  })
