import * as yup from "yup"

import { validationErrors } from "../validationErrors"
import { emailReg, multiNameReg, nameReg, nicknameReg } from "../validationRegex"

export const userSchema = yup.object().shape({
  lastName: yup
    .string()
    .trim()
    .matches(nameReg, validationErrors.VALID_NAME_ERROR)
    .required(validationErrors.REQUIRED_ERROR),
  firstName: yup
    .string()
    .trim()
    .matches(nameReg, validationErrors.VALID_NAME_ERROR)
    .required(validationErrors.REQUIRED_ERROR),
  patronymic: yup.string().matches(nameReg, validationErrors.VALID_NAME_ERROR).trim(),
  corporateEmail: yup
    .string()
    .matches(emailReg, validationErrors.VALID_EMAIL_ERROR)
    .required(validationErrors.REQUIRED_ERROR),
  birthDate: yup.date(),
  position: yup
    .string()
    .matches(multiNameReg, validationErrors.VALID_LANGUAGE_ERROR)
    .trim()
    .required(validationErrors.REQUIRED_ERROR),
  slack: yup.string().matches(nicknameReg, validationErrors.VALID_NICKNAME_ERROR).trim(),
  gitlab: yup.string().matches(nicknameReg, validationErrors.VALID_NICKNAME_ERROR).trim(),
  location: yup
    .string()
    .matches(multiNameReg, validationErrors.VALID_LANGUAGE_ERROR)
    .trim()
    .required(validationErrors.REQUIRED_ERROR)
})

export const newUserSchema = yup.object().shape({
  lastName: yup
    .string()
    .trim()
    .required(validationErrors.REQUIRED_ERROR)
    .matches(nameReg, validationErrors.VALID_NAME_ERROR),
  firstName: yup
    .string()
    .trim()
    .required(validationErrors.REQUIRED_ERROR)
    .matches(nameReg, validationErrors.VALID_NAME_ERROR),
  patronymic: yup.string().matches(nameReg, validationErrors.VALID_NAME_ERROR).trim(),
  personalEmail: yup
    .string()
    .required(validationErrors.REQUIRED_ERROR)
    .matches(emailReg, validationErrors.VALID_EMAIL_ERROR),
  birthDate: yup.date().required(validationErrors.REQUIRED_ERROR),
  workBeginDate: yup.date().required(validationErrors.REQUIRED_ERROR)
})
