import React, { FC, useState } from "react"
import { Collapse, Typography } from "antd"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { Separators, generateDateTemplate } from "@helpers"
import { generateUserProfileRoute } from "@routes/inboundRoutes"
import { AccessGroupLogType } from "@interfaces*"
import { groupsApi } from "@state/services/subApis"

import style from "./style.m.scss"

type LogsProps = {
  type: AccessGroupLogType
}

const Logs: FC<LogsProps> = ({ type }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { logs } = groupsApi.endpoints.fetchLogs.useQuery(type, {
    selectFromResult: ({ data, isFetching }) => ({
      logs: data || [],
      isFetching
    }),
    skip: !isExpanded
  })

  const handleCollapse = () => {
    setIsExpanded(!isExpanded)
  }

  const logsItems = [
    {
      label: (
        <Typography.Title level={4} className={style.header}>
          History
        </Typography.Title>
      ),
      children: logs.map(log => (
        <p key={log.id}>
          <span>{`${log.record} by `}</span>
          <span>
            {log.userId ? <Link to={generateUserProfileRoute(log.userId)}>{`${log.email} `} </Link> : `${log.email} `}
          </span>
          <span>{dayjs(log.createdAt).format(generateDateTemplate("YYYYMMDD", Separators.Dash))} </span>
          <span>{dayjs(log.createdAt).format("HH:mm")}</span>
        </p>
      ))
    }
  ]

  return (
    <div className={style.collapseWrapper}>
      <Collapse ghost onChange={handleCollapse} items={logsItems} expandIconPosition="end" className={style.collapse} />
    </div>
  )
}

export default Logs
