import React, { FC } from "react"
import { Col, Row, Typography } from "antd"
import { AnnualLeavesData } from "@interfaces"

import { Field } from "@components/Field"

import { LeaveInfo } from "./LeaveInfo"
import style from "./style.m.scss"

type LeavesInfoProps = {
  leavesData: AnnualLeavesData
}

const formatValue = (value: string | number | null) => {
  if (!value) return "\u221E"
  const remainedValue = parseFloat(String(value))
  return Number.isInteger(remainedValue) ? remainedValue.toString() : remainedValue.toFixed(1)
}

export const LeavesInfo: FC<LeavesInfoProps> = ({
  leavesData: { annualLeaves, sickLeaves, annualLeavesAmount, annualLeavesType, sickLeavesAmount }
}) => (
  <>
    <Row className={style.row}>
      <Col span={12}>
        <Typography.Title level={5}>Paid time offs</Typography.Title>
        <Field
          title="Amount of days"
          value={`${formatValue(annualLeavesAmount.remained)}/${formatValue(annualLeavesAmount.all)}`}
        />
        <Field title="Type" value={annualLeavesType} />

        <Field
          title="Used leaves"
          value={
            <div className={style.leavesWrapper}>
              {annualLeaves.map(leave => (
                <LeaveInfo leave={leave} key={`${leave.startedAt}-${leave.finishedAt}`} />
              ))}
            </div>
          }
          className={style.leavesList}
        />
      </Col>
      {sickLeaves.length > 0 && (
        <Col span={12}>
          <Typography.Title level={5}>Sick leaves</Typography.Title>
          <Field title="Total amount of leaves" value={sickLeavesAmount.spent} />
          <Field
            title="Used leaves"
            value={
              <div className={style.leavesWrapper}>
                {sickLeaves.map(leave => (
                  <LeaveInfo leave={leave} key={`${leave.startedAt}-${leave.finishedAt}`} />
                ))}
              </div>
            }
            className={style.leavesList}
          />
        </Col>
      )}
    </Row>
  </>
)
