import React, { FC, useState } from "react"
import { Flex, Table } from "antd"
import { usePerformanceReviewsAbilities } from "hooks/query/useAbilities"
import { performanceReviewApi } from "@state/services/subApis"

import { SectionWrapper } from "@components/SectionWrapper"
import { SaveButton } from "@components/buttons/SaveButton"
import { EditButton } from "@components/buttons/EditButton"
import { Title } from "@components/Title"

import { FileForm } from "./FileForm"
import { useCertificatesTableColumns } from "./helpers/usePerformanceReviewsColumns"

type PerformanceReviewProps = {
  userId: string
}

const PerformanceReview: FC<PerformanceReviewProps> = ({ userId }) => {
  const [editMode, setEditMode] = useState(false)

  const { edit_all } = usePerformanceReviewsAbilities()

  const { data = [], isLoading, isFetching } = performanceReviewApi.endpoints.fetchPerformanceReview.useQuery(userId)

  const handleEdit = () => {
    setEditMode(true)
  }

  const handleSave = () => {
    setEditMode(false)
  }

  const columns = useCertificatesTableColumns(editMode)

  if (!edit_all && data.length === 0) return null

  return (
    <SectionWrapper>
      <Title>
        <Flex align="center" justify="space-between">
          Performance review results
          {editMode ? <SaveButton onClick={handleSave} /> : <EditButton onClick={handleEdit} disabled={!edit_all} />}
        </Flex>
      </Title>
      {editMode && <FileForm userId={userId} />}
      <Table
        columns={columns}
        dataSource={data}
        rowKey="reviewId"
        loading={isLoading || isFetching}
        pagination={false}
      />
    </SectionWrapper>
  )
}

export default PerformanceReview
