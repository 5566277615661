import { Rule } from "antd/es/form"

export const deriveEqualFieldsRule =
  (fieldName: string): Rule =>
  ({ getFieldValue, isFieldTouched }) => ({
    validator(_, value) {
      if (!value || !isFieldTouched(fieldName) || getFieldValue(fieldName) === value) {
        return Promise.resolve()
      }

      return Promise.reject(new Error("Passwords do not match"))
    }
  })
