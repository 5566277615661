import React, { FC } from "react"
import { Col, Row } from "antd"
import cn from "classnames"

import style from "./style.m.scss"

type FieldProps = {
  title: React.ReactNode
  value: React.ReactNode
  actions?: React.ReactNode
  className?: string
  dataTestId?: string
}

export const Field: FC<FieldProps> = ({ title, value, actions, className, dataTestId }) => (
  <Row className={cn(style.row, className)}>
    <Col span={8}>{title}:</Col>
    <Col span={16} className={style.valueColumn} data-testid={dataTestId}>
      <div className={style.value}>{value}</div>
      {actions && <div className={style.actions}>{actions}</div>}
    </Col>
  </Row>
)
