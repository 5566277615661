import { ThingToDoField } from "@interfaces"
import { Build } from "@state/services/types"
import { FifteenFiveData } from "interfaces/dashboard"

import { CURRENT_REVIEW_URL } from "../urls"

export const fetchCurrentReviews = (build: Build) =>
  build.query<ThingToDoField<FifteenFiveData>, string>({
    query: () => ({
      url: CURRENT_REVIEW_URL
    }),
    providesTags: ["EducationalMaterial"]
  })
