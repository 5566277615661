import React, { FC } from "react"
import { Form } from "antd"

import { ExpandableSection } from "../../../components/ExpandableSection"

import { SaveButton } from "@components/buttons/SaveButton"
import { CancelButton } from "@components/buttons/CancelButton"
import { ValidatedTextArea } from "@components/ValidatedInputs/ValidatedTextArea"

type FormFields = { description: string }

type EditPanelProps = {
  text: string
  isExpanded: boolean
  onToggleExpanded: () => void
  onSave: (description: string) => void
  onCancel: () => void
}

export const EditPanel: FC<EditPanelProps> = ({ text, isExpanded, onToggleExpanded, onSave, onCancel }) => {
  const [form] = Form.useForm<FormFields>()

  const handleFormSubmit = (formFields: FormFields) => {
    onSave(formFields.description)
  }

  return (
    <Form form={form} onFinish={handleFormSubmit} initialValues={{ description: text }}>
      <ExpandableSection
        title="Job description"
        actions={
          <>
            <SaveButton htmlType="submit" data-testid="save-job-description" />
            <CancelButton onClick={onCancel} data-testid="cancel-save-job-description" />
          </>
        }
        isExpanded={isExpanded}
        onToggleExpanded={onToggleExpanded}
      >
        <Form.Item name="description">
          <ValidatedTextArea
            showCount
            maxLength={5000}
            style={{ height: 250 }}
            placeholder="Job description"
            isWarningHidden={!isExpanded}
            data-testid="job-description-textarea"
          />
        </Form.Item>
      </ExpandableSection>
    </Form>
  )
}
