import React, { FC, useState } from "react"
import { UserFullInfoWithAccesses } from "@interfaces"
import { noop } from "@helpers"
import { useProfileHandlers, useUserProfileEmploymentInfoAbilities } from "@hooks"

import { UserForm } from "../../components/UserForm"
import { deriveUpdateProfileData } from "../../helpers/userConverters"
import { UserFormFields } from "../../types"

import { SectionWrapper } from "@components/SectionWrapper"

import { ViewPanel } from "./ViewPanel"

type EmploymentInformationProps = {
  user: UserFullInfoWithAccesses
  isFetching: boolean
  // forced refetch is a workaround here for the following case:
  // user opens the profile, it is in view mode by default
  // then he clicks edit button, uploads a new photo and saves the form.
  // Old photo is deleted on a server and the request to its old utl returns 401
  // When user is switched to a view mode on a successfull save mutation he retrieves
  // the view he has started from with old cached data from RTK-Query. And it still has old
  // photo url that leads to 401 (BTW not 404) error message in browser console.
  // Using just isFetching RTK property does not make sense as there is a tiny moment when
  // RTK invalidation tag is already invalid and isFetching is not true yet - so this leads to
  // a render with non-existing url
  onSaved: () => void
}

export const EmploymentInformation: FC<EmploymentInformationProps> = ({ user, onSaved, isFetching }) => {
  const { update_all: canUpdateSection } = useUserProfileEmploymentInfoAbilities()

  const [isTotalEditMode, setIsTotalEditMode] = useState(false)

  const { saveAll } = useProfileHandlers()

  const handleTotalEditClick = canUpdateSection
    ? () => {
        setIsTotalEditMode(true)
      }
    : undefined

  const handleSaveClick = async (updatedFields: UserFormFields, onFinish: () => void = noop) => {
    const { updateUserBody, hireDate } = deriveUpdateProfileData(updatedFields)

    const result = await saveAll(updateUserBody, user, hireDate)
    onFinish()

    if (result) {
      onSaved()
      setIsTotalEditMode(false)
    }
  }

  const handleCancelClick = () => {
    setIsTotalEditMode(false)
  }

  return (
    <SectionWrapper>
      {isTotalEditMode ? (
        <UserForm
          user={user}
          header="Employment information"
          onSave={handleSaveClick}
          onCancel={handleCancelClick}
          isEditForm
        />
      ) : (
        <ViewPanel user={user} onTotalEdit={handleTotalEditClick} isFetching={isFetching} />
      )}
    </SectionWrapper>
  )
}
