import Profile from "@app/sections/Profile"
import { Abilities } from "@interfaces"

import { DefaultWrapper } from "@components/PageWrappers"

import { RouteItem } from "./types"
import { MY_PROFILE, MY_PROFILE_EDIT } from "./inboundRoutes"

const profileRoutes = (abilities: Abilities | null): Array<RouteItem> => [
  {
    path: MY_PROFILE,
    title: "Profile",
    slug: "profile",
    component: Profile,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.profiles?.show
  },
  {
    path: MY_PROFILE_EDIT,
    title: "Edit profile",
    slug: "profile-edit",
    component: Profile,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.profiles?.update
  }
]

export default profileRoutes
