import React from "react"
import ReactDOM from "react-dom"
import "./sentry/init.ts"
import * as Sentry from "@sentry/react"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import store, { persistor } from "state/store"
import fonts from "assets/fonts/fonts"
import InitialRouting from "@app/sections/App/InitialRouting"
import "./styles/index.scss"
import { PersistGate } from "redux-persist/integration/react"
import { App } from "antd"

import { AlertsListener } from "@components/AlertsListener"
import { Theme } from "@components/Theme"

const AppWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => <>{children}</>

const renderApp = () => {
  const target = document.getElementById("root")
  if (!target) return

  try {
    ReactDOM.render(
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <App component={AppWrapper}>
            <Theme>
              <AlertsListener>
                <PersistGate loading={null} persistor={persistor}>
                  <React.Suspense fallback={null}>
                    <BrowserRouter>
                      <InitialRouting />
                    </BrowserRouter>
                  </React.Suspense>
                </PersistGate>
              </AlertsListener>
            </Theme>
          </App>
        </Provider>
      </Sentry.ErrorBoundary>,
      target
    )
  } catch (err) {
    const div = document.createElement("div")
    div.innerHTML = "Something went wrong."
    target.appendChild(div)
  }

  // Insert font separated from components to prevent blinking
  const head = document.getElementsByTagName("head")[0]
  const style = document.createElement("style")
  style.innerHTML = fonts
  head.appendChild(style)
}

renderApp()
