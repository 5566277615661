import { Build } from "@state/services/types"
import { SolutionVacationQuery } from "@state/types/vacations"

import { generateVacationSolutionURL } from "../urls"

export const resolveVacation = (build: Build) =>
  build.mutation<void, SolutionVacationQuery>({
    query: ({ id, solution }) => ({
      url: generateVacationSolutionURL(id),
      method: "PATCH",
      body: { vote: solution }
    }),
    invalidatesTags: (result, error, arg) => [{ type: "Vacation", id: arg.id }]
  })
