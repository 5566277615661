import { BackendBannedIpsResponse } from "@state/types/bannedIps"
import { Build } from "@state/services/types"

import { BANNED_IPS_URL } from "../urls"

export const fetchBannedIps = (build: Build) =>
  build.query<Array<string>, void>({
    query: () => ({ url: BANNED_IPS_URL }),
    transformResponse: (data: BackendBannedIpsResponse) => data.banned_ips,
    providesTags: ["BannedIps"]
  })
