import backendApi from "@state/services/backendApi"

import { fetchAvailableSystems } from "./GET/fetchAvailableSystems"
import { createAvailableSystem } from "./POST/createAvailableSystem"
import { updateAvailableSystem } from "./PUT/updateAvailableSystem"
import { deleteAvailableSystem } from "./DELETE/deleteAvailableSystem"
import { fetchAvailableSystemsLogs } from "./GET/fetchAvailableSystemsLogs"

export const availableSystemsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchAvailableSystems: fetchAvailableSystems(build),
    createAvailableSystem: createAvailableSystem(build),
    updateAvailableSystem: updateAvailableSystem(build),
    deleteAvailableSystem: deleteAvailableSystem(build),
    fetchAvailableSystemsLogs: fetchAvailableSystemsLogs(build)
  })
})
