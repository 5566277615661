import React, { FC } from "react"
import { AutoComplete, AutoCompleteProps, RefSelectProps } from "antd"

import { Regular } from "../ValidateInputWrap/ValidateInputWrap"

import { ValidateInputWrap } from "@components/ValidatedInputs/ValidateInputWrap"

type ValidatedAutocompleteProps = {
  ref?: React.RefObject<RefSelectProps>
  value?: string
  onChange?: (value: string) => void
  isWarningHidden?: boolean
} & Omit<AutoCompleteProps, "value" | "onChange">

export const ValidatedAutocomplete: FC<ValidatedAutocompleteProps> = props => {
  const { ref, value, onChange, isWarningHidden, ...restProps } = props

  return (
    <ValidateInputWrap
      regular={Regular.Text}
      value={value}
      onValueChange={onChange}
      renderInput={(internalChangeValue, onInternalChange) => (
        <AutoComplete ref={ref} value={internalChangeValue} onChange={onInternalChange} {...restProps} />
      )}
      isWarningHidden={isWarningHidden}
    />
  )
}
