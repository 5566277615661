import { DepartmentLog } from "@interfaces"
import { BackendDepartmentLogsResponse } from "@state/types/departments"
import { Build } from "@state/services/types"

import { generateDepartmentsLogsURL } from "../urls"
import { transformDepartmentLogs } from "../transformDepartments"

export const fetchDepartmentsLogs = (build: Build) =>
  build.query<Array<DepartmentLog>, string>({
    query: departmentId => ({
      url: generateDepartmentsLogsURL(departmentId)
    }),
    providesTags: res =>
      res
        ? [...res.map(log => ({ type: "DepartmentLog" as const, id: log.id })), "Department"]
        : ["Department", "DepartmentLog"],
    transformResponse: (response: BackendDepartmentLogsResponse) => transformDepartmentLogs(response)
  })
