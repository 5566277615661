import * as Sentry from "@sentry/browser"

import { SentryConfig } from "./utils"

if (SentryConfig.inUse) {
  Sentry.init({
    dsn: SentryConfig.dsn,
    tracesSampleRate: SentryConfig.sampleRate,
    release: SentryConfig.version,
    integrations: [Sentry.httpClientIntegration()]
  })
}
