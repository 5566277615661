import { Build } from "@state/services/types"

import { BANNED_IPS_URL } from "../urls"

export const deleteBannedIp = (build: Build) =>
  build.mutation<unknown, string>({
    query: ip => ({
      url: BANNED_IPS_URL,
      method: "DELETE",
      body: { ip }
    }),
    invalidatesTags: ["BannedIps"]
  })
