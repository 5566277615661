import { DASHBOARD_ROUTE, MY_PROFILE } from "@routes/inboundRoutes"
import { useParams } from "react-router"
import { useAppSelector } from "@hooks"

export const useProfileLocation = () => {
  const { userId } = useParams()
  const isDashboardPage = window.location.pathname === DASHBOARD_ROUTE
  const isMyProfilePage = window.location.pathname.includes(MY_PROFILE)
  const profileId = useAppSelector(state => state.meReducer.user?.id)
  const isMyself = isDashboardPage || isMyProfilePage || Boolean(userId && profileId && userId === profileId)

  return { isMyself }
}
