import { SlackUsersOptions } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendSlackUsersOptions } from "@state/types/slackUsers"

import { SLACK_USERS_OPTIONS_URL } from "../urls"
import { transformSlackUsersOptions } from "../transformer"

export const fetchSlackUsersOptions = (build: Build) =>
  build.query<SlackUsersOptions, void>({
    query: () => ({ url: SLACK_USERS_OPTIONS_URL }),
    transformResponse: (data: BackendSlackUsersOptions) => transformSlackUsersOptions(data)
  })
