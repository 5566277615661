import { VacationLog } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendVacationLogResponse } from "@state/types/vacations"

import { generateGetVacationLogsURL } from "../urls"
import { transformVacationLogs } from "../transformers"

export const fetchVacationLogs = (build: Build) =>
  build.query<Array<VacationLog>, string>({
    query: id => ({
      url: generateGetVacationLogsURL(id)
    }),
    transformResponse: (res: BackendVacationLogResponse) => transformVacationLogs(res),
    providesTags: ["Vacation"]
  })
