import { UsersDataFromBackend, ResponseWithTotalCount, User, PagedUsersQueryParams } from "@interfaces"
import { Build } from "@state/services/types"

import { generateGetPagedUsersURL } from "../urls"
import { usersTransformer } from "../transformer"

export const fetchPagedUsers = (build: Build) =>
  build.query<ResponseWithTotalCount<User>, PagedUsersQueryParams>({
    query: params => ({
      url: generateGetPagedUsersURL(params)
    }),

    providesTags: res =>
      res?.entities ? [...res.entities.map(user => ({ type: "User" as const, id: user.id }))] : ["User"],

    transformResponse: (data: UsersDataFromBackend, meta) => ({
      entities: usersTransformer(data),
      totalCount: Number(meta?.response?.headers.get("X-Total-Count"))
    })
  })
