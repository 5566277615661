import { DestroySettingParams } from "@interfaces"
import { Build } from "@state/services/types"

import { generateDestroySettingURL } from "../urls"

export const deleteEmploymentSetting = (build: Build) =>
  build.mutation<{ message: string }, DestroySettingParams>({
    query: params => ({
      url: generateDestroySettingURL(params.id, params.type),
      method: "DELETE"
    }),
    invalidatesTags: ["EmploymentSettings"]
  })
