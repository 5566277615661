import { CreateConferenceBody } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendConference } from "@state/types/conferences"

import { CONFERENCES_URL } from "../urls"

export const createConference = (build: Build) =>
  build.mutation<{ data: BackendConference }, CreateConferenceBody>({
    query: body => ({
      url: CONFERENCES_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["Conference"]
  })
