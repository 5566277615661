import React, { FC, useEffect } from "react"
import { useAlert, useAppDispatch, useAppSelector } from "@hooks"
import { newPolicyActions } from "@state/reducers/newPolicy"
import { defaultError } from "@helpers"
import { Policy } from "@interfaces*"
import { policiesApi } from "@state/services/subApis"

import { generatePoliciesPermissions } from "../helpers"
import { CreateForm } from "../../components/CreateForm"
import { PermissionsCollapse } from "../../components/PermissionsCollapse"
import { ListWrapper } from "../../components/ListWrapper"

import style from "./style.m.scss"

type CreatePoliciesProps = {
  policies: Array<Policy>
}

type FormData = { name: string }

const CreatePolicy: FC<CreatePoliciesProps> = ({ policies }) => {
  const dispatch = useAppDispatch()

  const { sections, currentPolicy } = useAppSelector(state => state.newPolicyReducer)

  const [createPolicy] = policiesApi.endpoints.createPolicy.useMutation()
  const [updatePolicy] = policiesApi.endpoints.updatePolicy.useMutation()

  const { success, error } = useAlert()

  useEffect(() => {
    dispatch(newPolicyActions.clearAllSections())
  }, [])

  const handlePolicyReset = () => {
    dispatch(newPolicyActions.clearAllSections())
  }

  const handleCreatePolicySubmit = async ({ name }: FormData, operation: "Update" | "Create") => {
    const pickedPermissions = generatePoliciesPermissions(sections)
    const bodyReq = {
      data: {
        id: currentPolicy.id ? Number(currentPolicy.id) : undefined,
        type: "policies" as const,
        attributes: {
          name,
          permissions: pickedPermissions
        }
      },
      format: ":jsonapi" as const
    }

    try {
      if (operation === "Create") {
        await createPolicy(bodyReq).unwrap()
        handlePolicyReset()
        success("Policy was succesfully created")
        return
      }

      if (operation === "Update") {
        await updatePolicy(bodyReq).unwrap()
        handlePolicyReset()
        success("Policy was succesfully updated")
        return
      }
    } catch {
      error(defaultError)
    }
  }

  const generateHandleClearSection = (section: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      dispatch(newPolicyActions.clearSection({ section }))
    }
  }

  const generateHandleTogglePermission = (section: string, permissionListId: number) => {
    return () => {
      dispatch(newPolicyActions.togglePermission({ section, permissionListId }))
    }
  }

  const options = policies
    .filter(policy => !!Object.entries(policy.permissions).length)
    .map(policy => ({ label: policy.name, value: policy.name }))

  const checkPoliciesExist = (currentPolicyName: string) => {
    const currentPolicy = policies.find(policy => policy.name === currentPolicyName)

    if (currentPolicy) {
      dispatch(newPolicyActions.setCurrentPolicy({ policyName: currentPolicy.name, id: currentPolicy.id }))
      const currentPermissions = Object.entries(currentPolicy.permissions).map(([sectionName, permission]) => [
        sectionName,
        permission.map(permission => permission.name)
      ])

      const currentPolicyPermissionsNames = Object.fromEntries(currentPermissions)
      dispatch(newPolicyActions.addPolicyPermission(currentPolicyPermissionsNames))
    }
  }

  const disabledCondition = !Object.entries(sections).some(([, { chosen }]) => chosen.length)

  return (
    <ListWrapper title="Create policies" wrapperClassName={style.wrapper}>
      <CreateForm
        title="Policy"
        currentValue={currentPolicy}
        options={options}
        disabled={disabledCondition}
        handleSubmit={handleCreatePolicySubmit}
        setCurrentValue={checkPoliciesExist}
        reset={handlePolicyReset}
      />
      <PermissionsCollapse
        sections={sections}
        isSecondary
        generateSectionsCallback={generateHandleClearSection}
        generatePermissionCallback={generateHandleTogglePermission}
      />
    </ListWrapper>
  )
}

export default CreatePolicy
