import * as Sentry from "@sentry/browser"
import { LOGIN_ROUTE } from "@routes/inboundRoutes"
import { AuthErrorResponse } from "@interfaces"
import { RESET_ALL_ACTION_TYPE } from "@state/const"
import { meActions } from "@state/reducers/me"
import { myAbilitiesActions } from "@state/reducers/myAbilities"
import { batch } from "react-redux"
import { useNavigate } from "react-router"
import { abilitiesTransformer, authenticationApi } from "@state/services/subApis"
import backendApi from "@state/services/backendApi"
import { SentryConfig } from "sentry/utils"

import { useAppDispatch, useAppSelector } from "../utils/redux"
import { useAlert } from "../alert/useAlert"

const ERROR_LOGOUT = "Error while logout"

export const useAuthActions = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { error } = useAlert()

  const isUserLoggedIn = useAppSelector(state => state.meReducer.isUserLoggedIn)
  const [logout] = authenticationApi.endpoints.logout.useMutation()
  const [switchUser] = authenticationApi.endpoints.switchUser.useMutation()
  const [fetchMe] = authenticationApi.endpoints.fetchMe.useLazyQuery()

  const handleSignOut = async () => {
    try {
      await logout().unwrap()

      if (SentryConfig.inUse) Sentry.setUser(null)

      batch(() => {
        dispatch({ type: RESET_ALL_ACTION_TYPE })
        dispatch(backendApi.util.resetApiState())
      })
    } catch (err) {
      const { data } = err as AuthErrorResponse
      const errorDescription = data?.["field-error"]?.[1]

      error(errorDescription ?? data?.error ?? ERROR_LOGOUT)
    }
  }

  const handleSwitchUser = async (userId: string) => {
    try {
      await switchUser({ user_id: userId }).unwrap()
      const meResponse = await fetchMe().unwrap()

      const abilities = meResponse?.data?.attributes?.abilities ?? []
      const transformedAbilities = abilitiesTransformer(abilities)

      if (SentryConfig.inUse)
        Sentry.setUser({
          id: meResponse.data.id,
          username: meResponse.data.attributes.email
        })

      batch(() => {
        dispatch({ type: RESET_ALL_ACTION_TYPE })
        dispatch(backendApi.util.resetApiState())
        dispatch(meActions.setMe({ user: meResponse.data }))
        dispatch(meActions.passTwoFa())
        dispatch(myAbilitiesActions.setMyAbilities({ abilities: transformedAbilities }))
      })
      navigate("/")
    } catch {
      error("cannot switch current user")
    }
  }

  const checkUserLogin = () => () => {
    if (!isUserLoggedIn) {
      navigate(LOGIN_ROUTE)
    }
  }
  return { handleSignOut, checkUserLogin, handleSwitchUser }
}
