import { Build } from "@state/services/types"
import { ChangeVacationQuotaResponse, ChangeVacationQuotaRequest } from "@state/types/vacations"

import { generateVacationQuotaIdURL } from "../urls"

export const changeVacationsQuotas = (build: Build) =>
  build.mutation<ChangeVacationQuotaResponse, ChangeVacationQuotaRequest>({
    query: arg => ({
      url: generateVacationQuotaIdURL(arg.data.id),
      method: "PUT",
      body: arg
    }),
    invalidatesTags: result => (result && result.data ? [{ type: "Quota", id: result.data.id }] : ["Quota"])
  })
