import { UploadFile } from "antd"
import { Rule } from "antd/es/form"

import { MAX_PDF_SIZE } from "./constants"

export const requiredRule: Rule = { required: true, message: "Can't be blank" }

export const emailRule: Rule = {
  type: "email",
  message: "Incorrect email format"
}

export const urlRule: Rule = { type: "url", message: "Must be a valid url" }

export const checkFilesSize = (_: Rule, files: Array<UploadFile<any>>) => {
  const checkEachFile = files.map(file => (file.size ? file.size >= MAX_PDF_SIZE : false))
  if (checkEachFile.includes(true)) {
    return Promise.reject(new Error("Maximum allowed file size 5 MB"))
  }
  return Promise.resolve()
}
