import { generateFullName } from "@helpers"
import { usersApi } from "@state/services/subApis"

export const useDeptCardUtility = () => {
  const { usersWithoutPosition } = usersApi.endpoints.fetchUsersWithoutPosition.useQuery(
    {
      only_not_appointed: "1"
    },
    {
      selectFromResult: ({ data }) => ({
        usersWithoutPosition: data
          ? data.map(user => ({
              label: generateFullName(user.name.split(" ")[1], user.name.split(" ")[0]),
              value: user.id,
              products: user.products
            }))
          : []
      })
    }
  )

  return { usersWithoutPosition }
}
