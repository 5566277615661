import React from "react"
import { PersonalDocument } from "@interfaces"

import { DocumentStatus } from "../../const"
import { FormRow } from "../FormRow"

type DocumentRowProps = {
  document?: PersonalDocument
}

export const DocumentRow: React.FC<DocumentRowProps> = ({ document, ...props }) => {
  if (!document || document.status !== DocumentStatus.pending) return <tr {...props} />

  return <FormRow document={document} {...props} />
}
