import React, { FC } from "react"
import { Button, ButtonProps, Tooltip } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { TooltipPlacement } from "antd/es/tooltip"

type CancelButtonProps = Omit<ButtonProps, "icon"> & { tooltipPlacement?: TooltipPlacement }

export const CancelButton: FC<CancelButtonProps> = ({ type = "text", tooltipPlacement = "top", ...props }) => (
  <Tooltip title="Cancel" placement={tooltipPlacement}>
    <Button icon={<CloseOutlined />} type={type} {...props} />
  </Tooltip>
)
