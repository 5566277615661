import React, { FC } from "react"
import { personalDocumentsApi } from "@state/services/subApis"
import { Spin } from "antd"
import { UserFullInfoWithAccesses } from "@interfaces"

import { PersonalDocumentsGroup } from "./PersonalDocumentsGroup"

type PersonalDocumentsHubProps = {
  user: UserFullInfoWithAccesses
}

export const PersonalDocumentsHub: FC<PersonalDocumentsHubProps> = ({ user }) => {
  const userId = user.id ?? ""

  const { data, isLoading, isFetching } = personalDocumentsApi.endpoints.fetchPersonalDocuments.useQuery({ userId }, {})

  if (isLoading) return <Spin />

  if (!data) return null

  const { currentDocuments, archivedDocuments } = data

  return (
    <>
      <PersonalDocumentsGroup
        documents={currentDocuments}
        userId={userId}
        isLoading={isLoading || isFetching}
        isArchived={false}
        location={user.location}
        dataTestId="current-pd"
        key="current-pd"
      />
      {archivedDocuments.map(docs => (
        <PersonalDocumentsGroup
          documents={docs.documents}
          userId={userId}
          isArchived
          location={docs.location}
          isLoading={isLoading || isFetching}
          dataTestId={`pd-${docs.location.replace(/\s+/g, "-")}`}
          key={docs.location}
        />
      ))}
    </>
  )
}
