import { JobHistoryItem } from "@interfaces"
import { BackendJobHistoryResponse } from "@state/types/users"

export const transformJobHistory = (response: BackendJobHistoryResponse): Array<JobHistoryItem> => {
  return response.job_history.map(entity => {
    const { id, previous_department, previous_job_title, previous_position_start_date } = entity

    return {
      id: String(id),
      previousDept: previous_department,
      previousJobTitle: previous_job_title,
      previousPositionStartDate: previous_position_start_date
    }
  })
}
