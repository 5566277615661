import { LocationForSettings } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendLocationsResponse } from "@state/types/locations"

import { generateGetLocationsURL } from "../urls"
import { transformLocations } from "../transformLocations"

export const fetchLocationsForSettings = (build: Build) =>
  build.query<Array<LocationForSettings>, "">({
    query: () => ({
      url: generateGetLocationsURL()
    }),
    providesTags: res => (res ? res.map(location => ({ type: "Location" as const, id: location.id })) : ["Location"]),
    transformResponse: (response: BackendLocationsResponse) => transformLocations(response)
  })
