import React, { FC, useEffect } from "react"
import { useAppSelector, useAuthActions, useFavicon } from "@hooks"

import AppLoader from "./AppLoader"
import { NonauthenticatedRouter } from "./NonauthenticatedRouter"

const InitialRouting: FC = () => {
  const isTwoFAPassed = useAppSelector(state => state.meReducer.isTwoFAPassed)
  const isUserLoggedIn = useAppSelector(state => state.meReducer.isUserLoggedIn)
  const { checkUserLogin } = useAuthActions()

  useEffect(() => {
    checkUserLogin()
  }, [isUserLoggedIn])

  useFavicon()

  return <>{isUserLoggedIn && isTwoFAPassed ? <AppLoader /> : <NonauthenticatedRouter />}</>
}

export default InitialRouting
