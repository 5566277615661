import { VacationAccumulation } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendVacationAccumulationResponse } from "@state/types/vacations"

import { generateVacationAccumulationsURL } from "../urls"
import { transformVacationAccumulations } from "../transformers"

export const fetchVacationAccumulations = (build: Build) =>
  build.query<Array<VacationAccumulation>, string>({
    query: id => ({
      url: generateVacationAccumulationsURL(id)
    }),
    transformResponse: (res: BackendVacationAccumulationResponse) => transformVacationAccumulations(res),
    providesTags: ["VacationAccumulation"]
  })
