import { BackendWorkspaceAttributes } from "@state/types/slackGroups"
import { BackendSlackUsersResponse, BackendSlackUsersOptions } from "@state/types/slackUsers"

export const transformSlackUsers = (responseData: BackendSlackUsersResponse) => {
  const { data, included } = responseData

  const workspacesMapping = included.reduce<Record<string, BackendWorkspaceAttributes>>(
    (acc, item) => ({ ...acc, [item.id]: item.attributes }),
    {}
  )

  return data.map(item => ({
    id: item.id,
    email: item.attributes.email,
    fullName: item.attributes.full_name,
    photoUrl: item.attributes.photo_url,
    permissions: item.attributes.permissions,
    workspace: workspacesMapping[item.relationships.workspace.data.id]?.name || ""
  }))
}

export const transformSlackUsersOptions = (responseData: BackendSlackUsersOptions) => ({
  permissions: Object.entries(responseData.permissions).map(([key, value]) => ({ name: key, description: value }))
})
