type FindItem<T> = (element: T) => string

type SortList = <T>(list: Array<T>, findMainItem: FindItem<T>, findSecondItem: FindItem<T>) => Array<T>

const compareItems =
  <T>(callback: FindItem<T>) =>
  (element1: T, element2: T) => {
    const firstItem = callback(element1)
    const secondItem = callback(element2)

    return firstItem.localeCompare(secondItem)
  }

const divideList = <T>(list: Array<T>, findItem: FindItem<T>) => {
  if (!list.length) return []

  const dividedList: Array<Array<T>> = []
  let listChunk: Array<T> = []
  let referenceItem = findItem(list[0])

  list.forEach(element => {
    const item = findItem(element)

    if (item === referenceItem) {
      listChunk.push(element)
    } else {
      dividedList.push(listChunk)
      referenceItem = item
      listChunk = [element]
    }
  })

  dividedList.push(listChunk)

  return dividedList
}

export const doubleStableSortList: SortList = (list, findMainItem, findSecondItem) => {
  const newList = [...list]

  const mainSortedList = newList.sort(compareItems(findMainItem))

  const dividedList = divideList(mainSortedList, findMainItem)

  const dividedSecondSortedList = dividedList.map(listChunk => listChunk.sort(compareItems(findSecondItem)))

  const secondSortedList = dividedSecondSortedList.reduce((reducer, listChunk) => [...reducer, ...listChunk], [])

  return secondSortedList
}
