import { UploadOutlined } from "@ant-design/icons"
import { requiredRule } from "@app/sections/Profile/helpers/validation"
import { defaultError, generateDateTemplate, Separators } from "@helpers"
import { useAlert } from "@hooks"
import { certificatesApi } from "@state/services/subApis"
import { Button, Col, DatePicker, Form, Row, UploadFile } from "antd"
import Upload, { UploadChangeParam } from "antd/es/upload"
import React, { FC } from "react"
import { formItemStyle } from "@app/sections/Profile/helpers/style"
import dayjs from "dayjs"

import { ValidatedTextInput } from "@components/ValidatedInputs"

type FileFormProps = {
  userId: string
}

type FormFile = UploadFile<unknown>

type FormFields = {
  file: Array<FormFile>
  name: string
  dates: Array<string>
}

const FileForm: FC<FileFormProps> = ({ userId }) => {
  const [addCertificate] = certificatesApi.endpoints.createCertificate.useMutation()
  const { success, error } = useAlert()

  const [form] = Form.useForm<FormFields>()
  const name = Form.useWatch("name", form)
  const dates = Form.useWatch("dates", form)

  const onFinish = async ({ file, name, dates }: FormFields) => {
    const formData = new FormData()
    const formatedDates = dates.map(date => dayjs(date).format(generateDateTemplate("YYYYMMDD", Separators.Dash)))
    file && formData.append("data[document_attributes][file]", file[0].originFileObj!)
    formData.append("data[user_id]", userId)
    formData.append("data[name]", name)
    formData.append("data[started_at]", formatedDates[0])
    formData.append("data[ended_at]", formatedDates[1])

    try {
      await addCertificate(formData).unwrap()
      form.resetFields()
      success("Certificate was successfully created")
    } catch {
      error(defaultError)
    }
  }

  const getFile = (e: UploadChangeParam<FormFile>) => {
    if (Array.isArray(e)) {
      return e
    }
    return e ? e.fileList : []
  }

  return (
    <Form form={form} onFinish={onFinish} style={{ marginBottom: 10 }}>
      <Row gutter={8}>
        <Col span={2.5}>
          <Form.Item label="Training / Certificate name" name="name" style={formItemStyle} rules={[requiredRule]}>
            <ValidatedTextInput placeholder="Enter Training / Certificate name" data-testid="file-name-input" />
          </Form.Item>
        </Col>
        <Col span={2.5}>
          <Form.Item label="Add certificate" name="file" valuePropName="fileList" getValueFromEvent={getFile}>
            <Upload
              name="file"
              maxCount={1}
              accept=".pdf"
              beforeUpload={() => {
                return false
              }}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={2.5}>
          <Form.Item
            label="Training / Certificate dates"
            name="dates"
            style={formItemStyle}
            rules={[{ required: true, message: "Choose dates" }]}
          >
            <DatePicker.RangePicker data-testid="file-due-date-picker" />
          </Form.Item>
        </Col>
        <Col span={1}>
          <Button type="primary" htmlType="submit" disabled={!(dates?.length && name?.length)}>
            Add
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default FileForm
