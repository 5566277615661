import type { StoreEnhancer } from "@reduxjs/toolkit"
import * as Sentry from "@sentry/react"

if (module.hot) {
  module.hot.accept()
}

const ENV = process.env
const isUseSentry = (): boolean => {
  const dsn = ENV?.SENTRY_DSN
  const nodeEnv = ENV?.NODE_ENV

  return !!dsn && nodeEnv !== "development"
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

export const SentryConfig = {
  dsn: ENV?.SENTRY_DSN || "",
  inUse: isUseSentry(),
  sampleRate: 0,
  version: ENV?.SENTRY_RELEASE
}

export const configureReduxEnhancers = (enhancers: ReadonlyArray<StoreEnhancer>): Array<StoreEnhancer> => {
  return SentryConfig.inUse ? [sentryReduxEnhancer, ...enhancers] : [...enhancers]
}
