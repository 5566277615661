import React, { FC } from "react"
import { Subordinate } from "@interfaces"
import { Col, Row } from "antd"

import { SectionWrapper } from "@components/SectionWrapper"
import { Title } from "@components/Title"

import { SubordinateCard } from "./SubordinateCard"

const GUTTER = 16

type SubordinatesProps = {
  subordinates: Array<Subordinate>
}

export const Subordinates: FC<SubordinatesProps> = ({ subordinates }) => (
  <SectionWrapper>
    <Title>Subordinates</Title>
    <Row gutter={[GUTTER, GUTTER]}>
      {subordinates.map((subordinate, index) => (
        <Col sm={24} md={12} xl={8} key={subordinate.id}>
          <SubordinateCard subordinate={subordinate} dataTestId={`subordinate-${index}`} />
        </Col>
      ))}
    </Row>
  </SectionWrapper>
)
