import { Holiday } from "@interfaces"
import { Build } from "@state/services/types"
import { FetchHolidaysQuery } from "@state/types/vacations"

import { HOLIDAYS_URL } from "../urls"

export const fetchHolidays = (build: Build) =>
  build.query<{ data: Array<Holiday> }, FetchHolidaysQuery>({
    query: ({ locationId, active }) => ({
      url: HOLIDAYS_URL,
      params: {
        "filter[location_id]": locationId,
        "filter[active]": active
      }
    }),
    providesTags: result =>
      result && result.data.length ? result.data.map(holiday => ({ type: "Holiday", id: holiday.id })) : ["Holiday"]
  })
