import React, { FC } from "react"
import { Route, Routes } from "react-router"
import { RESET_PASSWORD_ROUTE } from "@routes/inboundRoutes"

import { BrandedAuthPage } from "./BrandedAuth/BrandedAuthPage"

export const NonauthenticatedRouter: FC = () => (
  <Routes>
    <Route key={RESET_PASSWORD_ROUTE} path={RESET_PASSWORD_ROUTE} element={<BrandedAuthPage isRestorePasswordPage />} />
    <Route key="defaultRoute" path="/*" element={<BrandedAuthPage />} />
  </Routes>
)
