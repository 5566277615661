import React, { FC, useMemo } from "react"
import { SelectProps } from "antd"

import { ValidatedSelect } from "@components/ValidatedInputs"

type TagsFieldProps = {
  tags: Array<string>
  value?: string
  onChange?: (value: string) => void
} & Omit<SelectProps, "value" | "onChange">

export const TagsField: FC<TagsFieldProps> = ({ tags, value, onChange }) => {
  const options = useMemo(() => tags.map(tag => ({ label: tag, value: tag })), [tags])

  return <ValidatedSelect value={value} options={options} onChange={onChange} mode="tags" data-testid="tags-select" />
}
