import backendApi from "@state/services/backendApi"

import { fetchGroups } from "./GET/fetchGroups"
import { createGroup } from "./POST/createGroup"
import { updateGroup } from "./PUT/updateGroup"
import { deleteGroup } from "./DELETE/deleteGroup"
import { changeUsersInGroups } from "./PATCH/changeUsersInGroups"
import { fetchLogs } from "./GET/fetchLogs"

export const groupsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchGroups: fetchGroups(build),
    createGroup: createGroup(build),
    updateGroup: updateGroup(build),
    deleteGroup: deleteGroup(build),
    changeUsersInGroups: changeUsersInGroups(build),
    fetchLogs: fetchLogs(build)
  })
})
