import { OrgChartResponse } from "@state/types/orgChart"
import { OrgChartPosition } from "@interfaces"
import { Build } from "@state/services/types"

import { ORGCHART_URL } from "../urls"
import { transformOrgChartResponse } from "../transformOrgChartResponse"

export const fetchTree = (build: Build) =>
  build.query<Array<OrgChartPosition>, string>({
    query: () => ({ url: ORGCHART_URL }),
    providesTags: ["OrgChart", "Department", "User"],
    transformResponse: (res: OrgChartResponse) => transformOrgChartResponse(res)
  })
