import { ALERT_CUSTOM_EVENT } from "./constants"

export const dispatchAlert = (type: "success" | "error" | "info" | "warning", message: string) => {
  document.dispatchEvent(
    new CustomEvent(ALERT_CUSTOM_EVENT, {
      detail: {
        type,
        message
      }
    })
  )
}
