import { Build } from "@state/services/types"
import { UpdateUserPhotoParams } from "@state/types/users"

import { generateUserPhotoModificationURL } from "../urls"
import { deriveFileFormData } from "../utils"

export const updateUserPhoto = (build: Build) =>
  build.mutation<unknown, UpdateUserPhotoParams>({
    query: ({ id, photo }) => ({
      url: generateUserPhotoModificationURL(id),
      method: "PUT",
      body: deriveFileFormData(photo)
    }),
    invalidatesTags: ["User"]
  })
