import React from "react"
import { Button, Popconfirm } from "antd"
import { useAlert } from "@hooks"
import { personalDocumentsApi } from "@state/services/subApis"

import style from "./style.m.scss"

type FooterProps = {
  userId: string
}

export const Footer: React.FC<FooterProps> = ({ userId }) => {
  const { error, success } = useAlert()
  const [deleteAllDocuments] = personalDocumentsApi.endpoints.deleteAllPersonalDocuments.useMutation()

  const handleConfirm = async () => {
    try {
      await deleteAllDocuments({ userId }).unwrap()
      success("All documents deleted successfully")
    } catch {
      error("Error deleting documents")
    }
  }

  return (
    <div className={style.footer}>
      <Popconfirm
        title="You are about to delete all files. Are you sure?"
        onConfirm={handleConfirm}
        placement="topLeft"
      >
        <Button type="link">Delete all</Button>
      </Popconfirm>
    </div>
  )
}
