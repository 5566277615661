import React, { FC } from "react"
import { Tooltip } from "antd"
import { DoubleRightOutlined, FlagOutlined } from "@ant-design/icons"
import cn from "classnames"

import style from "./style.m.scss"

type ChiefIconProps = {
  departmentName: string
  isSubordinateChief?: boolean
  className?: string
}

export const ChiefIcon: FC<ChiefIconProps> = ({ departmentName, isSubordinateChief, className }) => (
  <div className={cn(style.container, className)}>
    <Tooltip title={`Head of ${departmentName}`}>
      {isSubordinateChief ? <DoubleRightOutlined className={style.upIcon} /> : <FlagOutlined />}
    </Tooltip>
  </div>
)
