import { DoubleLeftOutlined } from "@ant-design/icons"
import { Button, Collapse, CollapseProps, Tag, Tooltip } from "antd"
import { AccessGroup } from "interfaces"
import React, { FC } from "react"
import { useAlert, useAppDispatch, useAppSelector } from "@hooks"
import { newGroupActions } from "@state/reducers/newGroup"
import { newPolicyActions } from "@state/reducers/newPolicy"
import { defaultError } from "@helpers"
import { formatedLongNames } from "@app/sections/PolicySystem/helpers/formatedLongNames"
import { groupsApi } from "@state/services/subApis"

import { convertPermissions } from "../../../helpers/convertPermissions"

import { DeleteButton } from "@components/buttons/DeleteButton"

import style from "./style.m.scss"

type GroupsCollapseProps = {
  groups: Array<AccessGroup>
}

const GroupsCollapse: FC<GroupsCollapseProps> = ({ groups }) => {
  const dispatch = useAppDispatch()
  const currentGroup = useAppSelector(state => state.newGroupReducer.currentGroup)
  const { success, error } = useAlert()

  const [deleteGroup] = groupsApi.endpoints.deleteGroup.useMutation()

  const options: CollapseProps["items"] = groups.map(group => {
    const groupPolicies = Object.entries(group.policies)
    const { extraPermissions } = group

    const nestedOptions = groupPolicies.map(([, policy]) => {
      return {
        key: policy.id,
        label: policy.name,
        children: policy.permissions.map(permission => (
          <Tag key={permission.name + permission.section}>{`${permission.section}-${permission.name}`}</Tag>
        ))
      }
    })

    if (extraPermissions.length) {
      nestedOptions.push({
        key: "extraPermissions",
        label: "Extra permissions",
        children: extraPermissions.map(({ name, section }) => <Tag key={name + section}>{`${section}-${name}`}</Tag>)
      })
    }

    const handleMoveGroupToUpdateColumn = (group: AccessGroup, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation()

      const extraPermissions = convertPermissions(group.extraPermissions)
      dispatch(newGroupActions.moveGroupToMiddllecolumn(group))
      dispatch(newPolicyActions.addPolicyPermission(extraPermissions))
    }

    const handleDeleteGroup = async (id: string) => {
      try {
        const res = await deleteGroup(id).unwrap()
        success(res.message)
        dispatch(newGroupActions.clearAll())
        dispatch(newPolicyActions.clearAllSections())
      } catch {
        error(defaultError)
      }
    }

    return {
      key: group.id,
      label: (
        <div>
          <Button
            type="text"
            size="small"
            shape="round"
            disabled={!!currentGroup.name}
            icon={<DoubleLeftOutlined />}
            onClick={event => handleMoveGroupToUpdateColumn(group, event)}
          />
          <span>{formatedLongNames(group.name)}</span>
          <Tooltip title={group.inUse ? "Used in group or user settings" : ""} placement="topLeft">
            <DeleteButton
              message={`You are about to delete ${formatedLongNames(group.name)}. Are you sure?`}
              onConfirm={() => handleDeleteGroup(group.id)}
              disabled={group.inUse}
              className={style.deleteButton}
            />
          </Tooltip>
        </div>
      ),
      collapsible: group.policies.length || group.extraPermissions.length ? "header" : "disabled",
      children: <Collapse bordered={false} size="small" items={nestedOptions} />
    }
  })

  return <Collapse className={style.groupsList} size="small" items={options} />
}

export default GroupsCollapse
