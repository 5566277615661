import React, { FC } from "react"
import { BulkStatuses } from "@enums"

import { Title } from "@components/Title"

import { AddNewBulkForm } from "./AddNewBulkForm"
import { BulksLogs } from "./BulksLogs"
import style from "./style.m.scss"

export const EducationalMaterialsBulkAdd: FC = () => (
  <>
    <Title level={3} className={style.header}>
      Educational materials
    </Title>
    <Title level={4} className={style.header}>
      Bulk add
    </Title>
    <AddNewBulkForm />
    <BulksLogs status={BulkStatuses.Active} />
    <BulksLogs status={BulkStatuses.History} />
  </>
)
