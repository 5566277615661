import { CalculateBalanceParams } from "@interfaces"
import { Build } from "@state/services/types"
import { CalculateBalanceResponse } from "@state/types/vacations"

import { generateCalculateBalanceURL } from "../urls"

export const calculateBalance = (build: Build) =>
  build.query<CalculateBalanceResponse, CalculateBalanceParams>({
    query: params => ({
      url: generateCalculateBalanceURL(params)
    })
  })
