import { Button, Col, DatePicker, Form, Row } from "antd"
import React, { FC, useState } from "react"
import { useAlert, useAppSelector } from "@hooks"
import { CreateConferenceBody } from "@interfaces*"
import dayjs, { Dayjs } from "dayjs"
import { Separators, defaultError, generateDateTemplate } from "@helpers"
import { useParams } from "react-router-dom"
import { useConferencesAbilities } from "hooks/query/useAbilities"
import { conferencesApi } from "@state/services/subApis"

import { ValidatedTextInput } from "@components/ValidatedInputs"

import style from "./style.m.scss"

type ConferenceFormData = {
  name: string
  link: string
  dates: [Dayjs, Dayjs]
}

const initialFormValues = {
  name: "",
  link: ""
}

const AddConference: FC = () => {
  const currentUserId = useAppSelector(state => state.meReducer.user?.id)
  const { userId } = useParams()
  const { edit_all } = useConferencesAbilities()

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const [form] = Form.useForm()
  const { success, error } = useAlert()
  const [createLocation] = conferencesApi.endpoints.createConference.useMutation()

  const checkRequiredFields = () => {
    const name = form.getFieldValue("name")
    const dates = form.getFieldValue("dates")

    setIsSubmitDisabled(!(name && dates))
  }

  const handleSubmit = async (formData: ConferenceFormData) => {
    const formatedDates = formData.dates.map(date =>
      dayjs(date).format(generateDateTemplate("YYYYMMDD", Separators.Dash))
    )

    const bodyReq: CreateConferenceBody = {
      data: {
        type: "conferences" as const,
        attributes: {
          user_id: userId ? Number(userId) : Number(currentUserId),
          started_at: formatedDates[0],
          finished_at: formatedDates[1],
          name: formData.name,
          url: formData.link
        }
      },
      format: ":jsonapi" as const
    }

    try {
      await createLocation(bodyReq).unwrap()
      form.resetFields()
      success("Conference was succesfully created")
      setIsSubmitDisabled(true)
    } catch {
      error(defaultError)
    }
  }

  return (
    <Form
      form={form}
      className={style.form}
      initialValues={initialFormValues}
      onFinish={handleSubmit}
      onFieldsChange={checkRequiredFields}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            name="name"
            label="Conference name"
            required
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "can't be blank" }]}
          >
            <ValidatedTextInput placeholder="Enter a conference name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="link" label="Link to the conference" labelCol={{ span: 24 }}>
            <ValidatedTextInput placeholder="Add link to the conference" />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name="dates"
            label="Conference dates"
            required
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: "can't be blank" }]}
          >
            <DatePicker.RangePicker />
          </Form.Item>
        </Col>
        <Col span={2} className={style.buttonWrapper}>
          <Button type="primary" htmlType="submit" disabled={isSubmitDisabled || !edit_all}>
            Add
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default AddConference
