import React, { FC } from "react"
import { Typography } from "antd"
import { useAppSelector } from "@hooks"

import { EducationalMaterialsTable } from "./EducationalMaterialsTable"
import style from "./style.m.scss"

export const StaffEducationalMaterials: FC = () => {
  const userId = useAppSelector(state => state.meReducer.user?.id)

  if (!userId) return null

  return (
    <>
      <Typography.Title level={4} className={style.header}>
        Educational materials
      </Typography.Title>
      <Typography.Title level={5} className={style.header}>
        My educational materials
      </Typography.Title>
      <EducationalMaterialsTable userId={userId} />
    </>
  )
}
