import { Certificate } from "@interfaces"
import { BackendCertificatesResponse } from "@state/types/certificates"
import { Build } from "@state/services/types"

import { generateGetCertificatesURL } from "../urls"
import { transformCertificates } from "../transformer"

export const fetchCertificates = (build: Build) =>
  build.query<Array<Certificate>, string>({
    query: id => ({
      url: generateGetCertificatesURL(id)
    }),
    transformResponse: (res: BackendCertificatesResponse) => transformCertificates(res),
    providesTags: ["Certificate"]
  })
