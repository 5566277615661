import { Build } from "@state/services/types"
import { CreateVacationAccumulationBody } from "interfaces/vacations"

import { VACATION_ACCUMULATIONS_URL } from "../urls"

export const createVacationAccumulation = (build: Build) =>
  build.mutation<{ status: string }, CreateVacationAccumulationBody>({
    query: body => ({
      url: VACATION_ACCUMULATIONS_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["VacationAccumulation", "Balance"]
  })
