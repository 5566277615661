import * as Sentry from "@sentry/browser"
import { batch } from "react-redux"
import { useAlert, useAppDispatch, useAppSelector } from "@hooks"
import {
  AuthErrorResponse,
  LoginParams,
  RequestPasswordResetQuery,
  ResetPasswordParams,
  TwoFaParams
} from "@interfaces"
import { meActions } from "@state/reducers/me"
import { myAbilitiesActions } from "@state/reducers/myAbilities"
import { abilitiesTransformer, authenticationApi } from "@state/services/subApis"
import { authActions } from "@state/reducers/auth"
import { SentryConfig } from "sentry/utils"

import { ERROR__LOGIN } from "./constants"

const PASSWORD_CHANGE_ERROR = "Error changing password"

const useLogin = () => {
  const dispatch = useAppDispatch()
  const { error, success } = useAlert()
  const [login] = authenticationApi.endpoints.login.useMutation()
  const [resetPasswordRequest] = authenticationApi.endpoints.resetPasswordRequest.useMutation()
  const [resetPassword] = authenticationApi.endpoints.resetPassword.useMutation()
  const { otpRawSecret, otpSecret } = useAppSelector(state => state.authReducer.otp)
  const [logout] = authenticationApi.endpoints.logout.useMutation()
  const [setup2fa] = authenticationApi.endpoints.setup2fa.useMutation()
  const [auth2fa] = authenticationApi.endpoints.auth2fa.useMutation()

  const processError = (err: unknown) => {
    const { data } = err as AuthErrorResponse
    const errorDescription = data?.["field-error"]?.[1]

    error(errorDescription ?? data?.error ?? ERROR__LOGIN)
  }

  const handleLogin = async (data: LoginParams) => {
    try {
      const loginResponse = await login(data).unwrap()

      const abilities = loginResponse?.data?.attributes?.abilities ?? []

      const transformedAbilities = abilitiesTransformer(abilities)

      if (SentryConfig.inUse)
        Sentry.setUser({
          id: loginResponse.data.id,
          email: loginResponse.data.attributes.email
        })

      batch(() => {
        dispatch(
          authActions.setOtpFlags({
            otpIsRequired: loginResponse.data.otp_is_required,
            otpIsSet: loginResponse.data.otp_is_set
          })
        )
        dispatch(meActions.setMe({ user: loginResponse.data }))
        dispatch(myAbilitiesActions.setMyAbilities({ abilities: transformedAbilities }))
      })

      return { otpIsRequired: loginResponse.data.otp_is_required, otpIsSet: loginResponse.data.otp_is_set }
    } catch (err) {
      processError(err)
      return undefined
    }
  }

  const handleLogout = async () => {
    try {
      await logout().unwrap()
      return true
    } catch (err) {
      processError(err)
      return false
    }
  }

  const handleSetup2fa = async (data: TwoFaParams) => {
    try {
      await setup2fa({ ...data, otp_raw_secret: otpRawSecret, otp_secret: otpSecret }).unwrap()
      return true
    } catch (err) {
      processError(err)
      return false
    }
  }

  const handleAuth2fa = async (data: TwoFaParams) => {
    try {
      await auth2fa(data).unwrap()
      return true
    } catch (err) {
      processError(err)
      return false
    }
  }

  const handleRequestPasswordRequest = async (data: RequestPasswordResetQuery) => {
    try {
      await resetPasswordRequest(data).unwrap()

      return true
    } catch (err) {
      processError(err)
      return false
    }
  }

  const handleResetPassword = async (data: ResetPasswordParams) => {
    try {
      await resetPassword(data).unwrap()
      success("Password changed successfully")

      return { isError: false }
    } catch (err) {
      const { data } = err as AuthErrorResponse
      error(data?.error ?? PASSWORD_CHANGE_ERROR)

      return { isError: true, fieldError: data["field-error"] }
    }
  }

  return {
    onLogin: handleLogin,
    onRequestPasswordRequest: handleRequestPasswordRequest,
    onResetPassword: handleResetPassword,
    onSetup2fa: handleSetup2fa,
    onAuth2fa: handleAuth2fa,
    onLogout: handleLogout
  }
}

export default useLogin
