import { ChangeVacationSettingsResponse, CreateVacationSettingsRequest } from "@state/types/vacations"
import { Build } from "@state/services/types"

import { VACATIONS_SETTINGS_URL } from "../urls"

export const createVacationSettings = (build: Build) =>
  build.mutation<ChangeVacationSettingsResponse, CreateVacationSettingsRequest>({
    query: arg => ({
      url: VACATIONS_SETTINGS_URL,
      method: "POST",
      body: arg
    }),
    invalidatesTags: ["VacationSettings"]
  })
