import React, { FC } from "react"
import { Flex } from "antd"

import { MaterialsTable } from "../components/MaterialsTable"

import { EditButton } from "@components/buttons/EditButton"
import { Title } from "@components/Title"

import style from "./style.m.scss"

type ViewPanelProps = {
  userId: string
  onEdit?: () => void
}

export const ViewPanel: FC<ViewPanelProps> = ({ userId, onEdit }) => (
  <>
    <Title>
      <Flex align="center" justify="space-between" className={style.header}>
        Educational materials
        {onEdit && <EditButton onClick={onEdit} data-testid="educational-materials-edit-button" />}
      </Flex>
    </Title>
    <MaterialsTable userId={userId} />
  </>
)
