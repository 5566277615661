import { Certificate } from "@interfaces*"
import { BackendCertificatesResponse } from "@state/types/certificates"

export const transformCertificates = (res: BackendCertificatesResponse): Array<Certificate> =>
  res.data.map(cert => {
    const { id: certificateId, attributes: certificateAttributes } = cert
    const certAttributes = {
      certificateId,
      userId: String(certificateAttributes.user_id),
      createdAt: certificateAttributes.created_at,
      startedAt: certificateAttributes.started_at,
      endedAt: certificateAttributes.ended_at,
      name: certificateAttributes.name
    }

    if (cert.relationships.document.data) {
      const document = res.included.find(doc => doc.id === cert.relationships.document.data.id)!

      const { id: documentId, attributes: documentAttributes } = document

      return {
        ...certAttributes,
        documentId,
        fileName: documentAttributes.filename,
        fileKey: documentAttributes.key,
        fileSize: documentAttributes.size
      }
    }

    return {
      ...certAttributes
    }
  })
