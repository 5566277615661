import { Button, Col, Flex, Form, Popconfirm, Row } from "antd"
import React, { FC, useState } from "react"
import { vacationsSettingsApi } from "@state/services/subApis"
import { capitalizeFirstLetter, defaultError, productOptions } from "@helpers"
import { useForm } from "antd/es/form/Form"
import { VacationsNotificationsSettings } from "@interfaces*"
import { useAlert } from "@hooks"

import { Title } from "@components/Title"
import { ValidatedSelect, ValidatedTextInput } from "@components/ValidatedInputs"

import style from "./style.m.scss"

export const Notifications: FC = () => {
  const [form] = useForm()
  const { error, success } = useAlert()

  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const [updateSettings] = vacationsSettingsApi.endpoints.changeVacationNotificationsSettings.useMutation()

  const { data: vacationsNotificationsSettings } =
    vacationsSettingsApi.endpoints.fetchVacationsNotificationSettings.useQuery("")

  const handleSubmit = async (formData: VacationsNotificationsSettings) => {
    const bodyReq = {
      name: "Vacation notifications" as const,
      parameters: {
        upcoming_leaves: {
          products: formData
        }
      }
    }

    try {
      await updateSettings(bodyReq)
      success("Vacation notifications settings was succesfully updated")
    } catch {
      error(defaultError)
    }
  }

  const onConfirm = () => {
    form.submit()
  }

  const deriveIsDisabled = () => {
    const isErrors = form.getFieldsError().some(field => !!field.errors.length)
    setIsSaveDisabled(!form.isFieldsTouched() || isErrors)
  }

  return (
    <Flex vertical justify="center">
      <Title level={4} className={style.header}>
        Slack notifications
        <Popconfirm
          onConfirm={onConfirm}
          title="Are you about to change vacation notifications settings. Are you sure?"
        >
          <Button type="primary" disabled={isSaveDisabled}>
            Save
          </Button>
        </Popconfirm>
      </Title>
      {vacationsNotificationsSettings && (
        <Form
          className={style.form}
          initialValues={vacationsNotificationsSettings}
          form={form}
          onFinish={handleSubmit}
          onFieldsChange={deriveIsDisabled}
        >
          {Object.keys(vacationsNotificationsSettings).map(product => (
            <React.Fragment key={product}>
              <Flex vertical>
                <Title level={5}>{capitalizeFirstLetter(product)}</Title>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      name={[product, "workspace"]}
                      label="Workspace"
                      rules={[{ required: true, message: "Can't be blank" }]}
                    >
                      <ValidatedSelect datatest-id="vacation-workspace-select" options={productOptions} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[product, "channel"]}
                      label="Slack id/channel"
                      rules={[{ required: true, message: "Can't be blank" }]}
                    >
                      <ValidatedTextInput datatest-id="vacation-channel-input" />
                    </Form.Item>
                  </Col>
                </Row>
              </Flex>
            </React.Fragment>
          ))}
        </Form>
      )}
    </Flex>
  )
}
