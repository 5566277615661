import React, { FC } from "react"
import { Flex } from "antd"

import { MaterialsTable } from "../components/MaterialsTable"

import { SaveButton } from "@components/buttons/SaveButton"
import { Title } from "@components/Title"

import { LinkForm } from "./LinkForm"
import { FileForm } from "./FileForm"
import style from "./style.m.scss"

type EditPanelProps = {
  userId: string
  onSave: () => void
}

export const EditPanel: FC<EditPanelProps> = ({ userId, onSave }) => (
  <>
    <Title>
      <Flex align="center" justify="space-between" className={style.header}>
        Educational materials <SaveButton onClick={onSave} data-testid="educational-materials-save-button" />
      </Flex>
    </Title>
    <LinkForm userId={userId} />
    <FileForm userId={userId} />
    <MaterialsTable userId={userId} isEditMode />
  </>
)
