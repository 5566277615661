import { Build } from "@state/services/types"
import { BackendLeaveTypesReponse } from "@state/types/vacations"
import { LeaveType } from "@interfaces"

import { VACATIONS_LEAVE_TYPES_URL } from "../urls"
import { transformLeaveTypes } from "../transformer"

export const fetchLeaveTypes = (build: Build) =>
  build.query<Array<LeaveType>, string>({
    query: () => ({
      url: VACATIONS_LEAVE_TYPES_URL
    }),
    transformResponse: (res: BackendLeaveTypesReponse) => transformLeaveTypes(res),
    providesTags: ["LeaveType", "Vacation"]
  })
