import { SlackUser } from "@interfaces"
import {
  BackendSlackGroupResponse,
  BackendSlackGroupResponseIncluded,
  BackendSlackGroupsResponse,
  BackendSlackGroupMembership,
  BackendWorkspaceMembership,
  BackendWorkspace,
  BackendWorkspaceAttributes
} from "@state/types/slackGroups"

export const slackGroupsTransformer = (responseData: BackendSlackGroupsResponse) =>
  responseData.data.map(item => ({
    id: item.id,
    name: item.attributes.name,
    description: item.attributes.description,
    isActive: !item.attributes.closed_at,
    memberCount: item.relationships.members.data.length
  }))

const isMembership = (item: BackendSlackGroupResponseIncluded): item is BackendWorkspaceMembership =>
  item.type === "workspace_membership"

const isWorkspace = (item: BackendSlackGroupResponseIncluded): item is BackendWorkspace => item.type === "workspace"

export const slackGroupTransformer = (responseData: BackendSlackGroupResponse) => {
  const { data, included = [] } = responseData

  const membershipMapping = included
    .filter(isMembership)
    .reduce<Record<string, BackendWorkspaceMembership>>((acc, item) => ({ ...acc, [item.id]: item }), {})

  const workspacesMapping = included
    .filter(isWorkspace)
    .reduce<Record<string, BackendWorkspaceAttributes>>((acc, item) => ({ ...acc, [item.id]: item.attributes }), {})

  const deriveMembers = (memberships: Array<BackendSlackGroupMembership>) => {
    const members: Array<SlackUser> = []

    memberships.forEach(backendMembership => {
      const includedItem = membershipMapping[backendMembership.id]
      if (!includedItem) return

      members.push({
        id: backendMembership.id,
        email: includedItem.attributes.email,
        fullName: includedItem.attributes.full_name,
        photoUrl: includedItem.attributes.photo_url,
        permissions: includedItem.attributes.permissions,
        workspace: workspacesMapping[includedItem.relationships.workspace.data.id]?.name || ""
      })
    })

    return members
  }

  return {
    id: data.id,
    name: data.attributes.name,
    description: data.attributes.description,
    isActive: !data.attributes.closed_at,
    members: deriveMembers(data.relationships.members.data)
  }
}
