import React, { FC } from "react"
import { Button, DatePicker, Form } from "antd"
import { useAlert } from "@hooks"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { Dayjs } from "dayjs"
import { educationalMaterialsApi } from "@state/services/subApis"

import { requiredRule, urlRule } from "../../../../helpers/validation"
import { deriveErrorMessages } from "../helpers/errors"
import { formItemStyle } from "../helpers/style"
import style from "../style.m.scss"

import { ValidatedTextInput } from "@components/ValidatedInputs/ValidatedTextInput"

type FormFields = {
  link: string
  name: string
  dueDate?: Dayjs
}

type LinkFormProps = {
  userId: string
}

export const LinkForm: FC<LinkFormProps> = ({ userId }) => {
  const [addMaterial, { isLoading }] = educationalMaterialsApi.endpoints.createEducationalMaterial.useMutation()

  const { error } = useAlert()

  const [form] = Form.useForm<FormFields>()

  const name = Form.useWatch("name", form)
  const link = Form.useWatch("link", form)

  const handleFormSubmit = async ({ name, link, dueDate }: FormFields) => {
    try {
      await addMaterial({
        type: "link",
        name,
        userId,
        link,
        dueAt: dueDate ? dueDate.format("YYYY-MM-DD") : undefined
      }).unwrap()

      form.resetFields()
    } catch (e) {
      const errors = deriveErrorMessages(e as FetchBaseQueryError)
      errors.forEach(errorMsg => error(errorMsg))
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFormSubmit}
      initialValues={{ link: "", name: "", dueDate: undefined }}
      className={style.form}
    >
      <Form.Item
        label="Add link to educational materials"
        name="link"
        style={formItemStyle}
        rules={[requiredRule, urlRule]}
      >
        <ValidatedTextInput placeholder="Url" data-testid="link-input" />
      </Form.Item>
      <Form.Item label="Name" name="name" style={formItemStyle} rules={[requiredRule]}>
        <ValidatedTextInput placeholder="Name" data-testid="link-name-input" />
      </Form.Item>
      <Form.Item label="Due date" name="dueDate" style={formItemStyle}>
        <DatePicker data-testid="link-due-date-picker" />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
        disabled={!name || !link}
        data-testid="add-link-button"
        className={style.addButton}
      >
        Add
      </Button>
    </Form>
  )
}
