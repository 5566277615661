import backendApi from "../../backendApi"

import { fetchMe } from "./GET/fetchMe"
import { changePassword } from "./POST/changePassword"
import { login } from "./POST/login"
import { logout } from "./POST/logout"
import { resetPassword } from "./POST/resetPassword"
import { switchUser } from "./POST/switchUser"
import { resetPasswordRequest } from "./POST/resetPasswordRequest"
import { fetchQrForOTP } from "./GET/fetchQrForOTP"
import { auth2fa } from "./POST/auth2fa"
import { setup2fa } from "./POST/setup2fa"

export const authenticationApi = backendApi.injectEndpoints({
  endpoints: build => ({
    login: login(build),
    logout: logout(build),
    fetchMe: fetchMe(build),
    fetchQrForOTP: fetchQrForOTP(build),
    resetPassword: resetPassword(build),
    changePassword: changePassword(build),
    switchUser: switchUser(build),
    resetPasswordRequest: resetPasswordRequest(build),
    auth2fa: auth2fa(build),
    setup2fa: setup2fa(build)
  })
})
