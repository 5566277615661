import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Button, Col, Form, FormListFieldData, Row, Space } from "antd"
import React, { FC, useState } from "react"
import { SearchingUser } from "@interfaces*"
import { usersApi } from "@state/services/subApis"
import { formatLongNames } from "@helpers"

import { DebounceSelect } from "@components/DebounceSelect"

import style from "./style.m.scss"

type EmployeeFormListItemProps = {
  employeeField: FormListFieldData
  onAddItem: () => void
  onRemoveItem: (index: number) => void
  isLastItem: boolean
  derivePickedUsers: () => Array<string>
}

const MAX_OPTION_LABEL_LENGTH = 30

export const EmployeeFormListItem: FC<EmployeeFormListItemProps> = ({
  employeeField,
  isLastItem,
  onAddItem,
  onRemoveItem,
  derivePickedUsers
}) => {
  const [pickedUserId, setPickedUserId] = useState<string | null>(null)

  const [fetchUserOptions, { isFetching: isUsersFetching }] = usersApi.endpoints.searchUsers.useLazyQuery()

  const usersOptionsFetcher = (searchValue: string) =>
    fetchUserOptions({
      type: "name",
      value: searchValue,
      exclude: derivePickedUsers()
    })

  const convertUserOptions = (users: Array<SearchingUser>) =>
    users
      ? users.map(user => ({
          label: formatLongNames(`${user.email} - ${user.fullName}`, MAX_OPTION_LABEL_LENGTH),
          value: user.id
        }))
      : []

  const handleChangeUser = (userId: string | null) => {
    setPickedUserId(userId)
  }

  const handleAdd = () => {
    onAddItem()
  }

  const handleRemove = () => {
    onRemoveItem(employeeField.name)
  }

  return (
    <Row gutter={8} key={employeeField.key} className={style.employeeRow}>
      <Col span={8}>
        <Form.Item
          label="Applied to employee"
          name={[employeeField.name, "userId"]}
          style={{ marginBottom: 0, marginLeft: "10px", width: "370px" }}
        >
          <DebounceSelect
            value={pickedUserId}
            optionsFetcher={usersOptionsFetcher}
            onChange={handleChangeUser}
            isFetching={isUsersFetching}
            convertOptions={convertUserOptions}
            dataTestId="search-user-for-new-bulk"
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Space style={{ marginLeft: "20px" }}>
          {isLastItem && <Button icon={<PlusCircleOutlined />} onClick={handleAdd} />}
          <Button icon={<DeleteOutlined />} onClick={handleRemove} />
        </Space>
      </Col>
    </Row>
  )
}
