import React, { FC, useState } from "react"

import { ImageViewer } from "./ImageViewer"
import { UploadButton } from "./UploadButton"

type LogoFieldProps = {
  value?: string
  onChange?: (value: File) => void
}

export const LogoField: FC<LogoFieldProps> = ({ value, onChange }) => {
  const [imageSrc, setImageScr] = useState(value)

  const handlePhotoChange = (file: File) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        setImageScr(reader.result)
      }
    }
    reader.readAsDataURL(file)
    onChange?.(file)
  }

  return (
    <div>
      <ImageViewer src={imageSrc} />
      <UploadButton onChange={handlePhotoChange} />
    </div>
  )
}
