import { Build } from "@state/services/types"
import { ChangeUsersInGroupBody } from "@interfaces"

import { MANAGE_ACCESS_GROUPS_URL } from "../urls"

export const changeUsersInGroups = (build: Build) =>
  build.mutation<unknown, ChangeUsersInGroupBody>({
    query: body => ({
      url: MANAGE_ACCESS_GROUPS_URL,
      method: "PATCH",
      body
    }),
    invalidatesTags: ["AccessGroup"]
  })
