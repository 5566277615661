import { UserForFilter, BackendUsersFromSearch } from "@interfaces"
import { Build } from "@state/services/types"
import { generateFiltersRoute } from "@helpers"

import { USERS_URL } from "../urls"
import { transformUsersWithoutPosition } from "../transformer"

export const fetchUsersWithoutPosition = (build: Build) =>
  build.query<Array<UserForFilter>, { [k: string]: string }>({
    query: filters => ({
      url: generateFiltersRoute(filters, `${USERS_URL}/search`)
    }),
    providesTags: res =>
      res ? [...res.map(option => ({ type: "Department" as const, id: option.id }))] : ["Department"],
    transformResponse: (data: BackendUsersFromSearch) => transformUsersWithoutPosition(data)
  })
