import { PerformanceReview } from "@interfaces*"
import { PerformanceReviewResponse } from "@state/types/performanceReview"
import { Build } from "@state/services/types"

import { generatePerformanceReviewIndexURL } from "../urls"
import { transformPerformanceReviewResults } from "../transformer"

export const fetchPerformanceReview = (build: Build) =>
  build.query<Array<PerformanceReview>, string>({
    query: id => ({
      url: generatePerformanceReviewIndexURL(id)
    }),
    providesTags: ["PerformanceReview"],
    transformResponse: (res: PerformanceReviewResponse) => transformPerformanceReviewResults(res)
  })
