import { VacationFormFieldName } from "@enums"
import { VacationFormValues } from "@interfaces"
import { RuleObject } from "antd/es/form"

import { validationErrors } from "../validationErrors"

const MAX_REASON_LENGTH = 300

export const vacationRules: { [name in VacationFormFieldName]: Array<RuleObject> } = {
  reason: [{ max: MAX_REASON_LENGTH, message: validationErrors.VALID_REASON_ERROR }],
  legend: [{ required: true, message: validationErrors.REQUIRED_ERROR }],
  range: [
    {
      required: true,
      validator(_: RuleObject, values: VacationFormValues[VacationFormFieldName.Range]) {
        if (!values) return Promise.reject(new Error(validationErrors.REQUIRED_ERROR))
        return Promise.resolve()
      }
    }
  ],
  date: [
    {
      required: true,
      validator(_: RuleObject, value: VacationFormValues[VacationFormFieldName.Date]) {
        if (!value) return Promise.reject(new Error(validationErrors.REQUIRED_ERROR))
        return Promise.resolve()
      }
    }
  ]
}
