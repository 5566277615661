import { UserFullInfoWithAccesses } from "@interfaces"
import dayjs from "dayjs"

import { UserFormFields } from "../types"

export const deriveUpdateProfileData = (fields: UserFormFields) => {
  const updateUserBody = {
    attributes: {
      first_name: fields.firstName,
      second_name: fields.secondName,
      last_name: fields.lastName,
      birth_date: fields.birthDate ? fields.birthDate.format("YYYY-MM-DD") : null,
      email: fields.email,
      phone_number: fields.phone || null,
      additional_emails: fields.additionalEmails.filter(Boolean),
      grade: fields.grade,
      gender: fields.gender,
      employment_status_id: fields.employmentStatus,
      work_format_id: fields.workFormat,
      work_schedule_id: fields.workSchedule ? fields.workSchedule : null,
      main_product: fields.mainProduct,
      additional_products: fields.additionalProducts,
      tags: fields.tags.map(tag => ({ name: tag })),
      available_system_ids: fields.availableSystems,
      employee_type: fields.employeeType
    },
    photo: fields.photoFile,
    job: { department: fields.department, position: fields.position }
  }

  return { updateUserBody, hireDate: fields.hireDate.format("YYYY-MM-DD") }
}

export const deriveCreateProfileData = (fields: UserFormFields) => ({
  attributes: {
    first_name: fields.firstName,
    second_name: fields.secondName,
    last_name: fields.lastName,
    birth_date: fields.birthDate ? fields.birthDate.format("YYYY-MM-DD") : null,
    work_begin_date: fields.hireDate.format("YYYY-MM-DD"),
    location_id: fields.locationId.toString(),
    email: fields.email,
    phone_number: fields.phone || null,
    additional_emails: fields.additionalEmails.filter(Boolean),
    grade: fields.grade,
    employment_status_id: fields.employmentStatus,
    work_format_id: fields.workFormat,
    work_schedule_id: fields.workSchedule ? fields.workSchedule : null,
    main_product: fields.mainProduct,
    additional_products: fields.additionalProducts,
    tags: fields.tags?.map(tag => ({ name: tag })),
    position_id: fields.position
  },
  photo: fields.photoFile
})

export const deriveProfileFormValues = (user: UserFullInfoWithAccesses) => ({
  firstName: user.firstName,
  secondName: user.secondName,
  lastName: user.lastName,
  birthDate: user.birthDate ? dayjs(user.birthDate) : undefined,
  hireDate: user.hireDate ? dayjs(user.hireDate) : undefined,
  locationId: user.locationId ? Number.parseInt(user.locationId, 10) : null,
  email: user.corporateEmail,
  additionalEmails: user.additionalEmails || [],
  phone: user.phone,
  jobTitle: user.position,
  grade: user.grade,
  gender: user.gender,
  employmentStatus: user.employmentStatus?.id || null,
  workFormat: user.workFormat?.id || null,
  workSchedule: user.workSchedule?.id || null,
  mainProduct: user.mainProduct,
  additionalProducts: user.additionalProducts || [],
  photo: undefined,
  tags: user.tags || [],
  department: user.department?.id ?? null,
  position: user.position?.id ?? null,
  availableSystems: user.availableSystems?.map(item => item.id),
  employeeType: user.employeeType
})
