import { CreateSettingRequestBody } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendCreateOrUpdateSettingResponse, EmploymentSettingErrorResponse } from "@state/types/employmentSettings"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"

import { EMPLOYMENT_SETTINGS_URL } from "../urls"

export const createEmploymentSetting = (build: Build) =>
  build.mutation<BackendCreateOrUpdateSettingResponse, CreateSettingRequestBody>({
    query: body => ({
      url: EMPLOYMENT_SETTINGS_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["EmploymentSettings"],
    transformErrorResponse: (errorResponse: FetchBaseQueryError) => {
      const res = errorResponse.data as EmploymentSettingErrorResponse
      return res.errors.map(err => err.meta.message).join(" ,")
    }
  })
