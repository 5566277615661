import React from "react"
import { ConfigProvider, theme } from "antd"

export const Theme: React.FC = ({ children }) => {
  const { token } = theme.useToken()

  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            colorTextDisabled: token.colorText
          }
        }
      }}
    >
      {children}
    </ConfigProvider>
  )
}
