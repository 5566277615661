import { Build } from "@state/services/types"

import { generateAccessGroupURL } from "../urls"

export const deleteGroup = (build: Build) =>
  build.mutation<{ message: string }, string>({
    query: id => ({
      url: generateAccessGroupURL(id),
      method: "DELETE"
    }),
    invalidatesTags: ["AccessGroup"]
  })
