import { DepartmentCard } from "@interfaces"
import { BackendDepartmentCardResponse } from "@state/types/departments"
import { Build } from "@state/services/types"

import { generateDepartmentURL } from "../urls"
import { transformDepartment } from "../transformDepartments"

export const fetchDepartmentById = (build: Build) =>
  build.query<DepartmentCard, string>({
    query: deptId => ({
      url: generateDepartmentURL(deptId)
    }),
    providesTags: res => (res ? [{ type: "Department" as const, id: res?.id }] : ["Department"]),
    transformResponse: (response: BackendDepartmentCardResponse) => transformDepartment(response)
  })
