import React, { FC } from "react"

import { Card } from "@components/dashboardCards"

import style from "./style.m.scss"

type AvailableSystemCardProps = {
  link: string
  name?: string
  logo?: string
}

export const AvailableSystemCard: FC<AvailableSystemCardProps> = ({ link, logo, name }) => (
  <Card className={style.card}>
    <a href={link} className={style.link} target="_blank" rel="noreferrer">
      <div className={style.content}>
        {logo && <img src={logo} alt="" className={style.logo} />}
        {name && <div className={style.name}>{name}</div>}
      </div>
    </a>
  </Card>
)
