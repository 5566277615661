import React, { FC } from "react"
import { Button, Form, Upload, UploadFile } from "antd"
import { UploadChangeParam } from "antd/lib/upload"
import { useAlert } from "@hooks"
import { UploadOutlined } from "@ant-design/icons"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { personalDocumentsApi } from "@state/services/subApis"

import { checkFilesSize, requiredRule } from "../../../../helpers/validation"
import { deriveErrorMessages } from "../helpers/errors"
import { formItemStyle } from "../helpers/style"

import style from "./style.m.scss"

type FormFile = UploadFile<any>

type FormFields = {
  file: Array<FormFile>
}

type FileFormProps = {
  userId: string
}

export const FileForm: FC<FileFormProps> = ({ userId }) => {
  const [addPersonalDocument, { isLoading }] = personalDocumentsApi.endpoints.createPersonalDocument.useMutation()
  const { error } = useAlert()

  const [form] = Form.useForm<FormFields>()

  const file = Form.useWatch("file", form)

  const handleFormSubmit = async ({ file }: FormFields) => {
    try {
      await addPersonalDocument({
        userId,
        file: file[0].originFileObj!
      }).unwrap()

      form.resetFields()
    } catch (e) {
      const errors = deriveErrorMessages(e as FetchBaseQueryError)
      errors.forEach(errorMsg => error(errorMsg))
    }
  }

  const getFile = (e: UploadChangeParam<FormFile>) => {
    if (Array.isArray(e)) {
      return e
    }
    return e ? e.fileList : []
  }

  const renderUploadedFileItem = (originNode: React.ReactElement) => {
    const uploadFileError = form.getFieldError("file")
    return uploadFileError.length ? <></> : originNode
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFormSubmit}
      initialValues={{ link: [], name: "", dueDate: undefined }}
      className={style.form}
    >
      <Form.Item
        name="file"
        style={formItemStyle}
        getValueFromEvent={getFile}
        valuePropName="fileList"
        rules={[requiredRule, { validator: checkFilesSize }]}
      >
        <Upload
          name="file"
          maxCount={1}
          itemRender={renderUploadedFileItem}
          accept=".pdf"
          beforeUpload={() => {
            return false
          }}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
      <Button
        className={style.submitButton}
        type="primary"
        loading={isLoading}
        htmlType="submit"
        disabled={!file?.length}
        data-testid="pd-add-file-button"
      >
        Add
      </Button>
    </Form>
  )
}
