import React, { FC } from "react"
import { Form } from "antd"
import { usersApi } from "@state/services/subApis"

import { TagsField } from "../../TagsField"

import { SaveButton } from "@components/buttons/SaveButton"
import { CancelButton } from "@components/buttons/CancelButton"

import style from "./style.m.scss"

type FormFields = { tags: Array<string> }

type PhoneFormProps = {
  value: Array<string>
  onSave: (value: Array<string>) => void
  onCancel: () => void
}

export const TagsForm: FC<PhoneFormProps> = ({ value, onSave, onCancel }) => {
  const { data } = usersApi.endpoints.fetchUserEditOptions.useQuery()
  const [form] = Form.useForm<FormFields>()

  const handleFormSubmit = (formFields: FormFields) => {
    onSave(formFields.tags)
  }

  return (
    <Form form={form} onFinish={handleFormSubmit} initialValues={{ tags: value }} className={style.form}>
      <Form.Item name="tags" className={style.item}>
        <TagsField tags={data?.tags || []} />
      </Form.Item>
      <div>
        <SaveButton htmlType="submit" data-testid="save-tags" />
        <CancelButton onClick={onCancel} data-testid="cancel-edit-tags" />
      </div>
    </Form>
  )
}
