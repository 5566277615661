import { Location } from "@interfaces"
import { Build } from "@state/services/types"

import { LOCATIONS_URL } from "../urls"

export const fetchLocations = (build: Build) =>
  build.query<{ data: Array<Location> }, null>({
    query: () => ({
      url: LOCATIONS_URL
    }),
    providesTags: result =>
      result && result.data.length ? result.data.map(location => ({ type: "Location", id: location.id })) : ["Location"]
  })
