import { Build } from "@state/services/types"
import { CreateAccessGroupBody } from "@interfaces"

import { GROUPS_URL } from "../urls"

export const createGroup = (build: Build) =>
  build.mutation<unknown, CreateAccessGroupBody>({
    query: body => ({
      url: GROUPS_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["AccessGroup"]
  })
