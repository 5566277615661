import React from "react"

export const VoisoIcon = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" fill="white" fillOpacity="0.8" />
    <path d="M11 8L17.5 24H14.5L8 8H11Z" fill="#3B7BE1" />
    <path d="M11 8L17.5 24H14.5L8 8H11Z" fill="#3B7BE1" />
    <path d="M24 8L17.5 24H14.5L21 8H24Z" fill="#3B7BE1" />
    <path d="M24 8L17.5 24H14.5L21 8H24Z" fill="#3B7BE1" />
  </svg>
)
