import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type DashboardState = {
  isActive: boolean
}

const initialState: DashboardState = { isActive: false }

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setIsActive(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload
    }
  }
})

export const dashboardActions = dashboardSlice.actions

export default dashboardSlice.reducer
