import { OrgChartPosition } from "@interfaces*"
import { OrgChartResponse, IncludedOrgChartUser, IncludedOrChartDepartment } from "@state/types/orgChart"

import { generateUserPhotoURL } from "../users/urls"

type IncludedMap<T extends IncludedUnion> = { [k: string]: T }

type IncludedUnion = IncludedOrgChartUser | IncludedOrChartDepartment

type TagType = "users" | "departments"

function createIncludedMap<T extends IncludedUnion>(
  includeOfUserRoles: Array<IncludedUnion>,
  tag: TagType
): IncludedMap<T> {
  const map: IncludedMap<T> = {}

  includeOfUserRoles.forEach(entity => {
    if (entity.type === tag) map[entity.id] = entity as T
  })

  return map
}

export const transformOrgChartResponse = (response: OrgChartResponse): Array<OrgChartPosition> => {
  const usersMap = createIncludedMap<IncludedOrgChartUser>(response.included, "users")

  return response.data.map(pos => {
    const { attributes, id } = pos
    const currentUser = attributes.user_id ? usersMap[attributes.user_id] : null
    return {
      id,
      isCeo: attributes.ceo,
      commission: attributes.commission,
      children: attributes.children,
      departmentId: attributes.department_id,
      positionName: attributes.name,
      user: currentUser
        ? {
            id: currentUser.id,
            firstName: currentUser.attributes.first_name,
            secondName: currentUser.attributes.second_name,
            lastName: currentUser.attributes.last_name,
            photo: currentUser.attributes.photo_id ? generateUserPhotoURL(currentUser.attributes.photo_id) : null
          }
        : null
    }
  })
}
