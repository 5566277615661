import React, { FC, useState } from "react"
import { AccessGroup } from "interfaces"

import { Search } from "../../components/Search"
import { ListWrapper } from "../../components/ListWrapper"

import { GroupsCollapse } from "./GroupsCollapse"
import style from "./style.m.scss"

type GroupsListProps = {
  groups: Array<AccessGroup>
}

const GroupsList: FC<GroupsListProps> = ({ groups }) => {
  const [searchingGroupId, setSearchingGroupId] = useState<string | null>(null)

  const onSearch = (value: string) => {
    setSearchingGroupId(value)
  }

  const onClear = () => {
    setSearchingGroupId(null)
  }

  const policiesOptions = groups.map(item => ({ label: item.name, value: item.id }))

  const filteredGroups = searchingGroupId ? groups.filter(group => group.id === searchingGroupId) : groups

  return (
    <ListWrapper title="Groups" wrapperClassName={style.wrapper}>
      <Search options={policiesOptions} onSearch={onSearch} onClear={onClear} />
      <GroupsCollapse groups={filteredGroups} />
    </ListWrapper>
  )
}

export default GroupsList
