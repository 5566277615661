import { VacationSettingOptionsResponse } from "@state/types/vacations"
import { Build } from "@state/services/types"

import { VACATIONS_SETTINGS_OPTIONS_URL } from "../urls"

export const fetchVacationSettingsOptions = (build: Build) =>
  build.query<VacationSettingOptionsResponse, null>({
    query: () => ({
      url: VACATIONS_SETTINGS_OPTIONS_URL
    }),
    forceRefetch: () => true
  })
