import { UploadFile } from "antd"
import { Rule } from "antd/es/form"

import { MAX_FILE_SIZE } from "./constants"

export const requiredRule: Rule = { required: true, message: "Can't be blank" }

export const urlRule: Rule = { type: "url", message: "Must be a valid url" }

export const checkFilesSize = (_: Rule, files: Array<UploadFile<Blob>>) => {
  if (!files) return Promise.resolve()
  const checkEachFile = files.map(file => (file.size ? file.size >= MAX_FILE_SIZE : false))
  if (checkEachFile.includes(true)) {
    return Promise.reject(new Error("Maximum allowed file size 5 MB"))
  }
  return Promise.resolve()
}

export const checkMaterial = (_: Rule, material?: string | Blob, name?: string) => {
  if (!material && name) {
    return Promise.reject(new Error("Can't be blank"))
  }
  return Promise.resolve()
}

export const checkMaterialName = (_: Rule, name?: string, material?: string | Blob) => {
  if (material && !name) {
    return Promise.reject(new Error("Can't be blank"))
  }
  return Promise.resolve()
}
