import { Build } from "@state/services/types"
import { BackendSearchingUser } from "@state/types/users"
import { SearchingUser, SearchUsersQuery } from "interfaces"

import { generateSearchUsersURL } from "../urls"
import { transformSerachingUser } from "../transformer"

export const searchUsers = (build: Build) =>
  build.query<Array<SearchingUser>, SearchUsersQuery>({
    query: params => ({
      url: generateSearchUsersURL(params)
    }),
    transformResponse: (res: { users: Array<BackendSearchingUser> }) => transformSerachingUser(res),
    providesTags: ["User"]
  })
