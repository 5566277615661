export const SLACK_USERS_URL = "/slack_users"

export const generateSearchSlackUsersUrl = (search: string) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("search_string", search)

  return `${SLACK_USERS_URL}/search${separator}${searchParams.toString()}`
}

export const SLACK_USERS_OPTIONS_URL = `${SLACK_USERS_URL}/options`

export const generateSlackUserUrl = (id: string) => `${SLACK_USERS_URL}/${id}`
