import React, { FC } from "react"
import { Navigate, Route, Routes } from "react-router"
import { RedirectType, RouteItem } from "@routes/types"
import { Layout } from "antd"
import { routes, redirects } from "routes"
import { useAppSelector, useRoleAbilities } from "@hooks"

import { Sidebar } from "@components/Sidebar"

import styles from "./style.m.scss"

const routeGenerate = (route: RouteItem) =>
  !route.hidden &&
  route.component && (
    <Route
      key={route.path}
      path={route.path}
      element={
        route.componentWrap ? (
          <route.componentWrap>
            <route.component />
          </route.componentWrap>
        ) : (
          <route.component />
        )
      }
    />
  )

const App: FC = () => {
  const abilities = useRoleAbilities()
  const isChief = useAppSelector(state => state.meReducer.user?.attributes.is_chief) || false
  const isSuperaccess = useAppSelector(state => state.meReducer.user?.attributes.is_superaccess) || false
  const withSubordinates = useAppSelector(state => state.meReducer.user?.attributes.with_subordinates) || false

  const renderRedirects = () =>
    redirects().map((redirect: RedirectType) => (
      <Route path={redirect.from} element={<Navigate to={redirect.to} />} key={redirect.from + redirect.to} />
    ))

  const renderRoutes = () => routes(abilities, isChief, isSuperaccess, withSubordinates).map(routeGenerate)

  const renderSubroutes = () =>
    routes(abilities, isChief, isSuperaccess, withSubordinates)
      .filter(route => Array.isArray(route.subRoutes))
      .map(route => !route.hidden && route.subRoutes?.map(routeGenerate))

  return (
    <React.Fragment>
      <Layout className={styles.topLayout}>
        <Layout.Sider breakpoint="lg" collapsedWidth="0" width={250}>
          <div className={styles.leftNavigation}>
            <Sidebar />
          </div>
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <React.Suspense fallback={null}>
            <Routes>
              {renderRedirects()}
              {renderRoutes()}
              {renderSubroutes()}
            </Routes>
          </React.Suspense>
        </Layout.Content>
      </Layout>
    </React.Fragment>
  )
}

export default App
