export const nameReg = /^[a-zA-Z]{0,40}$/

export const nicknameReg = /^[a-zA-Zа0-9\s?;:,.{}[\]\-\\/@']{1,60}$/

export const multiNameReg = /^[a-zA-Z\s]{1,80}$/

export const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const passwordReg = /^.{6,}$/

export const text = /^[^А-яа-яё]+$/

export const AUTHENTICATION_CODE_REG = /^\d{6}$/

export const id = /^[0-9]+$/

export const NUMBERS = /^\d+$/
