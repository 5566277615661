import backendApi from "../../backendApi"

import { fetchUserSalariesChanges } from "./GET/fetchUserSalariesChanges"
import { createSalaryChanges } from "./POST/createSalaryChanges"
import { deleteSalaryChange } from "./DELETE/deleteSalaryChange"

export const salariesApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchUserSalariesChanges: fetchUserSalariesChanges(build),
    createSalaryChanges: createSalaryChanges(build),
    deleteSalaryChange: deleteSalaryChange(build)
  })
})
