import { Conference } from "@interfaces*"
import { ConferencesResponse } from "@state/types/conferences"

export const transformConferences = (response: ConferencesResponse): Array<Conference> =>
  response.data.map(conference => {
    const { id, attributes } = conference

    return {
      id: String(id),
      userId: String(attributes.user_id),
      name: attributes.name,
      url: attributes.url,
      startedAt: attributes.started_at,
      finishedAt: attributes.finished_at
    }
  })
