import { Build } from "@state/services/types"
import { CreateAssignmentHrRequest, CreateAssignmentHrResponse } from "@state/types/vacations"

import { ASSIGNMENT_HR_URL } from "../urls"

export const createAssignmentHr = (build: Build) =>
  build.mutation<CreateAssignmentHrResponse, CreateAssignmentHrRequest>({
    query: ({ user_id, setting_id }) => ({
      url: ASSIGNMENT_HR_URL,
      method: "POST",
      body: {
        data: {
          type: "assignment_hrs",
          attributes: { user_id, setting_id }
        }
      }
    }),
    invalidatesTags: result => [
      "AssignmentHr",
      result?.data ? { type: "VacationSettings", id: result.data.attributes.setting_id } : "VacationSettings"
    ]
  })
