import backendApi from "../../backendApi"

import { fetchEducationalMaterials } from "./GET/fetchEducationalMaterials"
import { createEducationalMaterial } from "./POST/createEducationalMaterial"
import { deleteEducationalMaterial } from "./DELETE/deleteEducationalMaterial"
import { updateEducationalMaterial } from "./PUT/updateEducationalMaterial"
import { createEducationalMaterialBulk } from "./POST/createEducationalMaterialsBulk"
import { fetchEducationalBulkLogs } from "./GET/fetchEducationalBulkLogs"
import { deactivateActiveBulk } from "./PATCH/deactivateActiveBulk"

export const educationalMaterialsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchEducationalMaterials: fetchEducationalMaterials(build),
    createEducationalMaterial: createEducationalMaterial(build),
    deleteEducationalMaterial: deleteEducationalMaterial(build),
    updateEducationalMaterial: updateEducationalMaterial(build),
    createEducationalMaterialBulk: createEducationalMaterialBulk(build),
    fetchEducationalBulkLogs: fetchEducationalBulkLogs(build),
    deactivateActiveBulk: deactivateActiveBulk(build)
  })
})
