import React, { useEffect } from "react"
import { ALERT_CUSTOM_EVENT } from "@helpers"
import { useAlert } from "@hooks"

export const AlertsListener: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { success, error, info, warning } = useAlert()

  const processEvent = (event: CustomEvent) => {
    switch (event.detail.type) {
      case "success": {
        success(event.detail.message)
        break
      }
      case "error": {
        error(event.detail.message)
        break
      }

      case "info": {
        info(event.detail.message)
        break
      }

      case "warning": {
        warning(event.detail.message)
        break
      }

      default:
    }
  }

  useEffect(() => {
    document.addEventListener(ALERT_CUSTOM_EVENT, processEvent)

    return () => document.removeEventListener(ALERT_CUSTOM_EVENT, processEvent)
  }, [])

  return <>{children}</>
}
