import { lazy } from "react"
import { Abilities } from "@interfaces"
import { Section } from "@enums"

import { DefaultWrapper } from "@components/PageWrappers"

import { RouteItem } from "./types"
import { IMPULSE_ACCESS_ROUTES, SLACK_GROUP_ROUTE, SLACK_GROUPS_ROUTE } from "./inboundRoutes"

const SlackGroup = lazy(() => import("@app/sections/SlackGroups/SlackGroup"))
const SlackGroups = lazy(() => import("@app/sections/SlackGroups"))
const ImpulseAccess = lazy(() => import("@app/sections/ImpulseAccess"))

export const slackRoutes = (abilities: Abilities | null): Array<RouteItem> => [
  {
    path: SLACK_GROUP_ROUTE,
    title: "Edit Slack group",
    slug: "slack_group",
    component: SlackGroup,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.[Section.SlackGroups]?.read_all
  }
]

export const slackMenuRoutes = (abilities: Abilities | null): Array<RouteItem> => [
  {
    path: SLACK_GROUPS_ROUTE,
    title: "Slack groups",
    slug: "slack-groups",
    component: SlackGroups,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.[Section.SlackGroups]?.read_all
  },
  {
    path: IMPULSE_ACCESS_ROUTES,
    title: "Impulse access",
    slug: "impulse-access",
    component: ImpulseAccess,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.[Section.SlackUsers]?.edit_all
  }
]
