import { NotificationsSettings } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendNotificationSettings } from "@state/types/users"

import { generateUserNotificationSettingsURL } from "../urls"
import { transformNotificationSettings } from "../transformer"

export const fetchNotificationSettings = (build: Build) =>
  build.query<NotificationsSettings, string>({
    query: userId => ({
      url: generateUserNotificationSettingsURL(userId)
    }),
    providesTags: ["NotificationSettings"],
    transformResponse: (response: BackendNotificationSettings) => transformNotificationSettings(response)
  })
