import React, { FC, useState } from "react"
import { useDepartmentsAbilities, useDepartmentsViewState } from "@hooks"
import { Button, Flex, Pagination, Table, Typography } from "antd"
import { PAGE_SIZE_OPTIONS } from "@helpers"
import { DepartmentStatus } from "@enums"
import { DepartmentsFilters } from "@interfaces*"
import { useNavigate } from "react-router"
import { ADD_DEPARTMENT_ROUTE } from "@routes/inboundRoutes"
import global from "@styles/global.m.scss"
import { FilterValue } from "antd/es/table/interface"

import { SearchSelect } from "@components/SearchSelect"
import { ValidatedSelect } from "@components/ValidatedInputs"

import style from "./style.m.scss"
import { useDepartmentsUtility } from "./helpers/useDepartmentsUtility"

const statusSelectOptions = [
  { value: DepartmentStatus.Active, label: DepartmentStatus.Active },
  { value: DepartmentStatus.Inactive, label: DepartmentStatus.Inactive },
  { value: DepartmentStatus.All, label: DepartmentStatus.All }
]

const Departments: FC = () => {
  const navigate = useNavigate()
  const { create: createDepartment } = useDepartmentsAbilities()

  const {
    page,
    size,
    departments,
    isLoading,
    isFetching,
    totalDepartmentsCount,
    filters,
    onFiltersChange,
    onPagingChange
  } = useDepartmentsViewState()

  const { departmentsColumns, headOfDepartmentsOptions, departmentsOptions } = useDepartmentsUtility(filters)

  const [disableSubFilters, setDisableSubFilters] = useState(false)

  const handleFiltersChange = (fieldName: keyof DepartmentsFilters) => (value: string) => {
    if (fieldName === "deptId" && !filters.deptId) setDisableSubFilters(true)
    onFiltersChange({ ...filters, [fieldName]: value })
  }

  const handleTableChange = (_: unknown, newFilters: Record<string, FilterValue | null>) => {
    const { products } = newFilters
    onFiltersChange({ ...filters, products: products && products.length ? (products as Array<string>) : undefined })
  }

  const handleClearDeptNameFilter = () => {
    setDisableSubFilters(false)
  }

  const handleCreateClick = () => {
    navigate(ADD_DEPARTMENT_ROUTE)
  }

  return (
    <>
      <Flex align="flex-end" justify="space-between">
        <Typography.Title datatest-id="dept-name" level={4} className={style.header}>
          Departments
        </Typography.Title>
        <Button
          datatest-id="add-dept-button"
          type="primary"
          onClick={handleCreateClick}
          disabled={!createDepartment}
          className={style.button}
        >
          Add deps
        </Button>
      </Flex>
      <Flex className={style.filtersWrapper}>
        <SearchSelect
          datatest-id="search-dept"
          classNameProp={style.searchSelect}
          options={departmentsOptions}
          onChange={handleFiltersChange("deptId")}
          onClear={handleClearDeptNameFilter}
          helperText="Departments"
          placeholder="Search dept."
        />
        <Flex align="flex-end" justify="space-between">
          <SearchSelect
            datatest-id="search-head-of-dept"
            classNameProp={style.searchSelect}
            disabled={disableSubFilters}
            options={headOfDepartmentsOptions}
            onChange={handleFiltersChange("headOfDeptId")}
            helperText="Head of dept."
            placeholder="Search head of dept."
          />
          <div className={style.filterWrapper}>
            <span className={style.helperText}>Status</span>
            <ValidatedSelect
              datatest-id="search-status"
              disabled={disableSubFilters}
              onChange={handleFiltersChange("status")}
              options={statusSelectOptions}
              className={style.filter}
              defaultValue={DepartmentStatus.Active}
              placeholder={<span>Status</span>}
            />
          </div>
          <SearchSelect
            datatest-id="search-parent-dept"
            classNameProp={style.searchSelect}
            disabled={disableSubFilters}
            onChange={handleFiltersChange("parentDeptId")}
            options={departmentsOptions}
            helperText="Parent dept."
            placeholder="Search parent dept."
          />
        </Flex>
      </Flex>
      <Table
        className={global.scrollbar}
        columns={departmentsColumns}
        dataSource={departments}
        rowKey="id"
        loading={isLoading || isFetching}
        pagination={false}
        onChange={handleTableChange}
        scroll={{
          y: "calc(100vh - 320px)",
          x: 1184
        }}
      />
      <div className={style.paginationWrapper}>
        <Pagination
          datatest-id="dept-pagination"
          onChange={onPagingChange}
          total={totalDepartmentsCount}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          pageSize={size}
          current={page}
        />
      </div>
    </>
  )
}

export default Departments
