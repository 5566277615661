import { Build } from "@state/services/types"

import { generateSlackGroupUrl } from "../urls"

export const deleteSlackGroup = (build: Build) =>
  build.mutation<unknown, string>({
    query: id => ({
      url: generateSlackGroupUrl(id),
      method: "DELETE"
    }),
    invalidatesTags: ["SlackGroup"]
  })
