import { CreateNewLeaveTypeRequest } from "@state/types/vacations"
import { Build } from "@state/services/types"

import { VACATIONS_LEAVE_TYPES_URL } from "../urls"

export const createNewLeaveType = (build: Build) =>
  build.mutation<{ status: string }, CreateNewLeaveTypeRequest>({
    query: body => ({
      url: VACATIONS_LEAVE_TYPES_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["LeaveType"]
  })
