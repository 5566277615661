import React, { FC } from "react"
import { Flex, Typography } from "antd"

import style from "./style.m.scss"

type ListWrapperProps = {
  children: React.ReactNode
  title: string
  wrapperClassName?: string
}

const ListWrapper: FC<ListWrapperProps> = ({ children, title, wrapperClassName }) => (
  <Flex vertical className={wrapperClassName}>
    <Typography.Title level={3} className={style.title}>
      {title}
    </Typography.Title>
    {children}
  </Flex>
)

export default ListWrapper
