import backendApi from "../../backendApi"

import { fetchVacationLogs } from "./GET/fetchVacationLogs"
import { fetchVacations } from "./GET/fetchVacations"
import { createVacation } from "./POST/createVacation"
import { cancelVacation } from "./PUT/cancelVacation"
import { resolveVacation } from "./PATCH/resolveVacation"
import { fetchVacationOptions } from "./GET/fetchVacationOptions"
import { fetchVacationAccumulations } from "./GET/fetchVacationAccumulations"
import { fetchVacationAccumulationsOptions } from "./GET/fetchVacationsAccumulationsOptions"
import { createVacationAccumulation } from "./POST/createVacationAccumulation"
import { cancelVacationAccumulation } from "./PUT/cancelVacationAccumulation"
import { fetchVacationsRequests } from "./GET/fetchVacationRequests"

export const vacationsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchVacations: fetchVacations(build),
    fetchVacationsRequests: fetchVacationsRequests(build),
    createVacation: createVacation(build),
    cancelVacation: cancelVacation(build),
    resolveVacation: resolveVacation(build),
    fetchVacationOptions: fetchVacationOptions(build),
    fetchVacationLogs: fetchVacationLogs(build),
    fetchVacationAccumulations: fetchVacationAccumulations(build),
    fetchVacationAccumulationsOptions: fetchVacationAccumulationsOptions(build),
    createVacationAccumulation: createVacationAccumulation(build),
    cancelVacationAccumulation: cancelVacationAccumulation(build)
  })
})
