import { Build } from "@state/services/types"
import { BackendVacationsNotificationsSettings } from "@state/types/vacations"
import { VacationsNotificationsSettings } from "@interfaces"

import { VACATIONS_NOTIFICATION_SETTINGS } from "../urls"

export const fetchVacationsNotificationSettings = (build: Build) =>
  build.query<VacationsNotificationsSettings, string>({
    query: () => ({
      url: VACATIONS_NOTIFICATION_SETTINGS
    }),
    transformResponse: (res: BackendVacationsNotificationsSettings) => res.parameters.upcoming_leaves.products,
    providesTags: ["VacationsNotificationsSetting"]
  })
