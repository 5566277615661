import { Paging, UsersFilters } from "@interfaces"
import { usersApi } from "@state/services/subApis"

export const useUsers = (filters: UsersFilters, paging: Paging) => {
  const { users, totalCount, isLoading, isFetching } = usersApi.endpoints.fetchPagedUsers.useQuery(
    { ...paging, ...filters },
    {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        users: data?.entities || [],
        totalCount: data?.totalCount || 0,
        isLoading,
        isFetching
      })
    }
  )

  return {
    users,
    totalUsersCount: totalCount,
    isLoading,
    isFetching
  }
}
