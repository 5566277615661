import { Build } from "@state/services/types"
import { BackendAccessGroupLogs } from "@state/types/accessGroups"
import { AccessGroupLog, AccessGroupLogType } from "@interfaces"

import { generateAccessGroupLogsURL } from "../urls"
import { transformLogs } from "../transformer"

export const fetchLogs = (build: Build) =>
  build.query<Array<AccessGroupLog>, AccessGroupLogType>({
    query: logsType => ({
      url: generateAccessGroupLogsURL(logsType)
    }),
    transformResponse: (res: BackendAccessGroupLogs) => transformLogs(res),
    providesTags: ["AccessGroup", "Policies"]
  })
