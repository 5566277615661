import React, { FC } from "react"
import { Spin } from "antd"
import { useAppSelector, useEducationalMaterialsAbilities, useProfileData, useProfileLocation } from "@hooks"
import {
  usePerformanceReviewsAbilities,
  useUserProfileEmploymentInfoAbilities,
  useVacationsAbilities
} from "hooks/query/useAbilities"

import { EmploymentInformation } from "./sections/EmploymentInformation"
import { JobDescription } from "./sections/JobDescription"
import { Leaves } from "./sections/Leaves"
import { Subordinates } from "./sections/Subordinates"
import { EducationalMaterials } from "./sections/EducationalMaterials"
import { PersonalDocuments } from "./sections/PersonalDocuments"
import { JobHistory } from "./sections/JobHistory"
import { Certificates } from "./sections/Certificates"
import { Conferences } from "./sections/Conferences"
import PerformanceReview from "./sections/PerformanceReview/PerformanceReview"
import { Logs } from "./sections/Logs"
import { EmergencyContacts } from "./sections/EmergencyContacts"

export const Profile: FC = () => {
  const { read_all: viewEducationalMaterials } = useEducationalMaterialsAbilities()
  const { read_all: viewPerformanceReviews } = usePerformanceReviewsAbilities()
  const { update_all: canViewLogs } = useUserProfileEmploymentInfoAbilities()
  const { manage } = useVacationsAbilities()

  const isChief = useAppSelector(state => state.meReducer.user?.attributes.is_chief)
  const isAdmin = useAppSelector(state => state.meReducer.user?.attributes.is_superaccess)

  const { user, isFetching, error, refetch } = useProfileData({
    includeLeaves: true,
    includeSubordinates: true,
    includeAvailableSystems: true
  })
  const { isMyself } = useProfileLocation()

  const canViewPerformanceReviews = isMyself || !!viewPerformanceReviews || isChief
  const canViewEmergencyContacts = !!manage || isAdmin

  const handleSaved = () => {
    refetch()
  }

  if (error) return <>User cannot be retrieved</>

  if (!user) return <Spin size="large" />

  return (
    <React.Fragment key={user.id}>
      <EmploymentInformation user={user} isFetching={isFetching} onSaved={handleSaved} />
      {user && canViewEmergencyContacts && <EmergencyContacts emergencyContacts={user.emergencyContacts || []} />}
      <JobDescription text={user.jobDescription || ""} />
      <JobHistory />
      {user.subordinates && user.subordinates.length > 0 && <Subordinates subordinates={user.subordinates} />}
      {user.leaves && user.leaves.length > 0 && <Leaves leaves={user.leaves} />}
      {user.id && canViewPerformanceReviews && <PerformanceReview userId={user.id} />}
      {user.id && <Certificates userId={user.id} />}
      {user.id && <Conferences userId={user.id} />}
      {user && <PersonalDocuments user={user} />}
      {user.id && viewEducationalMaterials && <EducationalMaterials userId={user.id} />}
      {user.id && canViewLogs && <Logs userId={user.id} />}
    </React.Fragment>
  )
}
