import { DepartmentsWithPositionsOptions } from "@interfaces"
import { GetDepartmentsOptionsParams } from "@state/types/departments"
import { Build } from "@state/services/types"

import { generateDepartmentsOptionsURL } from "../urls"

export const fetchDepartmentsOptions = (build: Build) =>
  build.query<DepartmentsWithPositionsOptions, GetDepartmentsOptionsParams>({
    query: params => ({
      url: generateDepartmentsOptionsURL(params)
    }),
    providesTags: res =>
      res ? [...res.options.map(option => ({ type: "Department" as const, id: option.value }))] : ["Department"]
  })
