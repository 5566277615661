import { Build } from "@state/services/types"
import { VacationsOptionsResponse } from "@state/types/vacations"

import { VACATIONS_OPTIONS_URL } from "../urls"

export const fetchVacationOptions = (build: Build) =>
  build.query<VacationsOptionsResponse, null>({
    query: () => ({
      url: VACATIONS_OPTIONS_URL
    }),
    providesTags: ["Quota"]
  })
