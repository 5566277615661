import React, { FC, useState } from "react"
import { useEducationalMaterialsAbilities } from "@hooks"
import { educationalMaterialsApi } from "@state/services/subApis"

import { SectionWrapper } from "@components/SectionWrapper"

import { EditPanel } from "./EditPanel"
import { ViewPanel } from "./ViewPanel"

type EducationalMaterialsProps = {
  userId: string
}

export const EducationalMaterials: FC<EducationalMaterialsProps> = ({ userId }) => {
  const { edit_all: canEditEducationalMaterials } = useEducationalMaterialsAbilities()

  const [isEditMode, setIsEditMode] = useState(false)

  const { data = [] } = educationalMaterialsApi.endpoints.fetchEducationalMaterials.useQuery({ userId }, {})

  const handleEditClick = canEditEducationalMaterials
    ? () => {
        setIsEditMode(true)
      }
    : undefined

  const handleSaveClick = () => {
    setIsEditMode(false)
  }

  if (!canEditEducationalMaterials && data.length === 0) return null

  return (
    <SectionWrapper>
      {isEditMode ? (
        <EditPanel userId={userId} onSave={handleSaveClick} />
      ) : (
        <ViewPanel userId={userId} onEdit={handleEditClick} />
      )}
    </SectionWrapper>
  )
}
