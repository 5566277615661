import { CreateUserRequest, CreateUserResponse } from "@interfaces"
import { Build } from "@state/services/types"

import { USERS_CREATE_URL } from "../urls"

export const createUser = (build: Build) =>
  build.mutation<CreateUserResponse, CreateUserRequest>({
    query: body => ({
      url: USERS_CREATE_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["User", "Tag"]
  })
