import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons/lib/icons"
import { LocationColumn } from "@enums"
import { defaultError } from "@helpers"
import { useAlert } from "@hooks"
import { LocationForSettings, UpdateLocationBodyReq } from "@interfaces*"
import { Button, Popconfirm, Tooltip } from "antd"
import { useLocationsAbilities } from "hooks/query/useAbilities"
import React, { useState } from "react"
import { locationsApi } from "@state/services/subApis"

import { ValidatedTextInput } from "@components/ValidatedInputs"
import Name from "@components/Name"

export const useLocations = () => {
  const [editingId, setEditingId] = useState<string | null>(null)
  const [newName, setNewName] = useState("")
  const { success, error } = useAlert()

  const { update: updateLocationName, destroy: destroyLocation } = useLocationsAbilities()

  const [updateLocation] = locationsApi.endpoints.updateLocation.useMutation()
  const [deleteLocation] = locationsApi.endpoints.deleteLocation.useMutation()

  const { locationsSettings, isLocationsLoading, isLocationsFetching } =
    locationsApi.endpoints.fetchLocationsForSettings.useQuery("", {
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        locationsSettings: data || [],
        isLocationsLoading: isLoading,
        isLocationsFetching: isFetching
      })
    })

  const handleEdit = (id: string, name: string) => {
    setEditingId(id)
    setNewName(name)
  }

  const handleNameChange = (value: string) => {
    setNewName(value)
  }

  const handleSave = async (id: string, country: string) => {
    if (country === newName) {
      setEditingId(null)
      return
    }

    const bodyReq: UpdateLocationBodyReq = {
      data: {
        id,
        type: "locations" as const,
        attributes: {
          name: newName
        }
      },
      format: ":jsonapi" as const
    }

    try {
      await updateLocation(bodyReq).unwrap()
      setEditingId(null)
      success("Location was succesfully updated")
    } catch (err) {
      const errorText = err as string
      error(errorText)
    }
  }

  const handleCancel = () => {
    setEditingId(null)
  }

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteLocation(id).unwrap()
      success(res.message)
    } catch {
      error(defaultError)
    }
  }

  const basicLocationColumns = [
    {
      title: LocationColumn.LocationName,
      key: "locationName",
      width: "200px",
      render: (_: unknown, { id, location }: LocationForSettings) =>
        id === editingId ? <ValidatedTextInput value={newName} onChange={handleNameChange} /> : <Name name={location} />
    },
    {
      title: LocationColumn.Country,
      key: "country",
      width: "200px",
      render: (_: unknown, { country }: LocationForSettings) => country
    },
    {
      title: LocationColumn.DataStorage,
      key: "dataStorage",
      render: (_: unknown, { dataStoredLocation }: LocationForSettings) => dataStoredLocation
    },
    {
      title: LocationColumn.EmployeesCount,
      key: "dataStorage",
      render: (_: unknown, { employeesCount }: LocationForSettings) => employeesCount
    },
    {
      title: LocationColumn.IsDocsStored,
      key: "docsIsStored",
      render: (_: unknown, { docsStored }: LocationForSettings) => (docsStored ? "Yes" : "No")
    }
  ]

  const locationActionColumn = {
    title: LocationColumn.Actions,
    key: "actions",
    width: "150px",
    render: (_: unknown, { id, location, employeesCount, docsStored }: LocationForSettings) => (
      <>
        {updateLocationName && editingId !== id && (
          <Tooltip title="Edit">
            <Button icon={<EditOutlined />} type="text" onClick={() => handleEdit(id, location)} />
          </Tooltip>
        )}
        {editingId === id && (
          <>
            <Tooltip title="Save">
              <Button icon={<CheckOutlined />} type="text" onClick={() => handleSave(id, location)} />
            </Tooltip>
            <Tooltip title="Cancel">
              <Button icon={<CloseOutlined />} type="text" onClick={() => handleCancel()} />
            </Tooltip>
          </>
        )}
        {destroyLocation && (
          <Tooltip title="Delete">
            <Popconfirm
              title={`You are about to delete ${location} location. Are you sure?`}
              onConfirm={() => handleDelete(id)}
              placement="topLeft"
            >
              <Button icon={<DeleteOutlined />} type="text" disabled={docsStored || employeesCount !== 0} />
            </Popconfirm>
          </Tooltip>
        )}
      </>
    )
  }

  const columns = [...basicLocationColumns, locationActionColumn]

  return { locations: locationsSettings, isLoading: isLocationsLoading, isFetching: isLocationsFetching, columns }
}
