export const PERFORMANCE_REVIEW_URL = "/performance_reviews"

export const generatePerformanceReviewIndexURL = (id: string) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("filter[user_id]", id)
  searchParams.set("include", "document")

  return `${PERFORMANCE_REVIEW_URL}${separator}${searchParams.toString()}`
}

export const generatePerformanceReviewDeleteURL = (id: string) => `${PERFORMANCE_REVIEW_URL}/${id}`
