import { BackendUpdateAvailableSystemParams } from "@state/types/availableSystems"
import { Build } from "@state/services/types"

import { generateAvailableSystemUrl } from "../urls"
import { deriveMutationParams } from "../transformer"

export const updateAvailableSystem = (build: Build) =>
  build.mutation<unknown, BackendUpdateAvailableSystemParams>({
    query: params => {
      const { id, ...data } = params
      return {
        url: generateAvailableSystemUrl(id),
        method: "PUT",
        body: deriveMutationParams(data)
      }
    },
    invalidatesTags: ["AvailableSystem"]
  })
