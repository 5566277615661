import { Build } from "@state/services/types"

import { generateUserPhotoModificationURL } from "../urls"

export const deleteUserPhoto = (build: Build) =>
  build.mutation<unknown, string>({
    query: id => ({
      url: generateUserPhotoModificationURL(id),
      method: "DELETE"
    }),
    invalidatesTags: ["User"]
  })
