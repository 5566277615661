import React, { FC } from "react"
import { Divider, Flex } from "antd"
import { useParams } from "react-router-dom"
import { departmentsApi } from "@state/services/subApis"

import DepartmentLogs from "./DepartmentLogs"
import DepartmentInfo from "./DepartmentInfo"
import DepartmentJobs from "./DepartmentJobs"

const DepartmentCard: FC = () => {
  const { id } = useParams()

  const { department } = departmentsApi.endpoints.fetchDepartmentById.useQuery(id!, {
    selectFromResult: ({ data }) => ({
      department: data
    }),
    skip: !id
  })

  const { departmentProducts } = departmentsApi.endpoints.fetchDepartmentProducts.useQuery(id!, {
    selectFromResult: ({ data }) => ({
      departmentProducts: data
    }),
    skip: !id
  })

  return (
    <Flex vertical key={department?.id || "default"}>
      {department && (
        <>
          <DepartmentInfo department={department} isCEO={department.isCeo} />
          <Divider />
        </>
      )}
      {department?.positions && departmentProducts && !department.isCeo && (
        <>
          <DepartmentJobs department={department} departmentProducts={departmentProducts} />
          <Divider />
        </>
      )}
      {id && !department?.isCeo && <DepartmentLogs departmentId={id} />}
    </Flex>
  )
}

export default DepartmentCard
