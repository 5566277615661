import { Build } from "@state/services/types"
import { DepartmentProducts } from "@interfaces*"
import { BackendDepartmentProducts } from "@state/types/departments"

import { generateDepartmentProductsURL } from "../urls"
import { transformDepartmentProductsResponse } from "../transformDepartments"

export const fetchDepartmentProducts = (build: Build) =>
  build.query<DepartmentProducts, string>({
    query: deptId => ({
      url: generateDepartmentProductsURL(deptId)
    }),
    transformResponse: (res: BackendDepartmentProducts) => transformDepartmentProductsResponse(res),
    providesTags: ["Department"]
  })
