import { Separators, generateDateTemplate } from "@helpers"
import { Collapse, Typography } from "antd"
import dayjs from "dayjs"
import React, { FC, useState } from "react"
import { generateUserProfileRoute } from "@routes/inboundRoutes"
import { Link } from "react-router-dom"
import { departmentsApi } from "@state/services/subApis"

import style from "./style.m.scss"

type DepartmentLogsProps = {
  departmentId: string
}

const DepartmentLogs: FC<DepartmentLogsProps> = ({ departmentId }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { logs } = departmentsApi.endpoints.fetchDepartmentsLogs.useQuery(departmentId, {
    selectFromResult: ({ data, isFetching }) => ({
      logs: data || [],
      isFetching
    }),
    skip: !isExpanded
  })

  const handleCollapse = () => {
    setIsExpanded(!isExpanded)
  }

  const logsItems = [
    {
      label: (
        <Typography.Title level={4} className={style.header}>
          History
        </Typography.Title>
      ),
      children: logs.map(log => (
        <p key={log.id}>
          <span>{`${log.message} by `}</span>
          <span>
            {log.user.id ? (
              <Link to={generateUserProfileRoute(log.user.id)}>{`${log.user.userEmail} `} </Link>
            ) : (
              `${log.user.userEmail} `
            )}
          </span>
          <span>{dayjs(log.timeStamp).format(generateDateTemplate("YYYYMMDD", Separators.Dash))}</span>
        </p>
      ))
    }
  ]

  return (
    <div className={style.collapseWrapper}>
      <Collapse ghost onChange={handleCollapse} items={logsItems} expandIconPosition="end" className={style.collapse} />
    </div>
  )
}

export default DepartmentLogs
