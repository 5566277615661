import { Build } from "@state/services/types"
import { BackendDeleteLocationResponse } from "@state/types/locations"

import { LOCATIONS_URL } from "../urls"

export const deleteLocation = (build: Build) =>
  build.mutation<BackendDeleteLocationResponse, string>({
    query: locationId => ({
      url: `${LOCATIONS_URL}/${locationId}`,
      method: "DELETE"
    }),
    invalidatesTags: ["Location"]
  })
