import React, { FC } from "react"

import { Field } from "@components/Field"

import { EditablePhone } from "./EditablePhone"

type PhoneProps = {
  value?: string
  isEditable: boolean
}

export const Phone: FC<PhoneProps> = ({ value, isEditable }) =>
  isEditable ? (
    <EditablePhone value={value} />
  ) : (
    <Field title="Phone number" value={value ? `+ ${value}` : ""} data-testid="phone" />
  )
