import { Build } from "@state/services/types"
import { AssignmentHrResponse } from "@state/types/vacations"

import { GET_ASSIGNMENT_HR_URL } from "../urls"

export const fetchAssignmentHr = (build: Build) =>
  build.query<AssignmentHrResponse, null>({
    query: () => ({
      url: GET_ASSIGNMENT_HR_URL,
      params: {
        include: ["user", "setting"]
      }
    }),
    providesTags: result =>
      result && result.data.length ? result.data.map(hr => ({ type: "AssignmentHr", id: hr.id })) : ["AssignmentHr"]
  })
