import { Build } from "@state/services/types"

import { generateEducationalMaterialURL } from "../urls"

export const deleteEducationalMaterial = (build: Build) =>
  build.mutation<unknown, string>({
    query: id => ({
      url: generateEducationalMaterialURL(id),
      method: "DELETE"
    }),
    invalidatesTags: ["EducationalMaterial"]
  })
