import { PerformanceReview } from "@interfaces*"
import { PerformanceReviewResponse } from "@state/types/performanceReview"

export const transformPerformanceReviewResults = (res: PerformanceReviewResponse): Array<PerformanceReview> =>
  res.data.map(review => {
    const { id: reviewId, attributes: reviewAttributes } = review

    const currentDoc = res.included.find(doc => doc.id === review.relationships.document.data.id)!

    const { id: documentId, attributes: documentAttributes } = currentDoc

    return {
      reviewId,
      createdAt: reviewAttributes.created_at,
      documentId,
      year: reviewAttributes.year,
      fileName: documentAttributes.filename,
      fileKey: documentAttributes.key,
      fileSize: documentAttributes.size
    }
  })
