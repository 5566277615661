import { useParams } from "react-router"
import { MAX_PHOTO_SIZE, derivePhotoErrorMessage, isErrorResponse } from "@helpers"
import { useAlert } from "@hooks"
import { positionsApi, userProfileApi, usersApi } from "@state/services/subApis"
import {
  UserCreateAttributes,
  UserFullInfoWithAccesses,
  UserUpdateAttributes,
  UserCreateData,
  UserUpdateData,
  EmergencyContactsData
} from "@interfaces"
import dayjs from "dayjs"

import { useProfileLocation } from "./useProfileLocation"

export const useProfileHandlers = () => {
  const { isMyself } = useProfileLocation()
  const { userId = "" } = useParams()

  const { error } = useAlert()

  const [createUser] = usersApi.endpoints.createUser.useMutation()

  const [editUser] = usersApi.endpoints.editUser.useMutation()
  const [editProfile] = userProfileApi.endpoints.editUserProfile.useMutation()

  const [updateUserPhoto] = usersApi.endpoints.updateUserPhoto.useMutation()
  const [updateProfilePhoto] = userProfileApi.endpoints.updateUserProfilePhoto.useMutation()
  const [updateUserHireDate] = usersApi.endpoints.updateUserHireDate.useMutation()
  const [updateSeparationDate, { isLoading: isUpdateSeparationDateLoading }] =
    usersApi.endpoints.separateUser.useMutation()
  const [updateUserLocation, { isLoading: isTransferUserLoading }] = usersApi.endpoints.transferUser.useMutation()

  const [setUserPosition] = positionsApi.endpoints.setUserPosition.useMutation()

  const addNewUser = async (attributes: UserCreateAttributes) => {
    try {
      const result = await createUser({ data: attributes }).unwrap()

      return result.data.id
    } catch (e) {
      if (isErrorResponse(e)) {
        e.data.errors.forEach(errorMsg => error(errorMsg))
      } else error("Error creating profile")

      return false
    }
  }

  const saveUserProfile = async (attributes: Partial<UserUpdateAttributes>) => {
    const id = userId

    const query = {
      data: {
        id,
        ...attributes
      },
      format: ":jsonapi"
    }

    try {
      if (isMyself) {
        await editProfile(query).unwrap()
      } else {
        await editUser(query).unwrap()
      }
      return true
    } catch (err) {
      if (isErrorResponse(err)) {
        err.data.errors.forEach(errorMsg => error(errorMsg))
      }
      return false
    }
  }

  const saveUserPhoto = async (photo: File, id: string = userId) => {
    if (photo.size >= MAX_PHOTO_SIZE) {
      error("Photo size should be less than 10MB")
      return false
    }

    try {
      if (isMyself) {
        await updateProfilePhoto({ photo }).unwrap()
      } else {
        await updateUserPhoto({ photo, id: id || "" }).unwrap()
      }
      return true
    } catch (errorResponse) {
      error(derivePhotoErrorMessage(errorResponse))
      return false
    }
  }

  const saveUserHireDate = async (hireDate: string, id: string = userId) => {
    try {
      await updateUserHireDate({ userId: id, hireDate }).unwrap()
      return true
    } catch {
      error("Error updating hire date")
      return false
    }
  }

  const saveUserPosition = async (userId: string, position: number) => {
    try {
      await setUserPosition({ userId: parseInt(userId, 10), positionId: position }).unwrap()
      return true
    } catch (errorResponse) {
      error("Error updating department and position")
      return false
    }
  }

  const saveUserSeparationDate = async (userId: string, separationDate: string | null) => {
    try {
      await updateSeparationDate({ userId, separationDate }).unwrap()
      return true
    } catch (errorResponse) {
      error("Error updating user separation date")
      return false
    }
  }

  const transferUser = async (userId: string, locationId: string) => {
    try {
      await updateUserLocation({ userId, locationId }).unwrap()
      return true
    } catch (errorResponse) {
      error("Error updating user location")
      return false
    }
  }

  const saveAll = async (data: UserUpdateData, initialUser: UserFullInfoWithAccesses, hireDate: string) => {
    const shouldHireDateBeChanged = !dayjs(hireDate).isSame(initialUser.hireDate, "day")

    let hireDateResult = true

    if (shouldHireDateBeChanged) hireDateResult = await saveUserHireDate(hireDate, initialUser.id)
    const profileResult = await saveUserProfile(data.attributes)
    const photoResult = data.photo ? await saveUserPhoto(data.photo) : true

    const { department, position } = data.job
    const positionResult =
      department && position && (department !== initialUser.department?.id || position !== initialUser.position?.id)
        ? await saveUserPosition(initialUser.id || "", position)
        : true

    return profileResult && photoResult && positionResult && hireDateResult
  }

  const saveEmergencyContacts = async (data: EmergencyContactsData, userId: string) => {
    try {
      await editUser({ data: { id: userId, emergency_contacts: data.emergencyContacts } }).unwrap()
      return true
    } catch {
      error("Error updating emergency contacts")
      return false
    }
  }

  const saveNewUser = async (data: UserCreateData) => {
    const userId = await addNewUser(data.attributes)

    if (userId && data.photo) await saveUserPhoto(data.photo, userId)

    return userId
  }

  return {
    saveUserProfile,
    saveUserPhoto,
    saveAll,
    saveNewUser,
    saveUserSeparationDate,
    saveEmergencyContacts,
    isUpdateSeparationDateLoading,
    transferUser,
    isTransferUserLoading
  }
}
