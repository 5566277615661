export const LOCATIONS_URL = "/locations"
export const DATA_STORAGE_OPTIONS_URL = `${LOCATIONS_URL}/options`

export const generateGetLocationsURL = () => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("extra_fields[locations]", "users_count,docs_stored")

  return `${LOCATIONS_URL}${separator}${searchParams.toString()}`
}
