import { Build } from "@state/services/types"
import { CreateSlackGroupParams } from "@interfaces"

import { SLACK_GROUPS_URL } from "../urls"

export const createSlackGroup = (build: Build) =>
  build.mutation<unknown, CreateSlackGroupParams>({
    query: body => ({
      url: SLACK_GROUPS_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["SlackGroup"]
  })
