import React, { ComponentType, FC } from "react"
import cn from "classnames"

import { DidlogicIcon, VoisoIcon, WavixIcon } from "./assets"
import style from "./style.m.scss"

const iconMapping: Record<string, ComponentType<{ className?: string }>> = {
  didlogic: DidlogicIcon,
  voiso: VoisoIcon,
  wavix: WavixIcon
}

type ProductIconProps = {
  product: string
  size?: number
  className?: string
}

export const ProductIcon: FC<ProductIconProps> = ({ product, size = 16, className }) => {
  const Icon = iconMapping[product]

  if (!Icon) return null

  return (
    <span
      style={{ width: `${size}px`, height: `${size}px` }}
      className={cn(style.wrapper, className)}
      data-testid={`icon-${product}`}
    >
      <Icon />
    </span>
  )
}
