import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { AuthView } from "@enums"
import { OtpCodes, OtpSettings } from "@interfaces*"

type AuthState = {
  view?: AuthView
  email: string
  otp: {
    otpIsSet?: boolean
    otpIsRequired?: boolean
    otpSecret: string
    otpRawSecret: string
  }
}

const initialState: AuthState = {
  view: undefined,
  email: "",
  otp: {
    otpSecret: "",
    otpRawSecret: ""
  }
}

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload
    },
    setView(state, action: PayloadAction<AuthView>) {
      state.view = action.payload
    },
    setOtpFlags(state, action: PayloadAction<OtpSettings>) {
      state.otp.otpIsRequired = action.payload.otpIsRequired
      state.otp.otpIsSet = action.payload.otpIsSet
    },
    setOtpCodes(state, action: PayloadAction<OtpCodes>) {
      state.otp.otpSecret = action.payload.otpSecret
      state.otp.otpRawSecret = action.payload.otpRawSecret
    },
    cleanOtpCodes(state) {
      state.otp.otpSecret = ""
      state.otp.otpRawSecret = ""
    }
  }
})

export const authActions = authSlice.actions

export default authSlice.reducer
