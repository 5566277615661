import React from "react"
import { Button, Popconfirm } from "antd"
import { DownloadOutlined } from "@ant-design/icons"
import { useAlert } from "@hooks"
import { Link } from "react-router-dom"
import { PerformanceReview } from "@interfaces*"
import { generatePerformanceReviewDownloadRoute, generatePerformanceReviewShowRoute } from "@routes/inboundRoutes"
import { formatedLongFileNames } from "@app/sections/Profile/helpers/formatedLongFileNames"
import { performanceReviewApi } from "@state/services/subApis"

export const useCertificatesTableColumns = (editMode: boolean) => {
  const { success, error } = useAlert()

  const [deletePerformanceReview] = performanceReviewApi.endpoints.deletePerformanceReview.useMutation()

  const handleDeleteUserClick = async (id: string) => {
    try {
      const res = await deletePerformanceReview(id).unwrap()
      success(res.message)
    } catch {
      error("Error while deleting performance review")
    }
  }

  const basicColumns = [
    {
      key: "number",
      width: "30px",
      render: (_: unknown, __: PerformanceReview, index: number) => (index + 1).toString()
    },
    {
      title: "Document",
      key: "document",
      width: "300px",
      render: (_: unknown, { reviewId, fileName }: PerformanceReview) => {
        return (
          <>
            <Link to={generatePerformanceReviewShowRoute(reviewId)} target="_blank">
              {formatedLongFileNames(fileName)}
            </Link>
            <a href={generatePerformanceReviewDownloadRoute(reviewId)}>
              <Button type="link" icon={<DownloadOutlined />} />
            </a>
          </>
        )
      }
    },
    {
      title: "Period",
      key: "period",
      render: (_: unknown, { year }: PerformanceReview) => year
    },
    {
      title: "Created date",
      key: "createdDate",
      render: (_: unknown, { createdAt }: PerformanceReview) => createdAt.split("-").join(".")
    }
  ]

  const actionsColumn = {
    title: "Actions",
    key: "actions",
    width: "100px",
    render: (_: unknown, { reviewId, fileName }: PerformanceReview) => (
      <Popconfirm
        title={`Are you about to delete ${fileName}. Are you sure?`}
        onConfirm={() => handleDeleteUserClick(reviewId)}
        placement="topLeft"
      >
        <Button type="link" style={{ padding: 0 }}>
          Delete
        </Button>
      </Popconfirm>
    )
  }

  return editMode ? [...basicColumns, actionsColumn] : basicColumns
}
