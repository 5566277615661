import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { AppState } from "@interfaces"

const initialState: AppState = {
  openMenuKeys: []
}

const appSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setOpenMenuKeys(state, action: PayloadAction<Array<string>>) {
      state.openMenuKeys = action.payload
    }
  }
})

export const appActions = appSlice.actions

export default appSlice.reducer
