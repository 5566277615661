import { formatedLongFileNames } from "@app/sections/Profile/helpers/formatedLongFileNames"
import { defaultError } from "@helpers"
import { useAlert } from "@hooks"
import { Conference } from "@interfaces*"
import { conferencesApi } from "@state/services/subApis"
import { Button, Popconfirm } from "antd"
import { useConferencesAbilities } from "hooks/query/useAbilities"
import React from "react"

export const useConferencesTableColumns = (isEditMode?: boolean) => {
  const [deleteConference] = conferencesApi.endpoints.deleteConference.useMutation()

  const { edit_all } = useConferencesAbilities()
  const { error, success } = useAlert()

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteConference(id).unwrap()
      success(res.message)
    } catch {
      error(defaultError)
    }
  }

  const basicColumns = [
    {
      key: "number",
      width: "30px",
      render: (_: unknown, __: Conference, index: number) => (index + 1).toString()
    },
    {
      title: "Document",
      key: "document",
      width: "300px",
      render: (_: unknown, { name, url }: Conference) => {
        return url ? (
          <a href={url} target="_blank" rel="noreferrer">
            {formatedLongFileNames(name)}
          </a>
        ) : (
          name
        )
      }
    },
    {
      title: "Conferences dates",
      key: "conferencesDates",
      width: "300px",
      render: (_: unknown, { startedAt, finishedAt }: Conference) => {
        return `${startedAt.split("-").join(".")} - ${finishedAt.split("-").join(".")}`
      }
    }
  ]

  const actionsColumn = {
    title: "Actions",
    key: "actions",
    render: (_: unknown, { id, name }: Conference) => (
      <Popconfirm
        title={`Are you about to delete ${formatedLongFileNames(name)} conference. Are you sure?`}
        onConfirm={() => handleDelete(id)}
        placement="topLeft"
      >
        <Button type="link" style={{ padding: 0 }} disabled={!edit_all}>
          Delete
        </Button>
      </Popconfirm>
    )
  }

  return isEditMode ? [...basicColumns, actionsColumn] : basicColumns
}
