import { Build } from "@state/services/types"
import { EditUserBody } from "@state/types/users"

import { generateGetUserByIdURL } from "../urls"

export const editUser = (build: Build) =>
  build.mutation<string, EditUserBody>({
    query: body => ({
      url: generateGetUserByIdURL(body.data.id),
      method: "PUT",
      body
    }),
    invalidatesTags: ["User", "Tag", "EmploymentSettings", "SlackSettingsOptions", "Department", "DepartmentLog"]
  })
