import React, { FC } from "react"
import { UserFullInfoWithAccesses } from "@interfaces"
import { Button, DatePicker, Flex, Form, Modal, Popconfirm } from "antd"
import dayjs from "dayjs"

import { TerminationData } from "../types"

import { Title } from "@components/Title"

import { DATE_FORMAT, deriveConfirmationMessage, deriveFormValues, deriveTerminationData } from "./utils"
import { TerminationFormFields } from "./types"
import style from "./style.m.scss"

const now = dayjs()

type TerminationModalProps = {
  user: UserFullInfoWithAccesses
  isOpen: boolean
  isLoading: boolean
  onSave: (terminationData: TerminationData) => Promise<void>
  onCancel: () => void
}

export const TerminationModal: FC<TerminationModalProps> = ({ user, isOpen, isLoading, onSave, onCancel }) => {
  const [form] = Form.useForm<TerminationFormFields>()

  const separationDate = Form.useWatch("separationDate", form)

  const isSameDate = (separationDate?.format(DATE_FORMAT) ?? null) === user.separationDate

  const handleCancel = () => {
    form.resetFields()
    onCancel()
  }

  const handleOk = async () => {
    try {
      const fieldsValues = await form.validateFields()
      const data = deriveTerminationData(fieldsValues)
      await onSave(data)
    } catch {
      // do nothing here
    }
  }

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
      footer={() => (
        <Flex justify="center" gap="15px">
          <Button onClick={handleCancel} data-testid="separation-button-cancel">
            Cancel
          </Button>
          {separationDate && !isSameDate ? (
            <Popconfirm
              title={<div className={style.message}>{deriveConfirmationMessage(user, separationDate)}</div>}
              onConfirm={handleOk}
              onCancel={handleCancel}
              placement="topRight"
            >
              <Button type="primary" data-testid="separation-button-ok">
                OK
              </Button>
            </Popconfirm>
          ) : (
            <Button
              type="primary"
              onClick={handleOk}
              disabled={isSameDate}
              loading={isLoading}
              data-testid="separation-button-ok"
            >
              OK
            </Button>
          )}
        </Flex>
      )}
    >
      <Title>Employment termination</Title>
      <Form form={form} initialValues={deriveFormValues(user)} preserve={false}>
        <Flex align="center" gap="15px">
          <p>Enter the employment separation date</p>
          <Form.Item name="separationDate" className={style.formItem}>
            <DatePicker minDate={now} data-testid="separation-date-picker" />
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  )
}
