import React, { FC, useState } from "react"
import { Collapse, List, Spin } from "antd"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { generateUserProfileRoute } from "@routes/inboundRoutes"
import { availableSystemsApi } from "@state/services/subApis"

import { Title } from "@components/Title"

import style from "./style.m.scss"

export const AvailableSystemsLogs: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { data, isLoading } = availableSystemsApi.endpoints.fetchAvailableSystemsLogs.useQuery(undefined, {
    skip: !isExpanded
  })

  const handleCollapse = () => {
    setIsExpanded(!isExpanded)
  }

  const logsItems = [
    {
      label: <Title style={{ marginBottom: 0 }}>History</Title>,
      children: isLoading ? (
        <Spin />
      ) : (
        <List
          dataSource={data || []}
          renderItem={(log, index) => (
            <List.Item>
              <div className={style.item} data-testid={`log-item-${index}`}>
                {log.event} by
                <Link to={generateUserProfileRoute(log.userId)} className={style.link}>
                  {log.userEmail}
                </Link>
                at {dayjs(log.date).format("YYYY-MM-DD HH:mm:ss")}
              </div>
            </List.Item>
          )}
        />
      )
    }
  ]

  return (
    <Collapse ghost onChange={handleCollapse} items={logsItems} expandIconPosition="end" className={style.collapse} />
  )
}
