import React, { FC, useCallback, useEffect, useState } from "react"
import { Spin } from "antd"
import { Option } from "@interfaces"
import { debounce } from "@helpers"

import { ValidatedSelect } from "@components/ValidatedInputs"

import style from "./style.m.scss"

const MIN_SEARCH_STRING_LENGTH = 3
const DEBOUNCE_DELAY = 1000

type DebounceSelectProps = {
  isFetching: boolean
  value: string | null
  defaultOption?: Option
  onChange: (value: string | null, option?: Option) => void
  optionsFetcher: (searchValue: string) => Promise<{ data?: unknown }>
  convertOptions: (data: unknown) => Array<Option>
  children?: React.ReactNode
  disabled?: boolean
  dataTestId?: string
}

export const DebounceSelect: FC<DebounceSelectProps> = ({
  isFetching,
  value,
  defaultOption,
  onChange,
  optionsFetcher,
  convertOptions,
  children,
  dataTestId,
  disabled = false
}) => {
  const defaultOptions = defaultOption ? [defaultOption] : []
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const [isShortValue, setIsShortValue] = useState(false)
  const [options, setOptions] = useState<Array<Option>>(defaultOptions)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!value) {
      setSearchValue(undefined)
      setOptions(defaultOptions)
      setOpen(false)
      onChange(null)
    } else {
      setSearchValue(value)
    }
  }, [value])

  const handleChange = (value: string, option: Option) => {
    onChange(value, option)
    setSearchValue(typeof option.label === "string" ? option.label : (option.value as string))
    setOpen(false)
  }

  const handleBlur = () => {
    if (!value) {
      setSearchValue(undefined)
      setOptions(defaultOptions)
    }
    setOpen(false)
  }

  const loadOptions = useCallback(
    debounce((value: string) => {
      if (value.length < MIN_SEARCH_STRING_LENGTH) {
        setIsShortValue(true)
        setOptions(defaultOptions)
        return
      }
      setOpen(true)
      setIsShortValue(false)
      setSearchValue(value)
      setOptions(defaultOptions)
      optionsFetcher(value).then(response => {
        onChange(null)
        setOpen(true)
        setSearchValue(value)
        setOptions(convertOptions(response.data))
      })
    }, DEBOUNCE_DELAY),
    [optionsFetcher]
  )

  return (
    <div className={style.container}>
      <ValidatedSelect
        className={style.select}
        value={searchValue}
        open={open}
        filterOption={false}
        showSearch
        onChange={handleChange}
        onSearch={loadOptions}
        onBlur={handleBlur}
        notFoundContent={
          isFetching ? <Spin size="small" /> : isShortValue ? "You should enter 3 symbols or more" : "Not found"
        }
        options={options}
        disabled={disabled}
        data-testid={dataTestId}
      />
      {children}
    </div>
  )
}
