import React, { FC } from "react"
import { Collapse } from "antd"
import { AvailableSystemItem } from "@interfaces"
import { DownOutlined } from "@ant-design/icons"

import style from "./style.m.scss"

const REVERSE_ANGLE = 180

type AvailableSystemsListProps = {
  availableSystems?: Array<AvailableSystemItem>
}

export const AvailableSystemsList: FC<AvailableSystemsListProps> = ({ availableSystems = [] }) => {
  if (availableSystems.length === 0) return null

  if (availableSystems.length === 1) return <>{availableSystems[0].name}</>

  const items = [
    {
      key: "content",
      label: availableSystems[0].name,
      children: availableSystems.slice(1).map(system => <p key={system.id}>{system.name}</p>)
    }
  ]

  return (
    <Collapse
      ghost
      items={items}
      expandIconPosition="end"
      expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? REVERSE_ANGLE : 0} />}
      className={style.collapse}
    />
  )
}
