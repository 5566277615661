import { Permission, Policy } from "@interfaces*"
import { BackendPoliciesResponse } from "@state/types/policies"

const sortPermissionBySections = (permissions: Array<Permission>) => {
  const sortedPermissions: { [k: string]: Array<Permission> } = {}

  permissions.forEach(permission => {
    if (!sortedPermissions[permission.section]) {
      sortedPermissions[permission.section] = [permission]
      return
    }

    sortedPermissions[permission.section] = sortedPermissions[permission.section].concat(permission)
  })

  return sortedPermissions
}

export const transformPolicies = (res: BackendPoliciesResponse): Array<Policy> =>
  res.data.map(policy => {
    const { id, attributes } = policy

    return {
      id,
      name: attributes.name,
      inUse: attributes.in_use,
      permissions: sortPermissionBySections(attributes.full_permissions)
    }
  })
