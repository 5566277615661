import React, { FC } from "react"
import { Form } from "antd"
import { Rule } from "antd/es/form"

import { EditModeField } from "./EditModeField"
import { Align } from "./types"

type FormFieldProps = {
  label: React.ReactNode
  fieldName: string | number | Array<string | number>
  editor: React.ReactNode
  actions?: React.ReactNode
  rules?: Array<Rule>
  dependencies?: Array<string>
  align?: Align
}

export const FormField: FC<FormFieldProps> = ({ label, fieldName, editor, actions, rules, dependencies, align }) => (
  <EditModeField
    title={label}
    value={
      <Form.Item name={fieldName} rules={rules} dependencies={dependencies} style={{ marginBottom: 0 }}>
        {editor}
      </Form.Item>
    }
    actions={actions}
    align={align}
  />
)
