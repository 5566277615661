import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Abilities } from "@interfaces"

type MyAbilitiesData = { abilities: Abilities | null }

const initialState: MyAbilitiesData = {
  abilities: null
}

const myAbilitiesSlice = createSlice({
  name: "myAbilities",
  initialState,
  reducers: {
    setMyAbilities(state, action: PayloadAction<{ abilities: Abilities }>) {
      state.abilities = action.payload.abilities
    },
    clearMyAbilities(state) {
      state.abilities = null
    }
  }
})

export const myAbilitiesActions = myAbilitiesSlice.actions

export default myAbilitiesSlice.reducer
