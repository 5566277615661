import React, { FC, useMemo } from "react"
import { UserFullInfoWithAccesses } from "@interfaces"
import { Button, Flex, Form, Modal, Popconfirm } from "antd"
import { usersApi } from "@state/services/subApis"

import { TransferData } from "../types"

import { Title } from "@components/Title"
import { Field } from "@components/Field"
import { FormField } from "@components/FormField"
import { ValidatedSelect } from "@components/ValidatedInputs"

import { deriveConfirmationMessage } from "./utils"
import { TransferFormFields } from "./types"
import style from "./style.m.scss"

type TransferModalProps = {
  user: UserFullInfoWithAccesses
  isOpen: boolean
  isLoading: boolean
  onSave: (transferData: TransferData) => Promise<void>
  onCancel: () => void
}

export const TransferModal: FC<TransferModalProps> = ({ user, isOpen, isLoading, onSave, onCancel }) => {
  const { data: allOptions } = usersApi.endpoints.fetchUserEditOptions.useQuery()

  const options = useMemo(
    () => allOptions?.locations?.filter(option => option.value.toString() !== user.locationId) ?? [],
    [allOptions, user.locationId]
  )

  const [form] = Form.useForm<TransferFormFields>()

  const location = Form.useWatch("location", form)

  const handleCancel = () => {
    form.resetFields()
    onCancel()
  }

  const handleOk = async () => {
    try {
      const fieldsValues = await form.validateFields()
      const { location } = fieldsValues

      await onSave({ locationId: location.value.toString() })
    } catch {
      // do nothing here
    }
  }

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      maskClosable={false}
      width="600px"
      destroyOnClose
      footer={() => (
        <Flex justify="center" gap="15px">
          <Button onClick={handleCancel} data-testid="transfer-button-cancel">
            Cancel
          </Button>

          <Popconfirm
            title={
              <div className={style.message}>
                {deriveConfirmationMessage(user.location ?? "", location?.label ?? "")}
              </div>
            }
            onConfirm={handleOk}
            onCancel={handleCancel}
            placement="topRight"
          >
            <Button type="primary" disabled={!location} loading={isLoading} data-testid="transfer-button-ok">
              Transfer
            </Button>
          </Popconfirm>
        </Flex>
      )}
    >
      <Title>Employment transfer</Title>
      <Field title="Current location" value={user.location} className={style.oldLocation} />
      <Form form={form} initialValues={{ location: undefined }} preserve={false} className={style.form}>
        <FormField
          label="New location"
          fieldName="location"
          editor={
            <ValidatedSelect
              options={options}
              optionFilterProp="label"
              showSearch
              labelInValue
              data-testid="new-location-select"
            />
          }
        />
      </Form>
    </Modal>
  )
}
