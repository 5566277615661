import React, { FC, useState } from "react"
import { Policy } from "@interfaces*"

import { Search } from "../../components/Search"
import { PoliciesCollapse } from "../../components/PoliciesCollapse"
import { ListWrapper } from "../../components/ListWrapper"

import style from "./style.m.scss"

type PoliciesListProps = {
  policies: Array<Policy>
  isGroupsPage?: boolean
  isSecondary?: boolean
}

export const PoliciesList: FC<PoliciesListProps> = ({ policies, isGroupsPage, isSecondary }) => {
  const [searchingPolicyId, setSearchingPolicyId] = useState<string | null>(null)

  const onSearch = (value: string) => {
    setSearchingPolicyId(value)
  }

  const onClear = () => {
    setSearchingPolicyId(null)
  }

  const policiesOptions = policies
    .filter(policy => !!Object.entries(policy.permissions).length)
    .map(policy => ({ label: policy.name, value: policy.id }))

  const filteredPolicies = searchingPolicyId ? policies.filter(policy => policy.id === searchingPolicyId) : policies

  return (
    <ListWrapper title="Policies" wrapperClassName={isGroupsPage ? style.wrapperGroupPage : style.wrapper}>
      <Search options={policiesOptions} onSearch={onSearch} onClear={onClear} />
      <PoliciesCollapse policies={filteredPolicies} isGroupsPage={isGroupsPage} isSecondary={isSecondary} />
    </ListWrapper>
  )
}
