import { SlackUser } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendSlackUsersResponse } from "@state/types/slackUsers"

import { SLACK_USERS_URL } from "../urls"
import { transformSlackUsers } from "../transformer"

export const fetchSlackUsers = (build: Build) =>
  build.query<Array<SlackUser>, void>({
    query: () => ({ url: SLACK_USERS_URL }),
    transformResponse: (data: BackendSlackUsersResponse) => transformSlackUsers(data),
    providesTags: ["SlackUser"]
  })
