import { DeleteOutlined, PlusCircleOutlined, UploadOutlined } from "@ant-design/icons"
import { Button, Col, Form, FormListFieldData, Row, Space } from "antd"
import Upload, { UploadChangeParam, UploadFile } from "antd/es/upload"
import React, { FC } from "react"

import { checkMaterial, checkMaterialName } from "../../../helpers/validation"

import { ValidatedDigitInput, ValidatedTextInput } from "@components/ValidatedInputs"

import style from "./style.m.scss"

type FileFormListItemProps = {
  fileField: FormListFieldData
  onAddItem: () => void
  onRemoveItem: (index: number) => void
  isLastItem: boolean
}

type FormFile = UploadFile<Blob>

export const FileFormListItem: FC<FileFormListItemProps> = ({ fileField, isLastItem, onAddItem, onRemoveItem }) => {
  const getFile = (e: UploadChangeParam<FormFile>) => {
    if (Array.isArray(e)) {
      return e
    }
    return e ? e.fileList : []
  }

  const handleAdd = () => {
    onAddItem()
  }

  const handleRemove = () => {
    onRemoveItem(fileField.name)
  }

  return (
    <Row gutter={16} key={fileField.key} className={style.fileRow}>
      <Col span={8}>
        <Form.Item
          label="Or add a file"
          labelCol={{ span: 24 }}
          name={[fileField.name, "file"]}
          style={{ marginBottom: 0 }}
          getValueFromEvent={getFile}
          valuePropName="fileList"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return checkMaterial(rule, value, getFieldValue(["newFiles", fileField.name, "name"]))
              }
            })
          ]}
        >
          <Upload
            name="file"
            maxCount={1}
            accept=".pdf"
            beforeUpload={() => {
              return false
            }}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
      </Col>

      <Col span={8}>
        <Form.Item
          label="Name"
          name={[fileField.name, "name"]}
          style={{ marginBottom: 0 }}
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return checkMaterialName(rule, value, getFieldValue(["newFiles", fileField.name, "file"]))
              }
            })
          ]}
          labelCol={{ span: 24 }}
        >
          <ValidatedTextInput placeholder="Name" data-testid="file-name-input" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Review time, days"
          name={[fileField.name, "reviewTime"]}
          style={{ marginBottom: 0 }}
          labelCol={{ span: 24 }}
        >
          <ValidatedDigitInput placeholder="Review time, days" data-testid="review-time-input" />
        </Form.Item>
      </Col>
      <Col span={2} className={style.btnContainer}>
        <Space>
          {isLastItem && <Button icon={<PlusCircleOutlined />} onClick={handleAdd} />}
          <Button icon={<DeleteOutlined />} onClick={handleRemove} />
        </Space>
      </Col>
    </Row>
  )
}
