import React from "react"
import { vacationsSettingsApi } from "@state/services/subApis"
import { List } from "antd"

import { transformVacationsSettingsToCards } from "../helpers/transformVacationsSettingsToCards"

import LocationCard from "./LocationCard"

const LocationsGrid = () => {
  const { vacationsSettings, vacationsSettingsIncluded } =
    vacationsSettingsApi.endpoints.fetchVacationSettings.useQuery(null, {
      selectFromResult: ({ data }) => ({
        vacationsSettings: data?.data || [],
        vacationsSettingsIncluded: data?.included || []
      })
    })

  const { categories } = vacationsSettingsApi.endpoints.fetchVacationSettingsOptions.useQuery(null, {
    selectFromResult: ({ data }) => ({
      categories: data?.categories || []
    })
  })

  const transformedSettings = transformVacationsSettingsToCards({
    vacationsSettings,
    vacationsSettingsIncluded
  })

  return (
    <List
      dataSource={transformedSettings}
      renderItem={settings => (
        <List.Item>
          <LocationCard settings={settings} key={settings.location.id} categories={categories} />
        </List.Item>
      )}
    />
  )
}

export default LocationsGrid
