import { CreatePolicyBody } from "@interfaces"
import { Build } from "@state/services/types"

import { POLICIES_URL } from "../urls"

export const createPolicy = (build: Build) =>
  build.mutation<unknown, CreatePolicyBody>({
    query: body => ({
      url: POLICIES_URL,
      method: "POST",
      body
    }),
    invalidatesTags: ["Policy"]
  })
