import React, { FC, useRef } from "react"
import { Button, Flex } from "antd"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"
import cn from "classnames"

import { INTERVAL, MAX_ZOOM, MIN_ZOOM, ZOOM_STEP } from "../helpers/constants"

import style from "./style.m.scss"

type ZoomChangerProps = {
  zoom: number
  setZoom: (deriveZoom: (value: number) => number) => void
}

const ZoomChanger: FC<ZoomChangerProps> = ({ zoom, setZoom }) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  const startInterval = (callback: () => void) => {
    if (intervalRef.current) return

    callback()
    intervalRef.current = setInterval(callback, INTERVAL)
  }

  const stopInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  const handlePlus = () => {
    setZoom(prevZoom => {
      if (prevZoom + ZOOM_STEP > MAX_ZOOM) {
        stopInterval()
        return prevZoom
      }
      return prevZoom + ZOOM_STEP
    })
  }

  const handleMinus = () => {
    setZoom(prevZoom => {
      if (prevZoom - ZOOM_STEP < MIN_ZOOM) {
        stopInterval()
        return prevZoom
      }
      return prevZoom - ZOOM_STEP
    })
  }

  return (
    <Flex className={style.container}>
      <div className={cn(style.buttonContainer, style.minus)}>
        <Button
          type="text"
          className={style.button}
          onMouseDown={() => startInterval(handleMinus)}
          onMouseUp={stopInterval}
          onMouseLeave={stopInterval}
          icon={<MinusOutlined />}
        />
      </div>
      <div className={style.percentage}>{`${zoom}%`}</div>
      <div className={cn(style.buttonContainer, style.plus)}>
        <Button
          type="text"
          className={style.button}
          size="large"
          onMouseDown={() => startInterval(handlePlus)}
          onMouseUp={stopInterval}
          onMouseLeave={stopInterval}
          icon={<PlusOutlined />}
        />
      </div>
    </Flex>
  )
}

export default ZoomChanger
