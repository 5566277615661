import backendApi from "@state/services/backendApi"

import { fetchBannedIps } from "./GET/fetchBannedIps"
import { deleteBannedIp } from "./DELETE/deleteBannedIp"

export const bannedIpsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchBannedIps: fetchBannedIps(build),
    deleteBannedIp: deleteBannedIp(build)
  })
})
