import { useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@hooks"
import { LoginParams, RequestPasswordResetQuery, TwoFaParams } from "@interfaces"
import { AuthView } from "@enums"
import { authActions } from "@state/reducers/auth"
import { meActions } from "@state/reducers/me"

import { PasswordParams } from "../types"

import useLogin from "./useLogin"
import useTwoFa from "./useTwoFa"

export const useAuthView = () => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const view = useAppSelector(state => state.authReducer.view)
  const { otpIsSet, otpIsRequired } = useAppSelector(state => state.authReducer.otp)
  const email = useAppSelector(state => state.authReducer.email)

  const { onLogin, onLogout, onRequestPasswordRequest, onResetPassword, onSetup2fa, onAuth2fa } = useLogin()
  const { qr, otpSecret, otpRawSecret } = useTwoFa()

  const initLoginPage = () => {
    dispatch(authActions.setView(AuthView.Login))
  }

  const initRestorePasswordPage = () => {
    dispatch(authActions.setView(AuthView.RestorePassword))
  }

  const setupTwoFA = async (data: TwoFaParams) => {
    const result = await onSetup2fa(data)

    if (!result) {
      dispatch(authActions.setView(AuthView.EnterTwoFA))
    }

    if (result) {
      dispatch(meActions.passTwoFa())
      dispatch(authActions.cleanOtpCodes())
    }
  }

  const authTwoFA = async (data: TwoFaParams) => {
    const result = await onAuth2fa(data)

    if (!result) {
      dispatch(authActions.setView(AuthView.EnterTwoFA))
      dispatch(authActions.setOtpFlags({ otpIsSet, otpIsRequired }))
    }

    if (result) {
      dispatch(meActions.passTwoFa())
      dispatch(authActions.cleanOtpCodes())
    }
  }

  const handleVerify2FA = (data: TwoFaParams) => {
    otpIsSet ? authTwoFA(data) : setupTwoFA(data)
  }

  const handleLogin = async (data: LoginParams) => {
    dispatch(authActions.setEmail(data.login))

    const otpFlags = await onLogin(data)

    if (!otpFlags) {
      dispatch(authActions.setView(AuthView.Login))
      return
    }

    if (!otpFlags.otpIsRequired) {
      dispatch(meActions.passTwoFa())
      return
    }

    if (!otpFlags?.otpIsSet) {
      dispatch(authActions.setView(AuthView.SetTwoFA))
      return
    }

    if (otpFlags?.otpIsSet && otpFlags?.otpIsRequired) {
      dispatch(authActions.setView(AuthView.EnterTwoFA))
    }
  }

  const handleGoToRestoreView = (email: string) => {
    dispatch(authActions.setEmail(email))
    dispatch(authActions.setView(AuthView.RestorePasswordRequest))
  }

  const handleBackToLoginView = () => {
    dispatch(authActions.setView(AuthView.Login))
  }

  const handleGoToEnter2FA = () => {
    dispatch(authActions.setOtpCodes({ otpRawSecret, otpSecret }))
    dispatch(authActions.setView(AuthView.EnterTwoFA))
  }

  const handleBackFromSetup2FA = async () => {
    const result = await onLogout()

    if (result) {
      dispatch(authActions.setView(AuthView.Login))
    }
  }

  const handleBackFromEnter2FA = async () => {
    if (!otpIsSet) {
      dispatch(authActions.setView(AuthView.SetTwoFA))
      return
    }

    const result = await onLogout()

    if (result) {
      dispatch(authActions.setView(AuthView.Login))
    }
  }

  const handleSendRestoreLink = async (data: RequestPasswordResetQuery) => {
    const result = await onRequestPasswordRequest(data)

    if (result) {
      dispatch(authActions.setEmail(data.login))
      dispatch(authActions.setView(AuthView.RestorePasswordRequestDone))
    }
  }

  const handleResetPassword = async (data: PasswordParams, onFieldError: (error: Array<string>) => void) => {
    const { isError, fieldError } = await onResetPassword({ ...data, key: searchParams.get("key") || "" })

    if (fieldError) {
      onFieldError(fieldError)
    }

    if (!isError) {
      dispatch(authActions.setView(AuthView.Login))
    }
  }

  return {
    qr,
    view,
    email,
    otpIsSet,
    initLoginPage,
    initRestorePasswordPage,
    onLogin: handleLogin,
    onGoToRestoreView: handleGoToRestoreView,
    onBackToLoginView: handleBackToLoginView,
    onSendRestoreLink: handleSendRestoreLink,
    onResetPassword: handleResetPassword,
    onGoToEnter2FA: handleGoToEnter2FA,
    onVerify2FA: handleVerify2FA,
    onBackFromSetup2FA: handleBackFromSetup2FA,
    onBackFromEnter2FA: handleBackFromEnter2FA
  }
}
