import backendApi from "../../backendApi"

import { fetchVacationsQuotas } from "./GET/fetchVacationsQuotas"
import { changeVacationsQuotas } from "./PUT/changeVacationsQuotas"
import { calculateBalance } from "./GET/calculateBalance"

export const vacationsQuotasApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchVacationsQuotas: fetchVacationsQuotas(build),
    changeVacationsQuotas: changeVacationsQuotas(build),
    calculateBalance: calculateBalance(build)
  }),

  overrideExisting: true
})
