import React, { FC } from "react"

import { PhotoViewer } from "@components/PhotoViewer"

import style from "./style.m.scss"

type PhotoEditModeProps = {
  imageSrc?: string
  buttons?: React.ReactNode
}

export const PhotoEditMode: FC<PhotoEditModeProps> = ({ imageSrc, buttons }) => (
  <div className={style.wrapper}>
    <PhotoViewer src={imageSrc} />
    {buttons}
  </div>
)
