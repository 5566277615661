import React from "react"
import {
  DeactivatedByInBulkLog,
  DepartmentInBulkLog,
  EducationalMaterialInBulkLog,
  EmployeeInBulkLog
} from "@interfaces"
import { Link } from "react-router-dom"
import { generateEducationalMaterialURL } from "@state/services/subApis/educationalMaterials/urls"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { generateUserProfileRoute } from "@routes/inboundRoutes"
import { BulkStatuses } from "@enums"
import { formatLongNames } from "@helpers"

type AlignType = "left" | "right" | "center"

export const materialsTableColumns = [
  {
    title: "Educational materials",
    key: "educationalMaterials",
    render: (_: unknown, { id, name, externalUrl }: EducationalMaterialInBulkLog) => {
      if (!externalUrl) {
        return (
          <Link to={`/api${generateEducationalMaterialURL(id)}`} target="_blank">
            {name}
          </Link>
        )
      }

      return (
        <a href={externalUrl} target="_blank" rel="noreferrer">
          {name}
        </a>
      )
    }
  },
  {
    title: "Due date",
    key: "reviewDays",
    align: "right" as AlignType,
    render: (_: unknown, { reviewDays }: EducationalMaterialInBulkLog) =>
      reviewDays ? `${reviewDays} day(s)` : "Anytime"
  }
]

export const deriveDepartmentsColumns = (status: BulkStatuses) => {
  const defaultColumns = [
    {
      title: "Applied to Departments",
      key: "appliedToDepartments",
      render: (_: unknown, { name }: DepartmentInBulkLog) => name
    },
    {
      title: (
        <span>
          Exclude <br /> Head of dept
        </span>
      ),
      key: "isExcludeHeadOfDepartment",
      align: "center" as AlignType,
      render: (_: unknown, { rules }: DepartmentInBulkLog) =>
        rules.excludeHeadOfDepartment ? <CheckOutlined /> : <CloseOutlined />
    }
  ]

  const optionalColumns = [
    {
      title: (
        <span>
          Applied to <br /> current employees
        </span>
      ),
      key: "isAppliedToCurrentEmployees",
      align: "center" as AlignType,
      render: (_: unknown, { rules }: DepartmentInBulkLog) =>
        rules.appliesToCurrentEmployees ? <CheckOutlined /> : <CloseOutlined />
    },
    {
      title: (
        <span>
          Applied to <br /> new employees
        </span>
      ),
      key: "isAppliedToNewEmployees",
      align: "center" as AlignType,
      render: (_: unknown, { rules }: DepartmentInBulkLog) =>
        rules.appliesToNewEmployees ? <CheckOutlined /> : <CloseOutlined />
    }
  ]

  return status === BulkStatuses.History ? [...defaultColumns, ...optionalColumns] : [...defaultColumns]
}

export const employeesColumns = [
  {
    title: "Applied to employees",
    key: "employees",
    render: (_: unknown, { id, name }: EmployeeInBulkLog) => (
      <Link to={generateUserProfileRoute(id)}>{formatLongNames(name)}</Link>
    )
  }
]

export const deactivatedColumns = [
  {
    title: "Deactivated by",
    key: "deactivated-at",
    align: "center" as AlignType,
    render: (_: unknown, { deactivatedBy }: { deactivatedAt: string | null; deactivatedBy: DeactivatedByInBulkLog }) =>
      deactivatedBy?.id && deactivatedBy?.name ? (
        <Link to={generateUserProfileRoute(deactivatedBy.id)}>{formatLongNames(deactivatedBy.name)}</Link>
      ) : (
        "-"
      )
  },
  {
    title: "Deactivated at",
    key: "deactivated-at",
    align: "center" as AlignType,
    render: (_: unknown, { deactivatedAt }: { deactivatedAt: string | null; deactivatedBy: DeactivatedByInBulkLog }) =>
      deactivatedAt ? dayjs(deactivatedAt).format("DD.MM.YYYY HH:mm") : "-"
  }
]
