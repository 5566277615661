import React, { FC, useState } from "react"
import { useAppDispatch, useAppSelector } from "@hooks"
import { newPolicyActions } from "@state/reducers/newPolicy"

import { Search } from "../../components/Search"
import { PermissionsCollapse } from "../../components/PermissionsCollapse"
import { ListWrapper } from "../../components/ListWrapper"

import style from "./style.m.scss"

type PermissionsListProps = {
  isGroupPage?: boolean
}

export const PermissionsList: FC<PermissionsListProps> = ({ isGroupPage }) => {
  const dispatch = useAppDispatch()
  const { sections } = useAppSelector(state => state.newPolicyReducer)

  const [searchingSection, setSearchingSection] = useState<string | null>(null)

  const onSearch = (value: string) => {
    setSearchingSection(value)
  }

  const onSearchClear = () => {
    setSearchingSection(null)
  }

  const findedSection = searchingSection ? { [searchingSection]: sections[searchingSection] } : sections
  const searchOptions = Object.keys(sections).map(key => ({ label: key, value: key }))

  const generateHandleEnableSection = (section: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      dispatch(newPolicyActions.enableSection({ section }))
    }
  }

  const generateHandleTogglePermission = (section: string, permissionListId: number) => {
    return () => {
      dispatch(newPolicyActions.togglePermission({ section, permissionListId }))
    }
  }

  return (
    <ListWrapper title="Permissions by section" wrapperClassName={isGroupPage ? style.wrapperGroupPage : style.wrapper}>
      <Search onSearch={onSearch} onClear={onSearchClear} options={searchOptions} />
      <PermissionsCollapse
        sections={findedSection}
        generateSectionsCallback={generateHandleEnableSection}
        generatePermissionCallback={generateHandleTogglePermission}
      />
    </ListWrapper>
  )
}
