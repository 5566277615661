import { Button, Flex, Typography } from "antd"
import React, { FC, useState } from "react"

import { VacationsSettings } from "./VacationsLocations"
import { LeaveTypes } from "./LeaveTypes"
import { Notifications } from "./Notifications"
import style from "./style.m.scss"

const VacationsSettingsPaging: FC = () => {
  const [page, setPage] = useState<"locations" | "leaveTypes" | "notifications">("locations")

  const handleChangePage = (pageName: "locations" | "leaveTypes" | "notifications") => {
    setPage(pageName)
  }

  const derivePage = () => {
    switch (page) {
      case "locations":
        return <VacationsSettings />
      case "leaveTypes":
        return <LeaveTypes />
      case "notifications":
        return <Notifications />
      default:
        return null
    }
  }

  return (
    <>
      <Flex>
        <Typography.Title datatest-id="dept-name" level={3} className={style.header}>
          Vacations settings
        </Typography.Title>
      </Flex>
      <Flex className={style.buttonsContainer} gap={20}>
        <div className={page === "locations" ? style.buttonContainerActive : style.buttonContainer}>
          <Button
            className={page === "locations" ? style.buttonActive : style.button}
            type="link"
            onClick={() => {
              handleChangePage("locations")
            }}
          >
            Locations
          </Button>
        </div>
        <div className={page === "leaveTypes" ? style.buttonContainerActive : style.buttonContainer}>
          <Button
            className={page === "leaveTypes" ? style.buttonActive : style.button}
            type="link"
            onClick={() => {
              handleChangePage("leaveTypes")
            }}
          >
            Leave types
          </Button>
        </div>
        <div className={page === "notifications" ? style.buttonContainerActive : style.buttonContainer}>
          <Button
            className={page === "notifications" ? style.buttonActive : style.button}
            type="link"
            onClick={() => {
              handleChangePage("notifications")
            }}
          >
            Notifications
          </Button>
        </div>
      </Flex>
      {derivePage()}
    </>
  )
}

export default VacationsSettingsPaging
