import { GetUserProfileIncludeParams } from "@interfaces*"

const extraAttributes: Partial<GetUserProfileIncludeParams> = {
  department: true,
  directManager: true,
  isTerminated: true,
  leaves: true,
  location: true,
  photoId: true,
  position: true,
  status: true,
  subordinates: true,
  workFormat: true,
  workSchedule: true
}

export const deriveUserProfileExtraFields = (
  includes: Partial<GetUserProfileIncludeParams> = {},
  useExtraAttributes: boolean = false
) => {
  const attributes = useExtraAttributes ? { ...includes, ...extraAttributes } : includes
  const includedFields = []

  if (attributes.includeLeaves) includedFields.push("leaves")
  if (attributes.includeSubordinates) includedFields.push("subordinates")
  if (attributes.includeAvailableSystems) includedFields.push("available_systems")
  if (attributes.department) includedFields.push("department")
  if (attributes.directManager) includedFields.push("direct_manager")
  if (attributes.isTerminated) includedFields.push("is_terminated")
  if (attributes.leaves) includedFields.push("leaves")
  if (attributes.location) includedFields.push("location")
  if (attributes.photoId) includedFields.push("photo_id")
  if (attributes.position) includedFields.push("position")
  if (attributes.status) includedFields.push("status")
  if (attributes.subordinates) includedFields.push("subordinates")
  if (attributes.workFormat) includedFields.push("work_format")
  if (attributes.workSchedule) includedFields.push("work_schedule")

  return includedFields.join(",")
}

export const PROFILE_URL = "/profile"
export const PROFILE_EDIT_URL = `${PROFILE_URL}`
export const PROFILE_PHOTO_URL = `${PROFILE_URL}/photo`

export const generateGetProfileURL = (includes: Partial<GetUserProfileIncludeParams> = {}) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  const extraFields = deriveUserProfileExtraFields(includes, true)

  if (extraFields.length > 0) {
    searchParams.set("extra_fields[users]", extraFields)
  }

  return `${PROFILE_URL}${separator}${searchParams.toString()}`
}
