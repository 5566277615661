import { VacationSettingResponse } from "@state/types/vacations"
import { Build } from "@state/services/types"

import { generateVacationSettingsURL } from "../urls"

export const fetchVacationSettings = (build: Build) =>
  build.query<VacationSettingResponse, null>({
    query: () => ({
      url: generateVacationSettingsURL()
    }),
    providesTags: result =>
      result && result.data.length
        ? [...result.data.map(setting => ({ type: "VacationSettings", id: setting.id })), "LeaveType"]
        : ["VacationSettings", "LeaveType"]
  })
