import React from "react"
import { useAppSelector } from "@hooks"
import { DidlogicLogo } from "@assets/svg/products/DidlogicLogo"
import { VoisoLogo } from "@assets/svg/products/VoisoLogo"
import { WavixLogo } from "@assets/svg/products/WavixLogo"

import style from "./style.m.scss"

const LogoMapping: Record<string, React.ReactNode> = {
  didlogic: <DidlogicLogo />,
  voiso: <VoisoLogo />,
  wavix: <WavixLogo />
}

export const Logo: React.FC = () => {
  const product = useAppSelector(state => state.meReducer.user?.current_product) || ""

  const logo = LogoMapping[product]

  return logo ? <div className={style.wrapper}>{logo}</div> : null
}
