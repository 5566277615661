import { Build } from "@state/services/types"

import { generatePerformanceReviewDeleteURL } from "../urls"

export const deletePerformanceReview = (build: Build) =>
  build.mutation<{ message: string }, string>({
    query: id => ({
      url: generatePerformanceReviewDeleteURL(id),
      method: "DELETE"
    }),
    invalidatesTags: ["PerformanceReview"]
  })
