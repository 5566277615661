import { DepartmentCard, DepartmentProducts } from "@interfaces*"
import React, { FC, useState } from "react"

import style from "./style.m.scss"
import JobsList from "./JobsList"
import JobsForm from "./JobForm"

type DepartmentJobsProps = {
  department: DepartmentCard
  departmentProducts: DepartmentProducts
}

const DepartmentJobs: FC<DepartmentJobsProps> = ({ department, departmentProducts }) => {
  const [editMode, setEditMode] = useState(false)

  const onEdit = () => {
    setEditMode(true)
  }

  const onSave = () => {
    setEditMode(false)
  }

  return (
    <div className={style.container}>
      {editMode ? (
        <JobsForm department={department} departmentProducts={departmentProducts} toggleEditMode={onSave} />
      ) : (
        <JobsList department={department} toggleEditMode={onEdit} />
      )}
    </div>
  )
}

export default DepartmentJobs
