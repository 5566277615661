import { useEffect } from "react"
import { useAppSelector } from "@hooks"
import voiso from "@assets/favicon/voiso.ico"
import didlogic from "@assets/favicon/didlogic.png"
import wavix from "@assets/favicon/wavix.ico"
import { deriveProductFromUrl } from "@helpers"

const IconMapping: Record<string, string> = {
  didlogic,
  voiso,
  wavix
}

export const useFavicon = () => {
  const product =
    useAppSelector(state => state.meReducer.user?.current_product) ||
    deriveProductFromUrl(window.location.hostname) ||
    ""

  useEffect(() => {
    const iconLink = document.querySelector("link[rel*='icon']") as HTMLLinkElement

    if (iconLink) {
      iconLink.href = IconMapping[product] || ""
    }
  }, [product])
}
