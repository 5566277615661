import React, { FC } from "react"
import { useProfileLocation, useUserProfileEmploymentInfoAbilities } from "@hooks"
import { UserFullInfoWithAccesses } from "@interfaces"

import { PersonalDocumentsHub } from "./PersonalDocumentsHub"

type PersonalDocumentsProps = {
  user: UserFullInfoWithAccesses
}

export const PersonalDocuments: FC<PersonalDocumentsProps> = ({ user }) => {
  const { update_all: hasFullAccessToPersonalDocuments } = useUserProfileEmploymentInfoAbilities()
  const { isMyself } = useProfileLocation()

  if (!hasFullAccessToPersonalDocuments && !isMyself) return null

  return <PersonalDocumentsHub user={user} />
}
