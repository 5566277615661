import { EmploymentSettings } from "@interfaces"
import { Build } from "@state/services/types"
import { BackendEmploymentSettingsResponse } from "@state/types/employmentSettings"

import { EMPLOYMENT_SETTINGS_URL } from "../urls"
import { transformEmploymentSettings } from "../transformEmploymentSettings"

export const fetchEmploymentSettings = (build: Build) =>
  build.query<EmploymentSettings, string>({
    query: () => ({
      url: EMPLOYMENT_SETTINGS_URL
    }),
    providesTags: ["EmploymentSettings"],
    transformResponse: (response: BackendEmploymentSettingsResponse) => transformEmploymentSettings(response)
  })
