import { lazy } from "react"
import { Abilities } from "@interfaces"

import { DefaultWrapper } from "@components/PageWrappers"

import { RouteItem } from "./types"
import { USER_CREATE_ROUTE, USER_PROFILE_EDIT_ROUTE, USER_PROFILE_ROUTE } from "./inboundRoutes"

const Profile = lazy(() => import("@app/sections/Profile"))
const NewUser = lazy(() => import("@app/sections/Profile/NewUser"))

const usersRoutes = (abilities: Abilities | null): Array<RouteItem> => [
  {
    path: USER_CREATE_ROUTE,
    title: "Create user",
    slug: "user-create",
    component: NewUser,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.users?.create
  },
  {
    path: USER_PROFILE_EDIT_ROUTE,
    title: "Edit user",
    slug: "user-edit",
    component: Profile,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.users?.update
  },
  {
    path: USER_PROFILE_ROUTE,
    title: "Profile",
    slug: "profile",
    component: Profile,
    componentWrap: DefaultWrapper,
    hidden: !abilities?.profiles?.show
  }
]

export default usersRoutes
