import React, { FC } from "react"

import { TagsViewer } from "./TagsViewer"
import { EditableTags } from "./EditableTags"

type TagsProps = {
  value?: Array<string>
  isEditable?: boolean
}

export const Tags: FC<TagsProps> = ({ value = [], isEditable = false }) =>
  isEditable ? <EditableTags value={value} /> : <TagsViewer tags={value} />
