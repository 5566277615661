import { authenticationApi } from "@state/services/subApis"
import { useAppSelector } from "@hooks"
import DOMPurify from "dompurify"

const useTwoFa = () => {
  const otpIsSet = useAppSelector(state => state.authReducer.otp.otpIsSet)

  const { qr, otpSecret, otpRawSecret } = authenticationApi.endpoints.fetchQrForOTP.useQuery("", {
    selectFromResult: ({ data }) => ({
      qr: data?.qr,
      otpSecret: data?.otp_secret ?? "",
      otpRawSecret: data?.otp_raw_secret ?? ""
    }),
    skip: otpIsSet === undefined ? true : otpIsSet
  })

  const purifiedQr = qr ? DOMPurify.sanitize(qr) : ""

  return {
    qr: purifiedQr,
    otpSecret,
    otpRawSecret
  }
}

export default useTwoFa
