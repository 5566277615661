import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { UserData } from "@interfaces"

type MeSliceData = {
  user: UserData | null
  isUserLoggedIn: boolean
  isTwoFAPassed: boolean
}

const initialState: MeSliceData = {
  user: null,
  isUserLoggedIn: false,
  isTwoFAPassed: false
}

const meSlice = createSlice({
  name: "meSlice",
  initialState,
  reducers: {
    setMe(state, action: PayloadAction<{ user: UserData }>) {
      state.user = action.payload.user
      state.isUserLoggedIn = true
    },
    clearMe(state) {
      state.user = null
      state.isUserLoggedIn = false
    },
    passTwoFa(state) {
      state.isTwoFAPassed = true
    }
  }
})

export const meActions = meSlice.actions

export default meSlice.reducer
