import { NewMaterial } from "@interfaces"
import { Build } from "@state/services/types"

import { EDUCATIONAL_MATERIALS_URL } from "../urls"
import { deriveCreateParams } from "../transformer"

export const createEducationalMaterial = (build: Build) =>
  build.mutation<unknown, NewMaterial>({
    query: data => ({
      url: EDUCATIONAL_MATERIALS_URL,
      method: "POST",
      body: deriveCreateParams(data)
    }),
    invalidatesTags: ["EducationalMaterial"]
  })
