import React, { FC } from "react"

import { useAppLoader } from "./helpers/useAppLoader"

import App from "./index"

const AppLoader: FC = () => {
  const { isLoaded } = useAppLoader()

  return isLoaded ? <App /> : <></>
}

export default AppLoader
