import React, { FC } from "react"
import { Flex } from "antd"

import { Title } from "@components/Title"

import style from "./style.m.scss"

export const Header: FC = ({ children }) => (
  <Title>
    <Flex align="flex-end" justify="space-between" className={style.header}>
      {children}
    </Flex>
  </Title>
)
