import { Build } from "@state/services/types"
import { UserPersonalSettingsResponse } from "@state/types/users"
import { UserPersonalSettings } from "interfaces"

import { generatePersonalSettingsUrl } from "../urls"

export const fetchUserPersonalSettings = (build: Build) =>
  build.query<UserPersonalSettings, string>({
    query: userId => ({
      url: generatePersonalSettingsUrl(userId)
    }),
    transformResponse: (res: UserPersonalSettingsResponse) => ({ otpIsEnabled: res.otp_is_enabled }),
    providesTags: ["2FA"]
  })
