import React, { FC } from "react"
import { Flex } from "antd"
import { DownOutlined } from "@ant-design/icons"
import cn from "classnames"

import { Title } from "@components/Title"

import style from "./style.m.scss"

type ExpandableSectionProps = {
  isExpanded: boolean
  title: React.ReactNode
  actions?: React.ReactNode
  onToggleExpanded: () => void
}

export const ExpandableSection: FC<ExpandableSectionProps> = ({
  isExpanded,
  title,
  actions,
  onToggleExpanded,
  children
}) => (
  <>
    <Flex align="flex-end" justify="space-between">
      <Title onClick={onToggleExpanded}>
        {title}
        <DownOutlined
          className={cn(style.expandCollapseIcon, isExpanded && style.collapseIcon)}
          data-testid="expand-collapse"
        />
      </Title>
      <div>{actions}</div>
    </Flex>
    <div className={style.wrapper}>
      <div className={cn(style.expandableContent, isExpanded && style.expanded)}>{children}</div>
    </div>
  </>
)
