import { TransferUserParams } from "@interfaces"
import { Build } from "@state/services/types"

import { generateTransferUserURL } from "../urls"

export const transferUser = (build: Build) =>
  build.mutation<unknown, TransferUserParams>({
    query: params => ({
      url: generateTransferUserURL(params.userId),
      method: "PUT",
      body: {
        location_id: params.locationId
      }
    }),
    invalidatesTags: ["User", "Vacation", "PersonalDocument"]
  })
