import { ErrorResponse, PhotoError } from "@interfaces"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"

import { UNPROCESSABLE_ENTITY_CODE } from "./constants"

export const isErrorResponse = (error: any): error is ErrorResponse => Array.isArray(error.data?.errors)

export const derivePhotoErrorMessage = (error: unknown) => {
  const baseError = error as FetchBaseQueryError
  let message = ""
  if (baseError.status === UNPROCESSABLE_ENTITY_CODE && baseError.data) {
    ;[message] = (baseError.data as PhotoError).errors.filename
  }

  return message || "Error uploading photo"
}
