import { GetUserProfileIncludeParams, PagedUsersQueryParams, SearchUsersQuery } from "@interfaces*"

import { deriveUserProfileExtraFields } from "../userProfile/urls"
import { REMOVE_OTP_URL } from "../auth/urls"

import { getUsersExtraFields } from "./utils"

export const USERS_URL = "/users"
export const USERS_CREATE_URL = `${USERS_URL}`
export const USER_EDIT_OPTIONS_URL = `${USERS_URL}/edit_options`
export const USER_FILTERS_OPTIONS_URL = `${USERS_URL}/filters_options`

export const generateUpdateUserHireDateURL = (userId: string) => `${USERS_URL}/${userId}/hire`
export const generateUpdateUserPermissionsURL = (userId: string) => `${USERS_URL}/${userId}/permissions`
export const generateUserPhotoModificationURL = (userId: string) => `${USERS_URL}/${userId}/photo`
export const generateUserTeamURL = (userId: string) => `${USERS_URL}/${userId}/team`
export const generateUserNotificationSettingsURL = (userId: string) => `${USERS_URL}/${userId}/notification_settings`
export const generateSeparateUserURL = (userId: string) => `${USERS_URL}/${userId}/separate`
export const generateTransferUserURL = (userId: string) => `${USERS_URL}/${userId}/transfer`

export const generateUserNotificationSettingsOptionsURL = (userId: string) =>
  `${USERS_URL}/${userId}/notification_settings/options`

export const generateSearchUsersURL = (params: SearchUsersQuery) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("search_type", params.type)
  searchParams.set("search_string", params.value)

  if (params.exclude) searchParams.set("exclude", params.exclude.join(","))

  return `${USERS_URL}/search${separator}${searchParams.toString()}`
}

export const generateGetUserByIdURL = (userId: string, includes: Partial<GetUserProfileIncludeParams> = {}) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  const extraFields = deriveUserProfileExtraFields(includes, true)

  if (extraFields.length > 0) {
    searchParams.set("extra_fields[users]", extraFields)
  }

  return `${USERS_URL}/${userId}${separator}${searchParams.toString()}`
}

export const generateGetPagedUsersURL = (params: PagedUsersQueryParams) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  const { page, size, departments, email, fullName, locations, positions, products, status, employeeTypes } = params

  if (page !== undefined) searchParams.set("page[size]", String(size))
  if (size !== undefined) searchParams.set("page[number]", String(page))

  if (fullName) searchParams.set("filter[full_name][match]", fullName)
  if (email) searchParams.set("filter[email][match]", email)

  if (locations && locations.length > 0) searchParams.set("filter[location_id]", locations.join(","))
  if (departments && departments.length > 0) searchParams.set("filter[department]", departments.join(","))
  if (positions && positions.length > 0) searchParams.set("filter[positions][match]", positions.join(","))
  if (products && products.length > 0) searchParams.set("filter[products]", products.join(","))
  if (employeeTypes && employeeTypes.length > 0) searchParams.set("filter[employee_types]", employeeTypes.join(","))

  if (status !== undefined) searchParams.set("filter[active]", status)

  searchParams.set("extra_fields[users]", getUsersExtraFields.join(","))

  return `${USERS_URL}${separator}${searchParams.toString()}`
}

export const generateUserPhotoURL = (userId: string) => `/api/user_photos/${userId}`

export const generateUserLogURL = (userId: string) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("include", "author")
  searchParams.set("fields[users]", "email")

  return `${USERS_URL}/${userId}/logs${separator}${searchParams.toString()}`
}

export const generatePersonalSettingsUrl = (id: string | number) => `/personal_settings/${id}`

export const generateRemoveUserTwoFaUrl = (id: string | number) => `${REMOVE_OTP_URL}?user_id=${id}`
