import { Build } from "@state/services/types"
import { ChangeAssignmentHrRequest, ChangeAssignmentHrResponse } from "@state/types/vacations"

import { generateHrAssignmentURL } from "../urls"

export const changeAssignmentHr = (build: Build) =>
  build.mutation<ChangeAssignmentHrResponse, ChangeAssignmentHrRequest>({
    query: ({ id, user_id, setting_id }) => ({
      url: generateHrAssignmentURL(id),
      method: "PUT",
      body: {
        data: {
          id: Number(id),
          type: "assignment_hrs",
          attributes: {
            user_id,
            setting_id
          },
          format: ":jsonapi"
        }
      }
    }),
    invalidatesTags: result =>
      result && result.data ? [{ type: "AssignmentHr", id: result.data.id }] : ["AssignmentHr"]
  })
