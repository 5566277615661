import backendApi from "@state/services/backendApi"

import { fetchLocationsForSettings } from "./GET/fetchLocationsForSettings"
import { fetchLocations } from "./GET/fetchLocations"
import { fetchDataStorageOptions } from "./GET/fetchDataStorageOptions"
import { createLocation } from "./POST/createLocation"
import { updateLocation } from "./PUT/updateLocation"
import { deleteLocation } from "./DELETE/deleteLocation"

export const locationsApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchLocationsForSettings: fetchLocationsForSettings(build),
    fetchLocations: fetchLocations(build),
    fetchDataStorageOptions: fetchDataStorageOptions(build),
    createLocation: createLocation(build),
    updateLocation: updateLocation(build),
    deleteLocation: deleteLocation(build)
  })
})
