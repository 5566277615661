import { Section } from "@enums"
import { abilitiesApi } from "@state/services/subApis"

import { useAppSelector } from "../utils/redux"

export const useAllAbilities = () => {
  const { abilities } = abilitiesApi.endpoints.fetchAbilities.useQuery("", {
    selectFromResult: ({ data }) => ({
      abilities: data || null
    })
  })

  return abilities
}

export const useRoleAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities
}

export const useProjectAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.Projects] || {}
}

export const useTeamsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.Teams] || {}
}

export const useLeadsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.Leads] || {}
}

export const useUsersAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.users || {}
}

export const useProfileAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.profiles || {}
}

export const useVacationsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.vacations || {}
}

export const useInternsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.intern_profiles || {}
}

export const useMentorsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.mentors || {}
}

export const useHolidaysAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.["vacations/holidays"] || {}
}

export const usePermissionsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.permissions || {}
}

export const useRelationshipsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.relationships || {}
}

export const useDepartmentsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.Departments] || {}
}

export const useRolesAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.Roles] || {}
}

export const useVacationsAssignmentHrsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.VacationsAssignmentHrs] || {}
}

export const useSlackGroupsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.SlackGroups] || {}
}

export const useSlackUsersAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.SlackUsers] || {}
}

export const useUserProfileEmploymentInfoAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.UserProfileEmploymentInfo] || {}
}

export const useLocationsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.Locations] || {}
}

export const useVacationSettingsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.VacationSettings] || {}
}

export const useEducationalMaterialsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.EducationalMaterials] || {}
}

export const useSalariesAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.Salaries] || {}
}

export const useJobHistoryAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.JobHistory] || {}
}

export const useCertificatesAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.Certificates] || {}
}

export const useConferencesAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.Conferences] || {}
}

export const usePerformanceReviewsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.PerformanceReviews] || {}
}

export const useBannedIpsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.BannedIps] || {}
}

export const usePersonalSettingsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.PersonalSettings] || {}
}

export const useUserCommissionAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.UserCommission] || {}
}

export const useVacationAccumulationsAbilities = () => {
  const { abilities } = useAppSelector(store => store.myAbilitiesReducer)

  return abilities?.[Section.VacationAccumulations] || {}
}
