import { Conference } from "@interfaces"
import { Build } from "@state/services/types"
import { ConferencesResponse } from "@state/types/conferences"

import { generateGetConferencesURL } from "../urls"
import { transformConferences } from "../transformConferences"

export const fetchConferences = (build: Build) =>
  build.query<Array<Conference>, string>({
    query: id => ({
      url: generateGetConferencesURL(id)
    }),
    transformResponse: (res: ConferencesResponse) => transformConferences(res),
    providesTags: ["Conference"]
  })
