import { ArrowLeftOutlined, ArrowRightOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { CollapseProps, Button, List, Flex, Typography, Collapse } from "antd"
import React, { FC } from "react"
import { PermissionsSection } from "@state/reducers/newPolicy"

import { formatedLongNames } from "../../helpers/formatedLongNames"

import style from "./style.m.scss"

type PermissionsCollapseProps = {
  sections: PermissionsSection
  isSecondary?: boolean
  isGroupPage?: boolean
  generateSectionsCallback: (section: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  generatePermissionCallback: (section: string, abilityListId: number) => () => void
}

export const PermissionsCollapse: FC<PermissionsCollapseProps> = ({
  sections,
  isSecondary,
  isGroupPage,
  generatePermissionCallback,
  generateSectionsCallback
}) => {
  const options: CollapseProps["items"] = Object.entries(sections)
    .filter(([, { chosen, idList }]) => (isSecondary ? chosen.length : chosen.length !== idList.length))
    .map(([section, { permissions, chosen }]) => {
      const listData = permissions.filter(({ utilId }) =>
        isSecondary ? chosen.includes(utilId) : !chosen.includes(utilId)
      )

      return {
        key: section,
        label: section,
        extra: (
          <Button
            type="text"
            size="small"
            shape="round"
            icon={isSecondary ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
            onClick={generateSectionsCallback(section)}
          />
        ),
        children: (
          <List
            size="small"
            dataSource={listData}
            renderItem={permission => (
              <List.Item>
                <Flex vertical>
                  <Typography.Text strong>{formatedLongNames(permission.name)}</Typography.Text>
                  <Typography.Text italic>{permission.description}</Typography.Text>
                </Flex>
                <Button
                  type="link"
                  size="small"
                  shape="round"
                  icon={isSecondary ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
                  onClick={generatePermissionCallback(section, permission.utilId)}
                />
              </List.Item>
            )}
          />
        )
      }
    })

  return (
    <Collapse
      className={isGroupPage && isSecondary ? style.sectionsListGroupPage : style.sectionsList}
      size="small"
      accordion
      items={options}
    />
  )
}
