import { UploadFile } from "antd"
import { Rule } from "antd/es/form"

const MAX_FILE_SIZE = 2097152

export const requiredRule: Rule = { required: true, message: "Can't be blank" }

export const urlRule: Rule = {
  type: "url",
  message: "This field must be a valid url"
}

export const checkedRule: Rule = formInstance => ({
  message: "At least one checkbox must be checked",
  validator() {
    const showName = formInstance.getFieldValue("showNameOnBadge")
    const showLogo = formInstance.getFieldValue("showLogoOnBadge")

    if (!showName && !showLogo) {
      return Promise.reject(new Error())
    }
    return Promise.resolve()
  }
})

export const checkedLogoRule: Rule = formInstance => ({
  message: "Can't be blank",
  validator(_, value) {
    const showLogo = formInstance.getFieldValue("showLogoOnBadge")

    if (showLogo && !value) {
      return Promise.reject(new Error())
    }
    return Promise.resolve()
  }
})

export const fileSizeRule: Rule = () => ({
  message: "Maximum allowed file size is 2 MB",
  validator(_, value: UploadFile<any> | string | undefined) {
    if (!value) return Promise.resolve()
    if (typeof value === "string") return Promise.resolve()

    if (value.size && value.size > MAX_FILE_SIZE) {
      return Promise.reject(new Error())
    }
    return Promise.resolve()
  }
})
