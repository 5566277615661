import { TransformedHolidayLog } from "@interfaces"
import { Build } from "@state/services/types"

import { HOLIDAYS_LOGS_URL } from "../urls"
import { transformHolidaysLogs } from "../transformHolidaysLogs"

export const fetchHolidaysLogs = (build: Build) =>
  build.query<Array<TransformedHolidayLog>, { id: number }>({
    query: ({ id }) => ({
      url: HOLIDAYS_LOGS_URL,
      params: {
        "include": "user",
        "filter[location_id]": id
      }
    }),
    forceRefetch: () => true,
    transformResponse: transformHolidaysLogs,
    providesTags: result =>
      result && result.length ? result.map(holiday => ({ type: "HolidayLogs", id: holiday.id })) : ["HolidayLogs"]
  })
