import { BackendSlackGroupsResponse } from "@state/types/slackGroups"
import { Build } from "@state/services/types"
import { SlackGroupWithMemberCount, SlackGroupsParams } from "@interfaces"

import { slackGroupsTransformer } from "../transformer"
import { generateSlackGroupsUrl } from "../urls"

export const fetchSlackGroups = (build: Build) =>
  build.query<Array<SlackGroupWithMemberCount>, SlackGroupsParams>({
    query: params => ({
      url: generateSlackGroupsUrl(params)
    }),
    transformResponse: (data: BackendSlackGroupsResponse) => slackGroupsTransformer(data),
    providesTags: ["SlackGroup"]
  })
