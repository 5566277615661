import React, { FC } from "react"

export const DidlogicLogo: FC = () => (
  <svg width="145" height="36" viewBox="0 0 145 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.9293 28.3161H46.1675V26.9481C45.0732 28.1109 43.7736 28.7264 42.3373 28.7264C38.2335 28.7264 36.1816 25.1014 36.1816 17.9198C36.1816 14.5684 36.7288 11.9693 37.8232 10.191C38.9175 8.41274 40.4906 7.52359 42.5425 7.52359C43.9104 7.52359 45.0732 8.00237 46.0991 9.02831V1.9151L49.8609 0V28.3161H49.9293ZM46.1675 23.3915V12.7901C45.3467 11.559 44.2524 10.9434 42.9529 10.9434C40.9694 10.9434 40.0118 13.2005 40.0118 17.6462C40.0118 22.7076 41.0378 25.1698 43.1581 25.1698C44.4576 25.2382 45.4151 24.6227 46.1675 23.3915Z"
      fill="white"
    />
    <path
      d="M58.6842 2.39383C58.6842 3.0094 58.479 3.55657 58.0002 4.03534C57.5214 4.51412 56.9742 4.71931 56.3587 4.71931C55.7431 4.71931 55.1275 4.51412 54.7172 4.03534C54.2384 3.55657 54.0332 3.0094 54.0332 2.39383C54.0332 1.77827 54.2384 1.1627 54.7172 0.752322C55.1959 0.273548 55.7431 0.0683594 56.3587 0.0683594C56.9742 0.0683594 57.5214 0.273548 58.0002 0.752322C58.479 1.1627 58.6842 1.70987 58.6842 2.39383ZM58.2738 28.316H54.512V7.86554H58.2738V28.316Z"
      fill="white"
    />
    <path
      d="M75.9195 28.3161H72.1577V26.9481C71.0634 28.1109 69.7639 28.7264 68.3275 28.7264C64.2238 28.7264 62.1719 25.1014 62.1719 17.9198C62.1719 14.5684 62.719 11.9693 63.8134 10.191C64.9077 8.41274 66.4808 7.52359 68.5327 7.52359C69.9007 7.52359 71.0634 8.00237 72.0893 9.02831V1.9151L75.8511 0V28.3161H75.9195ZM72.2261 23.3915V12.7901C71.4054 11.559 70.311 10.9434 69.0115 10.9434C67.028 10.9434 66.0705 13.2005 66.0705 17.6462C66.0705 22.7076 67.0964 25.1698 69.2167 25.1698C70.4478 25.2382 71.4738 24.6227 72.2261 23.3915Z"
      fill="white"
    />
    <path d="M84.2637 28.3161H80.502V1.9151L84.2637 0V28.3161Z" fill="white" />
    <path
      d="M102.936 17.9883C102.936 21.4765 102.252 24.1439 100.952 25.9222C99.5843 27.7689 97.806 28.6581 95.4805 28.6581C93.2918 28.6581 91.5135 27.7689 90.2824 26.059C88.9144 24.2123 88.2305 21.6133 88.2305 18.1251C88.2305 14.6368 88.9144 11.9694 90.214 10.0543C91.5135 8.27599 93.2918 7.38684 95.6173 7.38684C97.8743 7.38684 99.5843 8.27599 100.884 9.9859C102.252 11.901 102.936 14.5685 102.936 17.9883ZM99.1055 18.1251C99.1055 13.4057 97.8743 11.0118 95.4805 11.0118C93.0866 11.0118 91.9239 13.4057 91.9239 18.1251C91.9239 22.8444 93.0866 25.1699 95.4805 25.1699C96.6432 25.1699 97.5324 24.5543 98.1479 23.2548C98.8319 21.9552 99.1055 20.2453 99.1055 18.1251Z"
      fill="white"
    />
    <path
      d="M119.693 26.5377C119.693 29.5472 119.077 31.7358 117.846 33.1038C116.547 34.6085 114.358 35.4976 111.349 35.8396L110.323 32.8302C112.58 32.5566 114.084 32.0094 114.837 31.1887C115.589 30.3679 116 29 116 27.0849V26.9481C114.905 28.1108 113.606 28.7264 112.169 28.7264C108.066 28.7264 106.014 25.1014 106.014 17.9198C106.014 14.5684 106.561 11.9693 107.655 10.191C108.75 8.41271 110.323 7.52356 112.375 7.52356C113.742 7.52356 114.905 8.00233 115.931 9.02828V7.93394H119.693V26.5377ZM115.931 23.3915V12.7901C115.11 11.5589 114.016 10.9434 112.717 10.9434C110.733 10.9434 109.775 13.2005 109.775 17.6462C109.775 22.7075 110.801 25.1698 112.922 25.1698C114.153 25.2382 115.179 24.6226 115.931 23.3915Z"
      fill="white"
    />
    <path
      d="M128.448 2.39383C128.448 3.0094 128.243 3.55657 127.764 4.03534C127.285 4.51412 126.738 4.71931 126.122 4.71931C125.507 4.71931 124.891 4.51412 124.481 4.03534C124.002 3.55657 123.797 3.0094 123.797 2.39383C123.797 1.77827 124.002 1.1627 124.481 0.752322C124.96 0.273548 125.507 0.0683594 126.122 0.0683594C126.738 0.0683594 127.285 0.273548 127.764 0.752322C128.243 1.1627 128.448 1.70987 128.448 2.39383ZM128.037 28.316H124.276V7.86554H128.037V28.316Z"
      fill="white"
    />
    <path
      d="M144.999 25.1016C143.495 27.4954 141.511 28.7266 139.049 28.7266C136.86 28.7266 135.15 27.8374 133.919 26.1275C132.62 24.2808 131.936 21.6818 131.936 18.1935C131.936 14.7053 132.551 11.9695 133.851 10.1912C135.15 8.34447 136.928 7.45532 139.254 7.45532C141.716 7.45532 143.631 8.61806 144.999 11.0119L142.332 13.3374C141.374 11.7643 140.28 11.0119 139.117 11.0119C136.86 11.0119 135.697 13.4058 135.697 18.1251C135.697 22.8445 136.86 25.17 139.186 25.17C140.28 25.17 141.306 24.4176 142.332 22.9129L144.999 25.1016Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.30193L13.8844 17.3043V1.29956L0 9.30193Z"
      fill="url(#paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8848 1.2312V17.3043L27.7692 9.30196L13.8848 1.2312Z"
      fill="url(#paint1_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7692 25.3066L13.8848 17.3042L27.7692 9.30188V25.3066Z"
      fill="url(#paint2_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8848 33.3089V17.3042L27.7692 25.3066L13.8848 33.3089Z"
      fill="url(#paint3_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 25.3067L13.8844 17.3043V33.3774L0 25.3067Z"
      fill="url(#paint4_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 25.3066L13.8844 17.3042L0 9.30188V25.3066Z"
      fill="url(#paint5_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7331 21.1344L17.1671 17.3726L23.7331 13.5424L22.16 10.8066L15.594 14.6368V7.0448H12.4478V14.6368L5.88171 10.8066L4.30859 13.5424L10.8746 17.3726L4.30859 21.1344L5.88171 23.8703L12.4478 20.1085V27.6321H15.594V20.0401L22.16 23.8703L23.7331 21.1344Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.48787"
        y1="4.99494"
        x2="15.4572"
        y2="10.9304"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DB1F26" />
        <stop offset="0.2155" stopColor="#E41E25" />
        <stop offset="0.4144" stopColor="#EE2427" />
        <stop offset="0.7735" stopColor="#EE2B2C" />
        <stop offset="1" stopColor="#EF3F43" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="13.0595"
        y1="9.64025"
        x2="23.1534"
        y2="5.06618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C82127" />
        <stop offset="0.6298" stopColor="#EC2225" />
        <stop offset="1" stopColor="#E42327" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="21.0185"
        y1="13.4983"
        x2="28.4117"
        y2="21.2878"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C82127" />
        <stop offset="0.5028" stopColor="#B22024" />
        <stop offset="1" stopColor="#91191C" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="13.8687"
        y1="25.3143"
        x2="27.7617"
        y2="25.3143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D42027" />
        <stop offset="0.6133" stopColor="#BC2025" />
        <stop offset="1" stopColor="#971B1E" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="6.95679"
        y1="29.2309"
        x2="11.852"
        y2="17.073"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A21D21" />
        <stop offset="0.5083" stopColor="#A81E22" />
        <stop offset="1" stopColor="#AD1E23" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="-0.0270165"
        y1="17.311"
        x2="13.8654"
        y2="17.311"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B92025" />
        <stop offset="0.4088" stopColor="#D12027" />
        <stop offset="0.7017" stopColor="#E61E25" />
        <stop offset="1" stopColor="#ED2024" />
      </linearGradient>
    </defs>
  </svg>
)
