import React, { FC, useEffect, useState } from "react"
import { Button, Form, Popconfirm } from "antd"

import { formatedLongNames } from "../../helpers/formatedLongNames"

import { ValidatedAutocomplete, ValidatedTextInput } from "@components/ValidatedInputs"

import style from "./style.m.scss"

const MAX_NAME_LENGTH = 50

type FormData = {
  name: string
}

type CreateFormProps = {
  title: string
  currentValue: {
    name: string
    id?: string
  }
  options: Array<{ value: string; label: string }>
  disabled: boolean
  handleSubmit: (formData: FormData, operation: "Create" | "Update") => Promise<void>
  setCurrentValue: (currentEntityName: string) => void
  reset: () => void
}

const CreateForm: FC<CreateFormProps> = ({
  title,
  currentValue,
  handleSubmit,
  options,
  setCurrentValue,
  disabled,
  reset
}) => {
  const [currentOperation, setCurrentOperation] = useState<"Create" | "Update">("Create")
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)

  useEffect(() => {
    form.setFieldValue("name", currentValue.name)
    setCurrentOperation(currentValue.name ? "Update" : "Create")
  }, [currentValue])

  const [form] = Form.useForm()
  const allEntityNames = options.map(option => option.label)
  const initialFormValues = { policyName: currentValue.name, operation: "Create" }

  const filterOption = (inputValue: string, option: { label: string; value: string }) =>
    option.value.toLowerCase().includes(inputValue.toLowerCase())

  const message = `You are about to ${currentOperation.toLowerCase()} ${
    currentOperation === "Update" ? "current" : ""
  } ${title.toLowerCase()} ${formatedLongNames(currentValue.name)}. Are you sure?`

  const handleReset = () => {
    setCurrentOperation("Create")
    form.resetFields()
    reset()
  }

  const handleSelect = (value: string) => {
    setCurrentValue(value)
    setCurrentOperation(allEntityNames.includes(value) ? "Update" : "Create")
  }

  const onConfirm = () => {
    form.submit()
  }

  const deriveDisabledCondition = () => {
    const errors = form.getFieldError("name")

    if (errors.length) {
      setIsSubmitButtonDisabled(true)
      return
    }
    setIsSubmitButtonDisabled(false)
  }

  return (
    <>
      <Form
        className={style.form}
        form={form}
        layout="inline"
        onFinish={(formData: FormData) => handleSubmit(formData, currentOperation)}
        onFieldsChange={deriveDisabledCondition}
        initialValues={initialFormValues}
      >
        <Form.Item
          className={style.input}
          label={`${title} name`}
          name="name"
          rules={[
            { required: true, message: "Required field" },
            { max: MAX_NAME_LENGTH, message: "Must be shorter than 50 symbols" },
            {
              validator: (_, value) => {
                const isDuplicate = options.map(option => option.value).includes(value)
                if (isDuplicate && currentOperation !== "Update")
                  return Promise.reject(new Error("Name is already in use"))
                return Promise.resolve()
              }
            }
          ]}
        >
          {!currentValue.name ? (
            <ValidatedAutocomplete options={options} filterOption={filterOption} onSelect={handleSelect} />
          ) : (
            <ValidatedTextInput />
          )}
        </Form.Item>
        <Form.Item name="operation">
          <Popconfirm title={message} onConfirm={onConfirm}>
            <Button type="primary" disabled={disabled || isSubmitButtonDisabled}>
              {currentOperation}
            </Button>
          </Popconfirm>
        </Form.Item>
        <Form.Item className={style.reset}>
          <Button type="default" htmlType="reset" onClick={handleReset}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default CreateForm
