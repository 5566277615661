import { Build } from "@state/services/types"
import { BackendNotificationSettingsOptions } from "@state/types/users"

import { generateUserNotificationSettingsOptionsURL } from "../urls"

export const fetchNotificationSettingsOptions = (build: Build) =>
  build.query<Array<string>, string>({
    query: userId => ({
      url: generateUserNotificationSettingsOptionsURL(userId)
    }),
    providesTags: ["NotificationSettingsOptions"],
    transformResponse: (response: BackendNotificationSettingsOptions) => response.slack_settings
  })
