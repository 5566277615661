import * as yup from "yup"

import { validationErrors } from "../validationErrors"
import { emailReg, nameReg, nicknameReg } from "../validationRegex"

export const profileSchema = yup.object().shape({
  lastName: yup
    .string()
    .trim()
    .matches(nameReg, validationErrors.VALID_NAME_ERROR)
    .required(validationErrors.REQUIRED_ERROR),
  firstName: yup
    .string()
    .trim()
    .matches(nameReg, validationErrors.VALID_NAME_ERROR)
    .required(validationErrors.REQUIRED_ERROR),
  patronymic: yup.string().matches(nameReg, validationErrors.VALID_NAME_ERROR).trim(),
  additionalEmails: yup
    .array()
    .of(yup.string().required(validationErrors.REQUIRED_ERROR).matches(emailReg, validationErrors.VALID_EMAIL_ERROR)),
  position: yup
    .string()
    // .matches(multiNameReg, validationErrors.VALID_LANGUAGE_ERROR) uncomment before release
    .trim()
    .required(validationErrors.REQUIRED_ERROR),
  birthDate: yup.date(),
  slack: yup.string().matches(nicknameReg, validationErrors.VALID_NICKNAME_ERROR).trim(),
  gitlab: yup.string().matches(nicknameReg, validationErrors.VALID_NICKNAME_ERROR).trim(),
  location: yup.string().trim().required(validationErrors.REQUIRED_ERROR),
  // .matches(cityNameReg, validationErrors.VALID_LANGUAGE_ERROR), // uncomment before release
  department: yup.string().required(validationErrors.REQUIRED_ERROR)
})
