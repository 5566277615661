import React, { FC, useState } from "react"
import { Flex, Table } from "antd"
import { useCertificatesAbilities } from "hooks/query/useAbilities"
import { certificatesApi } from "@state/services/subApis"

import { SectionWrapper } from "@components/SectionWrapper"
import { SaveButton } from "@components/buttons/SaveButton"
import { EditButton } from "@components/buttons/EditButton"
import { Title } from "@components/Title"

import { useCertificatesTableColumns } from "./helpers/useCertificatesTableColumns"
import { FileForm } from "./FileForm"

type CertificatesProps = {
  userId: string
}

const Certificates: FC<CertificatesProps> = ({ userId }) => {
  const { edit_all } = useCertificatesAbilities()
  const [editMode, setEditMode] = useState(false)

  const { data = [], isLoading, isFetching } = certificatesApi.endpoints.fetchCertificates.useQuery(userId)

  const columns = useCertificatesTableColumns(editMode)

  const handleEdit = () => {
    setEditMode(true)
  }

  const handleSave = () => {
    setEditMode(false)
  }

  if (!edit_all && data.length === 0) return null

  return (
    <SectionWrapper>
      <Title>
        <Flex align="center" justify="space-between">
          Training and Certificate
          {editMode ? <SaveButton onClick={handleSave} /> : <EditButton onClick={handleEdit} disabled={!edit_all} />}
        </Flex>
      </Title>
      {editMode && <FileForm userId={userId} />}
      <Table
        columns={columns}
        dataSource={data}
        rowKey="certificateId"
        loading={isLoading || isFetching}
        pagination={false}
      />
    </SectionWrapper>
  )
}

export default Certificates
