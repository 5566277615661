import { SalaryHistoryItem } from "@interfaces"
import { BackendUserSalariesHistory } from "@state/types/users"

export const transformSalaryHistory = (response: BackendUserSalariesHistory): Array<SalaryHistoryItem> =>
  response.salaries.map(entity => {
    const { id, date_of_acceptance } = entity

    return {
      id: String(id),
      dateOfAcceptance: date_of_acceptance
    }
  })
