import { Tooltip, TooltipProps } from "antd"
import React, { FC } from "react"

const MAX_NAME_LENGTH = 30

export const Name: FC<{ name: string; nameLength?: number; tooltipPlacement?: TooltipProps["placement"] }> = ({
  name,
  nameLength = MAX_NAME_LENGTH,
  tooltipPlacement = "top"
}) => {
  const isLong = name.length >= nameLength

  return isLong ? (
    <Tooltip placement={tooltipPlacement} title={name}>{`${name.slice(0, nameLength)}...`}</Tooltip>
  ) : (
    <span>{name}</span>
  )
}
