import { useEffect } from "react"
import { batch } from "react-redux"
import { useAlert, useAppDispatch, useAppSelector } from "@hooks"
import { AuthErrorResponse } from "@interfaces"
import { meActions } from "@state/reducers/me"
import { myAbilitiesActions } from "@state/reducers/myAbilities"
import { abilitiesTransformer, authenticationApi } from "@state/services/subApis"

const LOADER_ERROR = "Error loading abilities"

export const useAppLoader = () => {
  const dispatch = useAppDispatch()
  const { error } = useAlert()
  const loadedAbilities = useAppSelector(state => state.myAbilitiesReducer.abilities)

  const [fetchMe] = authenticationApi.endpoints.fetchMe.useLazyQuery()

  const initUserAbilities = async () => {
    try {
      const response = await fetchMe().unwrap()

      const { abilities } = response.data.attributes
      const transformedAbilities = abilitiesTransformer(abilities)

      batch(() => {
        dispatch(meActions.setMe({ user: response.data }))
        dispatch(myAbilitiesActions.setMyAbilities({ abilities: transformedAbilities }))
      })
    } catch (err) {
      const { data } = err as AuthErrorResponse
      const errorDescription = data?.["field-error"]?.[1]

      error(errorDescription ?? data?.error ?? LOADER_ERROR)
    }
  }

  useEffect(() => {
    if (!loadedAbilities) initUserAbilities()
  }, [])

  return { isLoaded: Boolean(loadedAbilities) }
}
