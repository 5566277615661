import { Col, DatePicker, Flex, Form, Popconfirm, Row, Tooltip } from "antd"
import React, { FC, useEffect } from "react"
import { DepartmentCard } from "@interfaces*"
import dayjs from "dayjs"
import { generateDepartmentRoute } from "@routes/inboundRoutes"
import { Link } from "react-router-dom"
import { DepartmentStatus } from "@enums"
import { productOptions } from "@helpers"

import { Header } from "../../components/Header"

import { SearchSelect } from "@components/SearchSelect"
import { ValidatedTextArea, ValidatedTextInput } from "@components/ValidatedInputs"
import { SaveButton } from "@components/buttons/SaveButton"
import { CancelButton } from "@components/buttons/CancelButton"
import { FormField } from "@components/FormField"
import { Field } from "@components/Field"

import { StatusField } from "./StatusField"
import { useDepartmentFormViewState } from "./helpers/useDepartmentFormViewState"
import style from "./style.m.scss"

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  }
}

type DepartmentFormProps = {
  department?: DepartmentCard
  createMode?: boolean
  isCEO?: boolean
  toggleEditMode?: () => void
}

const DepartmentForm: FC<DepartmentFormProps> = ({ department, createMode, isCEO, toggleEditMode }) => {
  const {
    parentDepartmentsOptions,
    form,
    isPopconfirmVisible,
    isProcessing,
    userOptions,
    productsValidator,
    onFormSubmit,
    onPopconfirmCancel,
    onPopconfirmOk
  } = useDepartmentFormViewState(department, createMode, isCEO, toggleEditMode)

  const initialFormValues = createMode
    ? { status: DepartmentStatus.Active, startDate: dayjs(new Date()) }
    : {
        name: department?.name,
        closed_at: department?.status,
        chief: department?.chief ? department.chief.chiefId : null,
        parentDept: department?.parentDept ? department.parentDept.id : null,
        product: department?.product ?? null,
        status: department?.status,
        description: department?.description,
        startDate: dayjs(department?.startDate)
      }

  const handleCancelClick = () => {
    toggleEditMode?.()
  }

  useEffect(() => {
    if (!createMode) {
      form.validateFields()
    }
  }, [department])

  return (
    <Form {...formItemLayout} initialValues={initialFormValues} form={form} onFinish={onFormSubmit}>
      <div className={style.container}>
        <Header>
          {department?.name ?? "Department"}
          <Flex>
            <Popconfirm
              title="Are you sure you want to proceed?"
              open={isPopconfirmVisible}
              onConfirm={onPopconfirmOk}
              okButtonProps={{ loading: isProcessing }}
              onCancel={onPopconfirmCancel}
            >
              <SaveButton htmlType="submit" datatest-id="form-save-button" />
            </Popconfirm>

            <CancelButton onClick={handleCancelClick} datatest-id="form-cancel-button" />
          </Flex>
        </Header>

        <Row gutter={24}>
          <Col span={10}>
            <FormField
              label="Dept. name"
              fieldName="name"
              rules={[{ required: true, message: "can't be blank" }]}
              editor={
                <ValidatedTextInput datatest-id="form-dept-name" placeholder="Department name" disabled={isCEO} />
              }
            />
            <FormField
              label="Start date"
              fieldName="startDate"
              editor={
                <DatePicker
                  datatest-id="form-start-date"
                  style={{ width: "200px" }}
                  format="YYYY/MM/DD"
                  disabled={isCEO}
                  allowClear={false}
                />
              }
            />
            <FormField
              label="Status"
              fieldName="status"
              editor={<StatusField department={department} isCEO={isCEO} />}
            />
            <FormField
              label={isCEO ? "Employee" : "Head of dept."}
              fieldName="chief"
              editor={
                <SearchSelect
                  datatest-id="form-head-of-dept"
                  options={userOptions}
                  placeholder={isCEO ? "Select employee" : "Select head of dept."}
                />
              }
              rules={[
                {
                  validator: productsValidator,
                  warningOnly: true
                }
              ]}
            />
            <FormField
              label="Parent dept."
              fieldName="parentDept"
              rules={!isCEO ? [{ required: true, message: "can't be blank" }] : []}
              editor={
                <SearchSelect
                  datatest-id="form-parent-dept"
                  options={parentDepartmentsOptions}
                  placeholder="Select parent dept."
                  disabled={isCEO}
                />
              }
            />
            <FormField
              label="Product"
              fieldName="product"
              rules={!isCEO ? [{ required: true, message: "can't be blank" }] : []}
              editor={
                <SearchSelect
                  datatest-id="form-product"
                  options={productOptions}
                  placeholder="Select product"
                  disabled={isCEO}
                />
              }
            />
          </Col>
          <Col span={12}>
            <FormField
              label="Description"
              fieldName="description"
              align="top"
              editor={<ValidatedTextArea datatest-id="form-description" rows={4} />}
            />
            <Field
              title="Subordinate dept"
              value={
                department?.subordinateDepts.length ? (
                  <div className={style.subdepts}>
                    {department.subordinateDepts.map(subDept => (
                      <Tooltip key={subDept.value} placement="top" title={subDept.label}>
                        <Link to={generateDepartmentRoute(String(subDept.value))}>{subDept.label}</Link>
                        <br />
                      </Tooltip>
                    ))}
                  </div>
                ) : (
                  "No subordinate departments"
                )
              }
            />
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export default DepartmentForm
