import { AccessGroupLogType } from "@interfaces*"

export const GROUPS_URL = "/access_groups"
export const MANAGE_ACCESS_GROUPS_URL = `${GROUPS_URL}/manage`

export const ACCESS_GROUPS_LOGS_URL = "role_system/logs"

export const generateGetGroupsURL = (userId?: string) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("extra_fields[access_groups]", "in_use,full_permissions,users_data")
  searchParams.set("extra_fields[policies]", "full_permissions")
  searchParams.set("include", "policies")
  if (userId) searchParams.set("filter[user_id]", userId)

  return `${GROUPS_URL}${separator}${searchParams.toString()}`
}

export const generateAccessGroupURL = (id: string) => `${GROUPS_URL}/${id}`

export const generateAccessGroupLogsURL = (type: AccessGroupLogType) => {
  const searchParams = new URLSearchParams()
  const separator = "?"

  searchParams.set("include", "user")
  searchParams.set("filter[record_type]", type)
  searchParams.set("fields[users]", "email")

  return `${ACCESS_GROUPS_LOGS_URL}${separator}${searchParams.toString()}`
}
