export enum Section {
  Profile = "profiles",
  Projects = "projects",
  Teams = "projects/teams",
  Leads = "projects/leads",
  ProjectsLogs = "projects/logs",
  ProjectsHistory = "projects/history",
  Users = "users",
  Vacations = "vacations",
  OrgChart = "orgChart",
  Internship = "internship",
  Relationships = "relationships",
  Interns = "intern_profiles",
  Mentors = "mentors",
  Abilities = "abilities",
  Departments = "departments",
  Permissions = "permissions",
  VacationsHolidays = "vacations/holidays",
  VacationSettings = "vacation_settings",
  VacationAccumulations = "vacation_accumulations",
  Roles = "roles",
  VacationsAssignmentHrs = "vacations/assignment_hrs",
  SlackGroups = "slack_groups",
  SlackUsers = "slack_users",
  Locations = "locations",
  UserProfileEmploymentInfo = "user_profile_employment_info",
  EmploymentSettings = "employment_settings",
  EducationalMaterials = "education_materials",
  Salaries = "salaries",
  JobHistory = "job_history",
  Certificates = "certificates",
  Conferences = "conferences",
  PerformanceReviews = "performance_reviews",
  BannedIps = "banned_ips",
  PersonalSettings = "personal_settings",
  UserCommission = "user_commission",
  AvailableSystem = "available_systems"
}
