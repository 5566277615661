import backendApi from "@state/services/backendApi"

import { fetchCertificates } from "./GET/fetchCertificates"
import { createCertificate } from "./POST/createCertificate"
import { deleteCertificate } from "./DELETE/deleteCertificate"

export const certificatesApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchCertificates: fetchCertificates(build),
    createCertificate: createCertificate(build),
    deleteCertificate: deleteCertificate(build)
  })
})
