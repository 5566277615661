import { EmploymentSettings, EmploymentSettingsEntity, UpdateSettingRequestBody } from "@interfaces*"
import { Button, Col, Flex, Form, Row, Typography } from "antd"
import React, { FC, useState } from "react"
import { capitalizeFirstLetter, defaultError } from "@helpers"
import { useAlert } from "@hooks"
import { employmentSettingsApi } from "@state/services/subApis"

import { ValidatedTextInput } from "@components/ValidatedInputs/ValidatedTextInput"
import { EditButton } from "@components/buttons/EditButton"
import { DeleteButton } from "@components/buttons/DeleteButton"
import { SaveButton } from "@components/buttons/SaveButton"
import { CancelButton } from "@components/buttons/CancelButton"

import style from "./style.m.scss"

type EmploymentItemProps = {
  settings: Array<EmploymentSettingsEntity>
  type: string
  systemValues: Array<string>
}

const initialValues = {
  name: ""
}

const EmploymentItem: FC<EmploymentItemProps> = ({ settings, type, systemValues }) => {
  const [editingId, setEditingId] = useState<string | null>(null)
  const [newDescription, setNewDescription] = useState("")
  const { error, success } = useAlert()

  const [updateSetting] = employmentSettingsApi.endpoints.updateEmploymentSetting.useMutation()

  const [deleteSetting] = employmentSettingsApi.endpoints.deleteEmploymentSetting.useMutation()

  const [createSetting] = employmentSettingsApi.endpoints.createEmploymentSetting.useMutation()

  const [form] = Form.useForm()
  const title = capitalizeFirstLetter(type.split("_").join(" "))

  const handleEdit = (id: string, name: string) => {
    setEditingId(id)
    setNewDescription(name)
  }

  const handleCancel = () => {
    setEditingId(null)
  }

  const handleDescriptionChange = (value: string) => {
    setNewDescription(value)
  }

  const handleSave = async (setting: EmploymentSettingsEntity) => {
    if (setting.description === newDescription) {
      setEditingId(null)
      return
    }

    const bodyReq: UpdateSettingRequestBody = {
      data: {
        id: setting.id,
        type: setting.type,
        attributes: {
          description: newDescription
        }
      },
      format: ":jsonapi" as const
    }

    try {
      await updateSetting(bodyReq).unwrap()
      setEditingId(null)
      success("Employment setting was succesfully updated")
    } catch (err) {
      const errorText = err as string
      error(errorText)
    }
  }

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteSetting({ id, type }).unwrap()
      success(res.message)
    } catch {
      error(defaultError)
    }
  }

  const handleSubmit = async (formData: { name: string }) => {
    if (!formData.name) return

    const bodyReq = {
      data: {
        type: type as keyof EmploymentSettings,
        attributes: {
          description: formData.name
        }
      },
      format: ":jsonapi" as const
    }

    try {
      await createSetting(bodyReq).unwrap()
      form.resetFields()
      success("Employment setting was succesfully created")
    } catch (err) {
      const errorText = err as string
      error(errorText)
    }
  }

  return (
    <Flex vertical justify="center" key={type}>
      <Typography.Title level={4} className={style.header}>
        {title}
      </Typography.Title>
      <Form form={form} initialValues={initialValues} onFinish={handleSubmit} className={style.form}>
        <Row gutter={12} justify="space-between">
          <Col span={18}>
            <Form.Item name="name" label={`Add new ${type.split("_").join(" ")}`} labelCol={{ span: 24 }}>
              <ValidatedTextInput />
            </Form.Item>
          </Col>
          <Col span={4} className={style.buttonWrapper}>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Col>
        </Row>
      </Form>
      <Flex vertical>
        <Typography.Title level={5} className={style.header}>
          {title}
        </Typography.Title>
        <div className={style.listWrapper}>
          {settings.map(setting => (
            <Row className={style.itemContainer} justify="space-between" key={setting.id}>
              <Col span={16} className={style.listItem}>
                {setting.id === editingId ? (
                  <div className={style.inputWrapper}>
                    <ValidatedTextInput value={newDescription} onChange={handleDescriptionChange} />
                  </div>
                ) : (
                  <div className={style.text}>{setting.description}</div>
                )}
              </Col>
              <Col span={5}>
                <Flex justify="space-between" align="center">
                  {editingId === setting.id && (
                    <>
                      <SaveButton onClick={() => handleSave(setting)} />
                      <CancelButton onClick={handleCancel} />
                    </>
                  )}

                  {!systemValues.includes(setting.description) && editingId !== setting.id && (
                    <EditButton onClick={() => handleEdit(setting.id, setting.description)} />
                  )}
                  {!systemValues.includes(setting.description) && (
                    <DeleteButton
                      onConfirm={() => handleDelete(setting.id)}
                      tooltipText={setting.inUse ? "This value is used in user profiles" : undefined}
                      disabled={setting.inUse}
                    />
                  )}
                </Flex>
              </Col>
            </Row>
          ))}
        </div>
      </Flex>
    </Flex>
  )
}

export default EmploymentItem
