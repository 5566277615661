import React, { FC, useState } from "react"
import { Flex, Table } from "antd"
import { useConferencesAbilities } from "hooks/query/useAbilities"
import { conferencesApi } from "@state/services/subApis"

import { SectionWrapper } from "@components/SectionWrapper"
import { SaveButton } from "@components/buttons/SaveButton"
import { EditButton } from "@components/buttons/EditButton"
import { Title } from "@components/Title"

import { AddConference } from "./AddConference"
import { useConferencesTableColumns } from "./helpers/useConferencesTableColumns"
import style from "./style.m.scss"

type ConferencesProps = {
  userId: string
}

const Conferences: FC<ConferencesProps> = ({ userId }) => {
  const [editMode, setEditMode] = useState(false)

  const { data = [], isLoading, isFetching } = conferencesApi.endpoints.fetchConferences.useQuery(userId)

  const { edit_all } = useConferencesAbilities()

  const handleEditClick = () => {
    setEditMode(true)
  }

  const handleSaveClick = () => {
    setEditMode(false)
  }

  const columns = useConferencesTableColumns(editMode)

  if (!edit_all && data.length === 0) return null

  return (
    <SectionWrapper>
      <Title>
        <Flex align="center" justify="space-between" className={style.header}>
          Conferences
          <div>
            {editMode ? (
              <SaveButton onClick={handleSaveClick} />
            ) : (
              <EditButton onClick={handleEditClick} disabled={!edit_all} />
            )}
          </div>
        </Flex>
      </Title>
      {editMode && <AddConference />}
      {!!data?.length && (
        <Table columns={columns} dataSource={data} rowKey="id" loading={isLoading || isFetching} pagination={false} />
      )}
    </SectionWrapper>
  )
}

export default Conferences
