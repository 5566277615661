import backendApi from "@state/services/backendApi"

import { fetchThingsToDo } from "./GET/fetchThingsToDo"
import { fetchDashboardAvailableSystems } from "./GET/fetchDashboardAvailableSystems"
import { fetchCurrentReviews } from "./GET/fetchCurrentReviews"

export const dashboardApi = backendApi.injectEndpoints({
  endpoints: build => ({
    fetchThingsToDo: fetchThingsToDo(build),
    fetchDashboardAvailableSystems: fetchDashboardAvailableSystems(build),
    fetchCurrentReviews: fetchCurrentReviews(build)
  })
})
