import { Build } from "@state/services/types"

import { generatePersonalDocumentURL } from "../urls"

export const deletePersonalDocument = (build: Build) =>
  build.mutation<unknown, string>({
    query: id => ({
      url: generatePersonalDocumentURL(id),
      method: "DELETE"
    }),
    invalidatesTags: ["PersonalDocument"]
  })
