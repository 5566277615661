import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react"
import { Coordinates } from "@interfaces*"

import { offsetCalc } from "../helpers/offsetCalc"
import { QUARTER } from "../helpers/constants"

import style from "./style.m.scss"
import { Scroll } from "./Scroll"

type Props = { coords: Coordinates; rootClassName: string; setTranslate: Dispatch<SetStateAction<Coordinates>> }

const X_POSITION = 0
const Y_POSITION = 1

const getElementSizes = (className: string) =>
  document.getElementsByClassName(className)[0]?.getBoundingClientRect() || {
    height: 0,
    width: 0
  }

const getElementTranslate = (className: string) =>
  (document.getElementsByClassName(className)[0]?.getAttribute("transform") || "0,0")
    .split(" ")[0]
    .replace(/[translate()]/g, "")
    .split(",")
    .map(string => +string)

export const OrgChartScrolls = ({ coords, rootClassName, setTranslate }: Props) => {
  const treeSizes = getElementSizes("rd3t-g")
  const rootSizes = getElementSizes(rootClassName)
  const centerAdjustmentY = treeSizes.height / QUARTER

  const handleClick = useCallback(() => {
    const treeTranslate = getElementTranslate("rd3t-g")
    const treeSizes = getElementSizes("rd3t-g")
    const rootSizes = getElementSizes(rootClassName)
    const centerAdjustmentY = treeSizes.height / QUARTER

    const diffX =
      offsetCalc(treeTranslate[X_POSITION], { container: rootSizes.width, content: treeSizes.width }) -
      treeTranslate[X_POSITION]
    const diffY =
      offsetCalc(treeTranslate[Y_POSITION], {
        container: rootSizes.height,
        content: treeSizes.height
      }) - treeTranslate[Y_POSITION]

    const isInRootView = diffX === 0 && diffY === 0

    if (!isInRootView) setTranslate(prev => ({ x: prev.x + diffX, y: prev.y + diffY - centerAdjustmentY }))
  }, [setTranslate])

  useEffect(() => {
    let eventFlag = ""

    const handleTreeMove = (event: PointerEvent) => {
      event.preventDefault()
      setTranslate(prev => ({ x: prev.x + event.movementX, y: prev.y + event.movementY }))
    }

    const handleBottomMove = (event: PointerEvent) => {
      event.preventDefault()
      setTranslate(prev => ({ x: prev.x + event.movementX, y: prev.y }))
    }

    const handleRightMove = (event: PointerEvent) => {
      event.preventDefault()
      setTranslate(prev => ({ x: prev.x, y: prev.y + event.movementY }))
    }

    const onBottomDown = (e: PointerEvent) => {
      if (e.target instanceof Element) {
        const className = e.target.classList[0]

        switch (className) {
          case "rd3t-svg":
            e.preventDefault()
            document.addEventListener("pointermove", handleTreeMove)
            eventFlag = "rd3t-svg"
            break
          case "right":
            e.preventDefault()
            document.addEventListener("pointermove", handleRightMove)
            eventFlag = "right"
            break
          case "bottom":
            e.preventDefault()
            document.addEventListener("pointermove", handleBottomMove)
            eventFlag = "bottom"
            break
          default:
            break
        }
      }
    }

    const onBottomUp = () => {
      switch (eventFlag) {
        case "rd3t-svg":
          document.removeEventListener("pointermove", handleTreeMove)
          break
        case "right":
          document.removeEventListener("pointermove", handleRightMove)
          break
        case "bottom":
          document.removeEventListener("pointermove", handleBottomMove)
          break
        default:
          break
      }
      eventFlag = ""
    }

    document.addEventListener("pointerdown", onBottomDown)
    document.addEventListener("pointerup", onBottomUp)

    return () => {
      document.removeEventListener("pointerdown", onBottomDown)
      document.removeEventListener("pointerup", onBottomUp)
    }
  }, [])

  return (
    <>
      <Scroll
        className={style.bottomScroll}
        position="bottom"
        sizes={{ content: treeSizes.width, container: rootSizes.width }}
        value={coords.x}
        onClick={handleClick}
      />
      <Scroll
        className={style.rightScroll}
        position="right"
        sizes={{ content: treeSizes.height, container: rootSizes.height }}
        value={coords.y + centerAdjustmentY}
        onClick={handleClick}
      />
    </>
  )
}
