import { Build } from "@state/services/types"
import { BackendVacationAccumualationsOptionsResponse } from "@state/types/vacations"

import { generateVacationAccumulationsOptionsURL } from "../urls"

export const fetchVacationAccumulationsOptions = (build: Build) =>
  build.query<BackendVacationAccumualationsOptionsResponse, string>({
    query: id => ({
      url: generateVacationAccumulationsOptionsURL(id)
    })
  })
